import Vue from 'vue'

Vue.mixin({
    methods: {

        /**
         * Permet de regrouper une collection de données dans le but de l'adapter à l'utilisation d'un multiselect
         * Usage : this.groupForMultiselect(list, [object.key])
         *      Multiselect :
         *          group-label="group"
         *          group-values="items"
         *          :group-select="false"
         * Permet de regrouper une collection de données dans le but de l'adapter à l'utilisation d'un Multiselect
         * Configuration du Multiselect :
         *      :multiple="true"
         *      :group-select="true"
         *      group-values="items"
         *      group-label="group"
         * @param {array} source
         * @param {array} keys
         * @param itemSortKey
         * @param join
         * @returns
         */
        groupForMultiselect(source, keys, itemSortKey = null, join = ' - ') {

            let grouped = [];
            let temp = [];

            // Regroupement de données
            for (let i in source) {
                let item = source[i];
                let group_key_values = [];
                let concat_key = '';

                // Préparation du nom du groupe
                for (let j in keys) {
                    let key = keys[j];
                    group_key_values.push(data_get(item, key));
                }
                concat_key = group_key_values.join(join);

                if(!temp[concat_key]) {
                    temp[concat_key] = [];
                }

                temp[concat_key].push(item);
            }

            // Formatage du rendu pour le multiselect
            for (let i in temp) {
                let group = {};

                group['group'] = i;
                group['items'] = itemSortKey ?
                    temp[i].sort(function (a, b) {
                        return data_get(a, itemSortKey)?.localeCompare(data_get(b, itemSortKey));
                    })
                    : temp[i];

                grouped.push(group);
            }

            return grouped;
        }
    }
})
