<template>
    <div class="select">
        <h4>
            Identifier les personnes en capacité de répondre aux attendus d’un
            métier, d’un poste ou d’un savoir-faire
        </h4>
        <p>
            Pour chaque métier, poste ou savoir-faire sélectionné, ce tableau de
            bord permet d’identifier les personnes en capacité d’y accéder en
            sécurité.
        </p>
        <!-- Filtre de recherche -->
        <vx-card class="mb-4 mt-3">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row class="mb-6">
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un métier</label
                            >
                            <multiselect
                                v-if="occupationsList.length > 0"
                                v-model="occupationFilter"
                                :options="occupationsList"
                                :multiple="false"
                                group-values="occupations"
                                group-label="type"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :disabled="disabledOccupationFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un poste</label
                            >
                            <multiselect
                                v-if="groupedJobsByService.length > 0"
                                v-model="jobFilter"
                                :options="groupedJobsByService"
                                :multiple="false"
                                group-values="jobs"
                                group-label="service"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label="Etablissement/service"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledJobFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un savoir-faire</label
                            >
                            <multiselect
                                v-if="shownOccupationSkills"
                                v-model="occupationskillFilter"
                                :options="shownOccupationSkills"
                                :multiple="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="titleEstablishment"
                                select-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                                <span slot="noOptions">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>

                    <vs-row class="checkboxes-container">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox v-model="wishlistOnly">
                                Souhaités uniquement
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox v-model="checkOnly">
                                Accessibles en sécurité
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox
                                v-model="filterByUserRelated"
                                :disabled="disabledFilterByUserRelated"
                            >
                                Mes savoir-faire uniquement
                            </vs-checkbox>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un service</label
                            >
                            <multiselect
                                v-if="
                                    groupedServicesByEstablishments.length > 0
                                "
                                v-model="selectedServices"
                                :options="groupedServicesByEstablishments"
                                :multiple="true"
                                group-values="services"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label="L'établissement"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledServiceFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner une personne</label
                            >
                            <multiselect
                                v-if="
                                    groupedEmployeesByEstablishmentsAndFilteredByServices.length >
                                        0
                                "
                                v-model="employeeFilter"
                                :options="
                                    groupedEmployeesByEstablishmentsAndFilteredByServices
                                "
                                :multiple="true"
                                group-values="employees"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label=""
                                select-group-label="L'établissement"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledEmployeeFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mt-6">
            <vs-row v-if="orderedEmployeesAccessiblityCharts.length > 0">
                <ul
                    id="employee-jobs-show"
                    style="columns:4;-webkit-columns:4;-moz-columns:4;"
                >
                    <li
                        v-for="chart in orderedEmployeesAccessiblityCharts"
                        :key="chart.employee.id + Math.random()"
                    >
                        <a @click="scrollTo('chart-' + chart.employee.id)">
                            <span>{{
                                chart.options.title.text.split(":")[0]
                            }}</span
                            >:
                            <b class="text-primary">{{
                                round(chart.scoreNumber * 100, 0) + "% "
                            }}</b>
                        </a>
                        <b v-if="chart.check" style="color: green;">✓</b>
                    </li>
                </ul>
            </vs-row>
        </vx-card>
        <vx-card class="mt-6">
            <vs-row v-if="orderedEmployeesAccessiblityCharts.length > 0">
                <vs-col
                    v-for="chart in orderedEmployeesAccessiblityCharts"
                    :id="`chart-${chart.employee.id}`"
                    :key="chart.employee.id + Math.random()"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="6"
                    class="mt-6"
                >
                    <rating-chart
                        :employee="chart.employee"
                        class="rating"
                        :options="chart.options"
                        :chart-data="chart.data"
                    />
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import RatingChart from "@components/app/RatingChart";
import ratingService from "@components/services/ratings";

export default {
    name: "DashboardOccupationJobs",
    components: {
        RatingChart,
        Multiselect
    },
    mixins: [ratingChartsHelper],

    props: {
        skills: {
            type: Array,
            require: true
        },
        adminSkills: {
            type: Array,
            require: true
        },
        skill_occupationSkill: {
            type: Array,
            require: true
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true
        }
    },

    data() {
        return {
            errors: [],
            ratings: [],
            loading: false,
            employeeFilter: "",
            disabledEmployeeFilter: true,
            selectedServices: "",
            disabledServiceFilter: true,
            selectedOccupationSkills: null,
            jobFilter: "",
            disabledJobFilter: false,
            occupationskillFilter: "",
            occupationFilter: "",
            disabledOccupationFilter: false,
            wishlistOnly: false,
            checkOnly: false,
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: true,
                    text: `placeholder`,
                    fontSize: 16
                },
                scale: {
                    ticks: {
                        min: -0.5,
                        max: 3,
                        stepSize: 1
                    }
                }
            },
            filterByUserRelated: true,
            disabledFilterByUserRelated: false,
            employees: [],
            jobs: [],
            occupations: [],
            occupationskills: [],
            adminOccupationSkills: [],
            establishments: [],
            colors: [],
            referentialOccupations: []
        };
    },

    async created() {
        this.loading = true;

        await Promise.all([
            this.loadColors(),
            this.loadEmployees(),
            this.loadJobs(),
            this.loadOccupations(),
            this.loadOccupationskills(),
            this.loadAdminOccupationSkills(),
            this.loadReferentialOccupations(),
            this.loadEstablishment()
        ]);
    },

    computed: {
        employeeIds() {
            if (!this.employees?.length) return [];
            return this.employees.map(e => e.id);
        },

        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                const params = Object.keys(this.ratingSettings.params).map(
                    id => {
                        var param = this.ratingSettings.params[id];
                        param.id = id;
                        return param;
                    }
                );
                return params;
            }
            return [];
        },

        // A factoriser, problème de chargement des etablissements lorsque l'on met la fonction dans establishments.js
        shownEstablishments() {
            // Liste des établissements basée sur le périmètre de consultation des personnes
            if (this.establishments && this.establishments.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    const userEstablishments = this.establishments
                        .slice()
                        .filter(e =>
                            userServices
                                .map(service => service.establishment_id)
                                .includes(e.id)
                        );
                    return userEstablishments;
                }
            }
            return [];
        },

        shownOccupationSkills() {
            let occupationskills = [];
            if (this.jobFilter) {
                //Only Client OccupationSkills
                let jobOccupationSkills = this.jobFilter.occupation_skills
                    ? this.jobFilter.occupation_skills
                    : this.joboccupationskills(this.jobFilter.id);
                occupationskills = jobOccupationSkills
                    .slice()
                    .sort((a, b) => +a.pivot.order - +b.pivot.order);
            } else if (this.occupationFilter) {
                if (this.occupationFilter.admin_id) {
                    occupationskills = this.occupationFilter
                        .admin_occupation_skills
                        ? this.occupationFilter.admin_occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                } else {
                    occupationskills = this.occupationFilter.occupation_skills
                        ? this.occupationFilter.occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                }
            } else {
                if (!this.occupationskills) {
                    return [];
                }

                //Only Client OccupationSkills
                occupationskills = this.occupationskills
                    .slice()
                    .sort((a, b) => {
                        return a.title.localeCompare(b.title, "fr", {
                            sensitivity: "base"
                        });
                    });
            }

            if (this.filterByUserRelated) {
                occupationskills = occupationskills.filter(os => {
                    return (
                        !os.establishment_id ||
                        this.userEstablishmentsIds.includes(os.establishment_id)
                    );
                });
            }

            return occupationskills.map(os => ({
                ...os,
                titleEstablishment:
                    os.title +
                    (os.establishment ? " - " + os.establishment.name : "")
            }));
        },

        groupedEmployeesByEstablishmentsAndFilteredByServices() {
            let toShow = [];
            let employees = [];
            const establishments = this.shownEstablishments;
            establishments.forEach(e => {
                const establishmentEmployees = this.employees
                    ? this.employees.filter(
                          employee =>
                              employee.services.length &&
                              employee.services
                                  ?.map(s => s.establishment_id)
                                  .includes(e.id)
                      )
                    : [];
                if (this.selectedServices && this.selectedServices.length > 0) {
                    const services = this.selectedServices.map(s => s.id);
                    employees = establishmentEmployees.filter(
                        employee =>
                            services.filter(s =>
                                employee.services.map(es => es.id).includes(s)
                            ).length
                    );
                } else {
                    employees = establishmentEmployees;
                }
                toShow.push({
                    establishment: e.name,
                    employees: employees.map(employee => {
                        return {
                            ...employee,
                            name: `${employee.last_name.toUpperCase()} ${
                                employee.first_name
                            }`
                        };
                    })
                });
            });

            return toShow;
        },

        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services.filter(
                              service =>
                                  service.establishment_id === e.id &&
                                  this.listOfServicesViewableFor(
                                      "employees"
                                  ).includes(service.id)
                          )
                        : []
                });
            });
            return toShow;
        },

        groupedJobsByService() {
            let toShow = [];
            let services = this.services
                ? this.services.slice().sort((a, b) => {
                      return a.establishment.name.localeCompare(
                          b.establishment.name,
                          "fr",
                          { sensitivity: "base" }
                      );
                  })
                : [];

            services.forEach(service => {
                toShow.push({
                    service: service.establishment.name + " / " + service.title,
                    jobs: this.jobs
                        ? this.jobs.filter(
                              j =>
                                  j.service_id === service.id &&
                                  this.listOfServicesViewableFor(
                                      "jobs"
                                  ).includes(service.id)
                          )
                        : []
                });
            });
            return toShow;
        },

        shownOccupations() {
            return this.occupations
                ? [...this.occupations, ...this.referentialOccupations]
                : [];
        },

        occupationsTypes() {
            let types = this.shownOccupations.map(t => t.type);
            return types
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b));
        },

        occupationsList() {
            let occupations = this.shownOccupations;
            let types = this.occupationsTypes.filter(id =>
                occupations.map(occ => occ.type).includes(id)
            );

            let occupationsList = [];
            types.forEach(type => {
                occupationsList.push({
                    type: type,
                    occupations: occupations
                        .filter(occ => occ.type == type)
                        .sort((a, b) => a.title.localeCompare(b.title))
                });
            });
            return occupationsList;
        },

        shownEmployees() {
            let employees = [];
            const services = this.selectedServices;

            employees = this.employees;

            if (services && services.length > 0) {
                employees = employees
                    .slice()
                    .filter(
                        e =>
                            services
                                .map(s => s.id)
                                .filter(s =>
                                    e.services.map(es => es.id).includes(s)
                                ).length
                    );
            }

            if (this.employeeFilter.length > 0) {
                employees = this.employeeFilter;
            }

            return employees;
        },
        orderedEmployeesAccessiblityCharts() {
            const charts = [];
            var score;
            if (this.loading) return [];
            if (this.shownEmployees.length > 0) {
                const job = this.jobFilter;
                const occupation = this.occupationFilter;
                let occupationSkill;
                if (this.occupationskillFilter) {
                    if (this.occupationskillFilter.adminskills) {
                        occupationSkill = this.occupationskillFilter
                            ? window._.cloneDeep(
                                  this.adminoccupationskillById(
                                      this.occupationskillFilter.id
                                  )
                              )
                            : null;
                    } else {
                        occupationSkill = this.occupationskillFilter
                            ? window._.cloneDeep(
                                  this.occupationskillById(
                                      this.occupationskillFilter.id
                                  )
                              )
                            : null;
                    }
                }

                if (occupationSkill) {
                    if (occupation) {
                        if (occupation.admin_id) {
                            //fake data to be compatible with actual system
                            occupationSkill.skills = occupationSkill.adminskills.map(
                                obj => ({ ...obj, admin_skill_id: obj.id })
                            );
                        } else {
                            occupationSkill.skills = occupationSkill.skills; //.flatMap(s => s.map(s => this.skillById(s.id)))
                        }
                    }
                    for (let employee of this.shownEmployees) {
                        const latestRating = this.ratings[employee.id]
                            ? this.ratings[employee.id][0]
                            : null;
                        if (latestRating) {
                            let wantsThisJob = false;
                            if (!this.occupationFilter) {
                                if (occupationSkill.jobs) {
                                    wantsThisJob = latestRating.wishlist_jobs
                                        ? latestRating.wishlist_jobs.filter(j =>
                                              occupationSkill.jobs
                                                  .map(job => job.id)
                                                  .includes(j.id)
                                          ).length > 0
                                        : false;
                                } else {
                                    wantsThisJob = latestRating.wishlist_jobs
                                        ? latestRating.wishlist_jobs.filter(
                                              j =>
                                                  j.id ==
                                                  occupationSkill.pivot.job_id
                                          ).length > 0
                                        : false;
                                }
                            } else {
                                if (occupationSkill.admin_occupations) {
                                    wantsThisJob = latestRating.wishlist_occupations
                                        ? latestRating.wishlist_occupations.filter(
                                              o =>
                                                  occupationSkill.admin_occupations
                                                      .map(ao => ao.id)
                                                      .includes(o.id)
                                          ).length > 0
                                        : false;
                                }
                            }

                            if (this.wishlistOnly && !wantsThisJob) {
                                continue; // skip if employee doesn't have this job in his wishlist
                            }
                            score = this.employeeAccessibility(
                                latestRating,
                                occupationSkill,
                                "occupation_skill"
                            );

                            charts.push({
                                score:
                                    this.round(score[0] * 100, 0) +
                                    "% " +
                                    (score[1] ? "✓" : ""),
                                scoreNumber: score[0],
                                check: score[1],
                                options: this.chartOptionsWith(
                                    employee,
                                    this.round(score[0] * 100, 0) +
                                        "% " +
                                        (score[1] ? "✓" : ""),
                                    latestRating,
                                    occupationSkill,
                                    "occupation_skill"
                                ),
                                data: this.employeeAccessibilityChartData(
                                    latestRating,
                                    occupationSkill,
                                    "occupation_skill"
                                ),
                                employee: employee
                            });
                        } else {
                            if (
                                !this.errors
                                    .map(e => e.id)
                                    .includes(employee.id)
                            ) {
                                this.errors.push(employee); // TODO: add skipped employees to notifications
                            }
                        }
                    }
                } else if (job) {
                    for (let employee of this.shownEmployees) {
                        const latestRating = this.ratings[employee.id]
                            ? this.ratings[employee.id][0]
                            : null;
                        if (latestRating) {
                            const wantsThisJob = latestRating.wishlist_jobs
                                ? latestRating.wishlist_jobs.filter(
                                      j => j.id === job.id
                                  ).length > 0
                                : false;
                            if (this.wishlistOnly && !wantsThisJob) {
                                continue; // skip if employee doesn't have this job in his wishlist
                            }
                            score = this.employeeAccessibility(
                                latestRating,
                                job,
                                "job"
                            );
                            charts.push({
                                score:
                                    this.round(score[0] * 100, 0) +
                                    "% " +
                                    (score[1] ? "✓" : ""),
                                scoreNumber: score[0],
                                check: score[1],
                                options: this.chartOptionsWith(
                                    employee,
                                    this.round(score[0] * 100, 0) +
                                        "% " +
                                        (score[1] ? "✓" : ""),
                                    latestRating,
                                    job,
                                    "job"
                                ),
                                data: this.employeeAccessibilityChartData(
                                    latestRating,
                                    job,
                                    "job"
                                ),
                                employee: employee
                            });
                        } else {
                            if (
                                !this.errors
                                    .map(e => e.id)
                                    .includes(employee.id)
                            ) {
                                this.errors.push(employee); // TODO: add skipped employees to notifications
                            }
                        }
                    }
                } else if (occupation) {
                    for (let employee of this.shownEmployees) {
                        const latestRating = this.ratings[employee.id]
                            ? this.ratings[employee.id][0]
                            : null;
                        if (latestRating) {
                            const wantsThisOccupation = latestRating.wishlist_occupations
                                ? latestRating.wishlist_occupations.filter(
                                      o => o.id === occupation.id
                                  ).length > 0
                                : false;
                            if (this.wishlistOnly && !wantsThisOccupation) {
                                continue; // skip if employee doesn't have this job in his wishlist
                            }

                            const score = this.employeeAccessibility(
                                latestRating,
                                occupation,
                                "occupation"
                            );
                            charts.push({
                                score:
                                    this.round(score[0] * 100, 0) +
                                    "% " +
                                    (score[1] ? "✓" : ""),
                                scoreNumber: score[0],
                                check: score[1],
                                options: this.chartOptionsWith(
                                    employee,
                                    this.round(score[0] * 100, 0) +
                                        "% " +
                                        (score[1] ? "✓" : ""),
                                    latestRating,
                                    occupation,
                                    "occupation"
                                ),
                                data: this.employeeAccessibilityChartData(
                                    latestRating,
                                    occupation,
                                    "occupation"
                                ),
                                employee: employee
                            });
                        } else {
                            if (
                                !this.errors
                                    .map(e => e.id)
                                    .includes(employee.id)
                            ) {
                                this.errors.push(employee); // TODO: add skipped employees to notifications
                            }
                        }
                    }
                }
                charts.sort((a, b) =>
                    a.scoreNumber == b.scoreNumber
                        ? b.check
                            ? 1
                            : -1
                        : b.scoreNumber - a.scoreNumber
                );
            }

            return this.checkOnly
                ? charts.filter(
                      chart => chart.score.substr(chart.score.length - 1) == "✓"
                  )
                : charts;
        },
        userEstablishments() {
            if (this.user && this.user.establishments?.length) {
                return this.user.establishments;
            } else if (
                this.isASuperAdmin(this.user) ||
                (this.isAnAdmin(this.user) &&
                    this.user.establishments?.length == 0)
            ) {
                return this.establishments;
            }
            return [];
        },
        userEstablishmentsIds() {
            return this.userEstablishments.flatMap(e => e.id);
        }
    },

    watch: {
        async employeeIds() {
            if (this.employeeIds?.length) {
                let tempRatings = await ratingService.getLastRatings({
                    employee_ids: this.employeeIds.join("#"),
                    count: 1
                });
                Object.values(tempRatings).forEach(employeeRatings => {
                    employeeRatings.forEach(rating => {
                        this.reconstructRating(rating);
                    });
                });
                this.ratings = tempRatings;
                this.loading = false;
            }
        },
        selectedServices() {
            this.employeeFilter = "";
        },

        jobFilter(newValue) {
            if (newValue) {
                this.occupationskillFilter = "";
                this.occupationFilter = "";
                this.disabledEmployeeFilter = false;
                this.disabledServiceFilter = false;
                this.filterByUserRelated = false;
                this.disabledFilterByUserRelated = true;
            } else if (!this.occupationFilter && !this.occupationskillFilter) {
                this.disabledEmployeeFilter = true;
                this.disabledServiceFilter = true;
                this.filterByUserRelated = true;
                this.disabledFilterByUserRelated = false;
            }
        },

        occupationFilter(newValue) {
            if (newValue) {
                this.jobFilter = "";
                this.occupationskillFilter = "";
                this.disabledEmployeeFilter = false;
                this.disabledServiceFilter = false;
                this.filterByUserRelated = false;
                this.disabledFilterByUserRelated = true;
            } else if (!this.jobFilter && !this.occupationskillFilter) {
                this.disabledEmployeeFilter = true;
                this.disabledServiceFilter = true;
                this.filterByUserRelated = true;
                this.disabledFilterByUserRelated = false;
            }
        },

        occupationskillFilter(newValue) {
            if (newValue) {
                this.employeeFilter = "";
                this.disabledEmployeeFilter = false;
                this.disabledServiceFilter = false;
            } else if (!this.jobFilter && !this.occupationFilter) {
                this.disabledEmployeeFilter = true;
                this.disabledServiceFilter = true;
            }
        },

        loading(newValue) {
            if (newValue) {
                this.localLoading();
            } else {
                this.endLocalLoading();
            }
        }
    },
    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    this.colors = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: [
                            "services.establishment",
                            "currentJobs",
                            "currentOccupations"
                        ]
                    }
                })
                .then(response => {
                    this.employees = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: { perpage: 9999, include: ["occupation_skills"] }
                })
                .then(response => {
                    let jobs = response.data.data;
                    jobs.forEach(j => {
                        j.occupation_skills.forEach(jos => {
                            this.reconstructSkillOccupationSkillRelation(
                                jos,
                                this.skill_occupationSkill
                            );
                        });
                    });
                    this.jobs = jobs;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        joboccupationskills(id) {
            const job = this.jobs.slice().find(j => j.id === id);
            return job ? job.occupation_skills : [];
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills",
                            "occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data;
                    occupations.forEach(o => {
                        o.occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(
                                oos,
                                this.skill_occupationSkill,
                                false
                            );
                        });
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(
                                oos,
                                this.adminSkill_adminOccupationSkill,
                                true
                            );
                        });
                    });
                    this.occupations = occupations;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadReferentialOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations/referential",
                    params: {
                        perpage: 9999,
                        include: ["sector", "admin_occupation_skills"]
                    }
                })
                .then(response => {
                    let referentialOccupations = response.data.data;
                    referentialOccupations.forEach(o => {
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(
                                oos,
                                this.adminSkill_adminOccupationSkill,
                                true
                            );
                        });
                    });
                    this.referentialOccupations = referentialOccupations;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadOccupationskills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"]
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data;
                    occupationskills.forEach(os => {
                        this.reconstructSkillOccupationSkillRelation(
                            os,
                            this.skill_occupationSkill,
                            false
                        );
                    });
                    this.occupationskills = occupationskills;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupationskills.find(os => os.id == id);
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: { title: "asc" } }
                })
                .then(response => {
                    let adminOccupationskills = response.data.data;
                    adminOccupationskills.forEach(aos => {
                        this.reconstructSkillOccupationSkillRelation(
                            aos,
                            this.adminSkill_adminOccupationSkill,
                            true
                        );
                    });
                    this.adminOccupationSkills = adminOccupationskills;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        adminoccupationskillById(id) {
            return this.adminOccupationSkills.find(os => os.id == id);
        },

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        scrollTo(id) {
            const container = document.getElementById("content-area");
            const target = document.getElementById(id);
            const offset = 100;
            container.scrollTo({
                top: target.getBoundingClientRect().top - offset,
                behavior: "smooth"
            });
        },

        chartOptionsWith(employee, score, rating, entity, type) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            switch (type) {
                case "job": {
                    //entity = job
                    const hasThisJob = employee.current_jobs
                        ? employee.current_jobs.filter(j => j.id === entity.id)
                              .length > 0
                        : false;
                    const wantsThisJob = rating.wishlist_jobs
                        ? rating.wishlist_jobs.filter(j => j.id === entity.id)
                              .length > 0
                        : false;
                    options.title.text = `${employee.last_name.toUpperCase()} ${
                        employee.first_name
                    }${hasThisJob ? " (actuel)" : ""}${
                        wantsThisJob ? " (souhaité)" : ""
                    } : ${score}`;
                    break;
                }
                case "occupation_skill": {
                    //entity = occupation_skill
                    let hasThisJob = false;
                    let wantsThisJob = false;

                    if (this.jobFilter) {
                        hasThisJob = employee.current_jobs
                            ? employee.current_jobs.filter(
                                  j => j.id === this.jobFilter.id
                              ).length > 0
                            : false;
                        wantsThisJob = rating.wishlist_jobs
                            ? rating.wishlist_jobs.filter(
                                  j => j.id === this.jobFilter.id
                              ).length > 0
                            : false;
                    } else if (this.occupationFilter) {
                        hasThisJob = employee.current_occupations
                            ? employee.current_occupations.filter(
                                  j => j.id === this.occupationFilter.id
                              ).length > 0
                            : false;
                        wantsThisJob = rating.wishlist_occupations
                            ? rating.wishlist_occupations.filter(
                                  j => j.id === this.occupationFilter.id
                              ).length > 0
                            : false;
                    }

                    const occupationSkillRatingValue = !!rating
                        .occupations_skills?.length
                        ? rating.occupations_skills
                              .find(rOs => rOs.id === entity.id)
                              ?.pivots.find(
                                  p => p.occupation_skill_id === entity.id
                              )?.level
                        : null;
                    const occupationSkillRatingText = this.ratingParams.find(
                        param => +param.id === occupationSkillRatingValue
                    )?.label;

                    options.title.text = `${employee.last_name.toUpperCase()} ${
                        employee.first_name
                    }${hasThisJob ? " (actuel)" : ""}${
                        wantsThisJob ? " (souhaité)" : ""
                    }${
                        occupationSkillRatingValue > 1
                            ? " (" + occupationSkillRatingText + ")"
                            : ""
                    } : ${score}`;
                    break;
                }
                case "occupation": {
                    //entity = occupation
                    const wantsThisOccupation = rating.wishlist_occupations
                        ? rating.wishlist_occupations.filter(
                              o => o.id === entity.id
                          ).length > 0
                        : false;
                    options.title.text = `${employee.last_name.toUpperCase()} ${
                        employee.first_name
                    }${wantsThisOccupation ? " (souhaité)" : ""} : ${score}`;
                    options.scale.ticks.min = this.ratingSettings
                        ? this.ratingSettings.RadarCenter
                        : -1;
                    break;
                }
            }
            options.tooltips = {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    },
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value =
                            data.datasets[tooltipItem.datasetIndex].levels[
                                tooltipItem.index
                            ];
                        return ` ${label}  :  ${value}`;
                    }
                }
            };
            return options;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.select::v-deep .multiselect {
    font-size: 14px;
    color: grey;

    .multiselect__single {
        font-size: 14px;
        color: grey;
    }

    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;

        &-icon:hover {
            background: rgb(151, 47, 95);
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }

    .multiselect__select:before {
        top: 45%;
    }

    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }

    .multiselect__tag-icon::after {
        background: rgb(151, 47, 95);
    }

    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;

        &::after {
            display: none;

            &:hover {
                background: rgb(151, 47, 95);
            }
        }
    }
}

.checkboxes-container {
    padding-top: 10px;
    border-top: 1px solid $grid-gray;
    z-index: 0;
}
</style>
