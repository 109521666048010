<template>
  <div :class="cardClass">
    <p>
      <span v-if="card.category === 'employee_ratings'" class="bold">
        {{ projectNames.singular }}<br />
      </span>
      <span v-if="card.name instanceof Array" class="bold">
        <span v-for="(item) in card.name">
          {{ item }}
          <img v-if="card.isGraduate" class="is-graduate" src="/assets/images/qualification-white.png" alt="Formation diplômante">
          <br />
        </span>
      </span>
      <span v-else-if="card.name" class="bold">
        {{ card.name }}
        <img v-if="card.isGraduate" class="is-graduate" src="/assets/images/qualification-white.png" alt="Formation diplômante">
        <br />
      </span>

      <!-- List of acquired occupation_skill by job-->
      <div v-if="card.category === 'employee_ratings'">
        <div v-for="(job, i) in card.job_occupationSkills" :key="i" class="mt-2">
          <p>- {{job.jobTitle}}</p>
          <div v-for="(os, j) in job.occupation_skills" :key="i+'-'+j">
            <p style="padding-left: 5px;">✓ {{os.title}}</p>
          </div>
        </div>
      </div>

      <!--<span v-if="card.cumulatedOccupation">{{ card.cumulatedOccupation }} Métier(s)<br /></span>
      <span v-if="card.cumulatedPostes && !isEnd">{{ card.cumulatedPostes }} poste(s)<br /></span>
      <span v-if="card.cumulatedPostes && isEnd">{{ card.endCumulatedPostes }} poste(s)<br /></span>-->
      <span v-if="card.establishment">{{ card.establishment }}<br /></span>
      <span v-if="card.organisation">{{ card.organisation }}<br /></span><br />
      <span v-if="card.start && !card.date">{{ card.start.toLocaleDateString() }}</span>
      <span v-if="card.end && !card.date && card.end.toLocaleDateString() !== today">
        {{ ' - ' + card.end.toLocaleDateString() }}<br />
      </span>
      <span v-if="card.end && !card.date && card.end.toLocaleDateString() === today">
        {{ " - aujourd'hui" }}<br />
      </span>
      <span v-if="card.date">{{ card.date.toLocaleDateString() }}</span>
    </p>
  </div>
</template>

<script>
import * as moment from 'moment'
export default {
  name: 'CardCard',

  props: {
    card: Object,
    activeCard: Object,
    isTooltip: Boolean,
    isEndCard: Boolean
  },

  data: function() {
    return {
      cardClass: '',
      today: moment().format('L'),
      isEnd: false
    }
  },

  watch: {
    isEndCard: function() {
      this.isEnd = this.isEndCard
    },

    card: function() {
      this.setCardClass()
    },

    activeCard: function() {
      this.setCardClass()
    }
  },

  created: function() {
    this.setCardClass()
  },

  methods: {
    setCardClass: function() {
      var cardClass = this.card.category + ' card'
      if (this.isTooltip) {
        cardClass = cardClass + ' tooltip'
      }
      if (this.activeCard && this.activeCard !== this.card) {
        cardClass = cardClass + ' not-active'
      }
      this.cardClass = cardClass
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.card {
  z-index: 1002;
  width: 200px;
  color: $white;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  transition: all 0.2s ease-in-out;
  .bold {
    font-weight: bold;
  }
  p {
    margin-top: 0px;
  }
  &.not-active {
    background-color: $white;
    &.pageBreak {
      background-color: transparent;
      border: 2px solid transparent;
    }
  }
  &.tooltip {
    box-shadow: 4px 3px 32px 6px rgba(0, 0, 0, 0.2);
  }
}

.trainings {
  background-color: $color-formations;
  border: 2px solid $color-formations;
  &.not-active {
    color: $color-formations;
    border: 2px solid $color-formations;
  }
}

.Immersions {
  background-color: $color-immersions;
  border: 2px solid $color-immersions;
  &.not-active {
    color: $color-immersions;
    border: 2px solid $color-immersions;
  }
}

.occupations {
  background-color: $color-metiers;
  border: 2px solid $color-metiers;
  &.not-active {
    color: $color-metiers;
    border: 2px solid $color-metiers;
  }
}

.establishments {
  background-color: $color-etablissement;
  border: 2px solid $color-etablissement;
  &.not-active {
    color: $color-etablissement;
    border: 2px solid $color-etablissement;
  }
}

.jobs {
  background-color: $color-etablissement;
  border: 2px solid $color-etablissement;
  &.not-active {
    color: $color-etablissement;
    border: 2px solid $color-etablissement;
  }
}

.employee_ratings {
  background-color: $color-bilan;
  border: 2px solid $color-bilan;
  &.not-active {
    color: $color-bilan;
    border: 2px solid $color-bilan;
  }
}

.employee_rating_job_wishlists {
  background-color: $color-etablissement;
  border: 2px solid $color-etablissement;
  &.not-active {
    color: $color-etablissement;
    border: 2px solid $color-etablissement;
  }
}

.employee_rating_occupation_wishlists {
  background-color: $color-metiers;
  border: 2px solid $color-metiers;
  &.not-active {
    color: $color-metiers;
    border: 2px solid $color-metiers;
  }
}

.is-graduate {
  float: right;
  width: 25px;
}
</style>
