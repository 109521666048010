var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.footerDisplay == "true"
    ? _c(
        "footer",
        {
          staticClass: "the-footer flex-wrap justify-between",
          class: _vm.classes
        },
        [
          _vm.debug && _vm.debug.database !== "" && !_vm.fullpage
            ? _c("span", [
                _vm._v("\n        © " + _vm._s(_vm.date) + " MySkilliz RH "),
                _c("b", [
                  _vm._v(_vm._s(_vm.version.split("-")[0].toUpperCase()))
                ]),
                _vm._v(" par\n        "),
                _vm._m(0),
                _vm._v("\n        (DB:\n        "),
                _c("b", [_vm._v(_vm._s(_vm.debug.database))]),
                _vm._v(", Branch: "),
                _c("b", [_vm._v(_vm._s(_vm.branch))]),
                _vm._v(", LastUpdate: "),
                _c("b", [_vm._v(_vm._s(_vm.$moment(_vm.commitDate)))]),
                _vm._v(")\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.debug && _vm.debug.database === "" && !_vm.fullpage
            ? _c("span", [
                _vm._v("\n        © " + _vm._s(_vm.date) + " MySkilliz RH "),
                _c("b", [
                  _vm._v(_vm._s(_vm.version.split("-")[0].toUpperCase()))
                ]),
                _vm._v(" par\n        "),
                _vm._m(1)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.fullpage
            ? _c("span", [
                _vm._v(
                  "\n        © " +
                    _vm._s(_vm.date) +
                    " MySkilliz RH par\n        "
                ),
                _vm._m(2)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(3)
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.quadrare.fr/", target: "_blank" } },
      [_c("b", [_vm._v("SAS QUADRARE MYSKILLIZ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.quadrare.fr/", target: "_blank" } },
      [_c("b", [_vm._v("SAS QUADRARE MYSKILLIZ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.quadrare.fr/", target: "_blank" } },
      [_c("b", [_vm._v("SAS QUADRARE MYSKILLIZ")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "md:flex hidden items-center footer_links" },
      [
        _c("span", [
          _c("a", { attrs: { href: "/legal", target: "_blank" } }, [
            _vm._v("Mentions Légales")
          ])
        ]),
        _vm._v(" "),
        _c("span", [
          _c("a", { attrs: { href: "/privacy", target: "_blank" } }, [
            _vm._v("Protection des données personnelles")
          ])
        ]),
        _vm._v(" "),
        _c("span", [
          _c("a", { attrs: { href: "/cgu", target: "_blank" } }, [
            _vm._v("CGU")
          ])
        ]),
        _vm._v(" "),
        _c("span", [
          _c("a", { attrs: { href: "/cgv", target: "_blank" } }, [
            _vm._v("CGV")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }