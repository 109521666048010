<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
        />


        <div v-if="!loading && !resource.occupations_skills" class="not-data-table vs-table--not-data">Il n'y a aucun savoir-faire à évaluer</div>

        <Rating-Manager
            v-if="resource.occupations_skills || loading"
            name="occupation-skills"
            :type-form="typeForm"
            v-model="resource.occupations_skills"
            :show-deleted="showDeletedOccSkills"
            :col1="{label: 'Savoir-faire', value: 'title', title: 'description'}"
            :col2="{label: 'Poste / Métier', value: 'job_name', title: 'type'}"
            :col3="{label: 'Service / Filière', value: 'service_name'}"
            :loading="loading"
            :skill-review="skillReview"
        >
            <vs-checkbox v-model="showDeletedOccSkills">
                Affichage des savoir-faire supprimés
            </vs-checkbox>
        </Rating-Manager>
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import RatingManager from '../RatingManager';

export default {
    components: {
        JobsOccupationsResume, RatingManager
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            resource: {
                occupations_skills: []
            },
            showDeletedOccSkills: false,
            iconInfo: '<i class="vs-icon notranslate icon-scale feather icon-info small bg-small vs-icon-bg round" style="background: rgb(217, 217, 217); max-width: 50px;"></i>',
            loading: true,
            ratings: []
        };
    },
    created() {
        this.loading = true;
        this.initResource();
        this.loadDefaultRatings();
    },
    computed: {},
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.occupations_skills = val.occupations_skills

                this.$emit('input', data);
            },
            deep: true
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        formatDefaultRatings(ratings) {
            let formatedRatings = [];

            if (ratings.length > 0) {
                ratings.forEach((item) => {
                    item.job_name += ' ' + this.iconInfo;
                    formatedRatings.push(item)
                })
            }

            return formatedRatings;
        },

        loadDefaultRatings() {

            let start = Date.now();
            var employee_rating = window._.cloneDeep(this.value);
            const occupations = employee_rating.occupations;

            employee_rating.occupations_skills = this.resource.occupations_skills;
            employee_rating.admin_occupations  = occupations.filter(o => o.admin_id != null).map(o => o.admin_id);
            employee_rating.occupations        = occupations.filter(o => o.admin_id == null).map(o => o.id);
            employee_rating.jobs               = employee_rating.jobs.map(o => o.id);
            employee_rating.externals          = employee_rating.externals?.map(o => o.id) || [];
            employee_rating.personals          = employee_rating.personals?.map(o => o.id) || [];
            employee_rating.skill_review       = this.skillReview

            window
                .axios({
                    method: "post",
                    url: `/api/gestion/employees/${this.value.employee.id}/ratings/default-occupation-skills`,
                    params: {},
                    data: employee_rating,
                })
                .then(response => {
                    this.resource.occupations_skills = this.formatDefaultRatings(response.data);
                    this.loading = false;
                    console.info('=> Chargement des savoirs-faire depuis API : ' + (Date.now() - start) + 'ms');
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    this.loading = false;
                });
        }
    }
};
</script>
