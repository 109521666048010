<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">

                <vs-prompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @accept="storeResource"
                    @close="resetResource"
                >
                    <OccupationForm
                        v-model="resource"
                        :occupationTypes="occupationTypes"
                        :type="formType"
                        @error="setFormError"
                    />
                </vs-prompt>

                <vs-prompt
                    :active.sync="showPopupOccupationSkill"
                    title="Consulter un savoir faire"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    button-accept="false"
                    @cancel="resetResource"
                    @close="resetResource"
                >
                    <OccupationSkillForm
                        v-model="resourceOccupationSkill"
                        type="show"
                    />
                </vs-prompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            v-model="selected"
            multiple
            @search="eventSearchItems"
            @sort="eventSortByCol"
            @input="selectedInputHandle"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="items.data"
        >
            <template v-if="!selected.length" slot="thead">
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol"></Sort>Métier
                </vs-th>
                <vs-th>
                    <Sort name="sector,title" @sort="eventSortByCol"></Sort>Filière
                </vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Savoir-faire</vs-th>
                <vs-th width="160"></vs-th>
            </template>

            <template v-else slot="thead">
                <vs-th colspan="3">
                    <vs-checkbox
                            v-model="selectAll"
                            name="selectAll"
                            @input="selectAllItemsHandle"
                        >
                            Sélectionner la totalité des lignes
                    </vs-checkbox>
                    <vs-button
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-download"
                        class="ml-10"
                        @click.stop="downloadParents(selected)"
                    >
                        Télécharger les lignes sélectionnées
                    </vs-button>
                </vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                    <vs-td
                        v-tooltip="{
                            content: item.description,
                            placement: 'top-start',
                            classes: ['job-description']
                        }"
                    >
                        <div style="text-wrap: nowrap; display: flex">
                            <div class="float-left mr-5" style="line-height: 25px;">{{ get(item, ":title") }}</div>
                            <vs-chip v-if="item.is_new" color="warning" class="bg-chip-transparent">
                                Nouveau
                            </vs-chip>

                            <vs-chip v-if="item.beta_status == true">
                                Bêta
                            </vs-chip>
                        </div>
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":sector.title") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":type") }}
                    </vs-td>
                    <vs-td>
                        <vs-chip v-for="(item, index) in get(item, ':admin_occupation_skills')" :key="index" color="primary">
                            <span name="zone" style="cursor:pointer !important; min-height: 26px;min-width: 26px; padding-top: 6px;" @click="showOccupationSkill(item)">
                                {{ item.title }}
                            </span>
                        </vs-chip>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('read', 'occupations', item.id)"
                                :id="'showSkill_' + i"
                                v-tooltip="'Consulter'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-list"
                                @click="showResource(item)"
                            />

                            <vs-button
                                v-tooltip="'Télécharger le métier parent'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                                @click.stop="downloadParent(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination
                    :total="Math.ceil(items.total / apiParams.perpage)"
                    v-model="currentPage"
                >
                </vs-pagination>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import OccupationForm from "@components/views/rh/referentiel/occupation/OccupationForm";
import OccupationSkillForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillForm";

export default {
    name: "Occupation",
    components: {
        OccupationForm, OccupationSkillForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            selected: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            showPopupOccupationSkill: false,
            resourceReadonly: true,
            resource: {},
            resourceOccupationSkill: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un métier centralisé',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un métier centralisé',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un métier centralisé',
                    btnAccept: 'filled'
                }
            },
            selectAll: false
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        },
        items(value) {
            this.updateSelectedItems(this.items.data)
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
        occupationTypes() {
            let types = [];
            this.items.data.forEach(item => {
                if (item.admin_id == null) {
                    types.push(item.type);
                }
            })

            return types;
        }
    },

    methods: {
        // selectAllItemsHandle(value) {
        //     this.selected = value ? this.items.data : []
        // },
        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'show';
            this.showPopup = true;
        },

        downloadParent(parent) {
            this.selectAll = false;
            this.downloadParents([parent])

            // this.$swal({
            //     title: `Êtes-vous sûr de vouloir télécharger cet élément ?`,
            //     text: '',
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: 'Ok',
            //     cancelButtonText: 'Annuler'
            // }).then(async result => {
            //     if (result.value) {
            //         this.selectAll = false;
            //         this.downloadParents([parent])
            //     }
            // });
        },

        storeResource() {

        },

        downloadParents(parents) {
            window.axios({
                method: 'post',
                url: `${this.api}`,
                data: {
                    admin_occupation_ids: parents.map(p => p.id),
                    all: this.selectAll
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élement téléchargé avec succès !')
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));

            // Compatibilité avec le selector
            this.resource.occupation_skills = this.resource.admin_occupation_skills;
            this.resource.occupation_skills.map(os => {
                os.skills = os.adminskills
            })

            console.log(this.resource);
        },

        resetResource() {
            this.resource = {
                title: null,
                type: null,
                description: null,
                sector: {},
                occupation_skills: []
            };
        },

        setFormError(value) {
            this.formError = value;
        },

        showOccupationSkill(item) {
            this.resourceOccupationSkill = JSON.parse(JSON.stringify(item));
            this.showPopupOccupationSkill = true;
        },

        isEditable (occupationSkill) {
            if (occupationSkill.admin_id) return false
            return this.can('update', 'occupations')
        },

        isDeletable (occupationSkill) {
            if (occupationSkill.admin_id) return false
            return this.can('delete', 'occupations')
        },
    },
    created() {
        this.apiParams.include = [
            "sector",
            "admin_occupation_skills.sector",
            "admin_occupation_skills.adminskills"
        ];
        this.apiParams.append = [
            'is_new'
        ];
        this.resetResource();
        this.loadItems();
    }
};
</script>
