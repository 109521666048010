<template>
    <div>
        <!-- Objectifs sur savoir faire -->
        <vs-row class="mt-20">
            <vs-col>
                <h5>Autres objectifs :</h5>
            </vs-col>
        </vs-row>
        <vs-row class="mt-10" v-if="resources.length > 0">
            <vs-col class="w-full">
                <vs-row v-for="(res, i) in resources" :key="i" class="py-5 border-bottom">
                    <vs-col vs-justify="center" vs-align="center" vs-w="8" class="mb-3">
                        <div class="font-bold">Libellé</div>
                        <h4 v-if="res.id" class="pt-3"><i class="fa fa-hand-o-right mr-2"></i>{{ res.label }}</h4>
                        <vs-input v-else v-model="res.label" name="label" class="w-full" />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="3" class="mb-3">
                        <div class="font-bold">État</div>
                        <Multiselect
                            v-model="res.state"
                            :options="states"
                            class="multiselect-sm"
                            track-by="id"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Choisir..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                            :disabled="readonly"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="1" class="mb-3">
                        <div class="font-bold ">&nbsp;</div>
                        <vs-button
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            class="mt-1"
                            :disabled="readonly"
                            @click="deleteResource(i)"
                        >
                        </vs-button>
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="4" class="mb-3">
                        <div class="font-bold">Thème</div>
                        <SemiSelectForm
                            v-model="res.theme"
                            classes="multiselect-sm"
                            :availables="themes"
                            field-label="thème"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Date d'échéance</div>
                        <vs-input
                            type="date"
                            v-model="res.due_at"
                            name="due_at"
                            class="w-full"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Date de notification</div>
                        <vs-input
                            type="date"
                            v-model="res.notify_at"
                            name="notify_at"
                            class="w-full"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="4" class="mb-3">
                        <div class="font-bold">Utilisateurs à notifier</div>
                        <Multiselect
                            v-model="res.notify_users_field"
                            class="multiselect-sm"
                            :options="users.data"
                            :multiple="true"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="full_name"
                            select-label
                            selected-label
                            deselect-label
                            :loading="users.loading"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>
        <vs-row class="mt-5">
            <vs-col vs-offset="9" vs-w="3" vs-align="flex-end">
                <vs-button
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    :disabled="readonly"
                    @click="addResource"
                >
                    Ajouter un objectif
                </vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import SemiSelectForm from "@components/form/SemiSelectForm.vue";
import { loadUsers as loadUsersService } from "@components/services/resources.js";
export default {
    components: {
        Multiselect, SemiSelectForm
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean
        },
        themes: {
            type: Array,
            require: true
        },
        states: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            log: console.log,
            resources: [],
            loadUsersService,
            users: {
                data: [],
                loading: false
            }
        }
    },
    created() {
        this.loadUsers().then(() => {
            this.initResources();
        });
    },
    watch: {
        resources: {
            handler: function() {
                this.$emit('input', this.resources)
            },
            deep: true
        }
    },
    computed: {

        /**
         * Liste des notes disponibles
         */
         levelsAvailables() {
            return Object.values(this.ratingSettings.params);
        },
    },
    methods: {
        addResource() {
            this.resources.push({
                label: null,
                state: this.states.find(i => i.id == 1)
            });
        },
        deleteResource(indice) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    delete this.resources[indice];
                    this.resources = this.resources.filter(r => r != null);
                }
            })
        },
        initResources() {
            if (this.value && this.value.length > 0) {
                this.resources = this.value.map(r => {

                    const notifyUsers = this.users.data.filter(u => {
                        return r.notify_users ? r.notify_users.includes(u.id) : false;
                    });

                    r.notify_users_field = notifyUsers
                    r.state = this.states.find(s => s.id == r.state);

                    return {...r};
                })
            }
        },

        /**
         * Changement des utilisateurs pour notifications
         */
         async loadUsers() {
            this.users.loading = true;

            const response = await this.loadUsersService()
            this.users.data = response;
            this.users.loading = false
        }
    }
}
</script>
