<template>
    <vx-card class="overflow-hidden pb-6">
        <div slot="no-body">
            <div
                class="p-6 pb-0"
                :class="{
                    'flex justify-between flex-row-reverse items-center': iconRight
                }"
            >
                <template v-if="div || chip">
                    <div v-if="div" class="circle" :style="backgroundColor">{{ statistic }}</div>
                    <vs-chip v-else-if="chip" class="min-chip" :color="customColor" transparent>
                        {{ statistic }}
                    </vs-chip>
                    <div style="font-size: 1.2rem; font-weight: 600">
                        <span>{{ statisticTitle }}</span>
                    </div>
                </template>
                <template v-else>
                    <feather-icon
                        :icon="icon"
                        class="p-3 inline-flex rounded-full"
                        :class="[textColor, { 'mb-4': !iconRight }]"
                        :style="backgroundColor"
                    />
                    <div>
                        <h2 class="mb-1 font-bold">
                            {{ statistic }}
                        </h2>
                        <span>{{ statisticTitle }}</span>
                    </div>
                </template>
            </div>

            <div v-if="chartData" :id="chartData.id" class="line-area-chart">
                <vue-apex-charts
                    ref="apexChart"
                    :type="type"
                    height="100"
                    width="100%"
                    :options="chartData.chartOptions"
                    :series="chartData.series"
                />
            </div>
        </div>
    </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        VueApexCharts
    },
    props: {
        div: {
            type: Boolean,
            required: false
        },
        chip: {
            type: Boolean,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        statistic: {
            type: [Number, String],
            required: true
        },
        statisticTitle: {
            type: String
        },
        chartData: {
            type: Object,
            default: () => null,
            required: false
        },
        color: {
            type: String,
            default: "primary"
        },
        chartType: {
            type: String,
            default: "line-chart"
        },
        type: {
            type: String,
            default: "line"
        },
        iconRight: {
            type: Boolean,
            default: false
        },
        customColor: {
            type: String,
            default: () => null
        }
    },
    computed: {
        themePrimaryColor() {
            return '#972F5F';
        },
        backgroundColor() {
            if (this.customColor) {
                return {
                    background: `${this.customColor}26`,
                    color: `${this.customColor}`
                };
            } else {
                return { background: `rgba(var(--vs-${this.color}),.15)` };
            }
        },
        textColor() {
            if (!this.customColor) {
                return `text-${this.color}`;
            } else {
                return "";
            }
        }
    },
    watch: {
        themePrimaryColor() {
            this.$refs.apexChart.updateOptions({
                theme: { monochrome: { color: this.getHex() } }
            });
        }
    },
    created() {
        if (this.type == "area") {
            this.chartData.chartOptions["theme"] = {
                monochrome: {
                    enabled: true,
                    color: this.getHex(this.color),
                    shadeTo: "light",
                    shadeIntensity: 0.65
                }
            };
        }
    },
    methods: {
        getHex() {
            let rgb = window
                .getComputedStyle(document.documentElement)
                .getPropertyValue(`--vs-${this.color}`);
            rgb = rgb.split(",");
            return (
                "#" +
                (
                    (1 << 24) +
                    (Number(rgb[0]) << 16) +
                    (Number(rgb[1]) << 8) +
                    Number(rgb[2])
                )
                    .toString(16)
                    .slice(1)
            );
        }
    }
};
</script>
<style lang="scss" scoped>
.circle {
    width: 61px;
    min-width: 61px;
    height: 41px;
    border-radius: 35%;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.5rem;
}
.min-chip {
    min-height: 41px;
    min-width: 41px;
    font-weight: bold;
    font-size: 1.5rem;
}
</style>
