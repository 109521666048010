<template>
    <div>
        <!-- Postes occupés -->
        <vs-row class="mb-4" vs-w="12">
            <vs-col
                class="sm:w-1/5 w-full mb-4"
                vs-type="flex"
                vs-align="flex-start"
            >
                <span class="font-bold text-lg">Postes ou stages occupés :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <template v-if="jobs.length && !displayCollapses">
                    <div
                        v-for="job in jobs"
                        :key="`job-${job.id}`"
                        class="job-container"
                    >
                        <vs-chip>
                            <b>{{ job.title }}</b>
                        </vs-chip>
                    </div>
                </template>
                <template v-if="jobs.length && displayCollapses">
                    <vs-collapse>
                        <vs-collapse-item
                            v-for="job in jobs"
                            :key="`job-${job.id}`"
                            class="job-container"
                        >
                            <div slot="header"><vs-chip>
                                <b>{{ job.title }}</b>
                            </vs-chip></div>

                            <!-- Récap all Savoir-faire -->
                            <div
                                v-if="evaluatedOccupationSkills && jobHasEvaluatedOccupationSkills(job.id)"
                                class="os-wrapper all-os-wrapper"
                            >
                                <div v-if="jobHasEvaluatedOccupationSkillsNotMesured(job.id).length"><strong>Savoir-faire niveau Non mesuré</strong></div>
                                <div v-if="jobHasEvaluatedOccupationSkillsNotMesured(job.id).length" class="os-container">
                                    <template v-for="(os, j) in jobHasEvaluatedOccupationSkillsNotMesured(job.id)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="jobHasEvaluatedOccupationSkillsOnGoing(job.id).length"><strong>Savoir-faire niveau En cours</strong></div>
                                <div v-if="jobHasEvaluatedOccupationSkillsOnGoing(job.id).length" class="os-container">
                                    <template v-for="(os, j) in jobHasEvaluatedOccupationSkillsOnGoing(job.id)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="jobHasEvaluatedOccupationSkillsAcquired(job.id).length"><strong>Savoir-faire niveau Acquis</strong></div>
                                <div v-if="jobHasEvaluatedOccupationSkillsAcquired(job.id).length" class="os-container">
                                    <template v-for="(os, j) in jobHasEvaluatedOccupationSkillsAcquired(job.id)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="jobHasEvaluatedOccupationSkillsAutonomy(job.id).length"><strong>Savoir-faire niveau Autonomie</strong></div>
                                <div v-if="jobHasEvaluatedOccupationSkillsAutonomy(job.id).length" class="os-container">
                                    <template v-for="(os, j) in jobHasEvaluatedOccupationSkillsAutonomy(job.id)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <!-- Liste des objectifs & commentaires des SFs -->
                                <div v-if="jobHasCommentedOrGoaledOccupationSkills(job.id).length">
                                    <vs-row class="mb-4" vs-w="12">
                                        <p><b>Synthèse des objectifs et commentaires</b></p>
                                        <vs-table
                                            style="width:100%; min-width: 100%"
                                            :data="jobHasCommentedOrGoaledOccupationSkills(job.id)"
                                        >
                                            <template slot="thead">
                                                <vs-th>Savoir-faire</vs-th>
                                                <vs-th>Evalué</vs-th>
                                                <vs-th>Objectif fixé</vs-th>
                                                <vs-th>Commentaires</vs-th>
                                            </template>
                                            <template slot-scope="{ data }">
                                                <vs-tr v-for="(occupationSkill, i) in data" :key="'row-os-'+job.id+'-'+i">
                                                    <vs-td>{{ occupationSkill.title }}</vs-td>
                                                    <vs-td>{{ getRatingLevelLabel(occupationSkill.level) }}</vs-td>
                                                    <vs-td>
                                                        <span v-if="getOccSkillGoal(occupationSkill).level">
                                                            {{ getRatingLevelLabel(getOccSkillGoal(occupationSkill).level.id) }}
                                                        </span>
                                                    </vs-td>
                                                    <vs-td>{{ occupationSkill.comment }}</vs-td>
                                                </vs-tr>
                                            </template>
                                        </vs-table>
                                    </vs-row>
                                </div>
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </template>
                <template v-if="!jobs.length">
                    <p class="empty">/</p>
                </template>
            </vs-col>
        </vs-row>

        <!-- Métiers occupés -->
        <vs-row class="mb-4" vs-w="12">
            <vs-col
                class="sm:w-1/5 w-full"
                vs-type="flex"
                vs-align="flex-start"
            >
                <span class="font-bold text-lg"
                    >Métiers occupés ou en préparation :</span
                >
            </vs-col>
            <vs-col class="sm:w-4/5 w-full" vs-type="flex" vs-align="center">
                <template v-if="occupations.length && !displayCollapses">
                    <div
                        v-for="occupation in occupations"
                        :key="`occupation-${occupation.id}`"
                        class="occupation-container"
                    >
                        <vs-chip>
                            <b>{{ occupation.title }}</b>
                        </vs-chip>
                    </div>
                </template>
                <template v-if="occupations.length && displayCollapses">
                    <vs-collapse>
                        <vs-collapse-item
                            v-for="occupation in occupations"
                            :key="`occupation-${occupation.id}`"
                            class="occupation-container"
                        >
                            <div slot="header"><vs-chip>
                                <b>{{ occupation.title }}</b>
                            </vs-chip></div>

                            <!-- Récap all Savoir-faire -->
                            <div
                                v-if="evaluatedOccupationSkills && occupationHasEvaluatedOccupationSkills(occupation)"
                                class="os-wrapper all-os-wrapper"
                            >
                                <div v-if="occupationHasEvaluatedOccupationSkillsNotMesured(occupation).length"><strong>Savoir-faire niveau Non mesuré</strong></div>
                                <div v-if="occupationHasEvaluatedOccupationSkillsNotMesured(occupation).length" class="os-container">
                                    <template v-for="(os, j) in occupationHasEvaluatedOccupationSkillsNotMesured(occupation)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="occupationHasEvaluatedOccupationSkillsOnGoing(occupation).length"><strong>Savoir-faire niveau En cours</strong></div>
                                <div v-if="occupationHasEvaluatedOccupationSkillsOnGoing(occupation).length" class="os-container">
                                    <template v-for="(os, j) in occupationHasEvaluatedOccupationSkillsOnGoing(occupation)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="occupationHasEvaluatedOccupationSkillsAcquired(occupation).length"><strong>Savoir-faire niveau Acquis</strong></div>
                                <div v-if="occupationHasEvaluatedOccupationSkillsAcquired(occupation).length" class="os-container">
                                    <template v-for="(os, j) in occupationHasEvaluatedOccupationSkillsAcquired(occupation)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <div v-if="occupationHasEvaluatedOccupationSkillsAutonomy(occupation).length"><strong>Savoir-faire niveau Autonomie</strong></div>
                                <div v-if="occupationHasEvaluatedOccupationSkillsAutonomy(occupation).length" class="os-container">
                                    <template v-for="(os, j) in occupationHasEvaluatedOccupationSkillsAutonomy(occupation)">
                                        <p :key="os.uid">
                                            {{ os.title }}
                                        </p>
                                    </template>
                                </div>

                                <!-- Liste des objectifs & commentaires des SFs -->
                                <div v-if="occupationHasCommentedOrGoaledOccupationSkills(occupation).length">
                                    <vs-row class="mb-4" vs-w="12">
                                        <p><b>Synthèse des objectifs et commentaires</b></p>
                                        <vs-table
                                            style="width:100%"
                                            :data="occupationHasCommentedOrGoaledOccupationSkills(occupation)"
                                        >
                                            <template slot="thead">
                                                <vs-th>Savoir-faire</vs-th>
                                                <vs-th>Evalué</vs-th>
                                                <vs-th>Objectif fixé</vs-th>
                                                <vs-th>Commentaires</vs-th>
                                            </template>
                                            <template slot-scope="{ data }">
                                                <vs-tr v-for="(occupationSkill, i) in data" :key="'row-os-'+occupation.id+'-'+i">
                                                    <vs-td>{{ occupationSkill.title }}</vs-td>
                                                    <vs-td>{{ getRatingLevelLabel(occupationSkill.level) }}</vs-td>
                                                    <vs-td>{{ getRatingLevelLabel(getOccSkillGoal(occupationSkill).level.id) }}</vs-td>
                                                    <vs-td>{{ occupationSkill.comment }}</vs-td>
                                                </vs-tr>
                                            </template>
                                        </vs-table>
                                    </vs-row>
                                </div>
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </template>
                <template v-if="!occupations.length">
                    <p class="empty">/</p>
                </template>
            </vs-col>
        </vs-row>

        <!-- Postes souhaités -->
        <vs-row v-if="displayWishes" class="mb-4" vs-w="12">
            <vs-col
                class="sm:w-1/5 w-full"
                vs-type="flex"
                vs-align="flex-start"
            >
                <span class="font-bold text-lg">Postes souhaités :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <template v-if="jobsWish.length">
                    <vs-chip
                        v-for="jobWish in jobsWish"
                        :key="`jobWish-${jobWish.id}`"
                    >
                        <b>{{ jobWish.title }}</b>
                    </vs-chip>
                </template>
                <template v-else>
                    <p class="empty">/</p>
                </template>
            </vs-col>
        </vs-row>

        <!-- Métiers souhaités -->
        <vs-row v-if="displayWishes" class="mb-4" vs-w="12">
            <vs-col
                class="sm:w-1/5 w-full"
                vs-type="flex"
                vs-align="flex-start"
            >
                <span class="font-bold text-lg">Métiers souhaités :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <template v-if="occupationsWish.length">
                    <vs-chip
                        v-for="occupationWish in occupationsWish"
                        :key="`occupationWish-${occupationWish.id}`"
                    >
                        <b>{{ occupationWish.title }}</b>
                    </vs-chip>
                </template>
                <template v-else>
                    <p class="empty">/</p>
                </template>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
export default {
    props: {
        occupations: {
            required: true,
            type: Array,
            default: function() {
                return [];
            }
        },
        jobs: {
            required: true,
            type: Array,
            default: function() {
                return [];
            }
        },
        occupationsWish: {
            required: false,
            type: Array,
            default: function() {
                return [];
            }
        },
        jobsWish: {
            required: false,
            type: Array,
            default: function() {
                return [];
            }
        },
        acquiredOccupationSkills: {
            required: false,
            type: Object,
            default: null
        },
        displayWishes: {
            required: false,
            type: Boolean,
            default: true
        },
        evaluatedOccupationSkills: {
            required: false,
            type: Array,
            default: function() {
                return [];
            }
        },
        displayCollapses: {
            required: false,
            type: Boolean,
            default: false
        },
        displayCommentInCollapse: {
            required: false,
            type: Boolean,
            default: false
        },
        occupationSkillsGoals: {
            required: false,
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
            log: console.log
        }
    },
    watch: {
        // evaluatedOccupationSkills: {
        //     handler: function(val) {
        //         console.log('evaluatedOccupationSkills', window._.cloneDeep(val))
        //     },
        //     deep: true
        // }
    },
    methods: {
        jobHasEvaluatedOccupationSkills(job_id) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.job_id === job_id)
        },
        jobHasCommentedOrGoaledOccupationSkills(job_id) {
            return this.jobHasEvaluatedOccupationSkills(job_id)
                .filter(os => (os.comment && os.comment !== '') || this.occupationSkillsGoals.find(osg => osg.occupationSkill?.id === os.id))
        },
        jobHasEvaluatedOccupationSkillsNotMesured(job_id) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.job_id === job_id).filter(os => os.level === 1)
        },
        jobHasEvaluatedOccupationSkillsOnGoing(job_id) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.job_id === job_id).filter(os => os.level === 2)
        },
        jobHasEvaluatedOccupationSkillsAcquired(job_id) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.job_id === job_id).filter(os => os.level === 3)
        },
        jobHasEvaluatedOccupationSkillsAutonomy(job_id) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.job_id === job_id).filter(os => os.level === 4)
        },

        occupationHasEvaluatedOccupationSkills(occupation) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.occupation_id === occupation.id || os.admin_occupation_id === occupation.admin_id)
        },
        occupationHasCommentedOrGoaledOccupationSkills(occupation) {
            return this.occupationHasEvaluatedOccupationSkills(occupation)
                .filter(os =>
                    (os.comment && os.comment !== '') || this.occupationSkillsGoals.find(osg => osg.occupationSkill?.id === os.id)
                )
        },
        occupationHasEvaluatedOccupationSkillsNotMesured(occupation) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.occupation_id === occupation.id || os.admin_occupation_id === occupation.admin_id).filter(os => os.level === 1)
        },
        occupationHasEvaluatedOccupationSkillsOnGoing(occupation) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.occupation_id === occupation.id || os.admin_occupation_id === occupation.admin_id).filter(os => os.level === 2)
        },
        occupationHasEvaluatedOccupationSkillsAcquired(occupation) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.occupation_id === occupation.id || os.admin_occupation_id === occupation.admin_id).filter(os => os.level === 3)
        },
        occupationHasEvaluatedOccupationSkillsAutonomy(occupation) {
            if (!this.evaluatedOccupationSkills.length) return []
            return this.evaluatedOccupationSkills.filter(os => os.occupation_id === occupation.id || os.admin_occupation_id === occupation.admin_id).filter(os => os.level === 4)
        },

        getRatingLevelLabel(skillLevel) {
            return this.ratingSettings.params[skillLevel || 1]?.label || ''
        },
        getOccSkillGoal(occSkill) {
            return this.occupationSkillsGoals.find(osg => osg.occupationSkill?.id === occSkill.id) || {level: {}, occupationSkill: {}}
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.vs-row {
    min-height: 30px;

    .job-container, .occupation-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .os-wrapper {
            padding-left: 10px;
            margin-top: 5px;
            margin-bottom: 15px;

            .os-container {
                display: flex;
                white-space: nowrap;
                flex-wrap: wrap;

                > p {
                    margin-right: 10px;
                    color: $primary;
                    &:not(:last-child):after {
                        content: "|";
                        padding-left: 10px;
                    }
                }
            }
        }

        .all-os-wrapper {
            margin-top: -15px;
            margin-bottom: 0px;

            .os-container {
                margin-bottom: 10px;
            }
        }

        &:last-of-type {
            margin-bottom: 0 !important;
            .os-wrapper {
                margin-bottom: 0;
            }
        }
    }

    ::v-deep .vs-collapse {
        padding: 0 !important;

        .vs-collapse-item {
            border-bottom: none;
            > header {
                padding: 0 !important;
                padding-bottom: 2px !important;
                padding-right: 5px !important;
                > span.icon-header {
                    top: 50% !important;
                }
            }
        }
    }

    ::v-deep .vs-con-table {
        border: 0px;
        tr {
            border-bottom: 0px;
        }
    }

    ::v-deep .vs-con-tbody {
        border: 0px;
    }
}
.con-vs-chip {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 0.9rem;
}
p.empty {
    color: #c9c9c9;
}
</style>
