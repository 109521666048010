<template>
    <div id="resourceForm" class="con-exemple-prompt">
        <vs-row>
            <vs-col vs-w="12">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Libellé* :</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <div>
                            <b>{{ resource.title }}</b>
                        </div>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Libellé FALC* :</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <div>
                            <b>{{ resource.title_falc }}</b>
                        </div>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Catégorie* :</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <div>
                            <b>{{ resource.admin_skill.category.title }}</b>
                        </div>
                    </div>
                </div>

                <div v-if="resource.admin_skill && !readonly" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Description du référentiel : </span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <vs-textarea v-model="resource.admin_skill.description" class="disabledColor"
                            name="skill_ref_description" disabled />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Description : </span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <span v-if="readonly">{{ resource.description }}</span>
                        <vs-textarea v-else v-model="resource.description" name="skill_description" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Description FALC : </span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <span v-if="readonly">{{ resource.description_falc }}</span>
                        <vs-textarea v-else v-model="resource.description_falc" name="skill_description_falc" />
                    </div>
                </div>

                <div>
                    <div class="vx-row tenancy">
                        <div class="vx-col sm:w-1/5 w-full">
                            <span>La signification des notes : </span>
                        </div>
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ noteById(1) }} - {{ levelLabelById(1) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-3/5 w-full">
                            <span v-if="readonly">{{ resource.level.level_0 }}</span>
                            <vs-input v-else v-model="resource.level.level_0" name="modifySkillLevel_O" class="w-full"
                                placeholder="0 : " type="text" />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/5 w-full" />
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ noteById(2) }} - {{ levelLabelById(2) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-3/5 w-full">
                            <span v-if="readonly">{{ resource.level.level_1 }}</span>
                            <vs-input v-else v-model="resource.level.level_1" name="modifySkillLevel_1" class="w-full"
                                placeholder="1 : " type="text" />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/5 w-full" />
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ noteById(3) }} - {{ levelLabelById(3) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-3/5 w-full">
                            <span v-if="readonly">{{ resource.level.level_2 }}</span>
                            <vs-input v-else v-model="resource.level.level_2" name="modifySkillLevel_2" class="w-full"
                                placeholder="2 : " type="text" />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/5 w-full" />
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ noteById(4) }} - {{ levelLabelById(4) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-3/5 w-full">
                            <span v-if="readonly">{{ resource.level.level_3 }}</span>
                            <vs-input v-else v-model="resource.level.level_3" name="modifySkillLevel_3" class="w-full"
                                placeholder=" 3 : " type="text" />
                        </div>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Image de la compétence : </span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <avatar-cropper
                            :id="'cropper'"
                            :trigger="'#edited-pick-photo'"
                            :labels="{ submit: 'Soumettre', cancel: 'Annuler' }"
                            :upload-handler="submitPhoto"
                            :cropper-options="{'aspectRatio': '16:9', 'autoCropArea': 1, 'viewMode': 1, 'movable': false, 'zoomable': false}"
                        />
                        <img
                            v-if="resource.photo"
                            :id="'edited-pick-photo'"
                            :src="resource.photo"
                            width="120px"
                        />

                        <div v-else :id="'edited-pick-photo'" style="text-align:center; font-size: 20px; background-color: grey; color: white; width: 120px; height: 120px;">
                            Pas de photo <small>Cliquez pour en rajouter une</small>
                        </div>
                    </div>
                </div>
            </vs-col>
        </vs-row>

        <vs-row v-if="!readonly">
            <vs-col vs-type="flex" vs-w="3">
                <vs-button id="modifySkillReset" @click="resetSkillToAdminValue()">
                    Réinitialiser
                </vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AvatarCropper from 'vue-avatar-cropper'

export default {
    name: 'SkillForm',
    components: {
        Multiselect,
        AvatarCropper,
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        readonly: {
            required: true,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            resource: {},
            categories: [],
            categoriesLoading: true
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return
            this.initResource()
        },
        'resource': {
            handler() {
                this.value.description = this.resource.description
                this.value.description_falc = this.resource.description_falc
                this.value.level.level_0 = this.resource.level.level_0
                this.value.level.level_1 = this.resource.level.level_1
                this.value.level.level_2 = this.resource.level.level_2
                this.value.level.level_3 = this.resource.level.level_3
                this.value.photoBlob = this.resource.photoBlob
                this.value.photoDeleteBlob = this.resource.photoDeleteBlob
                this.$emit('input', this.value)
            }, deep: true
        },
        hasError(value) {
            this.$emit('error', value)
        }
    },
    computed: {

        hasError() {
            let error = !this.resource.description ||
                //!this.resource.description_falc ||
                !this.resource.level.level_0 ||
                !this.resource.level.level_1 ||
                !this.resource.level.level_2 ||
                !this.resource.level.level_3
            return error;
        },
    },
    methods: {
        async loadCategories() {
            return window.axios({
                method: 'get',
                url: '/api/admin/categories',
                params: { perpage: 9999, include: [] },
            })
                .then(response => {
                    this.categories = response.data.data;
                    this.categoriesLoading = false;
                })
                .catch((err) => {
                    console.log(err)
                    this.notifyError(err, `Une erreur est survenue`)
                })
        },

        initResource() {
            this.resource = {
                title: this.value.admin_skill.title,
                title_falc: this.value.admin_skill.title_falc,
                description: this.value.description,
                description_falc: this.value.description_falc,
                admin_skill: {
                    description: this.value.admin_skill.description,
                    category: {
                        id: this.value.admin_skill.category.id,
                        title: this.value.admin_skill.category.title
                    }
                },
                admin_level: {
                    level_0: this.value.admin_skill.admin_level.level_0,
                    level_1: this.value.admin_skill.admin_level.level_1,
                    level_2: this.value.admin_skill.admin_level.level_2,
                    level_3: this.value.admin_skill.admin_level.level_3
                },
                level: {
                    level_0: this.value.level.level_0,
                    level_1: this.value.level.level_1,
                    level_2: this.value.level.level_2,
                    level_3: this.value.level.level_3
                },
                photo: this.value.photo,
                photoBlob: this.value.photoBlob,
            }
        },

        resetSkillToAdminValue() {
            this.resource.description = this.value.admin_skill.description
            this.resource.description_falc = this.value.admin_skill.description_falc
            this.resource.level.level_0 = this.value.admin_skill.admin_level.level_0
            this.resource.level.level_1 = this.value.admin_skill.admin_level.level_1
            this.resource.level.level_2 = this.value.admin_skill.admin_level.level_2
            this.resource.level.level_3 = this.value.admin_skill.admin_level.level_3
            this.resource.photoBlob = undefined
            this.resource.photoDeleteBlob = true
            this.resource.photo = null

        },
        submitPhoto(ev) {
            ev.getCroppedCanvas({ width: 512, height: 512 }).toBlob(blob => {
                    this.resource.photo = URL.createObjectURL(blob)
                    this.resource.photoBlob = blob
                },
                'image/jpeg',
                0.9
            )
        },
    },
    async created() {
        this.initResource();

        this.$vs.loading();
        await Promise.all([
            this.loadCategories()
        ]);
        this.$vs.loading.close();
    }
}
</script>

<style lang="scss" scoped>
#resourceForm {
    display: block;

    >div {
        padding: 1rem;
    }

    ::v-deep input {
        min-height: 43px;
        padding: 1rem 1.3rem;
        font-size: 1.1rem;
    }

    .multiselect {
        min-height: 43px;

        &.multiselect--disabled {
            border-radius: 5px;

            ::v-deep .multiselect__select {
                border-radius: 0 4px 4px 0;
                height: 41px;
            }
        }
    }

    .label {
        margin-bottom: 1rem;
    }

    .disabledColor {
        color: #999;
    }

    .input-level {
        padding-bottom: 20px;
        position: relative;
        margin-bottom: 5px;

        .error {
            position: absolute;
            color: red;
            bottom: 0;
            right: 0;
            text-align: right;
        }
    }

    .tenancy {
        .input-level p.error {
            right: 1rem;
        }
    }

    .buttons>span {
        display: block;

        >* {
            display: block;
            margin-left: 0.5rem;
        }
    }
}
</style>
