<template>
    <div>
        <vs-row>
            <vs-col>
                <p class="h4 mt-5">{{ projectNames.plural }} à réaliser</p>
            </vs-col>
        </vs-row>
        <vs-row>
            <vs-col>
                <vs-table
                    :sst="true"
                    @search="eventSearchItems"
                    @sort="eventSortByCol"
                    search
                    :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
                    style="width:100%"
                    :data="itemsData"
                >
                    <template slot="thead">
                        <vs-th>
                            <Sort name="last_name" @sort="eventSortByCol">Nom</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="first_name" @sort="eventSortByCol">Prénom</Sort>
                        </vs-th>
                        <vs-th>
                            Établissements
                            <!--<Sort name="services.establishment,name" @sort="eventSortByCol">Établissements</Sort>-->
                        </vs-th>
                        <vs-th>
                            Services
                            <!--<Sort name="services,title" @sort="eventSortByCol">Services</Sort>-->
                        </vs-th>
                        <vs-th>Poste occupés</vs-th>
                        <vs-th>Métier occupés ou en préparation</vs-th>
                        <vs-th></vs-th>
                    </template>

                    <template>
                        <vs-tr v-for="(item, i) in itemsData" :key="i">
                            <vs-td>
                                {{ get(item, ":last_name") }}
                            </vs-td>
                            <vs-td>
                                {{ get(item, ":first_name") }}
                            </vs-td>
                            <vs-td>
                                {{ arrayUnique(get(item, ":services").map(s => s.establishment.name)).join(', ') }}
                            </vs-td>
                            <vs-td>
                                {{ arrayUnique(get(item, ":services").map(s => s.title)).join(', ') }}
                            </vs-td>
                            <vs-td>
                                <vs-chip
                                    v-for="(el, index) in get(item, ':current_jobs')" :key="index">
                                    <b>{{ el.title }}</b>
                                </vs-chip>
                            </vs-td>
                            <vs-td>
                                <vs-chip v-for="(el, index) in get(item, ':current_occupations')" :key="index">
                                    <b>{{ el.title }}</b>
                                </vs-chip>
                            </vs-td>
                            <vs-td class="action">
                                <div class="action-wrapper">
                                    <vs-button
                                        v-if="canMulti('update', 'ratings', item.services.map(s => s.id))"
                                        :id="'createRatingEmployee_' + i"
                                        :href="`/rh/gestion/personnes/${item.id}/bilans/creer`"
                                        :v-tooltip="'Créer le '+projectNames.singular"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-plus-square"
                                    />
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-row>
                    <vs-col class="mt-4" v-if="items.total > 0">
                        <vs-pagination
                            :total="Math.ceil(items.total / apiParams.perpage)"
                            v-model="currentPage"
                        ></vs-pagination>
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
export default {
    name: "RatingsToPerform",
    props: {
        api: {
            type: String,
            required: true
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            currentPage: 1,
            loading: false,
        }
    },
    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },
    created() {
        this.apiParams.include = [
            'services.establishment',
            'currentJobs.occupation_skills',
            'currentJobs.service',
            'currentOccupations'
        ];
        this.apiParams.perpage = 5;
        this.loadItems();
    }
};
</script>
