<template>
    <div>
        <vx-card class="mb-5" v-if="isASuperAdmin() || isAnAdmin()" title="Déposer des fichiers">
            <!-- Permission désactivé pour le moment -->
            <vs-row v-if="can('import', 'imports') || true">
                <input type="file" @change="onUpdateFile($event)" />
            </vs-row>
            <vs-table
                :data="uploadedFiles"
                no-data-text="Aucune donnée à afficher"
                style="width:100%;"
            >
                <template slot="thead">
                    <vs-th>Fichier</vs-th>
                    <vs-th>Taille (en Ko)</vs-th>
                    <vs-th>Date de dépôt</vs-th>
                    <vs-th>Déposé par</vs-th>
                    <vs-th>
                        Actions
                    </vs-th>
                </template>
                <template slot-scope="{ data }">
                    <vs-tr
                        v-for="(item, indextr) in data"
                        :key="indextr"
                        :data="item"
                    >
                        <vs-td>{{ item.frontName }}</vs-td>
                        <vs-td>{{ Math.round(item.size / 1024) }}</vs-td>
                        <vs-td>{{ item.at }}</vs-td>
                        <vs-td>{{ item.by }}</vs-td>
                        <vs-td>
                            <vs-button
                                :id="'downloadUpload_' + indextr"
                                v-tooltip="'Télécharger fichier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                                @click.stop="downloadUploadedFile(item)"
                            />

                            <vs-button
                                v-if="can('import', 'imports')"
                                :id="'deleteUpload_' + indextr"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                style="margin-left: 1rem;"
                                @click="deleteUploadedFile(item)"
                            />
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <vx-card v-if="!server.value && can('create', 'imports')" title="Paramétrer les imports">
            <vs-row>
                <p>Aucun paramétrage défini.</p>
                <a class="ml-1 cursor-pointer" @click="createServerPrompt">Ajouter un serveur</a>
            </vs-row>
        </vx-card>
        <vx-card v-else-if="can('create', 'imports')" title="Paramétrer les imports">
            <p class="mb-2">Imports configurés sur: </p>
            <vs-row>
                <vs-col vs-w="3">
                    <p><strong>Protocol:</strong> {{ server.value.protocolName }}</p>
                </vs-col>
                <vs-col vs-w="3">
                    <p><strong>Serveur:</strong> {{ server.value.ip }}</p>
                </vs-col>
                <vs-col v-if="server.value.protocol !== 'upload'" vs-w="3">
                    <p><strong>Identifiant:</strong> {{ server.value.user }}</p>
                </vs-col>
                <vs-col vs-w="3">
                    <vs-button
                        v-if="isASuperAdmin"
                        id="modifyServer"
                        v-tooltip="'Modifier'"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-edit"
                        @click.stop="updateServerPrompt"
                    />
                    <vs-button
                        v-if="isASuperAdmin"
                        id="deleteServer"
                        v-tooltip="'Supprimer'"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="deleteResource"
                        class="ml-1"
                    />
                </vs-col>
            </vs-row>

            <ImportsParams
                v-if="server.id"
                :api="this.api"
                :server="server"
                :config="config"
            />
        </vx-card>

        <vs-prompt
            :active.sync="displayPrompt"
            title="Définition du serveur"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            @cancel="initResource"
            @accept="createResource()"
        >
            <div class="con-exemple-prompt">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Protocole *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.protocol"
                            name="protocol"
                            :options="protocols"
                            track-by="slug"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>

                <div v-if="resource.protocol && resource.protocol.slug !== 'upload'" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>IP *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.ip"
                            name="ip"
                            class="w-full"
                        />
                    </div>
                </div>

                <div v-if="resource.protocol && resource.protocol.slug !== 'upload'" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Utilisateur *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.user"
                            name="user"
                            class="w-full"
                        />
                    </div>
                </div>

                <div v-if="resource.protocol && resource.protocol.slug !== 'upload'" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Password *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.password"
                            name="password"
                            class="w-full"
                        />
                    </div>
                </div>
            </div>
        </vs-prompt>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import ImportsParams from '@components/views/rh/referentiel/parameters/ImportsParams'

export default {
    name: "ImportsServer",
    components: {
        Multiselect,
        ImportsParams,
    },
    props: {
        api: {
            type: String
        },
        config: {
            type: Object
        }
    },
    data() {
        return {
            resource: {
                ip: null,
                protocol: null,
                user: null,
                password: null,
            },
            protocols: [
                {slug: 'ftp', name: 'FTP (distant ou local)'},
                {slug: 'sftp', name: 'SFTP (distant ou local)'},
                {slug: 'upload', name: 'Upload (local)'},
            ],
            loading: false,
            displayPrompt: false,
            uploadedFiles: [],
        };
    },

    created() {
        this.apiParams.filter = 'importServer';
        this.loadItems();
        this.loadUploadedFiles()
    },

    computed: {
        server() {
            let server = this.items.length ? this.items[0] : {}
            if (this.items.length) {
                server.value.protocolName = this.protocols.find(p => p.slug === server.value.protocol)?.name
            }
            return server
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
        items(value) {
            console.log('Watch items', value)
        },
        'resource.protocol': {
            handler (value) {
                if (value && value?.slug === 'upload') this.resource.ip = 'Serveur local'
                this.protocol = value?.slug
            }, deep: true
        },
        server(value) {
            console.log('Server', window._.cloneDeep(value))
        }
    },

    methods: {
        createServerPrompt() {
            this.initResource()
            this.displayPrompt = true
        },
        updateServerPrompt() {
            this.resource = this.server
            this.resource.name = 'importServer'
            this.displayPrompt = true
        },

        initResource() {
            this.displayPrompt = false
            this.resource = {
                name: 'importServer',
                ip: null,
                protocol: null,
                user: null,
                password: null,
            }
        },
        createResource() {
            this.loading = true;
            let data = window._.cloneDeep(this.resource)
            this.apiCreate(
                data,
                resp => {
                    console.log('resp', resp)
                    this.loadItems()
                    this.loading = false;
                },
                err => {
                    this.displayPrompt = true
                    this.loading = false;
                }
            );
        },

        updateResource(data) {
            this.loading = true;

            this.apiUpdate({
                    name: data.moving.name,
                    order: data.to
                },
                data.moving.id,
                resp => {
                    this.loadItems()
                    this.loading = false;
                },
                err => {
                    this.loading = false;
                    alert('Error !!');
                }
            );
        },

        deleteResource() {
            this.apiDelete(this.server.id, () => this.loadItems());
        },

        onUpdateFile(event) {
            let file = event.target.files ? event.target.files[0] : null
            let item = {
                file: file,
            }
            this.updateFile(item)
        },
        updateFile(item) {
            this.loading = true
            let form = new FormData();
            form.append('pilote_file', item.file);
            window.axios({
                method: 'post',
                data: form,
                url: `${this.api}/upload-file`,
                headers: {'Content-Type': 'multipart/form-data'},
            })
            .then(response => {
                this.loadUploadedFiles()
                this.loading = false;
                this.notifySuccess()
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },

        loadUploadedFiles() {
            window.axios({
                method: 'post',
                url: `${this.api}/index-upload-file`,
            })
            .then(response => {
                this.uploadedFiles = response.data
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },

        deleteUploadedFile(item) {
            window.axios({
                method: 'post',
                data: {
                    fileName: item.fullName
                },
                url: `${this.api}/delete-upload-file`,
            })
            .then(response => {
                this.loadUploadedFiles()
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },
        downloadUploadedFile(item) {
            window.axios({
                method: 'post',
                params: { fileName: item.fullName },
                url: `${this.api}/download-upload-file`,
                responseType: 'blob', // Indique que la réponse sera un fichier
            })
            .then(response => {
                console.log('Download DONE !!')
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item.frontName); // Spécifie le nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                this.notifyError(err, `Une erreur est survenue`, true);
            })
        },

    }
};
</script>
