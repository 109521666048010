<template>
  <div class="slider-container">
    <vue-slider
      v-model="value"
      :enable-cross="false"
      :data="allYears"
      :marks="true"
      @change="setSelectedYear"
    >
      <template v-slot:label="{ active, value }">
        <div :class="['vue-slider-mark-label', 'custom-label', isSelectedYear({ value }) ]">
          {{ value }}
        </div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'YearsSlider',

  components: {
    VueSlider
  },

  props: {
    allYears: Array,
    selectedYears : Array,
    setSelectedYear: Function
  },

  data: function () {
    return {
      value: this.selectedYears,
    }
  },

  watch: {
    selectedYears: function() {
      this.value = this.selectedYears;
    }
  },

  methods: {
    isSelectedYear(obj) {
      var labelIsActive = {}
      if (obj.value === this.selectedYears[0] || obj.value === this.selectedYears[1]) {
        labelIsActive.active = true;
      } else {
        labelIsActive.active = false;
      }
      return labelIsActive;
    }
  }
}

</script>

<style lang="scss">
@import "@sass/slider/slider.scss";

.slider-container {
  margin-top:15px;
  position: relative;
  z-index: 20;
  width: 100%;
  cursor: pointer;
}

.custom-label {
  color: $labelColor;
  padding-top: 5px;
  cursor: pointer;
}

.custom-label.active {
  color: $themeColor;
  font-size: 18px;
  padding-top: 2px;
  cursor: pointer;
}
</style>
