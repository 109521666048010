<template>
    <div class="con-exemple-prompt mb-6 scrollBug">
        <!-- Titre -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Libellé* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.title"
                    name="title"
                    class="w-full"
                />
                <b v-else>{{ resource.title }}</b>
            </div>
        </div>

        <!-- Choix du type -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Type* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <template v-if="type != 'show'">
                    <div
                        ref="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Cette donnée permet de catégoriser vos métiers (Exemple : ROME, RSFP, …) . Vous pouvez sélectionner une valeur existante ou en ajouter une nouvelle.">

                        <TypeForm
                            v-model="resource.type"
                            :availableTypes="formOccupationTypes"
                        ></TypeForm>
                    </div>
                </template>
                <span v-else>{{ resource.type }}</span>
            </div>
        </div>

        <!-- Filière -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Filière* :</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-if="sectors.length > 0 && type != 'show'"
                    v-model="resource.sector"
                    :options="sectors"
                    :multiple="false"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label
                    selected-label
                    deselect-label
                    :loading="sectorsLoading"
                    @close="onTouchService"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </multiselect>
                <span v-else>{{ resource.sector.title }}</span>
            </div>
        </div>

        <!-- Description -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Description :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea
                    v-if="type != 'show'"
                    v-model="resource.description"
                    name="description"
                />
                <span v-else>{{ resource.description }}</span>
            </div>
        </div>

        <!-- Filtres -->
        <div class="vx-row mb-6" v-if="type != 'show'">
            <vs-checkbox
                v-model="filterBySector"
                name="addJobFilterBySector"
            >
                Filière uniquement
            </vs-checkbox>
        </div>

        <!-- Choix des savoir-faire associés -->
        <SkillsSelector
            v-if="type != 'show'"
            v-model="resource.occupation_skills"
            :sector="resource.sector"
            :filterBySector="filterBySector"
        />

        <div v-else class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Compétences appelées par le Métier :</span>
            </div>
            <div class="vx-col w-full">
                <vs-table max-height="300px" search :data="orderedSkills">
                    <template slot="thead">
                        <vs-th>
                            <Sort name="title" @sort="eventSortByCol">Compétence</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="category_title" @sort="eventSortByCol">Catégorie</Sort>
                        </vs-th>
                        <vs-th class="space">
                            {{
                                ratingSettings.RequiredLevel
                                    ? ratingSettings.RequiredLevel.label
                                    : ""
                            }}
                        </vs-th>
                        <vs-th class="space">
                            {{
                                ratingSettings.IndispensableLevel
                                    ? ratingSettings.IndispensableLevel.label
                                    : ""
                            }}
                        </vs-th>
                        <vs-th class="space">
                            {{
                                ratingSettings.AutonomyLevel
                                    ? ratingSettings.AutonomyLevel.label
                                    : ""
                            }}
                        </vs-th>
                    </template>
                    <template slot-scope="{ data }">
                        <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                            <vs-td
                                v-tooltip="{
                                    content: item.description,
                                    placement: 'top-start',
                                    classes: ['occ-description']
                                }"
                                :data="item.title"
                            >
                                {{ item.title }}
                            </vs-td>
                            <vs-td :data="item.category_title">
                                {{ item.category_title }}
                            </vs-td>
                            <vs-td>
                                <vs-radio
                                    :key="item.id"
                                    v-model.number="
                                        resource.shownSkills[item.id]
                                    "
                                    v-tooltip="{
                                        content: item.level['level_1'],
                                        classes: ['occ-level']
                                    }"
                                    color="primary"
                                    :vs-name="'viewRequiredLevel' + i"
                                    disabled
                                    :vs-value="ratingSettings.RequiredLevel.id"
                                />
                            </vs-td>
                            <vs-td>
                                <vs-radio
                                    :key="item.id"
                                    v-model.number="
                                        resource.shownSkills[item.id]
                                    "
                                    v-tooltip="{
                                        content: item.level['level_2'],
                                        classes: ['occ-level']
                                    }"
                                    disabled
                                    :vs-name="'viewIndispensableLevel' + i"
                                    color="primary"
                                    :vs-value="
                                        ratingSettings.IndispensableLevel.id
                                    "
                                />
                            </vs-td>
                            <vs-td>
                                <vs-radio
                                    :key="item.id"
                                    v-model.number="
                                        resource.shownSkills[item.id]
                                    "
                                    v-tooltip="{
                                        content: item.level['level_3'],
                                        classes: ['occ-level']
                                    }"
                                    disabled
                                    :vs-name="'viewAutonomyLevel' + i"
                                    color="primary"
                                    :vs-value="ratingSettings.AutonomyLevel.id"
                                />
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TypeForm from "@components/form/TypeForm";
import SkillsSelector from "@components/form/SkillsSelector";

export default {
    name: "OccupationForm",
    components: {
        Multiselect,
        TypeForm,
        SkillsSelector
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            resource: {},
            sectors: [],
            sectorsLoading: true,
            skills: [],
            skillsLoading: true,
            filterBySector: false,
            formOccupationTypes: []
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.initResource();
        },
        resource: {
            handler() {
                this.value.title = this.resource.title;
                this.value.description = this.resource.description;
                this.value.type = this.resource.type;
                this.value.sector = this.resource.sector;
                this.value.occupation_skills = this.resource.occupation_skills;
                this.$emit("input", this.value);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        }
    },
    computed: {
        hasError() {
            let error = !this.resource.title || !this.resource.type || !this.resource.sector;
            return error;
        },
        usedSkills() {
            if (!this.skillsLoading) {
                return this.skills;
            }
            return [];
        },
        orderedSkills() {
            return this.usedSkills
                .slice()
                .map(obj => ({
                    ...obj,
                    title: obj.admin_skill.title,
                    category_title: obj.admin_skill.category ? obj.admin_skill.category.title : ""
                }))
                .sort(function(a, b) {
                    if (a.category_title > b.category_title) return 1;
                    if (a.category_title < b.category_title) return -1;
                    if (a.title > b.title) return 1;
                    if (a.title < b.title) return -1;
                    return 0;
                });
        }
    },
    methods: {
        async loadSectors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/sectors",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.sectors = response.data.data;
                    this.sectorsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: ["level", "admin_skill.category"] }
                })
                .then(response => {
                    this.skills = response.data.data;
                    this.skillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadOccupationtypes() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations/types",
                    params: {}
                })
                .then(response => {
                    this.formOccupationTypes = response.data.filter(i => i != 'Centralisé');
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        initResource() {
            this.resource = {
                title: this.value.title,
                type: this.value.type,
                sector: this.value.sector,
                description: this.value.description,
                occupation_skills: this.value.occupation_skills
            };

            if (this.type == "show") {
                let shownSkills = {}

                if (this.value.admin_id) {
                    this.value.admin_occupation_skills.forEach(
                        adminOS => {
                            adminOS.adminskills.forEach(adminSkill => {
                                if (
                                    !shownSkills[adminSkill.id] ||
                                    shownSkills[adminSkill.id] <
                                        adminSkill.pivot.required_level
                                ) {
                                    shownSkills[adminSkill.id] =
                                        adminSkill.pivot.required_level;
                                }
                            });
                        }
                    );

                } else {
                    this.value.occupation_skills.forEach(os => {
                        os.skills.forEach(skill => {
                            if (!shownSkills[skill.id] || shownSkills[skill.id] < skill.pivot.required_level) {
                                shownSkills[skill.id] = skill.pivot.required_level
                            }
                        })
                    })
                }

                this.resource.shownSkills = shownSkills;
            }
        },

        onTouchService() {
            if (this.resource.sector) {
                this.filterBySector = true;
            }
            else {
                this.filterBySector = false;
            }
        }
    },
    async created() {
        this.initResource();
        if (this.resource.sector.id) {
            this.filterBySector = true;
        }

        //this.localLoading()
        await Promise.all([
            this.loadOccupationtypes(),
            this.loadSectors(),
            this.loadSkills(),
        ]);
        //this.endLocalLoading();

        new bootstrap.Tooltip(this.$refs.tooltip)
    }
};
</script>

<style scoped>
body > .vs-tooltip {
   display: block !important;
}
</style>
