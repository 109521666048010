var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "vs-row",
            { staticClass: "mb-4", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:w-1/5 w-full mb-4",
                  attrs: { "vs-type": "flex", "vs-align": "flex-start" }
                },
                [
                  _c("span", { staticClass: "font-bold text-lg" }, [
                    _vm._v("Compétences évaluées :")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                { staticClass: "sm:w-3/5 w-full" },
                [
                  _c(
                    "vs-collapse",
                    [
                      _c("vs-collapse-item", [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("p", { staticClass: "mr-6" }, [
                              _c("b", [
                                _vm._v("Synthèse des objectifs et commentaires")
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { "padding-left": "10px !important" }
                          },
                          [
                            _vm.commentedOrGoaledSkills.length
                              ? _c(
                                  "vs-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.commentedOrGoaledSkills
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var data = ref.data
                                            return _vm._l(data, function(
                                              skill,
                                              i
                                            ) {
                                              return _c(
                                                "vs-tr",
                                                { key: "row-" + i },
                                                [
                                                  _c("vs-td", [
                                                    _vm._v(_vm._s(skill.title))
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("vs-td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getRatingLevelLabel(
                                                          skill
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("vs-td", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.value.skills_goals.find(
                                                            function(sg) {
                                                              return (
                                                                sg.skill.id ===
                                                                skill.id
                                                              )
                                                            }
                                                          )
                                                            ? _vm.value.skills_goals.find(
                                                                function(sg) {
                                                                  return (
                                                                    sg.skill
                                                                      .id ===
                                                                    skill.id
                                                                  )
                                                                }
                                                              ).level.label
                                                            : ""
                                                        ) +
                                                        "\n                                        "
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("vs-td", [
                                                    _vm._v(
                                                      _vm._s(skill.comment)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            })
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2947216901
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "thead" },
                                      [
                                        _c("vs-th", [_vm._v("Compétence")]),
                                        _vm._v(" "),
                                        _c("vs-th", [_vm._v("Evaluée")]),
                                        _vm._v(" "),
                                        _c("vs-th", [_vm._v("Objectif fixé")]),
                                        _vm._v(" "),
                                        _c("vs-th", [_vm._v("Commentaires")])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              : _c("div", [_vm._v("/")])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          jobsWish: _vm.value.wishlist_jobs,
          occupationsWish: _vm.value.wishlist_occupations,
          evaluatedOccupationSkills: _vm.value.occupations_skills,
          "display-collapses": true,
          "display-comment-in-collapse": true,
          "occupation-skills-goals": _vm.value.occupation_skills_goals
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.value.externals, function(rating, i) {
        return _c(
          "div",
          { key: i, staticClass: "mt-20 mb-30" },
          [
            _c("h3", { staticClass: "mb-6 pl-5" }, [
              _vm._v(
                "Commentaires de " +
                  _vm._s(rating.creator.last_name) +
                  " " +
                  _vm._s(rating.creator.first_name)
              )
            ]),
            _vm._v(" "),
            _c(
              "vs-row",
              { staticClass: "mb-3" },
              [
                _c(
                  "vs-col",
                  { staticClass: "sm:w-1/5 w-full font-weight-bold" },
                  [_c("b", [_vm._v("Synthèse de l'évaluation :")])]
                ),
                _vm._v(" "),
                _c("vs-col", { staticClass: "sm:w-4/5 w-full" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(rating.comment["Synthèse"]) }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  { staticClass: "sm:w-1/5 w-full font-weight-bold" },
                  [_c("b", [_vm._v("Autres commentaires :")])]
                ),
                _vm._v(" "),
                _c(
                  "vs-col",
                  { staticClass: "sm:w-4/5 w-full" },
                  _vm._l(rating.comment, function(comment, type) {
                    return _c("div", { key: type }, [
                      type != "Synthèse"
                        ? _c("div", [_c("b", [_vm._v(_vm._s(type) + ":")])])
                        : _vm._e(),
                      _vm._v(" "),
                      type != "Synthèse"
                        ? _c("div", {
                            domProps: { innerHTML: _vm._s(comment) }
                          })
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr")
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.resource.comment || Object.values(_vm.resource.comment).length == 0
        ? _vm._l(_vm.ratingFields, function(ratingField) {
            return _c(
              "vs-row",
              { key: ratingField.id, staticClass: "mb-20 mt-10" },
              [
                _c("vs-col", { staticClass: "sm:w-1/5 w-full" }, [
                  _c("span", { staticClass: "font-bold text-lg" }, [
                    _vm._v(_vm._s(ratingField.name) + " :")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "vs-col",
                  { staticClass: "sm:w-4/5 w-full" },
                  [
                    _c("quill-editor", {
                      attrs: {
                        name: "ratingField" + ratingField.id,
                        options: _vm.options,
                        disabled: _vm.typeForm === "consultation"
                      },
                      on: { input: _vm.inputHandle },
                      model: {
                        value: _vm.resource.comment[ratingField.name],
                        callback: function($$v) {
                          _vm.$set(_vm.resource.comment, ratingField.name, $$v)
                        },
                        expression: "resource.comment[ratingField.name]"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        : _vm._l(Object.keys(_vm.resource.comment), function(
            ratingFieldName,
            i
          ) {
            return _c(
              "vs-row",
              { key: i, staticClass: "mb-20 mt-10" },
              [
                _c("vs-col", { staticClass: "sm:w-1/5 w-full" }, [
                  _c("span", { staticClass: "font-bold text-lg" }, [
                    _vm._v(_vm._s(ratingFieldName) + " :")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "vs-col",
                  { staticClass: "sm:w-4/5 w-full" },
                  [
                    _c("quill-editor", {
                      attrs: {
                        name: "ratingField" + i,
                        options: _vm.options,
                        disabled: _vm.typeForm === "consultation"
                      },
                      on: { input: _vm.inputHandle },
                      model: {
                        value: _vm.resource.comment[ratingFieldName],
                        callback: function($$v) {
                          _vm.$set(_vm.resource.comment, ratingFieldName, $$v)
                        },
                        expression: "resource.comment[ratingFieldName]"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }