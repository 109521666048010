<template>
    <div class="select">
        <!--vx-card v-if="errors.length > 0" class="mb-10">
        <vs-row>
          <vs-alert color="warning" vs-icon="new_releases" style="height:auto;">
            Les personnes suivantes n'ont pas de {{ projectNames.singular }} :<br />
            <ul v-for="(error, index) in errors" :key="index">
              <li>- {{ error.last_name }} {{ error.first_name }}</li>
            </ul>
          </vs-alert>
        </vs-row>
      </vx-card-->
        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner une personne</label
                            >
                            <multiselect
                                v-if="
                                    groupedEmployeesByEstablishments.length > 0
                                "
                                v-model="employeeFilter"
                                :options="groupedEmployeesByEstablishments"
                                :multiple="false"
                                group-values="employees"
                                group-label="establishment"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="name"
                                label="name"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un service</label
                            >
                            <multiselect
                                v-if="
                                    groupedServicesByEstablishments.length > 0
                                "
                                v-model="serviceFilter"
                                :options="groupedServicesByEstablishments"
                                :multiple="true"
                                group-values="services"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledServiceFilter || currentOnly == this.currentOnlyValues.OCCUPATIONS"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un ou des postes</label
                            >
                            <multiselect
                                v-if="groupedJobsByService.length > 0"
                                v-model="jobsFilter"
                                :options="groupedJobsByService"
                                :multiple="true"
                                group-values="jobs"
                                group-label="service"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledJobFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un ou des métiers</label
                            >
                            <multiselect
                                v-if="occupationsList.length > 0"
                                v-model="occupationFilter"
                                :options="occupationsList"
                                :multiple="true"
                                group-values="occupations"
                                group-label="type"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :disabled="disabledOccupationFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mb-4 radio-container">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col class="vx-row" vs-align="flex-start">
                            <vs-radio
                                v-model="currentOnly"
                                vs-name="radios1"
                                id="radios1-metiers"
                                class="mr-20 mb-5"
                                :vs-value="currentOnlyValues.OCCUPATIONS"
                            >
                                Métiers
                            </vs-radio>
                            <vs-radio
                                v-model="currentOnly"
                                vs-name="radios1"
                                id="radios1-postes"
                                class="mr-20 mb-5"
                                :vs-value="currentOnlyValues.JOBS"
                            >
                                Postes
                            </vs-radio>
                            <vs-radio
                                v-model="currentOnly"
                                vs-name="radios1"
                                id="radios1-savoirFaire"
                                class="mr-20 mb-5"
                                :vs-value="currentOnlyValues.OCCUPATION_SKILLS"
                            >
                                Savoir-faire
                            </vs-radio>
                            <div class="vx-row">
                                <vs-checkbox
                                    class="mr-20 mb-5"
                                    v-model="wishlistOnly"
                                    name="checkboxWishlistOnly"
                                    :disabled="!this.rating"
                                >
                                    Souhaités uniquement
                                </vs-checkbox>
                                <vs-checkbox
                                    class="mr-20 mb-5"
                                    v-model="checkOnly"
                                    name="checkboxCheckOnly"
                                    :disabled="!this.rating"
                                >
                                    Accessibles en sécurité
                                </vs-checkbox>
                                <!--vs-checkbox
                      class="mr-20 mb-5"
                      v-model="filterByUserRelated"
                      name="checkboxMyKnowOnly"
                      id="checkboxMyKnowOnly"
                      :disabled="disabledFilterByUserRelated"
                    >
                      Mes savoir-faire uniquement
                    </vs-checkbox-->
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mt-6">
            <div v-if="orderedAccessiblityCharts.length > 0">
                <vs-row>
                    <ul
                        id="employee-skills-show"
                        style="columns:4;-webkit-columns:4;-moz-columns:4;"
                    >
                        <li
                            v-for="(chart, i) in orderedAccessiblityCharts"
                            :key="`${i}-${chart.job.id}`"
                        >
                            <a
                                @click="
                                    scrollTo('chart-' + i + '-' + chart.job.id)
                                "
                            >
                                <span
                                    v-tooltip="{
                                        content: chart.job.description
                                    }"
                                >
                                    {{
                                        chart.options.title.text.split(":")[0]
                                    }}</span
                                >
                                :
                                <b class="text-primary">{{
                                    round(chart.scoreNumber * 100, 0) + "% "
                                }}</b>
                            </a>
                            <b v-if="chart.check" style="color: green;">✓</b>
                        </li>
                    </ul>
                </vs-row>
            </div>
        </vx-card>
        <vx-card class="mt-6">
            <vs-row v-if="orderedAccessiblityCharts.length > 0">
                <vs-col
                    v-for="(chart, i) in orderedAccessiblityCharts"
                    :id="`chart-${i}-${chart.job.id}`"
                    :key="`chart-${i}-${chart.score}-${chart.job.id}`"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-6"
                    vs-lg="6"
                    vs-sm="12"
                    v-tooltip="{ content: chart.job.description }"
                >
                    <rating-chart
                        :employee="employeeFilter"
                        class="rating"
                        :options="chart.options"
                        :chart-data="chart.data"
                    />
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import RatingChart from "@components/app/RatingChart";
import ratingService from "@components/services/ratings";

export default {
    components: {
        RatingChart,
        Multiselect
    },
    mixins: [ratingChartsHelper],

    props: {
        skills: {
            type: Array,
            require: true
        },
        adminSkills: {
            type: Array,
            require: true
        },
        skill_occupationSkill: {
            type: Array,
            require: true
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true
        }
    },

    data() {
        return {
            log: console.log,
            errors: [],
            employeeFilter: "",
            serviceFilter: "",
            disabledServiceFilter: true,
            jobsFilter: "",
            disabledJobFilter: true,
            occupationFilter: "",
            disabledOccupationFilter: true,
            wishlistOnly: false,
            checkOnly: false,
            currentOnlyValues: {
                JOBS: 1,
                OCCUPATIONS: 2,
                OCCUPATION_SKILLS: 3
            },
            currentOnly: 1,
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: true,
                    text: `placeholder`,
                    fontSize: 16
                },
                scale: {
                    ticks: {
                        min: -0.5,
                        max: 3,
                        stepSize: 1
                    }
                }
            },
            rating: null,
            loading: false,
            employees: [],
            jobs: [],
            occupations: [],
            occupation_skills: [],
            adminOccupationSkills: [],
            establishments: [],
            colors: [],
            /*,
        filterByUserRelated: true,
        disabledFilterByUserRelated: false*/
        };
    },

    async created() {
        this.loading = true;

        await Promise.all([
            this.loadColors(),
            this.loadEmployees(),
            this.loadJobs(),
            this.loadOccupations(),
            this.loadOccupationskills(),
            this.loadAdminOccupationSkills(),
            this.loadEstablishment(),
        ])

        this.loading = false;
    },

    computed: {

        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                const params = Object.keys(this.ratingSettings.params).map(id => {
                    var param = this.ratingSettings.params[id];
                    param.id = id;
                    return param;
                });
                return params;
            }
            return [];
        },

        // A factoriser, problème de chargement des etablissements lorsque l'on met la fonction dans establishments.js
        shownEstablishments() {
            // Liste des établissements basée sur le périmètre de consultation des personnes
            if (this.establishments && this.establishments.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    const userEstablishments = this.establishments
                        .slice()
                        .filter(e =>
                            userServices
                                .map(service => service.establishment_id)
                                .includes(e.id)
                        );
                    return userEstablishments;
                }
            }
            return [];
        },
        groupedEmployeesByEstablishments() {
            let toShow = [];
            const establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    employees: this.employees
                        ? this.employees
                              .filter(
                                  employee =>
                                      employee.services.length &&
                                      employee.services?.map(s => s.establishment_id).includes(e.id) &&
                                      this.listOfServicesViewableFor(
                                          "employees"
                                      )
                              )
                              .map(employee => {
                                  return {
                                      ...employee,
                                      name: `${employee.last_name.toUpperCase()} ${
                                          employee.first_name
                                      }`
                                  };
                              })
                        : []
                });
            });

            return toShow;
        },
        groupedServicesByEstablishments() {
            let toShow = [];
            const establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services.filter(
                              service =>
                                  service.establishment_id === e.id &&
                                  this.listOfServicesViewableFor(
                                      "jobs"
                                  ).includes(service.id)
                          )
                        : []
                });
            });
            return toShow;
        },

        groupedJobsByService() {
            let toShow = [];
            let services = !this.serviceFilter.length
                ? this.services
                : this.serviceFilter;
            services = services.slice().sort((a, b) => {
                return a.establishment.name.localeCompare(
                    b.establishment.name,
                    "fr",
                    { sensitivity: "base" }
                );
            });

            services.forEach(service => {
                toShow.push({
                    service: service.establishment.name + " / " + service.title,
                    jobs: this.jobs
                        ? this.jobs.filter(
                              j =>
                                  j.service.id === service.id &&
                                  this.listOfServicesViewableFor(
                                      "jobs"
                                  ).includes(service.id)
                          )
                        : []
                });
            });

            return toShow;
        },

        occupationsTypes() {
            let types = this.occupations.map(t => t.type);
            return types
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b));
        },

        occupationsList() {
            if (!this.occupations.length) return [];
            let occupations = this.occupations.slice();
            if (this.serviceFilter.length) {
                occupations = this.occupations.filter(o =>
                    this.serviceFilter
                        .map(s => s.sector_id)
                        .includes(o.sector_id)
                );
            }
            let types = this.occupationsTypes.filter(id =>
                occupations.map(occ => occ.type).includes(id)
            );

            let occupationsList = [];
            types.forEach(type => {
                occupationsList.push({
                    type: type,
                    occupations: occupations
                        .filter(occ => occ.type == type)
                        .sort((a, b) => a.title.localeCompare(b.title))
                });
            });

            return occupationsList;
        },

        selectedEmployeeEstablishmentJobs() {
            let toShow = [];
            if (this.employeeFilter && this.employeeFilter.id) {
                toShow = this.jobsByEstablishments(
                    this.shownEstablishments.map(e => e.id)
                );
            }
            return toShow;
        },

        shownJobs() {
            let jobs = [];
            if (this.jobsFilter && this.jobsFilter.length) {
                jobs = this.jobsFilter;
            } else if (this.serviceFilter && this.serviceFilter.length) {
                jobs = this.jobsByServices(this.serviceFilter.map(sf => sf.id));
            } else {
                jobs = this.selectedEmployeeEstablishmentJobs;
            }

            if (this.wishlistOnly && this.rating) {
                const wishlist = this.rating.wishlist_jobs?.map(job => job.id);
                return jobs.filter(j => wishlist.includes(j.id)).slice();
            }

            return jobs;
        },

        shownOccupations() {
            let occupations = [];
            if (this.occupationFilter && this.occupationFilter.length) {
                occupations = this.occupationFilter;
            } else if (this.serviceFilter && this.serviceFilter.length) {
                occupations = this.occupationsByServices(
                    this.serviceFilter.map(sf => sf.id)
                );
            } else {
                occupations = this.occupations;
            }

            if (this.wishlistOnly && this.rating) {
                const wishlist = this.rating.wishlist_occupations?.map(
                    o => o.id
                );
                return occupations.filter(o => wishlist.includes(o.id));
            }

            return occupations;
        },

        orderedAccessiblityCharts() {
            const charts = [];
            if (
                this.rating &&
                (this.shownJobs.length || this.shownOccupations.length)
            ) {
                const latestRating = this.rating;
                const employee = this.employeeFilter;

                if (
                    this.currentOnly == this.currentOnlyValues.OCCUPATION_SKILLS
                ) {
                    //Switch sur savoir-faire
                    let occupationSkills = [];

                    if (this.occupationFilter.length) {
                        this.shownOccupations.forEach(occupation => {
                            let occ_skills;
                            if (occupation.admin_id) {
                                occ_skills = occupation.admin_occupation_skills.map(
                                    os => this.adminoccupationskillById(os.id)
                                );
                            } else {
                                occ_skills = occupation.occupation_skills.map(
                                    os => this.occupationskillById(os.id)
                                );
                            }

                            occ_skills.forEach(occ => {
                                let exists = occupationSkills.some(os => {
                                    let isAdmin = occ.adminskills
                                        ? true
                                        : false;
                                    return (
                                        os.id == occ.id &&
                                        os.adminskills == isAdmin
                                    );
                                });

                                if (!exists) {
                                    if (occupation.admin_id) {
                                        occ.skills = occ.adminskills.map(
                                            obj => ({
                                                ...obj,
                                                admin_skill_id: obj.id
                                            })
                                        ); //fake data to be compatible with actual system
                                    }
                                    occupationSkills.push(occ);
                                }
                            });
                        });
                    } else {
                        this.shownJobs.forEach(job => {
                            job.occupation_skills.forEach(occupation_skill => {
                                if (
                                    !occupationSkills
                                        .map(o => o.id)
                                        .includes(occupation_skill.id)
                                ) {
                                    occupationSkills.push(occupation_skill);
                                }
                            });
                        });
                    }
                    /*if (this.filterByUserRelated) { //Only OccupationSkills used in Jobs of user's establishments
              let servicesIds = this.establishmentsServicesIds
              occupationSkills = occupationSkills.filter((os) => {
                if (os.jobs?.length) {
                  return !!os.jobs.filter(j => servicesIds.includes(j.service_id)).length
                }
                return false
              })
            }*/
                    occupationSkills.forEach(occupation_skill => {
                        const scoreOccupationSkills = this.employeeAccessibility(
                            latestRating,
                            occupation_skill,
                            "occupation_skill"
                        );
                        charts.push({
                            score:
                                this.round(scoreOccupationSkills[0] * 100, 0) +
                                "% " +
                                (scoreOccupationSkills[1] ? "✓" : ""),
                            scoreNumber: scoreOccupationSkills[0],
                            check: scoreOccupationSkills[1],
                            options: this.chartOptionsWith(
                                employee,
                                this.round(scoreOccupationSkills[0] * 100, 0) +
                                    "% " +
                                    (scoreOccupationSkills[1] ? "✓" : ""),
                                latestRating,
                                occupation_skill,
                                "occupation_skill"
                            ),
                            data: this.employeeAccessibilityChartData(
                                latestRating,
                                occupation_skill,
                                "occupation_skill"
                            ),
                            job: occupation_skill
                        });
                    });
                } else if (
                    this.currentOnly == this.currentOnlyValues.OCCUPATIONS
                ) {
                    //(Occupations)

                    this.shownOccupations.forEach(occupation => {
                        const score = this.employeeAccessibility(
                            latestRating,
                            occupation,
                            "occupation"
                        );
                        charts.push({
                            score:
                                this.round(score[0] * 100, 0) +
                                "% " +
                                (score[1] ? "✓" : ""),
                            scoreNumber: score[0],
                            check: score[1],
                            options: this.chartOptionsWith(
                                employee,
                                this.round(score[0] * 100, 0) +
                                    "% " +
                                    (score[1] ? "✓" : ""),
                                latestRating,
                                occupation,
                                "occupation"
                            ),
                            data: this.employeeAccessibilityChartData(
                                latestRating,
                                occupation,
                                "occupation"
                            ),
                            job: occupation
                        });
                    });
                } else {
                    // this.currentOnly == this.currentOnlyValues.JOBS (Jobs)
                    this.shownJobs.forEach(job => {
                        const score = this.employeeAccessibility(
                            latestRating,
                            job,
                            "job"
                        );
                        charts.push({
                            score:
                                this.round(score[0] * 100, 0) +
                                "% " +
                                (score[1] ? "✓" : ""),
                            scoreNumber: score[0],
                            check: score[1],
                            options: this.chartOptionsWith(
                                employee,
                                this.round(score[0] * 100, 0) +
                                    "% " +
                                    (score[1] ? "✓" : ""),
                                latestRating,
                                job,
                                "job"
                            ),
                            data: this.employeeAccessibilityChartData(
                                latestRating,
                                job
                            ),
                            job: job
                        });
                    });
                }

                charts.sort((a, b) =>
                    a.scoreNumber === b.scoreNumber
                        ? b.check
                            ? 1
                            : -1
                        : b.scoreNumber - a.scoreNumber
                );
            }

            return this.checkOnly
                ? charts.filter(
                      chart => chart.score.substr(chart.score.length - 1) == "✓"
                  )
                : charts;
        }
    },

    watch: {
        async employeeFilter(newValue) {
            if (newValue) {
                this.rating = null;
                this.loading = true;
                let rating = (
                    await ratingService.getLastRatings({
                        employee_ids: [this.employeeFilter.id],
                        count: 1
                    })
                )[this.employeeFilter.id];
                rating?.forEach(tempRating => {
                    this.reconstructRating(tempRating)
                })
                this.loading = false;

                if (rating && rating.length) {
                    if (rating[0].employee_id == this.employeeFilter.id) {
                        this.rating = rating[0];
                    }
                } else {
                    if (
                        !this.errors
                            .map(e => e.id)
                            .includes(this.employeeFilter.id)
                    ) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.errors.push(this.employeeFilter);
                    }
                }
                this.disabledJobFilter = false;
                this.disabledServiceFilter = false;
                this.disabledOccupationFilter = false;
            } else {
                this.rating = null;
                this.disabledJobFilter = true;
                this.disabledServiceFilter = true;
                this.disabledOccupationFilter = true;
            }
            this.wishlistOnly = false;
            this.serviceFilter = "";
            this.jobsFilter = "";
            this.occupationFilter = "";
        },

        serviceFilter(newValue) {
            if (newValue) {
                this.jobsFilter = "";
                this.occupationFilter = "";
            }
        },

        jobsFilter(newValue) {
            if (newValue.length) {
                this.occupationFilter = "";
                /*this.filterByUserRelated = false
          this.disabledFilterByUserRelated = true*/
                if (
                    this.currentOnly !==
                    this.currentOnlyValues.OCCUPATION_SKILLS
                )
                    this.currentOnly = this.currentOnlyValues.JOBS;
            }
        },

        occupationFilter(newValue) {
            if (newValue.length) {
                this.jobsFilter = "";
                /*this.filterByUserRelated = false
          this.disabledFilterByUserRelated = true*/
                if (
                    this.currentOnly !==
                    this.currentOnlyValues.OCCUPATION_SKILLS
                )
                    this.currentOnly = this.currentOnlyValues.OCCUPATIONS;
            }
        },

        currentOnly(newValue) {
            if (newValue === this.currentOnlyValues.OCCUPATIONS) {
                this.jobsFilter = "";
            } else if (newValue === this.currentOnlyValues.JOBS) {
                this.occupationFilter = "";
            }
        },

        loading(newValue) {
            if (newValue) {
                this.localLoading();
            } else {
                this.endLocalLoading();
            }
        }
    },
    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    this.colors = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, 'include': ['services.establishment', 'currentJobs', 'currentOccupations'] }
                })
                .then(response => {
                    this.employees = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: { perpage: 9999, include: ['service', 'occupation_skills'] }
                })
                .then(response => {
                    let jobs = response.data.data
                    jobs.forEach(j => {
                        j.occupation_skills.forEach(jos => {
                            this.reconstructSkillOccupationSkillRelation(jos, this.skill_occupationSkill)
                        })
                    })
                    this.jobs = jobs;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        // Ajout - à l'image du store
        jobsByEstablishment(id) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return null;
            }

            const services = this.services.filter(
                service => service.establishment.id == id
            )

            const serviceIds = services.slice().map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        jobsByEstablishments(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return null;
            }

            const services = this.services.filter(service =>
                ids.includes(service.establishment.id)
            )
            const serviceIds = services.slice().map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        jobsByServices(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return null;
            }

            const serviceIds = this.services.filter(s => ids.includes(s.id)).map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills",
                            "occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    occupations.forEach(o => {
                        o.occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.skill_occupationSkill, false)
                        })
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.adminSkill_adminOccupationSkill, true)
                        })
                    })
                    this.occupations = occupations;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationsByServices(ids) {
            if (this.occupations.length == 0 || this.services.length == 0) {
                return null;
            }

            const sectorIds = this.services.filter(s => ids.includes(s.id)).map(service => service.sector_id)
            return this.occupations.filter(o => sectorIds.includes(o.sector_id))
        },

        async loadOccupationskills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"],
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data
                    occupationskills.forEach(os => {
                        this.reconstructSkillOccupationSkillRelation(os, this.skill_occupationSkill, false)
                    })
                    this.occupation_skills = occupationskills
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupation_skills.find(os => os.id == id);
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: {'title': 'asc'} }
                })
                .then(response => {
                    let adminOccupationskills = response.data.data
                    adminOccupationskills.forEach(aos => {
                        this.reconstructSkillOccupationSkillRelation(aos, this.adminSkill_adminOccupationSkill, true)
                    })
                    this.adminOccupationSkills = adminOccupationskills
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        adminoccupationskillById(id) {
            return this.adminOccupationSkills.find(os => os.id == id);
        },

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        scrollTo(id) {
            const container = document.getElementById("content-area");
            const target = document.getElementById(id);
            const offset = 100;
            container.scrollTo({
                top: target.getBoundingClientRect().top - offset,
                behavior: "smooth"
            });
        },
        chartOptionsWith(employee, score, rating, entity, type) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            options.scale.ticks.min = this.ratingSettings
                ? this.ratingSettings.RadarCenter
                : -1;
            /*const hasThisJob = employee.jobs
          ? employee.jobs.filter(j => j.id === entity.id).length > 0
          : false
        const wantsThisJob = rating.wishlist_jobs
          ? rating.wishlist_jobs.filter(j => j.id === entity.id).length > 0
          : false
        options.title.text = `${entity.title}${hasThisJob ? ' (actuel)': ''}${
          wantsThisJob ? ' (souhaité)': ''
        } : ${score}`*/

            switch (type) {
                case "job": {

                    //entity = job
                    const hasThisJob = employee.current_jobs
                        ? employee.current_jobs.filter(j => j.id === entity.id).length >
                          0
                        : false;
                    const wantsThisJob = rating.wishlist_jobs
                        ? rating.wishlist_jobs.filter(j => j.id === entity.id)
                              .length > 0
                        : false;
                    options.title.text = `${entity.title}${
                        hasThisJob ? " (actuel)" : ""
                    }${wantsThisJob ? " (souhaité)" : ""} : ${score}`;
                    break;
                }
                case "occupation": {
                    const hasThisOccupation = employee.current_occupations
                        ? employee.current_occupations.filter(o => o.id === entity.id)
                              .length > 0
                        : false;
                    const wantsThisOccupation = rating.wishlist_occupations
                        ? rating.wishlist_occupations.filter(
                              o => o.id === entity.id
                          ).length > 0
                        : false;
                    options.title.text = `${entity.title}${
                        hasThisOccupation ? " (actuel)" : ""
                    }${wantsThisOccupation ? " (souhaité)" : ""} : ${score}`;
                    break;
                }
                case "occupation_skill": {
                    //entity = occupation_skill
                    const occupationSkillRatingValue = !!rating
                        .occupations_skills?.length
                        ? rating.occupations_skills
                              .find(rOs => rOs.id === entity.id)
                              ?.pivots.find(
                                  p => p.occupation_skill_id === entity.id
                              )?.level
                        : null;
                    const occupationSkillRatingText = this.ratingParams.find(
                        param => +param.id === occupationSkillRatingValue
                    )?.label;
                    const hasThisJob = employee.jobs
                        ? employee.jobs.filter(j =>
                              j.occupation_skills
                                  .map(os => os.id)
                                  .includes(entity.id)
                          ).length > 0
                        : false;
                    const wantsThisJob = rating.wishlist_jobs
                        ? rating.wishlist_jobs.filter(j =>
                              j.occupation_skills
                                  .map(os => os.id)
                                  .includes(entity.id)
                          ).length > 0
                        : false;
                    options.title.text = `${entity.title +
                        (entity.establishment
                            ? " - " + entity.establishment.name
                            : "")}${hasThisJob ? " (actuel)" : ""}${
                        wantsThisJob ? " (souhaité)" : ""
                    }${
                        occupationSkillRatingValue > 1
                            ? " (" + occupationSkillRatingText + ")"
                            : ""
                    } : ${score}`;
                    break;
                }
            }
            options.tooltips = {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    },
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value =
                            data.datasets[tooltipItem.datasetIndex].levels[
                                tooltipItem.index
                            ];
                        return ` ${label}  :  ${value}`;
                    }
                }
            };
            return options;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.select::v-deep .multiselect {
    font-size: 14px;
    color: grey;
    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }
    .multiselect__single {
        font-size: 14px;
        color: grey;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;
        &-icon:hover {
            background: red;
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }
    .multiselect__select:before {
        top: 45%;
    }
    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        color: grey;
    }

    .multiselect__content {
        background: $primary-light;
    }

    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: red;
            }
        }
    }
}

a {
    cursor: pointer;
}

.radio-container {
    z-index: 0;
    ::v-deep.vx-card__body {
        padding-bottom: 0.5em;
        .vx-row {
            margin-left: 0;
            .con-vs-checkbox {
                margin-left: 0;
                @media screen and (max-width: 600px) {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
</style>
