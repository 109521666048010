var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.getStyle, attrs: { id: "employeesCard" } },
    [
      _c("div", {
        staticClass: "overlayed",
        style: _vm.images[0][0].exists
          ? _vm.images[0][0].custom
          : _vm.images[0][0].default
      }),
      _vm._v(" "),
      _c("vx-card", [
        _c("h2", { staticClass: "mb-2" }, [
          _vm._v("\n            Personnes\n        ")
        ]),
        _vm._v(" "),
        _c("ul", { staticStyle: { "min-height": "50px" } }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "link",
                attrs: { name: "cardProfil", href: "/rh/gestion/personnes" }
              },
              [_vm._v("Consulter un profil")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  name: "cardEntreeEmploye",
                  href: "/rh/gestion/tableaux/personnes"
                }
              },
              [_vm._v("Créer un parcours de mobilité")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }