!function() {
    'use strict'

    function data_get(source, key, def = null) {
        let value = source;
        let segments = key.split('.');
        let data;
        for (var i in segments) {

            if (typeof value === 'undefined' || value === null ||
                typeof value[segments[i]] === 'undefined' || value[segments[i]] === null
            ) {
                data = def;
                break;
            }

            data = value = value[segments[i]];
        }

        return data;
    }

    /**
     * export to either browser or node.js
     */
    /* eslint-disable quote-props */
    if (typeof exports !== 'undefined') {
        exports['data_get'] = data_get
    }
    if (typeof window !== 'undefined') {
        window['data_get'] = data_get

        if (typeof define === 'function' && define['amd']) {
            define(function() {
                return {
                    'data_get': data_get
                }
            })
        }
    }
    /* eslint-enable quote-props */
}();
