var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col sm:w-2/3 w-full" },
        [
          _vm.type != "show"
            ? _c("vs-input", {
                staticClass: "w-full",
                attrs: { name: "title" },
                model: {
                  value: _vm.resource.title,
                  callback: function($$v) {
                    _vm.$set(_vm.resource, "title", $$v)
                  },
                  expression: "resource.title"
                }
              })
            : _c("b", [_vm._v(_vm._s(_vm.resource.title))])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col sm:w-2/3 w-full" },
        [
          _vm.type != "show"
            ? _c(
                "vs-select",
                {
                  staticClass: "w-full",
                  attrs: { name: "title" },
                  model: {
                    value: _vm.resource.type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "type_id", $$v)
                    },
                    expression: "resource.type_id"
                  }
                },
                _vm._l(_vm.types, function(type, i) {
                  return _c("vs-select-item", {
                    key: i,
                    attrs: { value: type.id, text: type.name }
                  })
                }),
                1
              )
            : _c("b", [_vm._v(_vm._s(_vm.resource.title))])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
        _c("span", [
          _vm._v("Modèle"),
          _vm.type == "create" ? _c("span", [_vm._v("*")]) : _vm._e(),
          _vm._v(" :")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col sm:w-2/3 w-full" },
        [
          _vm.type != "show"
            ? _c("vs-input", {
                staticClass: "w-full",
                attrs: { type: "file", name: "model" },
                on: { change: _vm.changeFileHandle }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("b", [
            _c(
              "a",
              { attrs: { href: "#" }, on: { click: _vm.downloadFileClick } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.resource.original_filename) +
                    "\n                "
                )
              ]
            )
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col sm:w-2/3 w-full" },
        [
          _c("vs-switch", {
            attrs: { name: "visibility", disabled: _vm.type == "show" },
            model: {
              value: _vm.resource.visibility,
              callback: function($$v) {
                _vm.$set(_vm.resource, "visibility", $$v)
              },
              expression: "resource.visibility"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Titre* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Type* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Visibilité hors admin :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }