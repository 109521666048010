var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-tabs",
        { staticClass: "mt-10" },
        [
          _c(
            "vs-tab",
            { staticClass: "pt-10", attrs: { label: "Savoir-faire" } },
            [
              _c("OccupationSkillsTab", {
                attrs: { api: _vm.api, perpage: _vm.perpage }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canSeeReferentiel()
            ? _c(
                "vs-tab",
                { staticClass: "pt-10", attrs: { label: "Référentiel" } },
                [
                  _c("AdminOccupationSkillsTab", {
                    attrs: {
                      api: _vm.api + "/referential",
                      perpage: _vm.perpage
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }