var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      _c(
        "template",
        { slot: "actions" },
        [
          _c("Import-Export", {
            attrs: {
              name: "services",
              "import-url": "/api/referentiel/occupations/import",
              "import-acl": true,
              "export-url": "/api/referentiel/occupations/export",
              "export-acl": true
            },
            on: { imported: _vm.refreshData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-tabs",
        { staticClass: "mt-10" },
        [
          _c(
            "vs-tab",
            { staticClass: "pt-10", attrs: { label: "Métier" } },
            [
              _c("OccupationsTab", {
                attrs: {
                  api: _vm.api,
                  perpage: _vm.perpage,
                  freshImport: _vm.refreshDataAfterImport
                },
                on: {
                  "data-refresh": function($event) {
                    _vm.refreshDataAfterImport = false
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canSeeReferentiel()
            ? _c(
                "vs-tab",
                { staticClass: "pt-10", attrs: { label: "Référentiel" } },
                [
                  _c("AdminOccupationsTab", {
                    attrs: {
                      api: _vm.api + "/referential",
                      perpage: _vm.perpage
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }