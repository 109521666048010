<template>
    <div class="switch-container">
        <p :class="{ active: isLinesView }">
            Bulles
        </p>
        <div class="switch-button-control">
            <div
                class="switch-button"
                :class="{ enabled: isLinesView }"
                @click="setIsLinesView"
            >
                <div class="button" />
            </div>
        </div>
        <p :class="{ active: !isLinesView }">
            Lignes
        </p>
    </div>
</template>

<script>
export default {
    name: "DisplaySwitch",

    props: {
        isLinesView: Boolean,
        setIsLinesView: Function
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";
.switch-container {
    display: flex;
    p {
        color: $primary;
        margin: 10px;
        transition: all 0.3s ease-in-out;
        &.active {
            color: $gray;
        }
    }
}

.switch-button-control {
    display: flex;
    flex-direction: row;
    align-items: center;

    .switch-button {
        $switch-button-height: 1.3em;
        $switch-button-color: $primary;
        $switch-button-border-thickness: 0px;
        $switch-transition: all 0.3s ease-in-out;
        $switch-is-rounded: true;

        height: $switch-button-height;
        width: calc(#{$switch-button-height} * 2);
        background: $primary-light;
        border-radius: if($switch-is-rounded, $switch-button-height, 0);

        transition: $switch-transition;

        $button-side-length: calc(
            #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
        );

        cursor: pointer;

        .button {
            height: $button-side-length;
            width: $button-side-length;
            border-radius: if($switch-is-rounded, $button-side-length, 0);
            background: $switch-button-color;
            transition: $switch-transition;
        }

        &.enabled {
            .button {
                background: $primary;
                transform: translateX(
                    calc(
                        #{$button-side-length} + (2 *#{$switch-button-border-thickness})
                    )
                );
            }
        }
    }

    .switch-button-label {
        margin-left: 10px;
    }
}
</style>
