<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    id="saveResource"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="showFormCreate"
                >
                    Ajouter une nouvelle personne
                </vs-button>

                <vs-prompt
                    :active.sync="showPopup"
                    title="Ajouter une personne"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                <EmployeeForm
                    v-model="resource"
                    formType="create"
                    @error="setFormError"
                />
                </vs-prompt>

            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            >
            <template slot="thead">
                <vs-th><Sort name="last_name" @sort="eventSortByCol">Nom</Sort></vs-th>
                <vs-th><Sort name="first_name" @sort="eventSortByCol">Prénom</Sort></vs-th>
                <vs-th><Sort name="services.establishment,name" @sort="eventSortByCol">Établissements</Sort></vs-th>
                <vs-th><Sort name="services,title" @sort="eventSortByCol">Services</Sort></vs-th>
                <!--vs-th>Établissements</vs-th>
                <vs-th>Services</vs-th-->
                <vs-th>Postes occupés</vs-th>
                <vs-th>Métiers occupés</vs-th>
                <vs-th v-if="canSeeSwitchMobility">Compte portail</vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in employees" :key="i">
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + item.id + '/bilans'">
                            {{ get(item, ':last_name') }}
                        </a>

                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + item.id + '/bilans'">
                            {{ get(item, ':first_name') }}
                        </a>
                    </vs-td>

                    <vs-td>
                        {{ arrayUnique(get(item, ':active_establishments').map(s => s.name)).join(', ') }}
                        </vs-td>
                    <vs-td>
                        {{ arrayUnique(get(item, ':active_services').map(s => s.title)).join(', ') }}
                        </vs-td>
                    <vs-td>
                        <vs-chip v-for="(el, index) in get(item, ':current_jobs')" :key="index">
                            <b>{{ el.title }}</b>
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <vs-chip v-for="(el, index) in get(item, ':current_occupations')" :key="index">
                            <b>{{ el.title }}</b>
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <div v-if="planAllow('auto-eval-falc-portail')">
                            <vs-switch
                                v-if="canSeeSwitchMobility"
                                v-model="items.data[i].mobility"
                                :disabled="!canMulti('update', 'employees', item.active_services.map(s => s.id))"
                                @click.prevent.stop="eventSwitchMobility(i)"
                            />
                        </div>

                        <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                            <vs-switch
                                v-model="items.data[i].mobility"
                                :disabled="true"
                            />
                            <vs-icon class="button-bullet" bg="#FF9B3A" round >
                                <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                            </vs-icon>
                        </div>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('read', 'employee_ratings')"
                                :id="'ratingEmployee_' + i"
                                :href="'/rh/gestion/personnes/' + item.id + '/bilans'"
                                v-tooltip="'Evaluer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                            />

                            <vs-button
                                :id="'resumeEmployee_' + i"
                                :href="'/rh/gestion/parcours?employee=' + item.id"
                                v-tooltip="'Parcours graphique'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-wind"
                            />

                            <vs-button
                                :id="'modifyEmployee_' + i"
                                :href="'/rh/gestion/personnes/' + item.id + '/parcours'"
                                v-tooltip="'Compléter le parcours'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                            />

                            <vs-button
                                v-if="isASuperAdmin()"
                                :id="'deleteEmployee_' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click.stop="removeResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>
import objectToFormData from "object-to-formdata";
import EmployeeForm from '@components/views/rh/gestion/employee/EmployeeForm';
import CrownIcon from '@components/svg/CrownIcon';

export default {
    name: 'Employees',
    components: {
        EmployeeForm, CrownIcon
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            config: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: []
            },
            formError: true
        }
    },

    computed: {
        canSeeSwitchMobility() {
            return this.can('update', 'employees')
        },

        employees() {
            return this.items.data.map(el => {
                el.mobilityEditable = window._.intersection(this.authorizedEstablishmentsByLicence('mobilite'), el.active_establishments.map(s => s.id)).length
                return el;
            })
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    methods: {
        eventSwitchMobility: function(id) {
            let mobility = this.items.data[id].mobility;
            this.$swal({
                title: !mobility ? 'Vous êtes sur le point d\'activer le compte Portail pour cette personne. Confirmez-vous ?' : 'L\'accès au Portail sera suspendu. Souhaitez-vous confirmer ?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    mobility = !mobility
                    this.items.data[id].mobility = mobility;
                    let user_id = this.items.data[id].id;

                    window.axios({
                        method: 'post',
                        url: `${this.api}/${user_id}/mobility`,
                        params: {mobility: mobility}
                    })
                    .then(response => {
                        if (mobility) {
                            let e = response.data.mobility_data
                            let url = e.autoLoginUrl
                            //this.$swal(`Identifiant: ${e.username} \nMot de passe: ${e.password}`, '', 'success')
                            this.$swal({
                                //title: `Identifiant: ${e.username} \nMot de passe: ${e.password} \nVous pouvez aller sur <a href=${process.env.MIX_FRONTMOBILITE_URL}/security/connexion target="_blank" >${process.env.MIX_FRONTMOBILITE_URL}</a>`,
                                title: `Identifiant: ${e.username} \nMot de passe: ${e.password} \nVous pouvez aller sur myskilliz.com`,
                                confirmButtonText: 'Connexion automatique',
                                showCloseButton: true,
                            }).then(async result => {
                                if (result.value) {
                                    //window.open(process.env.MIX_FRONTMOBILITE_URL+'/security/connexion?login='+e.username+'&password='+e.password, '_blank')
                                    window.open(url)
                                }
                            });
                        }
                    })
                    .catch(err => {
                        this.items.data[id].mobility = !mobility
                        this.notifyError(err, 'Erreur !', true)
                    })
                }

            })
        },

        showFormCreate() {
            this.resetResource();
            this.showPopup = true
        },

        saveResource() {

            let services = {};
            this.resource.services.forEach((item, i) => {
                services[i] = {id: item.id, etp: item.etp};
            });

            let establishments = {};
            this.resource.establishments.forEach((item, i) => {
                establishments[i] = {id: item.id, start_date: item.start_date}
            });

            let data                 = window._.cloneDeep(this.resource);
                data.occupations     = data.occupations ? data.occupations.map(el => el.id) : null;
                data.jobs            = data.jobs ? data.jobs.map(el => el.id) : null;
                data.services = services;
                data.establishments  = establishments;

            if (data.id) {
                this.apiUpdate(data, data.id, () => this.loadItems());
            } else {
                window.axios({
                    method: 'post',
                    url: `${this.api}`,
                    data: objectToFormData(data),
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(response => {

                    if (response.status === 200 && response.data != '') {
                        this.notifySuccess('Succès', 'Élement modifié avec succès !')
                        this.loadItems();
                    } else {
                        this.notifyError('Erreur', `Une erreur est survenue lors de la création`)
                    }

                })
                .catch((err, data) => {
                    this.notifyErrorValidation(err)
                    this.showPopup = true

                })
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: []
            }
        },

        setFormError (value) {
            this.formError = value;
        }
    },
    created() {
        this.apiParams.include = [
            //'services.establishment',
            'active_establishments',
            'active_services.establishment',
            'currentJobs.occupation_skills',
            'currentJobs.service',
            'currentOccupations'
        ];
        this.loadItems();
    }
}
</script>
<style lang="css">
.swal2-title {
    display:block !important;
}
.swal2-popup {
    width: 35em !important;
}

</style>
