<template>
    <div>
        <div class="vx-card__title mb-8">
            <h4 class="mb-4">Réinitialiser le mot de passe</h4>
            <p>
                Entrez votre email et votre nouveau mot de passe.
            </p>
            <div>
                Votre mot de passe doit contenir :
                <ul>
                    <li>12 caractères minimum</li>
                    <li>
                        au moins :
                        <ul>
                            <li>1 majuscule</li>
                            <li>1 minuscule</li>
                            <li>1 chiffre</li>
                            <li>1 caractère spécial</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <vs-alert v-if="errors.length > 0" color="danger" icon="new_releases">
                <span
                    v-for="error in errors"
                    :key="errors.indexOf(error)"
                >{{ error }}<br /></span>
            </vs-alert>
            <vs-alert v-if="success" color="success" icon="check">
                <span>Votre mot de passe a été réinitialisé avec succès !</span>
            </vs-alert>
            <vs-input
                name="email"
                v-model="localEmail"
                :success="validateEmail() && errors.length === 0"
                :danger="
                    localEmail !== '' && (errors.length > 0 || !validateEmail())
                "
                icon="icon icon-user"
                icon-pack="feather"
                label-placeholder="Email"
                class="w-full no-icon-border"
            />
            <input-password
                name="password"
                v-model="password"
                :success="!!(validatePassword() && !errors.length)"
                :danger="!!(password !== confirm || errors.length)"
                class="mt-6 no-icon-border"
                placeholder="Mot de passe"
                :inputClasses="'w-full'"
            />
            <input-password
                name="password_confirmation"
                v-model="confirm"
                :success="!!(validatePassword() && confirm === password && !errors.length)"
                :danger="!!(password !== confirm || errors.length)"
                class="mt-6 no-icon-border"
                placeholder="Confirmer Mot de passe"
                :inputClasses="'w-full'"
            />
            <vs-input type="hidden" name="token" :value="token"></vs-input>
            <div class="flex flex-wrap justify-between my-5">
                <div class="my-5 placeholder">
                    <vs-button class="float-right" id="reset-password-submit" button="submit" :disabled="!isValidResetForm">Réinitialiser</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputPassword from '../theme/InputPassword';

export default {
    name: "ResetPassword",
    components: {
        'input-password': InputPassword,
    },
    props: {
        errors: {
            type: Array|Object,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            localEmail: '',
            password: '',
            confirm: '',
            remember: true,
            loading: false,
            success: false,
        }
    },
    computed: {
        //useless ?
        isValidLoginForm() {
        return this.validateEmail() && this.password && !this.loading
        },
        isValidResetForm() {
        return (
            this.validateEmail() &&
            this.validatePassword() &&
            this.password === this.confirm &&
            !this.loading
        )
        }
    },
    mounted() {
        this.localEmail = this.email
    },
    methods: {
        validateEmail() {
            // eslint-disable-next-line no-useless-escape
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(this.localEmail).toLowerCase())
        },

        validatePassword() {
            return (
                (this.password.length >= 12) &&
                (this.password.match(/[A-Z]/g)) &&
                (this.password.match(/[a-z]/g)) &&
                (this.password.match(/[0-9]/g)) &&
                (this.password.match(/[^a-zA-Z\d]/g))
            )
        },

        resetPassword() {
            //this.$emit('submit-reset-form')
            //this.errors = []
            /*this.success = false
            this.showLoading()
            axios.post('/reset-password', {
                email: this.email,
                password: this.password,
                password_confirmation: this.confirm,
                token: this.token
            })
            .then(() => {
                this.hideLoading()
                window.location.replace('/')
            })
            .catch(error => {
                this.hideLoading()
                if (error.response.status == 422) {
                    //const errs = error.response.data.errors
                    //Object.keys(errs).forEach(field => {
                    //this.errors.push(errs[field][0])
                    //})
                } else {
                    this.errors.push(
                    'Something went wrong, please refresh and try again.'
                    )
                }
            })*/
        },
        showLoading() {
            this.loading = true
        },
        hideLoading() {
            this.loading = false
        }
    },
    created() {
        console.log(this.errors);
    }
}
</script>

<style lang="scss">
ul {
  margin-left: 15px;
}
li {
    list-style-type: circle;
}

li li {
    list-style-type: square;
}
</style>
