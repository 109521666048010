<template>
    <ul>
        <li v-for="{ value, name, icon, isChecked } in togglesList" :key="name">
            <label class="checkbox">
                <input
                    :id="value"
                    type="checkbox"
                    :checked="!!isChecked"
                    :value="value"
                    @change="e => handleChangeToggle(e.target.value)"
                />
                <span class="checkmark" :for="value" />
            </label>
            <span>{{ name }}</span>
            <img :src="icon" :alt="name" />
        </li>
    </ul>
</template>

<script>

import FormationSVG from "@assets/images/icons/formations.svg";
import ImmersionSVG from "@assets/images/icons/immersions.svg";
import MetierSVG from "@assets/images/icons/metiers.svg";
import EtablissementSVG from "@assets/images/icons/etablissements.svg";
import PosteSVG from "@assets/images/icons/postes.svg";
import BilanSVG from "@assets/images/icons/bilans.svg";

export default {
    name: "Legende",

    props: {
        setActiveLegend: Function,
        names: Object,
    },

    data: function() {
        var selectedToggles = [
            "employee_rating_occupation_wishlists",
            "employee_rating_job_wishlists"
        ];
        let togglesList = [
            {
                name: "Formations",
                value: "trainings",
                icon: FormationSVG,
                isChecked: true
            },
            {
                name: "Immersions",
                value: "Immersions",
                icon: ImmersionSVG,
                isChecked: true
            },
            {
                name: "Métiers",
                value: "occupations",
                icon: MetierSVG,
                isChecked: true
            },
            {
                name: "Établissement actuel",
                value: "establishments",
                icon: EtablissementSVG,
                isChecked: true
            },
            {
                name: "Postes / Ateliers",
                value: "jobs",
                icon: PosteSVG,
                isChecked: true
            },
            {
                name: this.names.plural,
                value: "employee_ratings",
                icon: BilanSVG,
                isChecked: true
            }
        ];
        togglesList.forEach(item => selectedToggles.push(item.value));
        this.setActiveLegend(selectedToggles);
        return {
            togglesList: togglesList,
            selectedToggles: selectedToggles
        };
    },

    watch: {
        selectedToggles: function() {
            this.setActiveLegend(this.selectedToggles);
        }
    },

    methods: {
        handleChangeToggle: function(value) {
            var found = this.selectedToggles.find(item => item === value);
            var list = this.selectedToggles;
            if (found) {
                this.selectedToggles = this.selectedToggles.filter(
                    item => item !== value
                );
            } else {
                list.push(value);
                this.selectedToggles = list;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    li {
        display: flex;
        align-items: center;
        img {
            width: 45px;
        }
    }
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 1px #d6dee3;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
}

.checkbox:hover input ~ .checkmark {
    background-color: $primary-light;
}

.checkbox input:checked ~ .checkmark {
    border: solid 1px $primary;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $primary;
}
</style>
