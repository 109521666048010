<template>
    <div>
        <vs-tabs>
            <vs-tab label="Utilisateurs" class="pt-10">
                <UserTab
                    :api=this.api
                    :perpage=this.perpage
                    userType="Utilisateur"
                />
            </vs-tab>
            <vs-tab label="Evaluateurs" class="pt-10">
                <UserTab
                    :api=this.api
                    :perpage=this.perpage
                    userType="Evaluateur"
                />
            </vs-tab>
        </vs-tabs>

    </div>
</template>

<script>
import UserTab from "@components/views/rh/referentiel/user/UserTab";

export default {
    name: "User",
    components: {
        UserTab
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
};
</script>

