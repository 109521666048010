var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt mb-6 scrollBug" },
    [
      _c("SectorsSelector", {
        model: {
          value: _vm.resource.sectors,
          callback: function($$v) {
            _vm.$set(_vm.resource, "sectors", $$v)
          },
          expression: "resource.sectors"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }