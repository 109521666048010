import axios from "axios";

export default {
    /**
     * Get rating by id
     * @returns {Object}  rating object
     * @param ratingId
     */
    async getRating(ratingId) {
        return axios
            .get("/api/gestion/employees/0/ratings/" + ratingId)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Get rating by id
     * @param {Number} employeeId employee id
     * @returns {Object}  rating object
     */
    async getAllRatings({ employeesIds = null }) {
        return axios
            .get("/api/gestion/employees/0/ratings/all", { params: { employeesIds } })
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Get rating by id
     * @param {Number} ids ratings ids
     * @param {Number} withMobility include Mobility ratings (with rating status = 2)
     * @returns {Array}  Array of ratings
     */
    async getRatings({ ratingsIds, withMobility = 0 }) {
        return axios
            .get("/api/gestion/employees/0/ratings/ids", {
                params: { ids: ratingsIds, withMobility }
            })
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Get last ratings, by employee
     * @param {Array} employee_ids array of employee id
     * @param {Number} count count of ratings to be recovered
     * @param {Number} withMobility include Mobility ratings (with rating status = 2)
     * @returns {Array} Array of ratings
     */
    async getLastRatings({ employee_ids, count = 2, withMobility = 0 }) {
        return axios({
                method: "get",
                url: `/api/gestion/employees/0/ratings/lasts`,
                params: {
                    employee_ids, count, withMobility
                }
            })
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Get ratings dates and status, for one employee
     * @param {Number} employee_id employee id
     * @param {Number} withMobility include Mobility ratings (with rating status = 2)
     * @returns {Array} Array of ratings with basics information
     */
    async getRatingsDates({ employee_id = null, withMobility = 0 }) {
        return axios({
            method: "get",
            url: `/api/gestion/employees/${employee_id}/ratings`,
            params: {
                sortBy: {'rating_date': 'desc'},
                filter: withMobility === 0 ? 'status|!=|2': null
            }
        })
        .then(resp => {
            return resp.data.data;
        })
        .catch(error => {
            console.log(error);
            return Promise.reject(error)
        });
    },

    /**
     * Get pending rating (not ended) by employee id
     * @param {Number} employee_id employee id
     * @returns {Object} rating object
     */
    async getPendingRating({ employee_id }) {
        return axios
            .get("/api/ratings/pending", {
                params: { employee_id: employee_id }
            })
            .then(resp => {
                return resp.data[0];
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    // /**
    //  * Get rating pending for validation, by employee id
    //  * @param {Number|Null} employee_id employee id
    //  * @returns {Object} rating object
    //  */
    // async getToValidateRatings({employee_id = null}) {
    //   return axios.get('/api/ratings/status', { params: { employee_id, status: [0,1] }})
    //     .then(resp => {
    //       return resp.data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       return Promise.reject(error)
    //     })
    // },

    /**
     * Get rating by status (light data without relationship)
     * @param {Number|Null} employee_id employee id
     * @param {Array} status status
     * -1 = Not ended
     *  0 = Waiting for validation
     *  1 = validated
     *  2 = Mobility ratings
     *  3 = External ratings
     * @param {Number} page page number (pagination)
     * @param {Number} limit number of item to recovery
     * @param {String} orderBy recovery order
     * @param {String} direction sort direction
     * @param {String|Null} search search
     *
     * @returns {Object} rating object
     */
    async getRatingsByStatus({
        employee_id = null,
        statuses,
        page = 0,
        limit = 10,
        perpage = 10,
        orderBy = "rating_date",
        direction = "asc",
        search = null,
        include = []
    }) {

        let sortBy = {};
        sortBy[orderBy] = direction;

        return axios
            .get(`/api/gestion/employees/${employee_id}/ratings/statuses`, {
                params: {
                    statuses,
                    page,
                    limit,
                    sortBy,
                    search,
                    include,
                    perpage
                }
            })
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Add new rating
     * @param {Object} rating
     * @returns {Object} rating object
     */
    async addRating(rating) {
        return axios
            .post("/api/gestion/employees/0/ratings", rating)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Update rating
     * @param {Object} rating
     * @returns {Object} rating object
     */
    async updateRating(rating) {
        return axios
            .put(`/api/gestion/employees/0/ratings/${rating.id}`, rating)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Validate rating
     * @param {Object} rating
     * @returns {Object} rating object
     */
    async validateRating(rating) {
        return axios
            .post(`/api/rating/${rating.id}/validate`, rating)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    },

    /**
     * Get ratings dates and status, for one employee
     * @param {Number} ratingId rating id
     * @returns
     */
    async deleteRating(ratingId) {
        return axios
            .delete("/api/gestion/employees/0/ratings/" + ratingId)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
};
