var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-page" },
    [
      _c("Filters", { attrs: { "set-employees-list": _vm.setEmployeesList } }),
      _vm._v(" "),
      _vm.selectedYears
        ? _c("TimeLine", {
            attrs: {
              "employees-list": _vm.employeesList,
              "end-year": _vm.endYear,
              "selected-years": _vm.selectedYears
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedYears
        ? _c("YearsSlider", {
            attrs: {
              "all-years": _vm.allYears,
              "selected-years": _vm.selectedYears,
              "set-selected-year": _vm.setSelectedYear
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }