<template>
    <div class="dashboard">
        <LimitatedPlan v-if="!planAllow('dashbords')" class="mb-10"/>

        <h4>Identifier les potentiels d’une Personne</h4>
        <p>Pour chaque personne sélectionnée, ce tableau de bord permet d’orienter sur les métiers ou identifier les postes ou savoir-faire accessibles en sécurité à l'échelle d’un ou plusieurs établissements.</p>
        <!-- Filtre de recherche -->
        <vx-card class="mb-4 mt-3">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">

                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Sélectionner une personne</label>
                            <multiselect
                                v-model="filters.employee"
                                :options="groupedEmployeesByEstablishments"
                                :multiple="false"
                                group-values="employees"
                                group-label="establishment"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="name"
                                label="name"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :loading="employees.loading"
                                :disabled="!planAllow('dashbords')"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Sélectionner un ou des services</label>
                            <multiselect
                                v-model="filters.services"
                                :options="groupedServicesByEstablishments"
                                :multiple="true"
                                group-values="services"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledFilters.services || typeDisplay == typeDisplayValues.OCCUPATIONS || !planAllow('dashbords')"
                                :loading="establishments.loading"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Sélectionner un ou des postes</label>
                            <multiselect
                                v-model="filters.jobs"
                                :options="groupedJobsByService"
                                :multiple="true"
                                group-values="jobs"
                                group-label="service"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label=""
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledFilters.jobs"
                                :loading="jobs.loading"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Sélectionner un ou des métiers</label>
                            <multiselect
                                v-model="filters.occupations"
                                :options="occupationsList"
                                :multiple="true"
                                group-values="occupations"
                                group-label="type"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :disabled="disabledFilters.occupations || !planAllow('dashbords')"
                                :loading="occupations.loading"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Personnaliser le resultat -->
        <vx-card class="mb-4 radio-container">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col class="vx-row" vs-align="flex-start">
                            <vs-radio
                                v-model="typeDisplay"
                                vs-name="radios1"
                                id="radios1-metiers"
                                class="mr-20 mb-5"
                                :vs-value="typeDisplayValues.OCCUPATIONS"
                                :disabled="!planAllow('dashbords')"
                            >
                                Métiers
                            </vs-radio>
                            <vs-radio
                                v-model="typeDisplay"
                                vs-name="radios1"
                                id="radios1-postes"
                                class="mr-20 mb-5"
                                :vs-value="typeDisplayValues.JOBS"
                                :disabled="!planAllow('dashbords')"
                            >
                                Postes
                            </vs-radio>
                            <vs-radio
                                v-model="typeDisplay"
                                vs-name="radios1"
                                id="radios1-savoirFaire"
                                class="mr-20 mb-5"
                                :vs-value="typeDisplayValues.OCCUPATION_SKILLS"
                                :disabled="!planAllow('dashbords')"
                            >
                                Savoir-faire
                            </vs-radio>
                            <div class="vx-row">
                                <vs-checkbox
                                    class="mr-20 mb-5"
                                    v-model="filters.wishlistOnly"
                                    name="checkboxWishlistOnly"
                                    :disabled="!planAllow('dashbords')"
                                >
                                    Souhaités uniquement
                                </vs-checkbox>
                                <vs-checkbox
                                    class="mr-20 mb-5"
                                    v-model="filters.securityOnly"
                                    name="checkboxCheckOnly"
                                    :disabled="!planAllow('dashbords')"
                                >
                                    Accessibles en sécurité
                                </vs-checkbox>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <vs-row v-if="can('read', 'skill_reviews') || can('read', 'ratings')" class="mt-2">
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col class="vx-row" vs-align="flex-start">
                            Résultats basés sur :
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-ratings"
                                class="mr-5 ml-2"
                                :vs-value="typeComputeValues.RATINGS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ projectNames.plural }}
                            </vs-radio>
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-reviews"
                                class="mr-5"
                                :vs-value="typeComputeValues.REVIEWS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ skillRatingNames.plural }}
                            </vs-radio>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Lancer le recherche -->
        <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-end">
                <vs-button
                    v-if="planAllow('dashbords')"
                    id="search-button"
                    color="primary"
                    :disabled="!canSearch"
                    @click="searchClick"
                >
                    Lancer la recherche
                </vs-button>

                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        id="search-button"
                        color="primary"
                        disabled="true"
                    >
                        Lancer la recherche
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>
            </vs-col>
        </vs-row>

        <!-- Résumé -->
        <vx-card class="mt-6">
            <div v-if="evaluationForChart.length > 0">
                <vs-row>
                    <ul id="employee-skills-show" style="columns:4;-webkit-columns:4;-moz-columns:4;">
                        <li v-for="(chart, i) in evaluationForChart" :key="`${i}-${chart.job.id}`" >
                            <a @click="scrollTo('chart-' + i + '-' + chart.job.id)">
                                <span v-tooltip="{ content: chart.job.description }">
                                    {{ chart.options.title.text.split(":")[0] }}
                                </span>
                                :
                                <b class="text-primary">
                                    {{ round(chart.scoreNumber * 100, 0) + "% " }}
                                </b>
                            </a>
                            <b v-if="chart.check" style="color: green;">✓</b>
                        </li>
                    </ul>
                </vs-row>
                <vs-row class="mt-10">
                    <vs-col vs-type="flex" vs-justify="center">
                        <vs-button
                            v-if="hasMorePage"
                            id="more-button"
                            color="primary"
                            type="border"
                            @click="loadEvaluationsPage"
                        >
                            Charger plus...
                        </vs-button>
                    </vs-col>
                </vs-row>
            </div>

            <div v-else-if="evaluation.loaded">
                Aucune donnée à afficher
            </div>
        </vx-card>

        <!-- Détail avec graphique -->
        <vx-card class="mt-6">
            <vs-row v-if="evaluationForChart.length > 0">
                <vs-col
                    v-for="(chart, i) in evaluationForChart"
                    :id="`chart-${i}-${chart.job.id}`"
                    :key="`chart-${i}-${chart.score}-${chart.job.id}`"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-6"
                    vs-lg="6"
                    vs-sm="12"
                    v-tooltip="{ content: chart.job.description }"
                >
                    <RatingChart
                        :employee="filters.employee"
                        class="rating"
                        :options="chart.options"
                        :chart-data="chart.data"
                    />
                </vs-col>
            </vs-row>
        </vx-card>
        <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="center">
                <vs-button
                    v-if="hasMorePage"
                    id="more-button"
                    color="primary"
                    type="border"
                    @click="loadEvaluationsPage"
                >
                    Charger plus...
                </vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import RatingChart from "@components/app/RatingChart";
import hexToRgba from "hex-to-rgba";
import CrownIcon from '@components/svg/CrownIcon';
import LimitatedPlan from '@components/divers/LimitatedPlan';

export default {
    components: {
        RatingChart,
        Multiselect,
        CrownIcon,
        LimitatedPlan
    },
    mixins: [ratingChartsHelper],

    props: {
        skills: {
            type: Array,
            require: true
        },
        adminSkills: {
            type: Array,
            require: true
        },
        skill_occupationSkill: {
            type: Array,
            require: true
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            log: console.log,
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: true,
                    text: `placeholder`,
                    fontSize: 16
                },
                scale: {
                    ticks: {
                        min: -0.5,
                        max: 3,
                        stepSize: 1
                    }
                }
            },
            colors: [],
            employees: {
                data: [],
                loading: false
            },
            jobs: {
                data: [],
                loading: false
            },
            occupations: {
                data: [],
                loading: false
            },
            occupation_skills: {
                data: [],
                loading: false
            },
            adminOccupationSkills: {
                data: [],
                loading: false
            },
            establishments: {
                data: [],
                loading: false
            },
            disabledFilters: {
                services: true,
                jobs: true,
                occupations: true
            },
            filters: {
                employee: null,
                services: [],
                jobs: [],
                occupations: [],
                wishlistOnly: false,
                securityOnly: false
            },
            typeDisplayValues: {
                JOBS: 1,
                OCCUPATIONS: 2,
                OCCUPATION_SKILLS: 3
            },
            typeDisplay: 1,
            typeComputeValues: {
                RATINGS: 1,
                REVIEWS: 2,
            },
            typeCompute: 1,
            rating: null,
            searchLoading: false,
            evaluation: {
                rating: {},
                data: [],
                lastPage: 0,
                currentPage: 0,
                loaded: false
            },
        }
    },
    created() {
        let start = Date.now();

        if (this.can('read', 'skill_reviews') && !this.can('read', 'ratings')) {
            this.typeCompute = this.typeComputeValues.REVIEWS
        }

        if (this.planAllow('dashbords')) {

            this.localLoading();
    
            Promise.all([
                this.loadColors(),
                this.loadEmployees(),
                this.loadJobs(),
                this.loadOccupations(),
                this.loadOccupationskills(),
                this.loadAdminOccupationSkills(),
                this.loadEstablishment(),
            ]).then(() => {
                console.info('=> Chargement des listes (avec reconstruction) : +' + (Date.now() - start) + 'ms');
            })
            
            this.endLocalLoading();
        }


    },
    computed: {
        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                const params = Object.keys(this.ratingSettings.params).map(id => {
                    var param = this.ratingSettings.params[id];
                    param.id = id;
                    return param;
                });
                return params;
            }
            return [];
        },
        shownEstablishments() {
            // Liste des établissements basée sur le périmètre de consultation des personnes
            if (this.establishments.data && this.establishments.data.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments.data;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    const userEstablishments = this.establishments.data.slice()
                        .filter(e => userServices.map(service => service.establishment_id).includes(e.id));
                    return userEstablishments;
                }
            }
            return [];
        },

        groupedEmployeesByEstablishments() {
            let toShow = [];
            const establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    employees: this.employees.data ? this.employees.data.filter(employee =>
                            employee.services.length &&
                            employee.services?.map(s => s.establishment_id).includes(e.id) &&
                            this.listOfServicesViewableFor("employees")
                        )
                        .map(employee => {
                            return {
                                ...employee,
                                name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
                            };
                        })
                        : []
                });
            });

            return toShow;
        },
        groupedServicesByEstablishments() {
            let toShow = [];
            const establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services ?
                            this.services.filter(service => service.establishment_id === e.id && this.listOfServicesViewableFor("jobs").includes(service.id))
                            : []
                });
            });

            return toShow;
        },

        groupedJobsByService() {
            let toShow = [];
            let services = !this.filters.services.length ? this.services : this.filters.services;
            services = services.slice().sort((a, b) => {
                return a.establishment.name.localeCompare(b.establishment.name, "fr", { sensitivity: "base" });
            });

            services.forEach(service => {
                toShow.push({
                    service: service.establishment.name + " / " + service.title,
                    jobs: this.jobs.data ? this.jobs.data.filter(j =>
                            j.service.id === service.id &&
                            this.listOfServicesViewableFor("jobs").includes(service.id)
                        )
                        : []
                });
            });

            return toShow;
        },

        occupationsTypes() {
            let types = this.occupations.data.map(t => t.type);
            return types
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b));
        },

        occupationsList() {
            if (!this.occupations.data.length) return [];
            let occupations = this.occupations.data.slice();
            if (this.filters.services.length) {
                occupations = this.occupations.data.filter(o =>
                    this.filters.services.map(s => s.sector_id).includes(o.sector_id)
                );
            }
            let types = this.occupationsTypes.filter(id =>
                occupations.map(occ => occ.type).includes(id)
            );

            let occupationsList = [];
            types.forEach(type => {
                occupationsList.push({
                    type: type,
                    /* SGA 31/05/2024 Le style disabled est aussi appliqué sur le group et ne peut pas être différencier
                    occupations: occupations.map(o => {
                        if (this.occupationWithOnlyOccupationSkillsWithoutSkill(o)) {
                            o.$isDisabled = true
                        }
                        return o;
                    })*/
                    occupations: occupations
                        .filter(occ => occ.type == type)
                        // SGA 31/05/2024 Suppression de la liste des métiers avec des savoir-faire sans compétence appelée
                        .filter(occ => !this.occupationWithOnlyOccupationSkillsWithoutSkill(occ))
                        .sort((a, b) => a.title.localeCompare(b.title))
                });
            });

            return occupationsList;
        },

        evaluationForChart() {

            if (!this.evaluation.data) {
                return [];
            }

            let charts = [];

            this.evaluation.data.forEach(e => {

                let job = null;
                let type = null;
                let ignore = false;
                const score = `${Math.round(e.accessibility * 100)}% ${e.security ? '✓' : ''}`

                switch (this.typeDisplay) {
                    case this.typeDisplayValues.OCCUPATION_SKILLS :
                        type = "occupation_skill";
                        job = e.occupation_skill ? e.occupation_skill : e.admin_occupation_skill
                        ignore = false;
                        break;
                    case this.typeDisplayValues.JOBS :
                        type = "job"
                        job = e.job;
                        ignore = false;
                        break;
                    case this.typeDisplayValues.OCCUPATIONS :

                        // On ignore les métiers qui ont tous leur savoir-faire sans compétences
                        if (e.skills.length == 0) {
                            ignore = true;
                        } else {
                            type = "occupation"
                            job = e.occupation;
                            ignore = false;
                        }
                        break;
                }

                if (!ignore) {
                    charts.push({
                        score: score,
                        scoreNumber: e.accessibility,
                        check: e.security,
                        wishlist: e.wishlist,
                        options: this.chartOptionsWith(score, type, e),
                        data: this.employeeEvaluationChartData(this.evaluation.rating, e),
                        job: job
                    });
                }

            });

            charts.sort((a, b) =>
                a.scoreNumber === b.scoreNumber
                    ? b.check ? 1 : -1
                    : b.scoreNumber - a.scoreNumber
            );

            if (this.filters.securityOnly) {
                charts = charts.filter(chart => chart.check == true);
            }

            if (this.filters.wishlistOnly) {
                charts = charts.filter(chart => chart.wishlist == true);
            }

            return charts;
        },


        canSearch() {
            return this.filters.employee != null;
        },

        hasMorePage() {
            return this.evaluation.currentPage < this.evaluation.lastPage
        }
    },
    watch: {
        searchLoading(value) {
            if (value) {
                this.$vs.loading({
                    background: this.backgroundLoading,
                    color: this.colorLoading,
                    container: document.getElementById('search-button'),
                    scale: 0.45
                })
            } else {
                setTimeout(() => {
                    this.$vs.loading.close(document.getElementById('search-button'))
                }, 100)
            }
        },
        'filters.employee' : {
            handler(value) {
                if (value) {
                    this.disabledFilters.services = false;
                    this.disabledFilters.jobs = false;
                    this.disabledFilters.occupations = false;
                } else {
                    this.disabledFilters.services = true;
                    this.disabledFilters.jobs = true;
                    this.disabledFilters.occupations = true;
                }
                this.filters.wishlistOnly = false;
                this.filters.services = [];
                this.filters.jobs = [];
                this.filters.occupations = [];

                this.resetEvaluationData();
            }
        },
        'filters.services' : {
            handler(value) {
                if (value.length) {
                    this.filters.jobs = [];
                    this.filters.occupations = [];
                }
                this.resetEvaluationData();
            }
        },
        'filters.jobs' : {
            handler(value) {
                if (value.length) {
                    this.filters.occupations = [];

                    if (this.typeDisplay !== this.typeDisplayValues.OCCUPATION_SKILLS) {
                        this.typeDisplay = this.typeDisplayValues.JOBS;
                    }
                }
                this.resetEvaluationData();
            }
        },
        'filters.occupations' : {
            handler(value) {
                if (value.length) {
                    this.filters.jobs = [];

                    if (this.typeDisplay !== this.typeDisplayValues.OCCUPATION_SKILLS) {
                        this.typeDisplay = this.typeDisplayValues.OCCUPATIONS;
                    }
                }
                this.resetEvaluationData();
            }
        },

        typeDisplay(newValue) {
            if (newValue === this.typeDisplayValues.OCCUPATIONS) {
                this.filters.jobs = [];
                this.filters.services = []
            } else if (newValue === this.typeDisplayValues.JOBS) {
                this.filters.occupations = [];
            }
            this.resetEvaluationData();
        }
    },
    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    this.colors = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            this.employees.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, 'include': ['services.establishment'] }
                })
                .then(response => {
                    this.employees.data = response.data.data;
                    this.employees.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadJobs() {
            this.jobs.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: { perpage: 9999, include: ['service', 'occupation_skills'] }
                })
                .then(response => {
                    let jobs = response.data.data
                    jobs.forEach(j => {
                        j.occupation_skills.forEach(jos => {
                            this.reconstructSkillOccupationSkillRelation(jos, this.skill_occupationSkill)
                        })
                    })
                    this.jobs.data = jobs;
                    this.jobs.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        // Ajout - à l'image du store
        jobsByEstablishment(id) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const services = this.services.filter(
                service => service.establishment.id == id
            )

            const serviceIds = services.slice().map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        jobsByEstablishments(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const services = this.services.filter(service =>
                ids.includes(service.establishment.id)
            )
            const serviceIds = services.slice().map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        jobsByServices(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const serviceIds = this.services.filter(s => ids.includes(s.id)).map(s => s.id)
            return this.jobs.filter(j => serviceIds.includes(j.service.id))
        },

        async loadOccupations() {
            this.occupations.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills",
                            "occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    occupations.forEach(o => {
                        o.occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.skill_occupationSkill, false)
                        })
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.adminSkill_adminOccupationSkill, true)
                        })
                    })
                    this.occupations.data = occupations;
                    this.occupations.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadOccupationskills() {
            this.occupation_skills.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"],
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data
                    occupationskills.forEach(os => {
                        this.reconstructSkillOccupationSkillRelation(os, this.skill_occupationSkill, false)
                    })
                    this.occupation_skills.data = occupationskills;
                    this.occupation_skills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupation_skills.data.find(os => os.id == id);
        },

        async loadAdminOccupationSkills() {
            this.adminOccupationSkills.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: {'title': 'asc'} }
                })
                .then(response => {
                    let adminOccupationskills = response.data.data
                    adminOccupationskills.forEach(aos => {
                        this.reconstructSkillOccupationSkillRelation(aos, this.adminSkill_adminOccupationSkill, true)
                    })
                    this.adminOccupationSkills.data = adminOccupationskills;
                    this.adminOccupationSkills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        adminoccupationskillById(id) {
            return this.adminOccupationSkills.data.find(os => os.id == id);
        },

        async loadEstablishment() {
            this.establishments.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        scrollTo(id) {
            const container = document.getElementById("content-area");
            const target = document.getElementById(id);
            const offset = 100;
            container.scrollTo({
                top: target.getBoundingClientRect().top - offset,
                behavior: "smooth"
            });
        },

        chartOptionsWith(score, type, evaluation) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            options.scale.ticks.min = this.ratingSettings ? this.ratingSettings.RadarCenter : -1;
            let title = '';
            const ratingValue = evaluation.rating_level;
            const ratingText = ratingValue > 1 ? `(${this.ratingParams.find(param => +param.id === ratingValue)?.label})` : '';
            const actual = evaluation.actual ? " (actuel)" : "";
            const wishlist = evaluation.wishlist ? " (souhaité)" : "";

            switch (type) {
                case "job":
                    title = evaluation.job.title
                    break;
                case "occupation": {
                    title = evaluation.occupation.title
                    break;
                }
                case "occupation_skill":
                    title = evaluation.admin_occupation_skill ? evaluation.admin_occupation_skill.title : evaluation.occupation_skill.title;
                    break;
            }

            options.title.text = `${title}${actual}${wishlist}${ratingText} : ${score}`;

            options.tooltips = {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    },
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value =
                            data.datasets[tooltipItem.datasetIndex].levels[
                                tooltipItem.index
                            ];
                        return ` ${label}  :  ${value}`;
                    }
                }
            };
            return options;
        },

        searchClick() {
            this.evaluation.currentPage = 0;
            this.evaluation.data = [];

            this.loadEvaluationsPage();
        },

        loadEvaluationsPage() {
            this.evaluation.currentPage++;
            let start = Date.now();
            this.searchLoading = true;
            this.evaluation.loaded = false;

            let apiMethod = null;

            switch (this.typeDisplay) {
                case this.typeDisplayValues.OCCUPATIONS:
                    apiMethod = 'loadEvaluationOccupations';
                    break;
                case this.typeDisplayValues.OCCUPATION_SKILLS:
                    apiMethod = 'loadEvaluationOccupationSkills'
                    break;
                default :
                    apiMethod = 'loadEvaluationJobs';
                    break;
            }

            this[apiMethod](this.evaluation.currentPage).then(response => {
                this.evaluation.rating = response.data.rating;
                this.evaluation.data = this.evaluation.data.concat(response.data.data);
                this.evaluation.lastPage = response.data.last_page;
                console.info('=> Chargement accessibilité : +' + (Date.now() - start) + 'ms');
                this.searchLoading = false;
                this.evaluation.loaded = true;
            })
        },

        async loadEvaluationOccupationSkills(page = 1) {
            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/${this.filters.employee.id}/ratings/evaluation/occupation-skills`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        job_ids: this.filters.jobs.map(j => j.id),
                        occupation_ids: this.filters.occupations.map(o => o.id),
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEvaluationJobs(page = 1) {
            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/${this.filters.employee.id}/ratings/evaluation/jobs`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        job_ids: this.filters.jobs.map(j => j.id),
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEvaluationOccupations(page = 1) {
            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/${this.filters.employee.id}/ratings/evaluation/occupations`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        occupation_ids: this.filters.occupations.map(o => o.id),
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        resetEvaluationData() {
            this.evaluation.lastPage = 0;
            this.evaluation.currentPage = 0;
            this.evaluation.data = [];
            this.evaluation.rating = {}
            this.evaluation.loaded = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.dashboard::v-deep .multiselect {
    font-size: 14px;
    color: grey;
    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }
    .multiselect__single {
        font-size: 14px;
        color: grey;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;
        &-icon:hover {
            background: red;
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }
    .multiselect__select:before {
        top: 45%;
    }
    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        color: grey;
    }

    .multiselect__content {
        background: $primary-light;
    }

    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: red;
            }
        }
    }
}

a {
    cursor: pointer;
}

.radio-container {
    z-index: 0;
    ::v-deep.vx-card__body {
        padding-bottom: 0.5em;
        .vx-row {
            margin-left: 0;
            .con-vs-checkbox {
                margin-left: 0;
                @media screen and (max-width: 600px) {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
</style>
