<template>
    <div>
        <vx-card>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Nom Complet * :</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input v-model="name" class="w-full" name="name" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Email * :</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input
                        v-model="email"
                        class="w-full"
                        type="email"
                        name="email"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Etablissement :</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input
                        v-model="establishment"
                        class="w-full"
                        name="establishment"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Sujet * :</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input v-model="subject" class="w-full" name="subject" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Message * :</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <quill-editor
                        v-model="message"
                        :options="options"
                        name="message"
                    />
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-3/4 w-full ml-auto">
                    <vs-button
                        id="sendMessage"
                        color="primary"
                        type="filled"
                        class="mr-3 mb-2"
                        icon-pack="feather"
                        icon="icon-send"
                        :disabled="invalid"
                        @click.prevent="sendMessage()"
                    >
                        Envoyer
                    </vs-button>
                    <vs-button
                        id="resetForm"
                        color="primary"
                        type="border"
                        class="mr-3 mb-2"
                        icon-pack="feather"
                        icon="icon-x"
                        @click.prevent="resetForm()"
                    >
                        Annuler
                    </vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            name: "",
            email: "",
            establishment: "",
            subject: "",
            message: "",
            loading: false,
            options: {
                placeholder: "...",
                modules: {
                    toolbar: [
                        [{ size: ["small", false, "large"] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"]
                    ]
                }
            }
        };
    },
    computed: {
        invalid() {
            return (
                this.name == "" ||
                this.email == "" ||
                this.subject == "" ||
                this.message == ""
            );
        }
    },
    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },
    mounted() {
        if (window.Laravel.user) {
            const user = JSON.parse(JSON.stringify(window.Laravel.user));
            this.name = `${user.last_name} ${user.first_name}`;
            this.email = user.email;
            this.establishment = user.establishments
                ? user.establishments[0].name
                : "";
        }
    },
    methods: {
        sendMessage() {
            this.loading = true;


            const message = {
                name: this.name,
                email: this.email,
                establishment: this.establishment,
                subject: this.subject,
                message: this.message
            };

            return new Promise((resolve, reject) => {
                window.axios
                    .post(`/api/referentiel/contact/send-message`, message)
                    .then(res => {
                        const body = res.data;
                        if (body.status === "success") {
                            this.$swal(`Message envoyé !`, '', 'success')
                            this.loading = false
                            resolve();
                        } else {
                            reject(res.data);
                            alert('Erreur !!')
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);

                        this.$swal(`Something went wrong!`, '', 'error')
                        this.loading = false
                    });
            });

        },
        resetForm() {
            const user = JSON.parse(JSON.stringify(window.Laravel.user));
            this.name = `${user.last_name} ${user.first_name}`;
            this.email = user.email;
            this.establishment = user.establishment
                ? user.establishment.name
                : "";
            this.subject = "";
            this.message = "";
        }
    }
};
</script>

<style></style>
