<template>
    <GridView></GridView>
</template>

<script>
import GridView from './dashboard/GridView.vue'
    export default {
        components: { GridView },
        methods: {

        }
    };

</script>

<style lang="scss" scoped>

    #home-wrapper {
        margin-left: -10px;
        margin-right: -10px;
    }
    .overlayed {
        min-height: 200px;
        background-size: cover;
    }

    .link:hover {
        cursor: pointer;
    }
</style>
