export const dateTime = dateTime => {
    let local = dateTime ? new Date(dateTime) : new Date();
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());

    return (
        local.toISOString().substr(0, 10) +
        " " +
        local.toISOString().substr(11, 8)
    );
};

export const fixDate = function(date) {
    if (!date) return null;
    if (typeof date == "string") {
        date = date.replace(" ", "T");
        date = new Date(date);
    }
    if (typeof date == "number") {
        date = new Date(date);
    }
    if (date instanceof Date) {
        if (isNaN(date.getDate())) {
            debugger;
            return new Date();
        } else {
            return date;
        }
    }
    debugger;
};

export function throttle(callback, delay) {
    let last = null;
    let timeoutTimer = null;
    let lastResult = null;
    let lastParameters = null;

    return function(...params) {
        if (!last || last < Date.now() - delay) {
            if (timeoutTimer) {
                clearTimeout(timeoutTimer);
            }
            last = Date.now();
            return (lastResult = callback(...params));
        } else if (!timeoutTimer) {
            timeoutTimer = setTimeout(function() {
                last = Date.now();
                timeoutTimer = null;
                lastResult = callback(...lastParameters);
            }, delay - (Date.now() - last));
        }
        lastParameters = params;

        return lastResult;
    };
}
