import Vue from "vue";
import { UserCheckIcon } from "vue-feather-icons";

Vue.mixin({
    data() {
        return {
            settings: [],
            ppDefaultName: 'Projet personnalisé',
            ppDefaultPluralName: 'Projets personnalisés',
            srDefaultName: 'Bilan',
            srDefaultPluralName: 'Bilans',
        };
    },

    computed: {
        appSettings() {
            return window.Laravel.settings;
        },

        ratingSettings() {
            return this.appSettings.find(el => el.name == "ratings").value;
        },

        user() {
            return window.Laravel.user;
        },

        services() {
            return window.Laravel.services
        },

        is_admin_interface() {
            return window.Laravel.is_admin
        },

        projectNames() {
            return {
                singular: this.appSettings.find(el => el.name == 'namings')?.value?.projectSingularName || this.ppDefaultName,
                plural: this.appSettings.find(el => el.name == 'namings')?.value?.projectPluralName || this.ppDefaultPluralName
            }
        },
        skillRatingNames() {
            return {
                singular: this.appSettings.find(el => el.name == 'namings')?.value?.skillRatingSingularName || this.srDefaultName,
                plural: this.appSettings.find(el => el.name == 'namings')?.value?.skillRatingPluralName || this.srDefaultPluralName,
            }
        }
    },

    methods: {

        employeeById(id) {
            return this.employees.find(employee => employee.id == id);
        },

        // authorizedEstablishmentsByLicence(licence) {
        //     return window.Laravel.authorizedEstablishments[licence].map(e =>
        //         this.establishmentById(e)
        //     );
        // },

        authorizedServicesByLicence(licence) {
            let services = [];
            state.authorizedEstablishments[licence]
                .map(e => this.establishmentById(e))
                .forEach(e =>
                    e
                        ? e.services.forEach(s =>
                              services.push(this.serviceById(s.id))
                          )
                        : null
                );

            return services;
        },

        constants(id) {},

        employeeService(employee) {
            return this.services.find(s => s.id === employee.service_id);
        },

        countEstablishmentEmployees(id) {
            const serviceIds = this.services
                .filter(s => s.establishment_id === id)
                .map(s => s.id);

            return this.employees.filter(e => serviceIds.includes(e.service_id))
                .length;
        },

        // establishmentById(id) {
        //     return this.establishments.find(
        //         establishment => establishment.id == id
        //     );
        // },

        serviceById(id) {
            return this.services.find(service => service.id == id);
        },

        allServiceById(id) {
            return this.services.find(service => service.id == id);
        },

        // Paramètre des savoir-faire
        levelById(id) {
            if (this.ratingSettings) {
                switch (id) {
                    case 1:
                        return this.ratingSettings.RequiredLevel;
                    case 2:
                        return this.ratingSettings.IndispensableLevel;
                    case 3:
                        return this.ratingSettings.AutonomyLevel;
                    default:
                        return 0;
                }
            }
        },

        // Paramètres des evaluations
        noteById(id) {
            if (this.ratingSettings === {} || id === null) {
                return 0;
            }

            if (this.ratingSettings.params) {
                return this.ratingSettings.params[id]
                    ? this.ratingSettings.params[id].note
                    : this.ratingSettings.NonMesuredLevel;
            } else {
                return 0;
            }
        },

        levelLabelById(id) {
            if (this.ratingSettings === {} || id === null) {
                return "Non mesuré";
            }

            const labelDefault = Object.values(this.ratingSettings.params).find(p => p.note == 0).label;

            if (this.ratingSettings.params) {
                return this.ratingSettings.params[id]
                    ? this.ratingSettings.params[id].label
                    : labelDefault;
            } else {
                return labelDefault;
            }
        },

        levelLabelByNote(note) {
            if (this.ratingSettings === {} || note === null) {
                return "Non mesuré";
            }

            const labelDefault = Object.values(this.ratingSettings.params).find(p => p.note == 0).label;

            if (this.ratingSettings.params) {
                let level = Object.values(this.ratingSettings.params).find(p => p.note == note);
                return level ? level.label : labelDefault;
            } else {
                return labelDefault;
            }
        },

        getSkillById(id, callback) {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills/" + id,
                    params: { perpage: 9999, include: [] }
                })
                .then(response => {
                    callback(response.data);
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        // async getSectorById(id) {
        //     return window
        //         .axios({
        //             method: "get",
        //             url: "/api/admin/sectors/" + id
        //         })
        //         .then(response => {
        //             return response.data;
        //         })
        //         .catch(err => {
        //             console.log(err);
        //             this.notifyError(err, `Une erreur est survenue`);
        //         });
        // },

        settingByName(name) {
            return this.appSettings.find(s => s.name === name);
        },

        authorizedEstablishmentsByLicence(type) {
            return window.Laravel.authorizedEstablishments[type];
        },

        reconstructSkillOccupationSkillRelation(occupationSkill, skill_occupationSkill, isAdmin = false) {
            if (!this.skills) {
                throw new Error(`Impossible de reconstruire sans les Skills`);
            }

            if (!this.adminSkills) {
                throw new Error(`Impossible de reconstruire sans les Admin Skills`);
            }

            let skills
            if (!isAdmin) {
                let pivots = skill_occupationSkill.filter(
                    pivot => pivot.occupation_skill_id === occupationSkill.id
                )
                let pivotIds = pivots.map(
                    pivot => pivot.skill_id
                )
                skills = window._.cloneDeep(this.skills.filter(s => pivotIds.includes(s.id)))
                skills.forEach(s => {
                    s.pivot = pivots.find(pivot => pivot.skill_id === s.id)
                })
                occupationSkill.skills = skills
            }
            else {
                let pivots = skill_occupationSkill.filter(
                    pivot => pivot.admin_occupation_skill_id === occupationSkill.id
                )
                let pivotIds = pivots.map(
                    pivot => pivot.admin_skill_id
                )
                skills = window._.cloneDeep(this.adminSkills.filter(s => pivotIds.includes(s.id)))
                skills.forEach(s => {
                    s.pivot = pivots.find(pivot => pivot.admin_skill_id === s.id)
                })
                occupationSkill.adminskills = skills
            }
        },

        reconstructRating(rating) {
            if (rating.occupations_skills) {
                rating.occupations_skills.forEach(srOcc => {
                    this.reconstructSkillOccupationSkillRelation(srOcc, this.skill_occupationSkill)
                })
            }

            if(rating.jobs) {
                rating.jobs.forEach(srJob => {
                    srJob.occupation_skills.forEach(srJobOcc => {
                        this.reconstructSkillOccupationSkillRelation(srJobOcc, this.skill_occupationSkill)
                    })
                })
            }

            if (rating.occupations) {
                rating.occupations.forEach(srOccupation => {
                    srOccupation.occupation_skills.forEach(srOccupationOcc => {
                        this.reconstructSkillOccupationSkillRelation(srOccupationOcc, this.skill_occupationSkill)
                    })
                    srOccupation.admin_occupation_skills.forEach(srOccupationOcc => {
                        this.reconstructSkillOccupationSkillRelation(srOccupationOcc, this.adminSkill_adminOccupationSkill, true)
                    })
                })
            }

            if (rating.wishlist_jobs) {
                rating.wishlist_jobs.forEach(srWJobOcc => {
                    srWJobOcc.occupation_skills.forEach(srWJobOcc => {
                        this.reconstructSkillOccupationSkillRelation(srWJobOcc, this.skill_occupationSkill)
                    })
                })
            }

            if (rating.wishlist_occupations) {
                rating.wishlist_occupations.forEach(srWOccupation => {

                    srWOccupation.occupation_skills.forEach(srWOccupationOcc => {
                        this.reconstructSkillOccupationSkillRelation(srWOccupationOcc, this.skill_occupationSkill)
                    })

                    srWOccupation.admin_occupation_skills.forEach(srWOccupationOcc => {
                        this.reconstructSkillOccupationSkillRelation(srWOccupationOcc, this.adminSkill_adminOccupationSkill, true)
                    })
                })
            }
        },

        /**
         * Filtre les établissements en fonction de la portée de l'utilisateur
         * @param {array} establishments
         * @returns
         */
        filterEstablishmentFormViewableService(establishments) {
            if (establishments.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments.data;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    const userEstablishments = this.establishments.data
                        .slice()
                        .filter(e =>
                            userServices
                                .map(service => service.establishment_id)
                                .includes(e.id)
                        );
                    return userEstablishments;
                }
            }  return [];
        }
    }
});
