var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          displayWishes: false,
          evaluatedOccupationSkills: _vm.value.occupations_skills,
          "display-collapses": true,
          "occupation-skills-goals": _vm.value.occupation_skills_goals
        }
      }),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-6 mt-10" },
        [
          _c("vs-col", { staticClass: "sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Postes souhaités :")])
          ]),
          _vm._v(" "),
          _c(
            "vs-col",
            { staticClass: "sm:w-4/5 w-full" },
            [
              _c(
                "Multiselect",
                {
                  attrs: {
                    id: "wantedJobs",
                    options: _vm.jobs,
                    multiple: true,
                    "group-values": "jobs",
                    "group-label": "establishmentService",
                    "group-select": true,
                    placeholder: "Recherche ...",
                    "track-by": "id",
                    label: "title",
                    "select-label": "",
                    "select-group-label": "",
                    "selected-label": "",
                    "deselect-label": "",
                    "deselect-group-label": "",
                    disabled:
                      _vm.typeForm === "validation" ||
                      _vm.typeForm === "consultation"
                  },
                  model: {
                    value: _vm.resource.wishlist_jobs,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "wishlist_jobs", $$v)
                    },
                    expression: "resource.wishlist_jobs"
                  }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [_vm._v("Aucun résultat.")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-6" },
        [
          _c("vs-col", { staticClass: "sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Métiers souhaités :")])
          ]),
          _vm._v(" "),
          _c(
            "vs-col",
            { staticClass: "sm:w-4/5 w-full" },
            [
              _c(
                "Multiselect",
                {
                  attrs: {
                    id: "wantedOccupations",
                    multiple: "",
                    options: _vm.occupationsList,
                    "group-label": "type",
                    "group-values": "occupations",
                    "group-select": false,
                    label: "title",
                    disabled:
                      _vm.typeForm === "validation" ||
                      _vm.typeForm === "consultation",
                    placeholder: "Recherche ...",
                    "track-by": "id",
                    "select-label": "",
                    "select-group-label": "",
                    "selected-label": "",
                    "deselect-label": "",
                    "deselect-group-label": "",
                    loading: _vm.referentialOccupations.loading
                  },
                  model: {
                    value: _vm.resource.wishlist_occupations,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "wishlist_occupations", $$v)
                    },
                    expression: "resource.wishlist_occupations"
                  }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [_vm._v("Aucun résultat.")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("OccupationSkillsGoal", {
        attrs: {
          "occupation-skills": _vm.occupationSkillsAvailables,
          readonly: _vm.typeForm === "consultation",
          themes: _vm.themes.data.filter(function(t) {
            return t != "Compétence"
          }),
          states: _vm.states.data
        },
        model: {
          value: _vm.resource.occupation_skills_goals,
          callback: function($$v) {
            _vm.$set(_vm.resource, "occupation_skills_goals", $$v)
          },
          expression: "resource.occupation_skills_goals"
        }
      }),
      _vm._v(" "),
      _c("SkillsGoal", {
        attrs: {
          skills: _vm.value.skills,
          readonly: _vm.typeForm === "consultation",
          themes: _vm.themes.data.filter(function(t) {
            return t != "Savoir-faire"
          }),
          states: _vm.states.data
        },
        model: {
          value: _vm.resource.skills_goals,
          callback: function($$v) {
            _vm.$set(_vm.resource, "skills_goals", $$v)
          },
          expression: "resource.skills_goals"
        }
      }),
      _vm._v(" "),
      _c("OthersGoal", {
        attrs: {
          readonly: _vm.typeForm === "consultation",
          states: _vm.states.data,
          themes: _vm.themes.data
        },
        model: {
          value: _vm.resource.others_goals,
          callback: function($$v) {
            _vm.$set(_vm.resource, "others_goals", $$v)
          },
          expression: "resource.others_goals"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }