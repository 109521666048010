<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
        />

        <vs-row v-if="resource.skills.length" class="checkboxes-container mt-10">
            <vs-col vs-type="flex" vs-align="center" class="sm:w-4/5 w-full sm:w-">
                <label>Toutes les compétences</label>
                <vs-switch v-model="value.allSkills" name="showAllSkills" class="ml-2" disabled/>
                <template v-if="typeForm !== 'consultation' && typeForm !== 'validation'">
                    <label class="ml-20">Préremplir avec :</label>
                    <vs-radio
                        v-model="resource.self_skills_source"
                        vs-name="radios1"
                        id="radio-last-auto-eval"
                        class="ml-2"
                        :vs-value="selfRatingSourceValues.LAST_SELF"
                        @change="setDefaultHandle(selfRatingSourceValues.LAST_SELF)"
                    >
                        Dernière auto-évaluation
                    </vs-radio>
                    <vs-radio
                        v-model="resource.self_skills_source"
                        vs-name="radios1"
                        id="radio-declaration-competence"
                        class="ml-2"
                        :vs-value="selfRatingSourceValues.MOBILITY"
                        :disabled="!mobilityRating"
                        @change="setDefaultHandle(selfRatingSourceValues.MOBILITY)"
                    >
                        Auto-évaluation Portail
                        {{ !!mobilityRating ? `du ${this.$moment(mobilityRating.rating_date).format("l")}` : "" }}
                    </vs-radio>
                </template>
                <template v-if="typeForm !== 'creation' && typeForm !== 'poursuite'">
                    <label class="ml-20">Affichage :</label>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-table"
                        class="ml-2"
                        vs-value="table"
                    >
                        Tableau
                    </vs-radio>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-graphic"
                        class="ml-2"
                        vs-value="graphic"
                    >
                        Graphique
                    </vs-radio>
                </template>
            </vs-col>
        </vs-row>
        <div v-else-if="!loading" class="not-data-table vs-table--not-data">Il n'y a aucune compétence à évaluer</div>

        <Rating-Manager
            v-if="displayMode === 'table' && (resource.self_skills.length || loading)"
            name="self-skills"
            :type-form="typeForm"
            v-model="resource.self_skills"
            :show-hidden="value.allSkills"
            :col1="{label: 'Compétence', value: 'title', title: 'description'}"
            :col2="{label: 'Catégorie', value: 'category_name'}"
            :loading="loading"
            :skill-review="skillReview"
        />

        <div v-else-if="displayMode === 'graphic'" class="chart main">
            <Rating-Chart
                :employee="value.employee"
                class="main-chart"
                :options="chart.options"
                :chart-data="chart.data"
            />
        </div>
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import RatingManager from '../RatingManager';
import ratingService from "@components/services/ratings";
import RatingChart from "@components/app/RatingChart";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";

export default {
    components: {
        JobsOccupationsResume, RatingManager, RatingChart
    },
    mixins: [ratingChartsHelper],
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        selfRatingSourceValues: {
            type: Object,
            required: true
        },
        adminSkills: {
            type: Object,
            required: true
        },
        colors: {
            type: Array,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            resource: {
                self_skills: [],
                self_skills_source: null,
            },
            loading: true,
            lastRating: {},
            mobilityRating: null,
            displayMode: 'table'
        };
    },
    created() {
        this.loading = true;
        this.initResource();
        this.resource.self_skills_source = this.value.personals.length ? this.selfRatingSourceValues.MOBILITY : this.selfRatingSourceValues.LAST_SELF;
        this.getLastMobilitySelfRating();
        this.loadDefaultRatings(this.value.personals.length ? true : false);

        if (this.typeForm === 'consultation' || this.typeForm === 'validation') {
            this.displayMode = 'graphic'
        }
    },

    computed: {
        chart() {
            return {
                data: {
                    labels: this.resource.skills.map(s =>
                        this.adminSkillById(s.admin_skill_id)
                            ? this.adminSkillById(s.admin_skill_id).title
                            : ""
                    ),
                    datasets: this.generateDatasets([this.resource.skills])
                },
                options: this.getChartOptions()
            };
        }
    },
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.self_skills = val.self_skills
                data.self_skills_source = val.self_skills_source

                this.$emit('input', data);
            },
            deep: true
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        adminSkillById(id) {
            if (this.adminSkills.data.length == 0) {
                return;
            }
            return this.adminSkills.data.find(s => s.id == id);
        },

        loadDefaultRatings(setDefault) {

            let start = Date.now();
            var employee_rating = window._.cloneDeep(this.value);
            employee_rating.occupations = null;
            employee_rating.jobs = null;
            employee_rating.externals = null;
            employee_rating.self_skills = this.resource.self_skills;
            employee_rating.self_rating_source = this.resource.self_skills_source;
            employee_rating.self_rating_set_default = setDefault;
            employee_rating.skill_review = this.skillReview

            window
                .axios({
                    method: "post",
                    url: `/api/gestion/employees/${this.value.employee.id}/ratings/default-self-skills`,
                    params: {},
                    data: employee_rating,
                })
                .then(response => {
                    this.resource.self_skills = Object.values(response.data);
                    this.loading = false;

                    console.info('=> Chargement des auto-évaluations depuis API : ' + (Date.now() - start) + 'ms');
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    this.loading = false;
                });

        },

        /**
         * Récupération de la dernière auto-évaluation transmise du portail
         */
        getLastMobilitySelfRating() {
            ratingService.getRatingsByStatus({
                employee_id: this.value.employee.id,
                statuses: [2],
                limit: 1,
                orderBy: "rating_date",
                direction: "desc",
            })
                .then(response => {

                    if (response.data && response.data.length > 0) {
                        this.mobilityRating = response.data[0];

                    }
                })
        },

        setDefaultHandle(selfSkillsSource) {

            if (selfSkillsSource == this.selfRatingSourceValues.MOBILITY && !this.mobilityRating) {
                return;
            }

            // On force le rechargement
            this.resource.self_skills = this.resource.self_skills.map(s => {
                s.level = 1;
                return s;
            });

            this.loadDefaultRatings(true);
        },

        generateDatasets(ratings, onlySelfRating = false) {
            let NonMesuredLevel = 0;
            let datasets = [];
            if (this.ratingSettings.NonMesuredLevel !== null) {
                NonMesuredLevel = this.ratingSettings.NonMesuredLevel;
            }

            const date = this.$moment(this.resource.rating_date).format("l");

            datasets.push({
                label: `Auto-évaluation`,
                fill: true,
                pointBorderColor: "#fff",
                ...this.getRatingColorsBySlug('auto-eval-pp-1'), // Self-rating color
                data: this.showedSkillsNotes(this.resource.self_skills, NonMesuredLevel, "note"),
                levels: this.showedSkillsNotes(this.resource.self_skills, NonMesuredLevel, "label"),
                order: 1
            });

            datasets.push({
                label: `${this.resource.skill_review ? this.skillRatingNames.singular : this.projectNames.singular} du ${date}`,
                fill: true,
                pointBorderColor: "#fff",
                ...this.getRatingColorsBySlug(this.resource.skill_review ? 'skill-review-0' : 'pp-0'), // Last rating color
                data: this.showedSkillsNotes(this.value.skills, NonMesuredLevel, "note"),
                levels: this.showedSkillsNotes(this.value.skills, NonMesuredLevel, "label")
            });

            return datasets;
        },

        showedSkillsNotes(skills, NonMesuredLevel, type) {
            return skills.map(s =>
                this.ratedSkillNote(s, NonMesuredLevel, type)
            );
        },

        ratedSkillNote(s, NonMesuredLevel, type) {
            if (type === "note") {
                return this.noteById(s.level) || NonMesuredLevel;
            }

            return this.levelLabelById(s.level) || "Non mesuré";
        },

        getChartOptions() {
            return {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: `Compétences`
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: (tooltipItem, data) => {
                            const label =
                                data.datasets[tooltipItem.datasetIndex].label;
                            const value =
                                label != "Montée en compétences"
                                    ? data.datasets[tooltipItem.datasetIndex]
                                          .levels[tooltipItem.index]
                                    : null;
                            return value ? `${label}: ${value} ` : `${label}`;
                        }
                    },
                    filter: x => x.yLabel >= 0
                },
                scale: {
                    pointLabels: {
                        callback: function(value, index, values) {
                            // if more than 25 values, display the top label with space under and bottom label with space above
                            if (values.length < 25) {
                                return value;
                            } else {
                                if (index == 0) {
                                    return [value, [" "]];
                                } else if (
                                    values.length % 2 == 0 &&
                                    index == values.length / 2
                                ) {
                                    return [" ", [value]];
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                    ticks: {
                        min: this.ratingSettings
                            ? this.ratingSettings.RadarCenter
                            : -1,
                        max: 3,
                        stepSize: 1
                    }
                }
            };
        }

    }
};
</script>

<style lang="scss" scoped>
.chart {
    &.main {
        height: 750px;
        position: relative;
        max-width: 750px;
        margin-left: auto !important;
        margin-right: auto !important;
        @media screen and (max-width: 750px) {
            width: 100%;
            height: 400px;
        }
    }

    .main-chart {
        height: 100%;

        > #radar-chart {
            height: 100%;
        }
    }
}
</style>
