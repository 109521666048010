var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-password" },
    [
      _c("vs-input", {
        class: _vm.inputClasses,
        attrs: {
          success: _vm.success,
          danger: _vm.danger,
          type: _vm.type,
          icon: "icon icon-lock",
          "icon-pack": "feather",
          "label-placeholder": _vm.placeholder,
          name: _vm.name
        },
        on: { input: _vm.update },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          class: ["icon", { active: _vm.hasVisiblePassword }],
          on: {
            click: function($event) {
              _vm.hasVisiblePassword = !_vm.hasVisiblePassword
            }
          }
        },
        [
          _c("vs-icon", {
            attrs: { size: "15px", icon: _vm.icon, color: "rgba(0, 0, 0, 0.4)" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }