<template>
    <div>
        <vx-card>
            <div class="vx-row mt-2 mb-6" :api="api">
                <div class="vx-col w-full">
                    <h4>Paramétrer les exports</h4>
                </div>
            </div>

            <!-- Action -->
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    id="btn-create"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="createResource"
                >
                    Ajouter un nouveau modèle
                </vs-button>

                <vs-prompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @accept="storeResource"
                    @close="resetResource"
                >
                    <Form
                        v-model="resource"
                        :type="formType"
                        :types="types"
                        @error="setFormError"
                    />
                </vs-prompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <!-- Liste carnet de compétence -->
        <vs-row class="mt-20 with-border">
            <vs-col>
                <h4>Carnet de compétence</h4>
                <vs-table
                    class="border-grey-3"
                    :sst="true"
                    :search="false"
                    :no-data-text="listNoDataText"
                    style="width:100%"
                    :data="getSkillBookList"
                >
                    <template slot="thead">
                        <vs-th>
                            ID
                        </vs-th>
                        <vs-th>
                            Titre
                        </vs-th>
                        <vs-th>
                            Modèle
                        </vs-th>
                        <vs-th>
                            Visibilité hors admin
                        </vs-th>
                        <vs-th>
                            Date de création
                        </vs-th>
                        <vs-th></vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                            <vs-td :data="get(item, ':id')">
                                {{ get(item, ":id") }}
                            </vs-td>
                            <vs-td :data="get(item, ':title')">
                                {{ get(item, ":title") }}
                            </vs-td>
                            <vs-td :data="get(item, ':original_filename')">
                                {{ get(item, ":original_filename") }}
                            </vs-td>
                            <vs-td :data="get(item, ':visibility')">
                                <vs-switch
                                    v-model="item.visibility"
                                    :disabled="true"
                                />
                            </vs-td>
                            <vs-td :data="get(item, ':created_at')">
                                {{ formatDateTime(get(item, ":created_at")) }}
                            </vs-td>
                            <vs-td class="action">

                                <vs-button
                                    v-tooltip="'Modifier'"
                                    size="small"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    @click="updateResource(item)"
                                />

                                <vs-button
                                    v-tooltip="'Supprimer'"
                                    size="small"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    @click.stop="deleteResource(item)"
                                />
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-col>
        </vs-row>

        <!-- Liste projet personnalis& -->
        <vs-row class="mt-20 with-border">
            <vs-col>
                <h4>{{ projectNames.singular }}</h4>
                <vs-table
                    class="border-grey-3"
                    :sst="true"
                    :search="false"
                    :no-data-text="listNoDataText"
                    style="width:100%"
                    :data="getRatingList"
                >
                    <template slot="thead">
                        <vs-th>
                            ID
                        </vs-th>
                        <vs-th>
                            Titre
                        </vs-th>
                        <vs-th>
                            Modèle
                        </vs-th>
                        <vs-th>
                            Visibilité hors admin
                        </vs-th>
                        <vs-th>
                            Date de création
                        </vs-th>
                        <vs-th></vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                            <vs-td :data="get(item, ':id')">
                                {{ get(item, ":id") }}
                            </vs-td>
                            <vs-td :data="get(item, ':title')">
                                {{ get(item, ":title") }}
                            </vs-td>
                            <vs-td :data="get(item, ':original_filename')">
                                {{ get(item, ":original_filename") }}
                            </vs-td>
                            <vs-td :data="get(item, ':visibility')">
                                <vs-switch
                                    v-model="item.visibility"
                                    :disabled="true"
                                />
                            </vs-td>
                            <vs-td :data="get(item, ':created_at')">
                                {{ formatDateTime(get(item, ":created_at")) }}
                            </vs-td>
                            <vs-td class="action">

                                <vs-button
                                    v-tooltip="'Modifier'"
                                    size="small"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    @click="updateResource(item)"
                                />

                                <vs-button
                                    v-tooltip="'Supprimer'"
                                    size="small"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    @click.stop="deleteResource(item)"
                                />
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-col>
        </vs-row>


        </vx-card>
    </div>
</template>

<script>
import Form from './Form.vue';

export default {
    name: "Export",
    components: {Form},
    props: {
        api: {
            type: String,
            required: true
        },
        perpage: {
            type: String
        },
        types: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un modèle',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un modèle',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un modèle',
                    btnAccept: 'filled'
                }
            }
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
        getSkillBookList() {
            return this.items.data.filter(i => i.type_id == 1);
        },
        getRatingList() {
            return this.items.data.filter(i => i.type_id == 2);
        }
    },

    methods: {

        createResource() {
            this.resetResource();
            this.formType = "create";
            this.showPopup = true
        },

        updateResource(resource) {
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.resource));
            data.model = this.resource.model;
            data.visibility = data.visibility ? 1 : 0;

            if (data.id) {
                this.apiUpdate(
                    data,
                    data.id,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true;
                        this.loading = false
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true;
                        this.loading = false
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                title: null,
                type_id: null,
                visibility: 1,
                model: null,
            };
        },

        setFormError(value) {
            this.formError = value;
        },
    },
    created() {
        this.apiParams.perpage = 999;
        this.apiParams.include = [];
        this.apiParams.sortBy = {};
        this.loadItems();
    }
};
</script>
<style>
    .with-border .vs-con-table {
        border: 3px solid #d9d9d9;
        border-radius: 0;
    }

    .with-border .vs-con-table .vs-con-tbody {
        border: none;

    }
</style>
