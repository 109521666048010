var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      !_vm.planAllow("dashbords")
        ? _c("LimitatedPlan", { staticClass: "mb-10" })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v("Identifier les potentiels d’une Personne")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Pour chaque personne sélectionnée, ce tableau de bord permet d’orienter sur les métiers ou identifier les postes ou savoir-faire accessibles en sécurité à l'échelle d’un ou plusieurs établissements."
        )
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4 mt-3" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12"
                  }
                },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner une personne")
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.groupedEmployeesByEstablishments,
                                multiple: false,
                                "group-values": "employees",
                                "group-label": "establishment",
                                "group-select": false,
                                placeholder: "Recherche ...",
                                "track-by": "name",
                                label: "name",
                                "select-label": "",
                                "select-group-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                loading: _vm.employees.loading,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              model: {
                                value: _vm.filters.employee,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "employee", $$v)
                                },
                                expression: "filters.employee"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un ou des services")
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.groupedServicesByEstablishments,
                                multiple: true,
                                "group-values": "services",
                                "group-label": "establishment",
                                "group-select": true,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "select-group-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                disabled:
                                  _vm.disabledFilters.services ||
                                  _vm.typeDisplay ==
                                    _vm.typeDisplayValues.OCCUPATIONS ||
                                  !_vm.planAllow("dashbords"),
                                loading: _vm.establishments.loading
                              },
                              model: {
                                value: _vm.filters.services,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "services", $$v)
                                },
                                expression: "filters.services"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un ou des postes")
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.groupedJobsByService,
                                multiple: true,
                                "group-values": "jobs",
                                "group-label": "service",
                                "group-select": true,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "select-group-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                disabled: _vm.disabledFilters.jobs,
                                loading: _vm.jobs.loading
                              },
                              model: {
                                value: _vm.filters.jobs,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "jobs", $$v)
                                },
                                expression: "filters.jobs"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un ou des métiers")
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.occupationsList,
                                multiple: true,
                                "group-values": "occupations",
                                "group-label": "type",
                                "group-select": true,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                disabled:
                                  _vm.disabledFilters.occupations ||
                                  !_vm.planAllow("dashbords"),
                                loading: _vm.occupations.loading
                              },
                              model: {
                                value: _vm.filters.occupations,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "occupations", $$v)
                                },
                                expression: "filters.occupations"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4 radio-container" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-justify": "center", "vs-align": "center" } },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "vx-row",
                          attrs: { "vs-align": "flex-start" }
                        },
                        [
                          _c(
                            "vs-radio",
                            {
                              staticClass: "mr-20 mb-5",
                              attrs: {
                                "vs-name": "radios1",
                                id: "radios1-metiers",
                                "vs-value": _vm.typeDisplayValues.OCCUPATIONS,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              model: {
                                value: _vm.typeDisplay,
                                callback: function($$v) {
                                  _vm.typeDisplay = $$v
                                },
                                expression: "typeDisplay"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Métiers\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "vs-radio",
                            {
                              staticClass: "mr-20 mb-5",
                              attrs: {
                                "vs-name": "radios1",
                                id: "radios1-postes",
                                "vs-value": _vm.typeDisplayValues.JOBS,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              model: {
                                value: _vm.typeDisplay,
                                callback: function($$v) {
                                  _vm.typeDisplay = $$v
                                },
                                expression: "typeDisplay"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Postes\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "vs-radio",
                            {
                              staticClass: "mr-20 mb-5",
                              attrs: {
                                "vs-name": "radios1",
                                id: "radios1-savoirFaire",
                                "vs-value":
                                  _vm.typeDisplayValues.OCCUPATION_SKILLS,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              model: {
                                value: _vm.typeDisplay,
                                callback: function($$v) {
                                  _vm.typeDisplay = $$v
                                },
                                expression: "typeDisplay"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Savoir-faire\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vx-row" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  staticClass: "mr-20 mb-5",
                                  attrs: {
                                    name: "checkboxWishlistOnly",
                                    disabled: !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.filters.wishlistOnly,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters, "wishlistOnly", $$v)
                                    },
                                    expression: "filters.wishlistOnly"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Souhaités uniquement\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-checkbox",
                                {
                                  staticClass: "mr-20 mb-5",
                                  attrs: {
                                    name: "checkboxCheckOnly",
                                    disabled: !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.filters.securityOnly,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters, "securityOnly", $$v)
                                    },
                                    expression: "filters.securityOnly"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                Accessibles en sécurité\n                            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.can("read", "skill_reviews") || _vm.can("read", "ratings")
            ? _c(
                "vs-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-justify": "center", "vs-align": "center" } },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              staticClass: "vx-row",
                              attrs: { "vs-align": "flex-start" }
                            },
                            [
                              _vm._v(
                                "\n                        Résultats basés sur :\n                        "
                              ),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5 ml-2",
                                  attrs: {
                                    "vs-name": "radios2",
                                    id: "radios2-ratings",
                                    "vs-value": _vm.typeComputeValues.RATINGS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.projectNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    "vs-name": "radios2",
                                    id: "radios2-reviews",
                                    "vs-value": _vm.typeComputeValues.REVIEWS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.skillRatingNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
            [
              _vm.planAllow("dashbords")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "search-button",
                        color: "primary",
                        disabled: !_vm.canSearch
                      },
                      on: { click: _vm.searchClick }
                    },
                    [
                      _vm._v(
                        "\n                Lancer la recherche\n            "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { position: "relative", float: "left" },
                      attrs: {
                        title: "Votre formule n'inclut pas cette fonctionnalité"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            id: "search-button",
                            color: "primary",
                            disabled: "true"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Lancer la recherche\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-icon",
                        {
                          staticClass: "button-bullet",
                          attrs: { bg: "#FF9B3A", round: "" }
                        },
                        [
                          _c("CrownIcon", {
                            staticStyle: {
                              width: "16px",
                              height: "16px",
                              padding: "3px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-card", { staticClass: "mt-6" }, [
        _vm.evaluationForChart.length > 0
          ? _c(
              "div",
              [
                _c("vs-row", [
                  _c(
                    "ul",
                    {
                      staticStyle: {
                        columns: "4",
                        "-webkit-columns": "4",
                        "-moz-columns": "4"
                      },
                      attrs: { id: "employee-skills-show" }
                    },
                    _vm._l(_vm.evaluationForChart, function(chart, i) {
                      return _c("li", { key: i + "-" + chart.job.id }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.scrollTo(
                                  "chart-" + i + "-" + chart.job.id
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: { content: chart.job.description },
                                    expression:
                                      "{ content: chart.job.description }"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      chart.options.title.text.split(":")[0]
                                    ) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                            :\n                            "
                            ),
                            _c("b", { staticClass: "text-primary" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.round(chart.scoreNumber * 100, 0) + "% "
                                  ) +
                                  "\n                            "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        chart.check
                          ? _c("b", { staticStyle: { color: "green" } }, [
                              _vm._v("✓")
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c(
                  "vs-row",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-type": "flex", "vs-justify": "center" } },
                      [
                        _vm.hasMorePage
                          ? _c(
                              "vs-button",
                              {
                                attrs: {
                                  id: "more-button",
                                  color: "primary",
                                  type: "border"
                                },
                                on: { click: _vm.loadEvaluationsPage }
                              },
                              [
                                _vm._v(
                                  "\n                        Charger plus...\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm.evaluation.loaded
          ? _c("div", [
              _vm._v("\n            Aucune donnée à afficher\n        ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mt-6" },
        [
          _vm.evaluationForChart.length > 0
            ? _c(
                "vs-row",
                _vm._l(_vm.evaluationForChart, function(chart, i) {
                  return _c(
                    "vs-col",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: { content: chart.job.description },
                          expression: "{ content: chart.job.description }"
                        }
                      ],
                      key:
                        "chart-" + i + "-" + chart.score + "-" + chart.job.id,
                      staticClass: "mt-6",
                      attrs: {
                        id: "chart-" + i + "-" + chart.job.id,
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-lg": "6",
                        "vs-sm": "12"
                      }
                    },
                    [
                      _c("RatingChart", {
                        staticClass: "rating",
                        attrs: {
                          employee: _vm.filters.employee,
                          options: chart.options,
                          "chart-data": chart.data
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mt-10" },
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            [
              _vm.hasMorePage
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "more-button",
                        color: "primary",
                        type: "border"
                      },
                      on: { click: _vm.loadEvaluationsPage }
                    },
                    [_vm._v("\n                Charger plus...\n            ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }