<template>
    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"  xml:space="preserve">
    <!-- <style type="text/css">
        .st0{fill:#098001;}
    </style> -->
    <g>
        <path class="st0" d="M469.402,35.492C334.09,110.664,197.114,324.5,197.114,324.5L73.509,184.176L0,254.336l178.732,222.172
            l65.15-2.504C327.414,223.414,512,55.539,512,55.539L469.402,35.492z" style="fill:#098001;"/>
    </g>
    </svg>
</template>

<script>
// import CheckGreenIcon from '@components/svg/CheckGreenIcon';
</script>
