<template>
    <div>
        <vs-button @click="setModaleIsOpen" :disabled="!allEmployees.length">
            Export CV
        </vs-button>
        <div
            v-if="allEmployees.length"
            class="modale-container"
            :class="{ active: modaleIsOpen }"
        >
            <div class="modale">
                <div class="close-container">
                    <div
                        v-if="!isLoading"
                        class="btn-close"
                        @click="setModaleIsOpen"
                    >
                        <img :src="closeIcon" alt="close" />
                    </div>
                </div>

                <ul v-if="!isLoading" class="checklist">
                    <li>
                        <input
                            id="checkAll"
                            name="select"
                            type="checkbox"
                            v-model="selectAll"
                        />
                        <label class="checkbox" for="checkAll"
                            >Tout sélectionner</label
                        >
                    </li>
                    <li
                        v-for="(user, i) in employeesInfos"
                        :key="user.employee._id"
                    >
                        <input
                            :id="i"
                            type="checkbox"
                            :value="user.employee._id"
                            v-model="selected"
                        />
                        <label
                            :for="i"
                            class="checkbox"
                            :class="{
                                active: selected.find(
                                    id => id == user.employee._id
                                )
                            }"
                            >{{ user.employee.first_name }}
                            {{ user.employee.last_name }}</label
                        >
                    </li>
                </ul>

                <div v-if="isLoading" class="loader">
                    <Loader />
                </div>

                <button
                    id="export-pdf-button"
                    v-if="this.selected.length && !isLoading"
                    @click="initExport"
                    :class="[
                        'vs-con-loading__container',
                        { exportPDF: 'vs-con-loading__container' }
                    ]"
                >
                    Exporter
                </button>

                <!-- Export du CV de plusieurs personnes -->
                <div id="cv-container" class="hide-pdf">
                    <div v-for="(user, i) in exportList" :key="i" ref="cv">
                        <CV :employee="user.employeeModel"></CV>
                        <!-- <header cv-header>
                            <div class="employee-photo">
                                <img
                                    :src="employeePhoto(user.employee)"
                                    alt=""
                                />
                            </div>
                            <div class="employee-info">
                                <h1>
                                    {{ user.employee.first_name }}
                                    <span>{{ user.employee.last_name }}</span>
                                </h1>
                                <p>
                                    Date de naissance :
                                    {{
                                        user.employee.birthday
                                            ? format(
                                                  new Date(
                                                      user.employee.birthday
                                                  ),
                                                  "dd/MM/yyyy"
                                              )
                                            : "-"
                                    }}
                                </p>
                                <p>
                                    Email :
                                    {{
                                        user.employee.email
                                            ? user.employee.email
                                            : "-"
                                    }}
                                </p>
                                <p>
                                    Télephone :
                                    {{
                                        user.employee.phone
                                            ? user.employee.phone
                                            : "-"
                                    }}
                                </p>
                            </div>
                        </header>

                        <div class="main">

                            <div class="left">
                                <div
                                    cv-occupations
                                    v-if="user.career['occupations']"
                                    class="step occupations"
                                >
                                    <h2 class="round-purple">
                                        Métiers<span class="icon"></span>
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'occupations'
                                        ]"
                                        :key="'occupation-' + i"
                                    >
                                        <h3>{{ item.name }}</h3>
                                        <p>
                                            Du {{ formatDate(item.start) }}
                                            {{
                                                !formatDate(item.end, true)
                                                    ? "à aujourd'hui"
                                                    : "au " +
                                                      formatDate(item.end, true)
                                            }}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    cv-skills
                                    v-if="user.career['skills'].length"
                                    class="step skills"
                                >
                                    <h2 class="round-pink">
                                        Compétences<span class="icon"></span>
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'skills'
                                        ]"
                                        :key="'skill-' + i"
                                    >
                                        <p class="bold">
                                            {{ item.category_name }}
                                        </p>
                                        <div
                                            :data-rate="item.rate"
                                            :class="[
                                                'rate-container',
                                                { empty: !item.rate }
                                            ]"
                                        >
                                            <div
                                                v-for="i in 10"
                                                class="rate-circle"
                                                :key="i"
                                            ></div>
                                        </div>
                                    </div>


                                </div>

                                <div
                                    cv-occupationSkills
                                    v-if="
                                        user.career['occupation_skills'].length
                                    "
                                    class="step occupation_skills"
                                >
                                    <h2 class="round-yellow">
                                        Savoir-faire<span class="icon"></span>
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'occupation_skills'
                                        ]"
                                        :key="'sector-' + i"
                                    >
                                        <h3 class="addMargin">
                                            {{ item.sector.title }}
                                        </h3>
                                        <br />
                                        <template
                                            v-if="
                                                item.occupationSkills.filter(
                                                    el => el.level == 4
                                                ).length
                                            "
                                        >
                                            <h4>Acquis en autonomie :</h4>
                                            <ul>
                                                <li
                                                    v-for="(os,
                                                    j) in item.occupationSkills.filter(
                                                        el => el.level == 4
                                                    )"
                                                    :key="'os-' + i + j"
                                                >
                                                    <p>{{ os.title }}</p>
                                                </li>
                                            </ul>
                                        </template>
                                        <br />
                                        <template
                                            v-if="
                                                item.occupationSkills.filter(
                                                    el => el.level == 3
                                                ).length
                                            "
                                        >
                                            <h4>Acquis :</h4>
                                            <ul>
                                                <li
                                                    v-for="(os,
                                                    j) in item.occupationSkills.filter(
                                                        el => el.level == 3
                                                    )"
                                                    :key="'os-' + i + j"
                                                >
                                                    <p>{{ os.title }}</p>
                                                </li>
                                            </ul>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="right">
                                <div
                                    cv-w_jobs
                                    v-if="
                                        user.career[
                                            'employee_rating_job_wishlists'
                                        ].length
                                    "
                                    class="step employee_rating_job_wishlists"
                                >
                                    <h2 class="star">
                                        <span class="icon"></span>Postes
                                        souhaités
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'employee_rating_job_wishlists'
                                        ]"
                                        :key="'job-w-' + i"
                                    >
                                        <h3>{{ item.names }}</h3>
                                        <p>Filière {{ item.sector }}</p>
                                    </div>
                                </div>

                                <div
                                    cv-experiences
                                    v-if="user.career['jobs'].length"
                                    class="step jobs"
                                >
                                    <h2 class="circle">
                                        <span class="icon"></span>Expériences
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career['jobs']"
                                        :key="'job-' + i"
                                    >
                                        <h3>{{ item.names }}</h3>
                                        <p>
                                            Du {{ formatDate(item.start) }}
                                            {{
                                                !formatDate(item.end, true)
                                                    ? "à aujourd'hui"
                                                    : "au " +
                                                      formatDate(item.end, true)
                                            }}
                                        </p>
                                        <p class="bold">
                                            Service {{ item.service }}
                                        </p>
                                        <p class="bold">
                                            Etablissement
                                            {{ item.establishment }}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    cv-immersions
                                    v-if="user.career['Immersions']"
                                    class="step immersions"
                                >
                                    <h2 class="diamond">
                                        <span class="icon"></span>Immersions
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'Immersions'
                                        ]"
                                        :key="'immersion-' + i"
                                    >
                                        <h3>{{ item.name }}</h3>
                                        <p>
                                            Du {{ formatDate(item.start) }}
                                            {{
                                                !formatDate(item.end, true)
                                                    ? "à aujourd'hui"
                                                    : "au " +
                                                      formatDate(item.end, true)
                                            }}
                                        </p>
                                        <p class="bold">
                                            Etablissement
                                            {{ item.organisation }}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    cv-trainings
                                    v-if="user.career['trainings']"
                                    class="step trainings"
                                >
                                    <h2 class="square">
                                        <span class="icon"></span>Formations
                                    </h2>
                                    <div
                                        v-for="(item, i) in user.career[
                                            'trainings'
                                        ]"
                                        :key="'training-' + i"
                                    >
                                        <h3>
                                            {{ item.name }}
                                            <img
                                                v-if="item.isGraduate"
                                                class="is-graduate"
                                                :src="
                                                    imageUrl(
                                                        '/assets/images/qualification-blue.png'
                                                    )
                                                "
                                                alt="Formation diplômante"
                                            />
                                        </h3>
                                        <p>
                                            Du {{ formatDate(item.start) }}
                                            {{
                                                !formatDate(item.end, true)
                                                    ? "à aujourd'hui"
                                                    : "au " +
                                                      formatDate(item.end, true)
                                            }}
                                        </p>
                                        <p class="bold">
                                            Etablissement
                                            {{ item.organisation }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExportStep from "./ExportStep";
import Loader from "../Loader";

import closeIcon from "@assets/images/icons/close.svg";
//import { jsPDF } from "jspdf";
//import html2canvas from "html2canvas";
//import { format, isAfter, isSameDay } from "date-fns";
import CV from "@components/divers/CV"
import { buildCVMixin } from "@components/divers/buildCV.js";
//import { mapGetters, mapState } from "vuex";
// import VueWordCloud from 'vuewordcloud';

export default {
    name: "ExportModale",

    components: {
        ExportStep,
        Loader,
        CV
        // [VueWordCloud.name]: VueWordCloud,
    },
    mixins: [buildCVMixin],
    props: {
        allEmployees: Array
    },

    data() {
        return {
            log: console.log,
            modaleIsOpen: false,
            closeIcon: closeIcon,
            isLoading: false,
            selected: [],
            //format,
            //colors: ["#942193", "#d783fc", "#ff2f92", "#081993", "#96305e"], // Cloud words
            exportPDF: false,

            // PDF config
            // pdf: null,
            // a4_width: 210,
            // a4_height: 297,
            // nextSidePositionY: 0
        };
    },

    computed: {
        //...mapState(["skills"]),
        //...mapGetters(["employeeById", "adminSkillById"]),
        selectAll: {
            get: function() {
                return this.allEmployees
                    ? this.selected.length == this.allEmployees.length
                    : false;
            },
            set(value) {
                let selected = [];

                if (value) {
                    this.employeesInfos.forEach(function(user) {
                        selected.push(user.employee._id);
                    });
                }

                this.selected = selected;
            }
        },

        exportList() {
            let toExport = this.employeesInfos.filter(e =>
                this.selected.find(id => id == e.employee._id)
            );
            return toExport;
        },
        /**
         * Format the data to fit cv template
         */
        employeesInfos() {
            if (!this.allEmployees?.length) return [];
            let employees = window._.cloneDeep(this.allEmployees);
            // employees = employees.map(item => {
            //     // Sort and index career by category
            //     let career = [];
            //     item.career.forEach(c => {
            //         if (!career[c.category]) {
            //             career[c.category] = [];
            //         }
            //         career[c.category].push(c);
            //     });

            //     career["skills"] = [];
            //     career["occupation_skills"] = [];

            //     // Sélection uniquement des projets personnalisés en attente de validation et des projets personnalisés validés
            //     career.employee_ratings = career.employee_ratings
            //         ? career.employee_ratings.filter(
            //               rating => rating.status === 0 || rating.status === 1
            //           )
            //         : [];
            //     let lastRating =
            //         career.employee_ratings && career.employee_ratings.length
            //             ? career.employee_ratings[
            //                   career.employee_ratings.length - 1
            //               ]
            //             : null;

            //     let acquiredSkills = [];
            //     if (lastRating) {
            //         // Group ratings skills by category
            //         lastRating.skills
            //             .filter(s => s.admin_skill)
            //             .forEach(s => {

            //                 let category = career.skills.find(
            //                     el => el.category_id == s.admin_skill.category.id
            //                 );
            //                 if (!category) {
            //                     career.skills.push({
            //                         category_id: s.admin_skill.category.id,
            //                         category_name: s.admin_skill.category.title,
            //                         skills: [s],
            //                         rate: 0
            //                     });
            //                 } else {
            //                     category.skills.push(s);
            //                 }
            //             });

            //         // Set a ratio between the number of skills with level > 2 compared with
            //         // the total of skills for the category
            //         career.skills = career.skills.map(skillsByCategory => {
            //             acquiredSkills.push(
            //                 ...skillsByCategory.skills.filter(
            //                     s => s.pivot.level > 2
            //                 )
            //             );
            //             let totalAcquiredSkills = skillsByCategory.skills.filter(
            //                 s => s.pivot.level > 2
            //             ).length;
            //             skillsByCategory.rate = Math.floor(
            //                 (totalAcquiredSkills /
            //                     skillsByCategory.skills.length) *
            //                     10
            //             );
            //             return skillsByCategory;
            //         });

            //         // Group occupations skills by sector
            //         Object.values(lastRating.job_occupationSkills).forEach(
            //             job => {
            //                 let occSkills = job.occupation_skills.filter(
            //                     os => os.level >= 3
            //                 );
            //                 Object.values(occSkills).forEach(os => {
            //                     let sector = career.occupation_skills.find(
            //                         el => el.sector.id == os.sector.id
            //                     );
            //                     if (!sector) {
            //                         career.occupation_skills.push({
            //                             sector: os.sector,
            //                             occupationSkills: [os]
            //                         });
            //                     } else {
            //                         let exists = sector.occupationSkills.find(
            //                             el => el.title == os.title
            //                         );
            //                         if (!exists) {
            //                             sector.occupationSkills.push(os);
            //                         }
            //                     }
            //                 });
            //             }
            //         );
            //     }

            //     // Group wished jobs by sector
            //     let jobsBySector = [];
            //     if (career["employee_rating_job_wishlists"]) {
            //         career["employee_rating_job_wishlists"].forEach(job => {
            //             let sector = jobsBySector.find(
            //                 el => el.sector == job.sector
            //             );
            //             if (!sector) {
            //                 jobsBySector.push({
            //                     sector: job.sector,
            //                     names: [job.name]
            //                 });
            //             } else {
            //                 sector.names.push(job.name);
            //             }
            //         });
            //         jobsBySector = jobsBySector.map(job => {
            //             job.names = job.names.join(", ");
            //             return job;
            //         });
            //     }
            //     career["employee_rating_job_wishlists"] = jobsBySector;

            //     // Group experiences by date, sector and establishment
            //     let experiences = [];
            //     if (career["jobs"]) {
            //         career["jobs"].forEach(job => {
            //             let sector = experiences.find(el => {
            //                 let sameStartDay = isSameDay(
            //                     new Date(el.start),
            //                     new Date(job.start)
            //                 );
            //                 let sameEndDay = isSameDay(
            //                     new Date(el.end),
            //                     new Date(job.end)
            //                 );

            //                 return (
            //                     el.service == job.service &&
            //                     el.establishment == job.establishment &&
            //                     sameStartDay &&
            //                     sameEndDay
            //                 );
            //             });
            //             if (!sector) {
            //                 experiences.push({
            //                     service: job.service,
            //                     start: job.start,
            //                     end: job.end,
            //                     establishment: job.establishment,
            //                     names: [job.name]
            //                 });
            //             } else {
            //                 sector.names.push(job.name);
            //             }
            //         });
            //         experiences = experiences.map(job => {
            //             job.names = job.names.join(", ");
            //             return job;
            //         });
            //     }
            //     career["jobs"] = experiences;

            //     // Generate cloudworld with the first five acquired skills with the highest level
            //     // career['cloudWords'] = acquiredSkills
            //     // .sort((a, b) => b.pivot.level - a.pivot.level)
            //     // .slice(0, 5)
            //     // .map((el, index) => {
            //     //   let title = el.admin_skill.title
            //     //   title = title.length > 25 ? title.slice(0, 25) + '...' : title
            //     //   return [title, el.pivot.level, this.colors[index]]
            //     // });

            //     item.career = career;
            //     return item;
            // });
            return employees;
        }
    },

    methods: {
        setModaleIsOpen: function() {
            this.modaleIsOpen = !this.modaleIsOpen;
        },

        // async download() {
        //     const margin = 10;
        //     const content_width = this.a4_width - 2 * margin;
        //     const content_height = this.a4_height - 2 * margin;
        //     let totalPage = 0; // pdf total number of page

        //     this.pdf = new jsPDF({
        //         orientation: "p",
        //         unit: "mm",
        //         putOnlyUsedFonts: true
        //     });

        //     this.pdf.setFont("helvetica", "normal");
        //     this.pdf.setFontSize(8);

        //     let cv = this.$refs.cv;
        //     let countCV = 0;

        //     for (let e of cv) {
        //         let header = e.querySelectorAll("header[cv-header]")[0];
        //         let occupations = e.querySelectorAll("div[cv-occupations]")[0];
        //         let occupationSkills = e.querySelectorAll(
        //             "div[cv-occupationSkills]"
        //         )[0];
        //         let skills = e.querySelectorAll("div[cv-skills]")[0];
        //         let w_jobs = e.querySelectorAll("div[cv-w_jobs]")[0];
        //         let experiences = e.querySelectorAll("div[cv-experiences]")[0];
        //         let immersions = e.querySelectorAll("div[cv-immersions]")[0];
        //         let trainings = e.querySelectorAll("div[cv-trainings]")[0];
        //         let startPage = this.pdf.internal.getCurrentPageInfo()
        //             .pageNumber;

        //         let infosLeft = [occupations, skills, occupationSkills].filter(
        //             e => e != undefined
        //         );
        //         let infosRight = [
        //             w_jobs,
        //             experiences,
        //             immersions,
        //             trainings
        //         ].filter(e => e != undefined);
        //         let i = 0;

        //         let header_positionX = 0 + margin;

        //         // Cv header
        //         let avatar_height = 25;
        //         let avatar_width = avatar_height;
        //         this.nextSidePositionY = margin + 5;
        //         await this.addHeader(header, margin, avatar_height);
        //         header_positionX += avatar_width;

        //         let totalCvPage = 1; // Number of page by cv

        //         this.addTimeLine(margin, header_positionX, true);

        //         // For each cv side
        //         for (let e of [infosLeft, infosRight]) {
        //             let align = "right";
        //             this.pdf.setPage(startPage);
        //             i++;
        //             let size = 0.45; // Largeur du bloc sur la page, en pourcentage
        //             let offsetSide = 10;
        //             let width = content_width * size - offsetSide;
        //             let left = margin;
        //             let offsetTop = 15;

        //             if (i == 2) {
        //                 // Left side
        //                 align = "left";
        //                 offsetTop = 5;
        //                 left = width + margin + 2 * offsetSide;
        //                 size = 0.55;
        //                 width = content_width * size - offsetSide;
        //             }

        //             this.nextSidePositionY = header_positionX + offsetTop;
        //             let positionX = margin + width;
        //             if (align == "left") {
        //                 positionX = left;
        //             } else {
        //                 positionX = margin + width;
        //             }

        //             // For each part by side
        //             for (let el of e) {
        //                 let currentColor = "black";
        //                 for (let m = 0; m < el.children.length; m++) {
        //                     let pageCurrent = this.pdf.internal.getCurrentPageInfo()
        //                         .pageNumber;

        //                     // check for new page
        //                     if (this.nextSidePositionY + 5 > content_height) {
        //                         if (pageCurrent == totalCvPage) {
        //                             this.nextSidePositionY = margin;
        //                             this.pdf.addPage();
        //                             totalCvPage++;
        //                             this.addTimeLine(
        //                                 margin,
        //                                 header_positionX,
        //                                 false
        //                             );
        //                         } else {
        //                             this.pdf.setPage(pageCurrent + 1);
        //                             this.nextSidePositionY = margin + 5;
        //                         }
        //                     }

        //                     currentColor = getComputedStyle(el.children[m])
        //                         .color;

        //                     if (el.children[m].tagName == "H2") {
        //                         // Draw top line and display h2 inner text
        //                         this.pdf.setFont("helvetica", "bold");
        //                         this.pdf.setDrawColor(currentColor);
        //                         this.pdf.setLineWidth(0.5);
        //                         this.nextSidePositionY += 8;
        //                         if (align == "left") {
        //                             this.pdf.line(
        //                                 left + width,
        //                                 this.nextSidePositionY,
        //                                 positionX,
        //                                 this.nextSidePositionY,
        //                                 "FD"
        //                             );
        //                         } else {
        //                             this.pdf.line(
        //                                 left,
        //                                 this.nextSidePositionY,
        //                                 positionX,
        //                                 this.nextSidePositionY,
        //                                 "FD"
        //                             );
        //                         }
        //                         this.pdf.setTextColor(currentColor);
        //                         this.pdf.setFontSize(11);
        //                         this.nextSidePositionY += 8;
        //                         this.addText(
        //                             el.children[m].innerText,
        //                             width,
        //                             positionX,
        //                             align
        //                         );

        //                         await this.addTimeLineDot(el);
        //                         this.nextSidePositionY += 2;
        //                     } else if (el.children[m].tagName == "DIV") {
        //                         let elem = el.children[m];
        //                         this.nextSidePositionY += 6;

        //                         for (let n = 0; n < elem.children.length; n++) {
        //                             let style = getComputedStyle(
        //                                 elem.children[n]
        //                             );

        //                             if (elem.children[n].innerText) {
        //                                 // check for new page
        //                                 if (
        //                                     this.nextSidePositionY + 5 >
        //                                     content_height
        //                                 ) {
        //                                     if (pageCurrent == totalCvPage) {
        //                                         this.nextSidePositionY = margin;
        //                                         this.pdf.addPage();
        //                                         totalCvPage++;
        //                                         this.addTimeLine(
        //                                             margin,
        //                                             header_positionX,
        //                                             false
        //                                         );
        //                                     } else {
        //                                         this.pdf.setPage(
        //                                             pageCurrent + 1
        //                                         );
        //                                         this.nextSidePositionY =
        //                                             margin + 5;
        //                                     }
        //                                 }

        //                                 // Display inner text
        //                                 let align = style.textAlign;
        //                                 let color = style.color;
        //                                 let fontWeight = style.fontWeight
        //                                     ? parseInt(style.fontWeight) > 500
        //                                         ? "bold"
        //                                         : "normal"
        //                                     : "normal";

        //                                 this.pdf.setFont(
        //                                     "helvetica",
        //                                     fontWeight
        //                                 );
        //                                 this.pdf.setTextColor(color);
        //                                 this.pdf.setFontSize(9);
        //                                 this.nextSidePositionY += 5;

        //                                 let position;
        //                                 if (align == "left") {
        //                                     position = left;
        //                                 } else {
        //                                     position = margin + width;
        //                                 }

        //                                 if (
        //                                     elem.children[n].className ==
        //                                     "addMargin"
        //                                 ) {
        //                                     // only for cv-occupationSkills
        //                                     this.addText(
        //                                         elem.children[n].innerText,
        //                                         width,
        //                                         position,
        //                                         align
        //                                     );
        //                                     this.nextSidePositionY += 5;
        //                                 } else if (
        //                                     elem.children[n].tagName == "UL"
        //                                 ) {
        //                                     // Ajout SAVOIR-FAIRE
        //                                     for (let item of elem.children[n]
        //                                         .children) {
        //                                         this.addText(
        //                                             item.innerText,
        //                                             width,
        //                                             position,
        //                                             align
        //                                         );
        //                                         this.nextSidePositionY += 5;

        //                                         // Saut de page si besoin
        //                                         if (
        //                                             this.nextSidePositionY + 5 >
        //                                             content_height
        //                                         ) {
        //                                             if (
        //                                                 pageCurrent ==
        //                                                 totalCvPage
        //                                             ) {
        //                                                 this.nextSidePositionY = margin;
        //                                                 this.pdf.addPage();
        //                                                 totalCvPage++;
        //                                                 this.addTimeLine(
        //                                                     margin,
        //                                                     header_positionX,
        //                                                     false
        //                                                 );
        //                                             } else {
        //                                                 this.pdf.setPage(
        //                                                     pageCurrent + 1
        //                                                 );
        //                                                 this.nextSidePositionY =
        //                                                     margin + 5;
        //                                             }
        //                                         }
        //                                     }
        //                                 } else {
        //                                     let text =
        //                                         elem.children[n].innerText;

        //                                     this.addText(
        //                                         text,
        //                                         width,
        //                                         position,
        //                                         align
        //                                     );

        //                                     // Formation qualifiante (logo)
        //                                     if (
        //                                         elem.children[
        //                                             n
        //                                         ].querySelectorAll(
        //                                             ".is-graduate"
        //                                         ).length > 0
        //                                     ) {
        //                                         let image = elem.children[
        //                                             n
        //                                         ].querySelectorAll(
        //                                             ".is-graduate"
        //                                         )[0];
        //                                         let graduate = await this.getImageFromUrl(
        //                                             image.src
        //                                         );
        //                                         this.pdf.addImage(
        //                                             graduate,
        //                                             "PNG",
        //                                             180,
        //                                             this.nextSidePositionY,
        //                                             10,
        //                                             10
        //                                         );
        //                                     }
        //                                 }
        //                             } else if (
        //                                 elem.children[n].hasAttribute(
        //                                     "data-rate"
        //                                 )
        //                             ) {
        //                                 // By skills, draw 10 circles, filled or empty, depends on skills rating
        //                                 this.nextSidePositionY += 5;
        //                                 this.pdf.setDrawColor(currentColor);
        //                                 this.pdf.setFillColor(currentColor);
        //                                 let rate = elem.children[
        //                                     n
        //                                 ].getAttribute("data-rate");
        //                                 let isFullCircle = true;
        //                                 let radius = 2;
        //                                 let positionCircleX = left + radius;
        //                                 for (let i = 0; i < 10; i++) {
        //                                     if (i >= rate) {
        //                                         isFullCircle = false;
        //                                     }
        //                                     this.pdf.setLineWidth(0.25);
        //                                     this.pdf.circle(
        //                                         positionCircleX,
        //                                         this.nextSidePositionY,
        //                                         radius,
        //                                         isFullCircle ? "FD" : "S"
        //                                     );
        //                                     positionCircleX += 7;
        //                                 }
        //                                 this.nextSidePositionY += 2;
        //                             }
        //                         }
        //                     }
        //                 }

        //                 this.pdf.setDrawColor(currentColor);
        //                 this.pdf.setLineWidth(0.5);
        //                 this.nextSidePositionY += 10;
        //                 let position;
        //                 if (align == "left") {
        //                     position = this.a4_width - margin;
        //                 } else {
        //                     position = margin + width;
        //                 }
        //                 this.pdf.line(
        //                     left,
        //                     this.nextSidePositionY,
        //                     position,
        //                     this.nextSidePositionY,
        //                     "FD"
        //                 );
        //             }
        //         }

        //         totalPage += totalCvPage;
        //         this.addFooters(totalPage, totalCvPage, margin);
        //         countCV++;

        //         if (countCV < cv.length) {
        //             this.pdf.addPage();
        //         }
        //     }

        //     this.pdf.save("cv.pdf");
        //     this.closeExport();
        // },

        // // Add dot/shape image, in front of each major part of the cv (occupations, skills, jobs etc...)
        // async addTimeLineDot(el) {
        //     let icon;
        //     let iconWidth = 4;

        //     switch (true) {
        //         case el.hasAttribute("cv-occupations"): // Metiers
        //             // full circle
        //             icon = document.querySelectorAll(
        //                 "div[cv-occupations] .icon"
        //             )[0];
        //             break;
        //         case el.hasAttribute("cv-skills"): // Compétences
        //             // full circle
        //             icon = document.querySelectorAll("div[cv-skills] .icon")[0];
        //             break;
        //         case el.hasAttribute("cv-experiences"): // Experiences
        //             // empty circle
        //             icon = document.querySelectorAll(
        //                 "div[cv-experiences] .icon"
        //             )[0];
        //             break;
        //         case el.hasAttribute("cv-w_jobs"): // Postes souhaités
        //             // star icon
        //             icon = document.querySelectorAll("div[cv-w_jobs] .icon")[0];
        //             iconWidth = 5;
        //             break;
        //         case el.hasAttribute("cv-immersions"): // Immersions
        //             // full diamond
        //             icon = document.querySelectorAll(
        //                 "div[cv-immersions] .icon"
        //             )[0];
        //             iconWidth = 5;
        //             break;
        //         case el.hasAttribute("cv-trainings"): // Formations
        //             // full square
        //             icon = document.querySelectorAll(
        //                 "div[cv-trainings] .icon"
        //             )[0];
        //             break;
        //         case el.hasAttribute("cv-occupationSkills"): // Savoir-faire
        //             // full circle
        //             icon = document.querySelectorAll(
        //                 "div[cv-occupationSkills] .icon"
        //             )[0];
        //             break;
        //         default:
        //             icon = document.querySelectorAll(
        //                 "div[cv-experiences] .icon"
        //             )[0];
        //             break;
        //     }

        //     await html2canvas(icon).then(canvas => {
        //         canvas.getContext("2d");
        //         let canvasHeight = (canvas.height * iconWidth) / canvas.width;
        //         this.pdf.addImage(
        //             canvas,
        //             "PNG",
        //             this.a4_width * 0.45 - iconWidth / 2,
        //             this.nextSidePositionY - iconWidth + 1,
        //             iconWidth,
        //             canvasHeight
        //         );
        //     });
        // },

        // addText(innerText, width, position, align) {
        //     let text = this.pdf.splitTextToSize(innerText, width);
        //     this.pdf.text(text, position, this.nextSidePositionY, {
        //         align: align
        //     });
        //     if (text.length > 1) {
        //         this.nextSidePositionY += 5 * (text.length - 1) - text.length;
        //     }
        // },

        // // Draw vertical line in the middle of the page
        // addTimeLine(margin, headerHeight, hasHeader = false) {
        //     if (hasHeader) {
        //         headerHeight += 5;
        //     } else {
        //         headerHeight = margin;
        //     }
        //     this.pdf.setFillColor(223, 223, 223);
        //     this.pdf.setDrawColor(223, 223, 223);
        //     this.pdf.line(
        //         this.a4_width * 0.45,
        //         headerHeight,
        //         this.a4_width * 0.45,
        //         this.a4_height - 2 * margin,
        //         "FD"
        //     );
        // },

        // async addHeader(header, margin, avatar_height) {
        //     // avatar
        //     let avatar_width = avatar_height;
        //     let url = header.children[0].children[0].src;
        //     let employeeAvatar = await this.getImageFromUrl(url);
        //     this.pdf.addImage(
        //         employeeAvatar,
        //         "JPEG",
        //         margin,
        //         margin,
        //         avatar_width,
        //         avatar_height
        //     );

        //     // content
        //     let texts = header.children[1].children;
        //     let avatarContainer = avatar_width + 2 * margin;
        //     for (let text of texts) {
        //         this.pdf.setFontSize(9);
        //         if (text.tagName == "H1") {
        //             this.pdf.setFontSize(16);
        //         }
        //         let style = getComputedStyle(text);
        //         let align = style.textAlign;
        //         let color = style.color;
        //         let fontWeight = style.fontWeight
        //             ? parseInt(style.fontWeight) > 500
        //                 ? "bold"
        //                 : "normal"
        //             : "normal";

        //         this.pdf.setFont("helvetica", fontWeight);
        //         this.pdf.setTextColor(color);
        //         this.addText(
        //             text.innerText,
        //             this.a4_width - avatarContainer - margin,
        //             avatarContainer,
        //             align
        //         );
        //         this.nextSidePositionY += 5;
        //         if (text.tagName == "H1") {
        //             this.nextSidePositionY += 3;
        //         }
        //     }
        // },

        // addFooters(totalPage, totalCvPage, margin) {
        //     this.pdf.setFont("helvetica", "normal");
        //     for (let i = 1; i <= totalCvPage; i++) {
        //         this.pdf.setPage(totalPage - totalCvPage + i);
        //         this.pdf.setTextColor(0, 0, 0);
        //         this.pdf.setFillColor(255, 255, 255);
        //         this.pdf.rect(
        //             0,
        //             this.a4_height - margin,
        //             this.a4_width,
        //             this.a4_height,
        //             "F"
        //         );
        //         this.pdf.text(
        //             "Produit par MySkilliz RH",
        //             margin,
        //             this.a4_height - margin / 2,
        //             { align: "left" }
        //         );
        //         this.pdf.text(
        //             `Page ${i}/${totalCvPage}`,
        //             this.a4_width - margin,
        //             this.a4_height - margin / 2,
        //             { align: "right" }
        //         );
        //         this.pdf.setFillColor(0, 0, 0);
        //         this.pdf.line(
        //             margin,
        //             this.a4_height - margin,
        //             this.a4_width - margin,
        //             this.a4_height - margin,
        //             "F"
        //         );
        //     }
        // },

        // formatDate(date, checkDay = false) {
        //     let sameDay = false;
        //     if (checkDay) {
        //         sameDay = isSameDay(new Date(date), Date.now());
        //     }
        //     if (!date || (isAfter(date, Date.now()) && sameDay) || sameDay) {
        //         return false;
        //     }
        //     return format(new Date(date), "dd/MM/yyyy");
        // },
        // employeePhoto(employee) {
        //     if (employee && employee.photoUrl) {
        //         return employee.photoUrl;
        //     } else {
        //         return this.imageUrl("/images/employee-default.jpg");
        //     }
        // },

        // imageUrl(pathname) {
        //     return window.location.origin + pathname
        // },

        initExport() {
            this.exportPDF = true;
            this.$vs.loading({
                background: "primary",
                color: "#FFF",
                container: "#export-pdf-button",
                scale: 0.45
            });
            this.download();
        },

        closeExport() {
            this.exportPDF = false;
            this.$vs.loading.close("#export-pdf-button > .con-vs-loading");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

$grey: #7a7a7a;

button {
    padding: 0px 20px;
    height: 42px;
    border: none;
    color: $white;
    background-color: $primary;
    border-radius: 8px;
    font-size: 16px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: $primary-dark;
    }
}

.modale-container {
    @include scrollbar;
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    transform: scale(0);
    &.active {
        transform: scale(1);
    }
    .modale {
        width: 500px;
        height: 450px;
        background-color: $white;
        border-radius: 25px;
        box-shadow: 4px 3px 32px 6px rgba(0, 0, 0, 0.2);
        .close-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .btn-close {
            width: 25px;
            right: 0;
            margin: 20px;
            cursor: pointer;
        }
        button {
            margin: 20px;
        }
        .loader {
            height: 400px;
            width: 100%;
        }
        .checklist {
            overflow: auto;
            height: 300px;
            margin: 0px 20px;
            padding: 0px;
            width: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            align-items: center;
            li {
                width: 90%;
                list-style: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 40px;
                height: 3.3em;
                cursor: pointer;
                label {
                    color: $gray;
                    display: block;
                    position: relative;
                    padding-left: 1.5em;
                    cursor: pointer;
                    font-size: 1.2em;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    &.active {
                        color: $primary;
                    }
                }

                input[type="checkbox"] {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    border: solid 1px #d6dee3;
                    transform: translateX(-50%);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    cursor: pointer;
                }

                input[type="checkbox"]:checked {
                    border: solid 1px $primary;
                }

                input[type="checkbox"]:after {
                    content: "";
                    position: absolute;
                    background-color: white;
                    transition: all 0.2s ease-in-out;
                    top: 3px;
                    left: 3px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                }

                input[type="checkbox"]:checked:after {
                    background: $primary;
                }
            }
        }
    }
}

.hide-pdf {
    transform: translateX(-500vw);
    background-color: $white;
    z-index: -12;
}

#cv-container {
    background-color: white;
    position: absolute;
    top: 0;
    width: 800px;
    padding: 5px;
    z-index: -1;
    // z-index: 0;
    right: 0;

    p {
        font-size: 14px;
    }

    // header {
    //     display: flex;
    //     align-items: flex-start;

    //     .employee-photo {
    //         width: 110px;
    //         img {
    //             width: 100%;
    //         }
    //     }
    //     .employee-info {
    //         text-align: left;
    //         padding-left: 20px;

    //         h1 {
    //             font-weight: 900;
    //             font-size: 22px;
    //             margin-bottom: 10px;
    //             span {
    //                 text-transform: uppercase;
    //             }
    //         }
    //         p {
    //             font-size: 14px;
    //         }
    //     }
    // }

    // .star {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         background: transparent url(require("@assets/images/icons/starPostes.svg"))
    //             no-repeat;
    //         position: absolute;
    //         top: -20%;
    //         left: -13.6%;
    //         height: 24px;
    //         width: 24px;
    //         z-index: 1;
    //         background-size: contain;
    //     }
    // }

    // .round-purple {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: 0%;
    //         right: -15.5%;
    //         height: 16px;
    //         width: 16px;
    //         background-color: $color-metiers;
    //         border-radius: 15px;
    //         z-index: 1;
    //     }
    // }

    // .round-yellow {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: 0%;
    //         right: -15.5%;
    //         height: 16px;
    //         width: 16px;
    //         background-color: $color-bilan;
    //         border-radius: 15px;
    //         z-index: 1;
    //     }
    // }

    // .round-pink {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: 0%;
    //         right: -15.5%;
    //         height: 16px;
    //         width: 16px;
    //         border-radius: 15px;
    //         background-color: $primary;
    //         z-index: 1;
    //     }
    // }
    // .circle {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: 0%;
    //         left: -12.5%;
    //         height: 16px;
    //         width: 16px;
    //         border: 1px solid $color-etablissement;
    //         border-radius: 15px;
    //         background-color: #ffffff;
    //         z-index: 1;
    //     }
    // }
    // .square {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: -15%;
    //         left: -13.5%;
    //         margin-top: 4px;
    //         margin-left: 6px;
    //         height: 15px;
    //         width: 15px;
    //         background-color: $color-formations;
    //     }
    // }
    // .diamond {
    //     position: relative;
    //     .icon {
    //         content: "";
    //         position: absolute;
    //         top: -15%;
    //         left: -13.7%;
    //         margin-top: 4px;
    //         margin-left: 6px;
    //         height: 15px;
    //         width: 15px;
    //         transform: rotate(45deg);
    //         background-color: $color-immersions;
    //     }
    // }

    // .main {
    //     display: flex;
    //     > * {
    //         line-height: 1.2;
    //     }
    //     h2 {
    //         font-weight: 900;
    //         text-transform: uppercase;
    //         font-size: 16px;
    //         margin-bottom: 20px;
    //     }
    //     h3 {
    //         font-weight: 900;
    //         text-transform: uppercase;
    //         font-size: 12px;
    //         margin-bottom: 5px;
    //     }
    //     h4 {
    //         font-weight: 900;
    //         text-transform: uppercase;
    //         font-size: 12px;
    //         margin-bottom: 5px;
    //     }
    //     p {
    //         color: black;
    //         font-size: 12px;
    //         &.bold {
    //             color: $grey;
    //             font-weight: 900;
    //             text-transform: uppercase;
    //         }
    //     }

    //     .left {
    //         width: 45%;
    //         padding-right: 5%;
    //         padding-top: 50px;
    //     }
    //     .right {
    //         width: 55%;
    //         padding-left: 5%;
    //         border-left: 2px solid #dfdfdf;
    //     }

    //     .step {
    //         border-top: 2px solid black;
    //         border-bottom: 2px solid black;
    //         border-color: currentColor;
    //         padding: 20px 0;
    //         box-sizing: border-box;
    //         &:not(:last-child) {
    //             margin-bottom: 40px;
    //         }
    //         h2,
    //         h3 {
    //             color: currentColor;
    //         }
    //         &.occupations {
    //             color: $color-metiers;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: right;
    //             }
    //         }
    //         &.skills {
    //             color: $primary;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: right;
    //             }

    //             p.bold {
    //                 text-align: left;
    //                 margin-bottom: 10px;
    //             }

    //             .rate-container {
    //                 display: flex;
    //                 > div.rate-circle {
    //                     background-color: $primary;
    //                     width: 15px;
    //                     height: 15px;
    //                     border: 1px solid $primary;
    //                     border-radius: 50%;
    //                 }
    //                 &.empty {
    //                     > div.rate-circle {
    //                         background-color: white;
    //                     }
    //                 }
    //                 > div + div {
    //                     margin-left: 10px;
    //                 }
    //             }
    //             @for $i from 1 through 10 {
    //                 div[data-rate="#{$i}"] {
    //                     div:nth-child(#{$i}) ~ div {
    //                         background-color: white;
    //                     }
    //                 }
    //             }

    //             .cloud-word {
    //                 width: 100%;
    //                 border: 1px solid red;
    //                 ::v-deep > div {
    //                     width: 100%;
    //                     border: 1px solid blue; // TODO:
    //                 }
    //             }
    //         }
    //         &.employee_rating_job_wishlists {
    //             color: $color-etablissement;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: left;
    //             }
    //         }
    //         &.jobs {
    //             color: $color-etablissement;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: left;
    //             }
    //         }
    //         &.immersions {
    //             color: $color-immersions;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: left;
    //             }
    //         }
    //         &.trainings {
    //             color: $color-formations;
    //             h2,
    //             h3,
    //             p {
    //                 text-align: left;
    //             }
    //         }
    //         &.occupation_skills {
    //             color: $color-bilan;
    //             h2,
    //             h3,
    //             h4,
    //             ul,
    //             li,
    //             p {
    //                 text-align: right;
    //             }
    //             h4,
    //             ul {
    //                 color: $grey;
    //             }
    //             p {
    //                 line-height: 1.2;
    //             }
    //             ul {
    //                 li + li {
    //                     margin-top: 6px;
    //                 }
    //             }
    //         }

    //         > div {
    //             margin-top: 25px;
    //         }
    //     }
    // }
}
</style>
