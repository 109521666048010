<template>
    <div class="main-page">
        <Filters :set-employees-list="setEmployeesList" />

        <TimeLine
            v-if="selectedYears"
            :employees-list="employeesList"
            :end-year="endYear"
            :selected-years="selectedYears"
        />

        <YearsSlider
            v-if="selectedYears"
            :all-years="allYears"
            :selected-years="selectedYears"
            :set-selected-year="setSelectedYear"
        />
    </div>
</template>

<script>
/*
    C'est le composant Filters qui charge la liste des personnes.
    Puis à la selection dans le filtre, chaque interation dans le filtre fait des appels ajax pour charger les bilances des personnes concernés

    ## Map des composants :

    - Careers
        - Filters
        - TimeLine
            - UserCareer
                - CareerStep
                    - CareerCard
                - CareerCard
            - Legend
            - ExportModale
                - ExportStep
            - DisplaySwitch
            - Sort
        - YearsSlider
*/

import TimeLine from "./partials/TimeLine/TimeLine.vue";
import YearsSlider from "./partials/YearsSlider.vue";
import Filters from "./partials/Filters.vue";

export default {
    name: "Careers",

    components: {
        YearsSlider,
        TimeLine,
        Filters
    },

    data: function() {
        return {
            allYears: null,
            selectedYears: null,
            employeesList: [],
            endYear: null
        };
    },

    computed: {
        //...mapState(["employees"])
    },

    watch: {
        employeesList: function() {
            this.setAllYears();
        }
    },

    methods: {

        setEmployeesList: function(array) {
            this.employeesList = array.sort((a, b) =>
                a.employee.last_name > b.employee.last_name ? 1 : -1
            );
        },

        setSelectedYear: function(array) {
            this.selectedYears = array;
        },

        setAllYears: function() {
            var year = new Date().getFullYear();
            var endYear = new Date().getFullYear() - 1;
            this.employeesList.forEach(employee => {
                employee.career.forEach(step => {
                    if (
                        step.start &&
                        new Date(step.start).getFullYear() < year
                    ) {
                        year = new Date(step.start).getFullYear();
                    }
                    if (step.date && new Date(step.date).getFullYear() < year) {
                        year = new Date(step.date).getFullYear();
                    }
                    if (
                        step.end &&
                        new Date(step.end).getFullYear() > endYear
                    ) {
                        endYear = new Date(step.end).getFullYear() + 1;
                    }
                    if (
                        step.date &&
                        new Date(step.date).getFullYear() > endYear
                    ) {
                        endYear = new Date(step.date).getFullYear() + 1;
                    }
                });
            });
            var allYears = [];
            this.endYear = endYear;

            var selectedYears = [];
            selectedYears.push(year);
            selectedYears.push(endYear);
            this.setSelectedYear(selectedYears);

            while (year < endYear + 1) {
                allYears.push(year);
                year++;
            }
            return (this.allYears = allYears);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.main-page {
    max-width: 100%;
    height: 800px;
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: solid white;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $dark;
}
</style>
