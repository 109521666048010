import Vue from "vue";

Vue.mixin({
    data() {
        return {
            servicesImage: [
                [
                    {
                        id: 1,
                        text: "Personnes",
                        exists: null,
                        custom: { "background-image": null },
                        default: {
                            "background-image":
                                "url(/assets/images/cards/rh/1.jpeg)"
                        },
                        date: +new Date()
                    },
                    {
                        id: 2,
                        text: "Métiers & Postes",
                        exists: null,
                        custom: { "background-image": null },
                        default: {
                            "background-image":
                                "url(/assets/images/cards/rh/2.jpeg)"
                        }
                    },
                    {
                        id: 3,
                        text: "Compétences",
                        exists: null,
                        custom: { "background-image": null },
                        default: {
                            "background-image":
                                "url(/assets/images/cards/rh/3.jpeg)"
                        }
                    },
                    {
                        id: 4,
                        text: "Myskilliz Planning",
                        exists: null,
                        custom: { "background-image": null },
                        default: {
                            "background-image":
                                "url(/assets/images/cards/rh/4.jpeg)"
                        }
                    }
                ],
                [
                    {
                        id: 5,
                        text: "Myskilliz RH",
                        exists: null,
                        custom: { "background-image": null },
                        default: {
                            "background-image":
                                "url(/assets/images/cards/planning/1.jpeg)"
                        },
                        date: +new Date()
                    }
                ]
            ]
        };
    },

    methods: {
        getImageFromUrl(url) {
            let img = new Image();

            return new Promise((resolve, reject) => {
                img.onError = function() {
                    console.log('Cannot load image: "' + url + '"');
                    reject();
                };

                img.onload = function() {
                    resolve(img);
                };

                img.src = url;
            });
        },

        getServicesImage(customs) {

            for (let i = 0; i <= 3; i++) {
                let exist = customs[i];

                this.servicesImage[0][i].exists = exist;

                if (exist) {
                    this.servicesImage[0][i].custom["background-image"] = "url(/storage/" + customs[4] + "/images/rh/" + (i + 1) + ".jpg?rnd=" + (+new Date()) + ")";
                }
            }

            return this.servicesImage;
        }
    }
});
