import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import "./assets";

// Vuesax Component Framework
import Vuesax from "vuesax";

// Theme Configurations
import "./themeConfig.js";

// Globally Registered Components
import "./components.js";

// Mixin
import "./mixins.js";

// Vuesax Admin Filters
import "./filters/filters";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import UUID from "vue-uuid";
Vue.use(UUID);

// PrismJS
import "prismjs";
import Swal from 'sweetalert2';
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueFuse from "vue-fuse";
window.Vue = Vue;
window.axios = axios.create();

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error("CSRF token not found");
}

// Rechargement de la page si error 401
window.axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        Swal.fire({
            title: `Votre session a expiré.`,
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Recharger la page',
            showCancelButton: false,
        }).then(async result => {
            location.reload();
        });
    }

    return Promise.reject(error);
}
);

Vue.use(Vuesax);
Vue.use(VueHammer);
// import 'prismjs/themes/prism-tomorrow.css'

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);
Vue.config.productionTip = false;
Vue.config.devtools = true;

const options = {
    confirmButtonColor: "#41b882",
    cancelButtonColor: "#ff7674",
    target: "#app"
};

Vue.use(VueSweetalert2, options);

Vue.use(VueFuse);
import VueMoment from "vue-moment";

const moment = require("moment");
require("moment/locale/fr");
Vue.use(VueMoment, {
    moment
});

Object.defineProperty(Vue.prototype, "$_", { value: _ });

import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
import VueProgressBar from "vue-progressbar";

Vue.use(VueProgressBar, {
    color: "rgb(151, 47, 95)",
    failedColor: "red",
    height: "3px",
    transition: { speed: "0.5s", opacity: "0.6s", termination: 300 }
});

const host = window.location.host;
const parts = host.split(".");

new Vue({
    el: "#app"
});


// Garder en mémoire la position du menu
var sidebar = document.querySelector('.scroll-area--main-sidebar');

if (sidebar) {

    sidebar.addEventListener('scroll', function(e) {
        var position = e.target.scrollTop;

        localStorage.setItem('menu-position', position);
    })

    setTimeout(() => {
        // TODO - ajouter du smooth-scroll
        sidebar.scrollTop = localStorage.getItem('menu-position');
    }, 500)
}
