export default {
    computed: {
        establishmentsWithServices() {
            let establishments = [];

            const employee = this.resource ? this.resource : this.employee

            // Préparation des établissements depuis les services
            employee.services.forEach(item => {

                if (!establishments.find(e => e.id == item.establishment_id)) {
                    establishments.push({
                        id: item.establishment.id,
                        name: item.establishment.name,
                        start_date: null,
                        services: []
                    });
                }
            })

            employee.establishments.forEach(item => {
                const est = establishments.find(e => e.id == item.id);

                if (est) {
                    est.start_date = item.pivot.start_date;
                    est.services = this.employee.services.filter(s => s.establishment_id == est.id)
                }
            })

            return establishments;
        }
    }
}
