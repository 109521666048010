var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.planAllow("edit-cv-pro")
        ? _c(
            "vs-button",
            {
              class: [
                "ml-2",
                "vs-con-loading__container",
                { exportPDF: "vs-con-loading__container" }
              ],
              attrs: {
                id: "export-pdf-button",
                color: "primary",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-file-text"
              },
              on: { click: _vm.initExport }
            },
            [_vm._v("\n        Export CV\n    ")]
          )
        : _c(
            "div",
            {
              staticStyle: { position: "relative", float: "left" },
              attrs: {
                title: "Votre formule n'inclut pas cette fonctionnalité"
              }
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-2 includeIcon",
                  attrs: {
                    id: "export-pdf-button",
                    color: "primary",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-file-text",
                    disabled: "true"
                  }
                },
                [_vm._v("\n            Export CV\n        ")]
              ),
              _vm._v(" "),
              _c(
                "vs-icon",
                {
                  staticClass: "button-bullet",
                  attrs: { bg: "#FF9B3A", round: "" }
                },
                [
                  _c("CrownIcon", {
                    staticStyle: {
                      width: "16px",
                      height: "16px",
                      padding: "3px"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("div", { staticClass: "hide-pdf", attrs: { id: "cv-container" } }, [
        _c(
          "div",
          { ref: "cv" },
          [
            _vm.employee
              ? _c("CV", {
                  key: 0,
                  attrs: { employee: _vm.employee },
                  on: {
                    loaded: function($event) {
                      return _vm.download()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }