var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.can("read", "ratings")
        ? [
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [_c("RatingToPerform", { attrs: { api: _vm.urlToperform } })],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlInprogress,
                    "type-name": _vm.projectNames.singular.toLowerCase(),
                    title: _vm.projectNames.plural + " en cours",
                    filter: "skill_review|=|0",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "update",
                            "ratings",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/poursuivre/" +
                            item.id
                          )
                        },
                        tooltip: "Poursuivre",
                        icon: "icon-edit"
                      }
                    ]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlTovalidate,
                    "type-name": _vm.projectNames.singular.toLowerCase(),
                    title:
                      _vm.projectNames.plural + "  en attente de validation",
                    filter: "skill_review|=|0",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "update",
                            "rating_validations",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/valider/" +
                            item.id
                          )
                        },
                        tooltip:
                          "Valider le " +
                          _vm.projectNames.singular.toLowerCase(),
                        icon: "icon-check-square"
                      },
                      {
                        permission: function(item) {
                          return _vm.can("read", "ratings")
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/consulter/" +
                            item.id
                          )
                        },
                        tooltip: "Consulter",
                        icon: "icon-eye"
                      }
                    ]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlValidated,
                    "type-name": _vm.projectNames.singular.toLowerCase(),
                    title: _vm.projectNames.plural + " validés",
                    filter: "skill_review|=|0",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "read",
                            "ratings",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/consulter/" +
                            item.id
                          )
                        },
                        tooltip: "Consulter",
                        icon: "icon-eye"
                      }
                    ]
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.can("read", "skill_reviews")
        ? [
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlInprogress,
                    "type-name": _vm.skillRatingNames.singular.toLowerCase(),
                    title: _vm.skillRatingNames.plural + " en cours",
                    filter: "skill_review|=|1",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "update",
                            "skill_reviews",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/poursuivre/" +
                            item.id +
                            "?skill-review=true"
                          )
                        },
                        tooltip: "Poursuivre",
                        icon: "icon-edit"
                      }
                    ]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlTovalidate,
                    "type-name": _vm.skillRatingNames.singular.toLowerCase(),
                    title:
                      _vm.skillRatingNames.plural + " en attente de validation",
                    filter: "skill_review|=|1",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "update",
                            "skill_review_validations",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/valider/" +
                            item.id +
                            "?skill-review=true"
                          )
                        },
                        tooltip:
                          "Valider le " +
                          _vm.skillRatingNames.singular.toLowerCase(),
                        icon: "icon-check-square"
                      },
                      {
                        permission: function(item) {
                          return _vm.can("read", "skill_reviews")
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/consulter/" +
                            item.id +
                            "?skill-review=true"
                          )
                        },
                        tooltip: "Consulter",
                        icon: "icon-eye"
                      }
                    ]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vx-card",
              { staticClass: "mt-10" },
              [
                _c("RatingList", {
                  attrs: {
                    api: _vm.urlValidated,
                    "type-name": _vm.skillRatingNames.singular.toLowerCase(),
                    title: _vm.skillRatingNames.plural + " validés",
                    filter: "skill_review|=|1",
                    action: [
                      {
                        permission: function(item) {
                          return _vm.canMulti(
                            "read",
                            "skill_reviews",
                            item.employee.services.map(function(s) {
                              return s.id
                            })
                          )
                        },
                        href: function(item) {
                          return (
                            "/rh/gestion/personnes/" +
                            item.employee.id +
                            "/bilans/consulter/" +
                            item.id +
                            "?skill-review=true"
                          )
                        },
                        tooltip: "Consulter",
                        icon: "icon-eye"
                      }
                    ]
                  }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }