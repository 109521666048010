<template>
    <div id="resourceForm" class="con-exemple-prompt">
        <vs-row>
            <vs-col vs-w="12">

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date du document* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.document_date"
                            type="date"
                            name="document_date"
                            class="w-full"
                            required
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Thématique* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <ThematicField
                            v-model="resource.thematic"
                            :availableThematics="thematics.data"
                            :loading="thematics.loading"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Titre* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.title"
                            name="title"
                            class="w-full"
                            required
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Émetteur* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.emitter"
                            name="emitter"
                            class="w-full"
                            required
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Durée en min. :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.duration"
                            type="number"
                            name="duration"
                            class="w-full"
                            required
                        />
                    </div>
                </div>

                <vs-divider position="left" class="mt-10 font-bold">
                    <p class="bold">Support :</p>
                </vs-divider>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Fichier (.pdf, .mp4) :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            type="file"
                            name="file"
                            class="w-full"
                            @change="changeFileHandle"
                        />
                        <b>
                            <a href="#" @click="downloadFileClickHandle">
                                {{ resource.original_filename }}
                            </a>
                        </b>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Lien :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.link"
                            name="link"
                            class="w-full"
                            required
                            placeholder="https://..."
                        />
                    </div>
                </div>


                <vs-divider position="left" class="mt-10 font-bold">
                    <p class="bold">Visibilité :</p>
                </vs-divider>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Services :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Multiselect
                            v-model="resource.services"
                            :options="groupedServicesByEstablishments"
                            name="services"
                            label="title"
                            track-by="id"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                            :multiple="true"
                            :group-select="true"
                            group-values="items"
                            group-label="group"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Ajout de personnes :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Multiselect
                            v-model="resource.employees"
                            :options="groupedEmployeesByEstablishmentsAndFilteredByServices"
                            :multiple="true"
                            group-values="employees"
                            group-label="establishmentService"
                            :group-select="true"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="name"
                            select-label
                            select-group-label
                            selected-label
                            deselect-label
                            deselect-group-label
                            :loading="employees.loading"
                        />
                    </div>
                </div>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ThematicField from './ThematicField.vue'

export default {
    name: 'TrainingForm',
    components: {
        Multiselect,
        ThematicField
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        }
    },
    data () {
        return {
            log: console.log,
            resource: {},
            establishments: {
                data: [],
                loading: false
            },
            employees: {
                data: [],
                loading: false
            },
            thematics: {
                data: [],
                loading: false
            }
        }
    },
    watch: {
        // value (newValue, oldValue) {
        //     if (newValue === oldValue) return
        //     this.initResource()
        // },
        'resource': {
            handler () {
                this.$emit('input', this.resource)
            }, deep: true
        },
        hasError (value) {
            this.$emit('error', value)
        }
    },
    computed: {

        hasError() {
            let error = !this.resource.title || !this.resource.emitter || !this.resource.document_date || !this.resource.thematic
            return error;
        },

        isNotActivated() {
            return (
                this.resource.sector ?
                this.resource.sector.occupation_skills.length > 0 : true
            )
        },

        groupedServicesByEstablishments() {
            return this.groupForMultiselect(this.services.sort((a, b) => a.title < b.title ? -1 : 1), ['establishment.name'])
        },

        // groupedEmployeesByEstablishments() {
        //     let toShow = [];
        //     const establishments = _.cloneDeep(this.establishments.data);
        //     establishments.forEach(e => {
        //         toShow.push({
        //             establishment: e.name,
        //             employees: this.employees.data ? this.employees.data.filter(employee =>
        //                     employee.services.length &&
        //                     employee.services?.map(s => s.establishment_id).includes(e.id)
        //                 )
        //                 .map(employee => {
        //                     return {
        //                         ...employee,
        //                         name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
        //                     };
        //                 })
        //                 : []
        //         });
        //     });

        //     return toShow;
        // },

        groupedEmployeesByEstablishmentsAndFilteredByServices() {
            let toShow = [];

            const establishments = this.filterEstablishmentFormViewableService(this.establishments.data);

            establishments.forEach(establishment => {

                establishment.services.forEach(service => {

                    // Nom du groupe (etablissement / service)
                    const groupName = `${establishment.name} / ${service.title}`;

                    const filteredEmployees = this.employees.data.filter(e =>
                        e.services.length &&
                        e.services?.map(s => s.establishment_id).includes(establishment.id) &&
                        e.services.map(s => s.id).includes(service.id)
                    );

                    toShow.push({
                        establishmentService: groupName,
                        employees: filteredEmployees.map(employee => {
                            return {
                                ...employee,
                                name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
                            };
                        })
                    });
                })
            });

            return toShow;
        },
    },
    methods: {
        initResource() {
            this.resource = _.cloneDeep(this.value);
        },

        async loadExistingThematics() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/trainings/thematics",
                    params: {}
                })
                .then(response => {
                    this.thematics.data = response.data;
                    this.thematics.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ['services.establishment'],
                    }
                })
                .then(response => {

                    const employees = response.data.data.sort((a, b) => a.fullname < b.fullname ? -1 : 1);
                    this.employees.data = employees
                    this.employees.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        changeFileHandle(event) {
            this.resource.file = event.target.files[0];
        },

        downloadFileClickHandle() {
            window.axios({
                method: "get",
                url: `/api/referentiel/settings/trainings/download/${this.resource.filename}`,
                responseType: 'blob',
            }).then((response) => {
                let blob = new Blob([response.data], { type: response.headers['content-type'] } );
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = this.resource.original_filename;
                link.dispatchEvent(new MouseEvent('click'));
            })
        }

    },
    created() {
        this.initResource();
        this.loadExistingThematics(),
        this.loadEstablishment();
        this.loadEmployees()

        this.groupForMultiselect(this.services, ['establishment.name']);
    }
}
</script>

<style lang="scss" scoped>

</style>
