import { render, staticRenderFns } from "./Trainings.vue?vue&type=template&id=4011e992&"
import script from "./Trainings.vue?vue&type=script&lang=js&"
export * from "./Trainings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/myskilliz_new/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4011e992')) {
      api.createRecord('4011e992', component.options)
    } else {
      api.reload('4011e992', component.options)
    }
    module.hot.accept("./Trainings.vue?vue&type=template&id=4011e992&", function () {
      api.rerender('4011e992', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/views/rh/referentiel/parameters/Training/Trainings.vue"
export default component.exports