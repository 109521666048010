<template>
    <div>
        <!-- <EmployeeForm
            v-model="resource"
            formType="update"
            @error="setFormError"
        />
        <vs-row class="mb-6">
            <vs-col class="w-full">
                <vs-button
                    v-if="canUpdate()"
                    id="btn-save"
                    color="primary"
                    type="filled"
                    @click="update"
                    :disabled="hasError"
                >
                    Enregistrer
                </vs-button>
            </vs-col>
        </vs-row> -->

        <!-- Informations -->
        <vs-row class="mb-5">
            <vs-col class="w-full">
                <EmployeeInfos :employee="resource" :endReasons="endReasons" />
            </vs-col>
        </vs-row>

        <vs-row class="mb-10">
            <vs-col class="w-full">
                <EmployeeBadges :employee="resource" />
            </vs-col>
        </vs-row>

        <!-- Postes  -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeJobsStages
                    :api="`/api/gestion/employees/${resource.id}/jobs`"
                    :employee="resource"
                    :documentLicence="documentLicence"
                    :receptionTypes="receptionTypes"
                    @change="updateEmployee"
                    v-on:invite-tiers="createEvaluatorResource(userType, 'experience')"
                />
            </vs-col>
        </vs-row>

        <!-- Métiers -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeOccupations
                    :api="`/api/gestion/employees/${resource.id}/occupations`"
                    :employee="resource"
                    :documentLicence="documentLicence"
                    @change="updateEmployee"
                    v-on:invite-tiers="createEvaluatorResource(userType, 'occupation')"
                />
            </vs-col>
        </vs-row>

        <!-- RAE/VAE -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeRAE
                    :api="`/api/gestion/employees/${resource.id}/experiences/rae`"
                    @change="updateEmployee"
                    :employee="resource"
                    :documentLicence="documentLicence"
                />
            </vs-col>
        </vs-row>

        <!-- Formations -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeTrainings
                    :api="`/api/gestion/employees/${resource.id}/experiences/trainings`"
                    @change="updateEmployee"
                    :employee="resource"
                    :financingTypes="financingTypes"
                    :documentLicence="documentLicence"
                />
            </vs-col>
        </vs-row>

        <!-- Habilitation / Certificat -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeHabilitation
                    :api="`/api/gestion/employees/${resource.id}/experiences/certificates`"
                    @change="updateEmployee"
                    :employee="resource"
                    :documentLicence="documentLicence"
                />
            </vs-col>
        </vs-row>

        <!-- Suivi d'accompagnement -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeSuivi
                    :api="`/api/gestion/employees/${resource.id}/experiences/follows`"
                    @change="updateEmployee"
                    :employee="resource"
                    :documentLicence="documentLicence"
                />
            </vs-col>
        </vs-row>

        <!-- Evaluateurs -->
        <vs-row class="mb-20">
            <vs-col class="w-full">
                <EmployeeEvaluators
                    :employee="resource"
                    @change="updateEmployee"
                    v-on:update-tiers="updateEvaluatorResource($event)"
                    v-on:delete-tiers="deleteEvaluatorResource($event)"
                />
            </vs-col>
        </vs-row>


        <vs-prompt
            :active.sync="showPopup"
            :title="getDataPrompt.title"
            cancel-text="Fermer"
            accept-text="Enregistrer"
            :button-accept="getDataPrompt.btnAccept"
            :is-valid="!formError"
            @cancel="resetEvaluatorResource"
            @accept="storeEvaluatorResource"
            @close="resetEvaluatorResource"
        >
            <UserForm
                v-model="evaluatorResource"
                :type="formType"
                :user-type="userType"
                :users-list="true"
                :reduced="reduced"
                :reduced-for="resource.id"
                :reduced-type="reducedType"
                :form-validation-errors="formValidationErrors"
                @error="setEvaluatorFormError"
            />
        </vs-prompt>

    </div>
</template>

<script>
//import EmployeeForm from "@components/views/rh/gestion/employee/EmployeeForm";
import EmployeeInfos from "@components/views/rh/gestion/employee/career/EmployeeInfos";
import EmployeeBadges from "@components/views/rh/gestion/employee/career/EmployeeBadges";
import EmployeeTrainings from "@components/views/rh/gestion/employee/career/EmployeeTrainings";
import EmployeeInternShips from "@components/views/rh/gestion/employee/career/EmployeeInternShips";
import EmployeeJobsStages from "@components/views/rh/gestion/employee/career/EmployeeJobsStages";
import EmployeeOccupations from "@components/views/rh/gestion/employee/career/EmployeeOccupations";
import EmployeeRAE from "@components/views/rh/gestion/employee/career/EmployeeRAE";
import EmployeeSuivi from "@components/views/rh/gestion/employee/career/EmployeeSuivi";
import EmployeeHabilitation from "@components/views/rh/gestion/employee/career/EmployeeHabilitation";
import EmployeeEvaluators from "@components/views/rh/gestion/employee/career/EmployeeEvaluators";
import UserForm from "@components/views/rh/referentiel/user/UserForm";

import objectToFormData from "object-to-formdata"

export default {
    name: "EmployeeCareer",
    components: {
        //EmployeeForm,
        EmployeeInfos,
        EmployeeBadges,
        EmployeeTrainings,
        EmployeeInternShips,
        EmployeeJobsStages,
        EmployeeOccupations,
        EmployeeRAE,
        EmployeeSuivi,
        UserForm,
        EmployeeEvaluators,
        EmployeeHabilitation
    },
    props: {
        api: {
            type: String
        },
        employee: {
            type: Object,
            required: true
        },
        apiEvaluator: {
            type: String
        },

        receptionTypes: {
            type: Array,
            required: true
        },
        financingTypes: {
            type: Array,
            required: true
        },
        endReasons: {
            required: true,
            type: Array
        }
    },
    data() {

        return {
            resource: {},
            evaluatorResource: {},
            hasError: true,
            userType: "Evaluateur",
            showPopup: false,
            formError: true,
            formValidationErrors: {},
            formType: "show",
            prompt: {
                show: {
                    title: "Consulter un ",
                    btnAccept: "false"
                },
                create: {
                    title: "Ajouter un ",
                    btnAccept: "filled"
                },
                update: {
                    title: "Modifier un ",
                    btnAccept: "filled"
                }
            },
            reduced: true,
            reducedType: '',
            documentLicence: false,
        };
    },
    computed: {
        getDataPrompt() {
            switch (this.formType) {
                case "show":
                    this.prompt.show.title = "Consulter un " + this.resourceType;
                    return this.prompt.show;
                case "create":
                    this.prompt.create.title = "Ajouter un " + this.resourceType;
                    return this.prompt.create;
                case "update":
                    this.prompt.update.title = "Modifier un " + this.resourceType;
                    return this.prompt.update;
                default:
                    return this.prompt.show;
            }
        },
    },
    methods: {
        updateEmployee() {
            window.axios({
                method: 'get',
                url: `${this.api}/${this.employee.id}`,
            })
            .then(response => {
                this.resource = response.data;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },
        setFormError(value) {
            this.hasError = value;
        },
        setEvaluatorFormError(value) {
            this.formError = value;
        },
        canUpdate() {
            return this.canMulti("update", "employees", this.employee.active_services.map(s => s.id));
        },
        update() {
            let data = this.resource;
            data.occupations = data.occupations.length
                ? data.occupations.map(el => el.id)
                : null;
            data.jobs = data.jobs.length ? data.jobs.map(el => el.id) : null;
            data.services    = data.services ? data.services.map(el => el.id) : null;

            window.axios({
                method: 'post',
                url: `${this.api}/${data.id}?_method=PUT`,
                data: data.photo ? objectToFormData(data) : data,
                headers: data.photo ? {'Content-Type': 'multipart/form-data'} : undefined
            })
            .then(response => {

                if (response.data != '') {
                    this.notifySuccess('Succès', 'Élement modifié avec succès !')
                } else {
                    this.notifyError('Erreur', `Une erreur est survenue lors de la modification`)
                }
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },
        exportAsPDF() {},

        createEvaluatorResource(userType, source) {
            this.resourceReadonly = false;
            this.resourceType = userType;
            this.resetEvaluatorResource();
            this.formType = "create";
            this.reduced = true;
            this.reducedType = source
            this.showPopup = true;
        },
        updateEvaluatorResource(event) {
            this.resourceReadonly = false;
            this.resourceType = 'Evaluateur';
            this.resetEvaluatorResource();
            this.setEvaluatorResource(event);
            this.reduced = false;
            this.formType = "update";
            this.showPopup = true;
        },
        resetEvaluatorResource() {
            this.evaluatorResource = {
                last_name: null,
                first_name: null,
                email: null,
                roles: [],
                establishments: [],
                services: [],
                sendEmail: false
            };
        },
        setEvaluatorResource(data) {
            this.evaluatorResource = JSON.parse(JSON.stringify(data));
        },
        storeEvaluatorResource() {
            let data = JSON.parse(JSON.stringify(this.evaluatorResource));

            data.role = data.roles[0].id;

            data.establishments = data.establishments.map(e => e.id);
            data.services = data.services.map(s => s.id);

            if (data.evaluatorScope) {
                data.evaluatorScope = data.evaluatorScope.employees;
                data.reduced = this.reduced
            }

            if (data.id) {
                data.invitationToEvaluate = true
                window.axios({
                    method: 'patch',
                    url: `${this.apiEvaluator}/invite-to-evaluate/${data.id}`,
                    data: data
                }).then(response => {
                    this.notifySuccess('Succès', 'Evaluateur invité par email !')
                    setTimeout(() => {
                        location.reload()
                    }, 1500)
                }).catch((err) => {
                    console.log(err)
                    if(err.response.status === 422) {
                        this.formValidationErrors = err.response.data.errors
                        this.notifyErrorValidation(err)
                    }
                    this.showPopup = true
                })
            } else {
                window.axios({
                    method: 'post',
                    url: `${this.apiEvaluator}/invite-to-evaluate`,
                    data: data
                }).then(response => {
                    this.notifySuccess('Succès', 'Evaluateur invité par email !')
                    setTimeout(() => {
                        location.reload()
                    }, 1500)
                }).catch((err) => {
                    console.log(err)
                    if(err.response.status === 422) {
                        this.formValidationErrors = err.response.data.errors
                        this.notifyErrorValidation(err)
                    }
                    this.showPopup = true
                })
            }
        },
        deleteEvaluatorResource(event) {
            window.axios({
                method: 'put',
                url: this.apiEvaluator+'/detachEmployee/'+event.evaluator.id+'/'+event.employee.id,
            }).then(response => {
                this.notifySuccess('Succès', event.evaluator.first_name+' '+event.evaluator.last_name+' ne peut plus évaluer cette personne !')
            }).catch((err) => {
                console.log(err)
                this.notifyErrorValidation(err)
            })
        },
        initResource() {
            this.resource = window._.cloneDeep(this.employee);
            this.resource.services = this.resource.active_services
        }
    },

    created() {
        this.resource = JSON.parse(JSON.stringify(this.employee));
        this.resetEvaluatorResource()
        let uniqueEts = [...new Set(this.employee.active_services.map(s => s.establishment))]
        uniqueEts.some(et => {
            if (et.establishment_licence.types.filter(t => t.slug.indexOf('document') !== -1 && t.pivot.active === 1).length) {
                this.documentLicence = true
                return true
            }
        })
        this.initResource();
        this.resetEvaluatorResource();
    }
};
</script>

<style lang="scss" scoped>
#btn-save {
    margin-right: 1rem;
    margin-left: auto;
    display: flex;
}
</style>
