<template>
    <div>
        <vx-card class="mb-4 mt-3">
            <LimitatedPlan v-if="!planAllow('suivi-objectifs')" class="mb-10"/>

            <vs-row>
                <vs-col>
                
                    <h4>Suivi des objectifs</h4>
                    <p>Permet de suivre les objectifs et les échéances fixés lors de l'évaluation. L'état de l’objectif peut être modifié et sera repris dans la prochaine évaluation.</p>
                </vs-col>
            </vs-row>


            <vs-row class="mt-4">
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Services</label>
                    <Multiselect
                        v-model="filters.services"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :loading="establishments.loading"
                        :disabled="!planAllow('suivi-objectifs')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Personnes</label>
                    <Multiselect
                        v-model="filters.employees"
                        :options="groupedEmployeesByEstablishmentsAndFilteredByServices"
                        :multiple="true"
                        group-values="employees"
                        group-label="establishmentService"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :disabled="!planAllow('suivi-objectifs')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Thèmes</label>
                    <Multiselect
                        v-model="filters.themes"
                        :options="formatedThemes"
                        :multiple="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :loading="themes.loading"
                        :disabled="!planAllow('suivi-objectifs')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Action -->
        <vs-row class="mt-4">
            <vs-col vs-type="flex" vs-justify="flex-end">
                <vs-button
                    id="search-button"
                    v-if="planAllow('suivi-objectifs')"
                    color="primary"
                    :disabled="canSearch ? false : true"
                    @click="searchClickHandle"
                >
                    Lancer la recherche
                </vs-button>
                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        id="search-button"
                        color="primary"
                        disabled="true"
                    >
                    Lancer la recherche
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>
            </vs-col>
        </vs-row>

        <!-- Résultat -->
        <vs-row class="mt-4">
            <vs-col vs-type="flex" vs-justify="center">
                <vs-table
                    :sst="true"
                    @search="searchStringHandle"
                    search
                    class="goals-results"
                    :data="items.data"
                    :no-data-text="`Aucune donnée à afficher`"
                    style="width: 100%"
                >
                    <template slot="thead">
                        <vs-th>
                            <Sort name="rating_date" @sort="sortHandle">Date de l'évaluation</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="due_at" @sort="sortHandle">Date de l'échéance</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="theme" @sort="sortHandle">Thème</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="label" @sort="sortHandle">Objectif</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="last_name" @sort="sortHandle">Nom</Sort>
                        </vs-th>
                        
                        <vs-th>
                            <Sort name="establishments" @sort="sortHandle">Établissements</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="services" @sort="sortHandle">Services</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="state" @sort="sortHandle">État</Sort>
                        </vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr v-for="(goal, gKey) in data" :key="gKey">
                            <td>
                                {{ formatDate(goal.rating_date, '/') }}
                            </td>
                            <td>
                                {{ formatDate(goal.due_at, '/') }}
                            </td>
                            <td>
                                {{ goal.theme }}
                            </td>
                            <td>
                                <vs-chip color="primary">
                                    <span style="text-wrap: nowrap">{{ goal.label }}</span>
                                </vs-chip>
                            </td>
                            <td>
                                {{ goal.last_name }} {{ goal.first_name }}
                            </td>
                            <td>
                                {{ goal.establishments }}
                            </td>
                            <td>
                                {{ goal.services }}
                            </td>
                            <td>
                                <vs-select v-model="goal.state" @input="stateChangeHandle(goal, $event)">
                                    <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in states.data" />
                                </vs-select>
                            </td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-col>
        </vs-row>
    </div>
</template>


<script>
import Multiselect from "vue-multiselect";
import { formatDate } from "@assets/utils/helpers/helpers.js";
import { sortBy } from "lodash";
import LimitatedPlan from '@components/divers/LimitatedPlan';
import CrownIcon from '@components/svg/CrownIcon';

export default {
    name: "Goals",
    components: {
        Multiselect, CrownIcon, LimitatedPlan
    },
    props: {},
    data() {
        return {
            employees: {
                data: [],
                loading: false
            },
            establishments: {
                data: [],
                loading: false
            },
            themes: {
                data: [],
                loading: false
            },
            states: {
                data: [],
                loading: false
            },
            filters: {
                services: [],
                employees: [],
                themes: []
            },
            items: {
                data: [],
                loading: false,
                params: {
                    search: null,
                    sortBy: {}
                }
            }
        }
    },
    created() {
        const start_at = Date.now();

        if(this.planAllow('suivi-objectifs')) {
            Promise.all([
                this.loadEmployees(),
                this.loadEstablishments(),
                this.loadThemes(),
                this.loadStates()
            ]).then(() => {
                console.log(`Chargement des listes +${Date.now() - start_at}ms`)
            })
        }
        
    },
    computed: {
        canSearch() {
            return this.filters.services || this.filters.employees;
        },
        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments = this.filterEstablishmentFormViewableService(this.establishments.data);

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services ? this.services.filter(service =>
                                    service.establishment_id === e.id &&
                                    this.listOfServicesViewableFor("employees").includes(service.id))
                                : []
                });
            });
            return toShow;
        },
        groupedEmployeesByEstablishments() {
            let toShow = [];
            let establishments = this.establishments.data;

            if (this.filters.services.length > 0) {

            }

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    employees: this.employees.data ? this.employees.data.filter(employee =>
                            employee.services.length &&
                            employee.services?.map(s => s.establishment_id)
                            .includes(e.id) && this.listOfServicesViewableFor("employees")
                        )
                        .map(employee => {
                            return {
                                ...employee,
                                name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
                            };
                        })
                        : []
                });
            });

            return toShow;
        },
        formatedThemes() {
            return this.themes.data.map(t => {
                return { id: t, name: t }
            })
        },

        groupedEmployeesByEstablishmentsAndFilteredByServices() {
            let toShow = [];

            const establishments = this.filterEstablishmentFormViewableService(this.establishments.data);

            establishments.forEach(establishment => {

                establishment.services.forEach(service => {

                    // Nom du groupe (etablissement / service)
                    const groupName = `${establishment.name} / ${service.title}`;

                    const filteredEmployees = this.employees.data.filter(e =>
                        e.services.length &&
                        e.services?.map(s => s.establishment_id).includes(establishment.id) &&
                        e.services.map(s => s.id).includes(service.id)
                    );

                    toShow.push({
                        establishmentService: groupName,
                        employees: filteredEmployees.map(employee => {
                            return {
                                ...employee,
                                name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
                            };
                        })
                    });
                })
            });

            if (this.filters.services.length > 0) {
                const establishmentServiceNames = this.filters.services.map(s => `${s.establishment.name} / ${s.title}`);

                // Personne des services filtrés, en 1er
                toShow = toShow.sort((a, b) => establishmentServiceNames.includes(a.establishmentService) ? -1 : 1);
            }

            return toShow;
        },
    },
    methods: {
        async loadEstablishments() {
            this.establishments.loading = true;

            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            this.employees.loading = true;
            
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, include: ['services.establishment', 'currentJobs', 'currentOccupations'] }
                })
                .then(response => {
                    this.employees.data = response.data.data;
                    this.employees.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadThemes() {
            this.themes.loading = true;

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/goals/themes`,
                    params: {}
                })
                .then(response => {
                    this.themes.data = response.data;
                    this.themes.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadStates() {
            this.states.loading = true;

            return window
                .axios({
                    method: "get",
                    url: `/api/referentiel/rating-states`,
                    params: {}
                })
                .then(response => {
                    this.states.data = response.data;
                    this.states.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getEmployeeById(id) {
            return this.employees.data.find(e => e.id == id);
        },
        
        searchClickHandle() {
            this.search = null;
            this.sortBy = null;
            this.loadItems();
        },

        searchStringHandle(search) {
            this.items.params.search = search;
            this.loadItems();
        },

        sortHandle(sort, direction) {
            this.items.params.sortBy[sort] = direction;
            this.loadItems();
        },

        loadItems() {
            this.items.loading = true;

            window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/goals`,
                    params: {
                        service_ids: this.filters.services.map(s => s.id).join('|'),
                        employee_ids: this.filters.employees.map(e => e.id).join('|'),
                        themes: this.filters.themes.map(t => t.id).join('|'),
                        search: this.items.params.search,
                        sortBy: this.items.params.sortBy
                    }
                })
                .then(response => {
                    this.items.data = response.data.data;
                    this.items.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        stateChangeHandle(goal, stateValue) {

            window
                .axios({
                    method: "patch",
                    url: `/api/gestion/employees/-1/ratings/goals/state/${stateValue}`,
                    data: {...goal}
                })
                .then(response => {
                    this.notifySuccess('Succès', 'État mis à jour avec succès !')
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        }

    }
}
</script>