var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt mb-6 scrollBug" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c(
                  "multiselect",
                  {
                    attrs: {
                      options: ["Poste", "Stage"],
                      multiple: false,
                      placeholder: "Recherche ...",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": ""
                    },
                    model: {
                      value: _vm.resource.type,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "type", $$v)
                      },
                      expression: "resource.type"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _c("b", [_vm._v(_vm._s(_vm.resource.type))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "title" },
                  model: {
                    value: _vm.resource.title,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "title", $$v)
                    },
                    expression: "resource.title"
                  }
                })
              : _c("b", [_vm._v(_vm._s(_vm.resource.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.servicesList.length > 0 && _vm.type != "show"
              ? _c(
                  "multiselect",
                  {
                    attrs: {
                      options: _vm.servicesList,
                      multiple: false,
                      "group-label": "group",
                      "group-values": "items",
                      "group-select": false,
                      placeholder: "Recherche ...",
                      "track-by": "id",
                      label: "title",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": ""
                    },
                    on: { close: _vm.onTouchService },
                    model: {
                      value: _vm.resource.service,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "service", $$v)
                      },
                      expression: "resource.service"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _c("b", [_vm._v(_vm._s(_vm.resource.service.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-textarea", {
                  attrs: { name: "description" },
                  model: {
                    value: _vm.resource.description,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "description", $$v)
                    },
                    expression: "resource.description"
                  }
                })
              : _c("b", [_vm._v(_vm._s(_vm.resource.description))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        [
          _c(
            "vs-checkbox",
            {
              attrs: {
                name: "addJobFilterBySector",
                disabled: _vm.type == "show"
              },
              model: {
                value: _vm.filterBySector,
                callback: function($$v) {
                  _vm.filterBySector = $$v
                },
                expression: "filterBySector"
              }
            },
            [_vm._v("\n            Filière uniquement\n        ")]
          ),
          _vm._v(" "),
          _c(
            "vs-checkbox",
            {
              attrs: {
                name: "addJobFilterByUserRelated",
                disabled: _vm.type == "show"
              },
              model: {
                value: _vm.filterByUserRelated,
                callback: function($$v) {
                  _vm.filterByUserRelated = $$v
                },
                expression: "filterByUserRelated"
              }
            },
            [_vm._v("\n            Mes savoir-faire uniquement\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("skills-selector", {
        attrs: {
          service: _vm.resource.service,
          filterBySector: _vm.filterBySector,
          filterByUserRelated: _vm.filterByUserRelated
        },
        model: {
          value: _vm.resource.occupation_skills,
          callback: function($$v) {
            _vm.$set(_vm.resource, "occupation_skills", $$v)
          },
          expression: "resource.occupation_skills"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Type* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Libellé* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Service* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Description :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }