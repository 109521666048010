<template>
    <vx-card>
        <template slot="actions">
            <Import-Export
                name="services"
                import-url="/api/referentiel/occupations/import"
                :import-acl="true"
                export-url="/api/referentiel/occupations/export"
                :export-acl="true"
                @imported="refreshData"
            />
        </template>
        <vs-tabs class="mt-10">
            <vs-tab label="Métier" class="pt-10">
                <OccupationsTab
                    :api="api"
                    :perpage="perpage"
                    :freshImport="refreshDataAfterImport"
                    @data-refresh="refreshDataAfterImport = false"
                />
            </vs-tab>
            <vs-tab v-if="canSeeReferentiel()" label="Référentiel" class="pt-10">
                <AdminOccupationsTab
                    :api="`${api}/referential`"
                    :perpage="perpage"
                />
            </vs-tab>
        </vs-tabs>

    </vx-card>
</template>

<script>
import OccupationsTab from "@components/views/rh/referentiel/occupation/OccupationsTab";
import AdminOccupationsTab from "@components/views/rh/referentiel/occupation/AdminOccupationsTab";

export default {
    name: "User",
    components: {
        OccupationsTab, AdminOccupationsTab
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        },
    },
    data() {
        return {
            refreshDataAfterImport: false,
        };
    },
    methods: {
        canSeeReferentiel() {
            return this.isASuperAdmin() || this.isAnAdmin()
        },

        refreshData() {
            this.refreshDataAfterImport = true
        }
    }
};
</script>

