import Vue from "vue";

Vue.mixin({
    filters: {
        render: function(value, type, source) {
            if (type) {
                let render = new Render(source);
                return render.make(value, type);
            }
            return value;
        },
        lower: function(value) {
            return value.toLowerCase();
        },
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    data() {
        return {
            debouncerApiSearch: _.debounce(this.debounceApiSearchHandler, 600)
        }
    },
    computed: {
        itemsData: function() {
            return this.items.data;
        },
        getDataPrompt() {
            switch (this.formType) {
                case "show":
                    return this.prompt.show;
                    break;
                case "create":
                    return this.prompt.create;
                    break;
                case "update":
                    return this.prompt.update;
                    break;
                default:
                    return this.prompt.show;
            }
        },
        selectedLinesText() {
            const count = this.selectAll ? this.items.total : this.selected.length;
            const plural = count > 1 ? 's' : '';

            return `${count} ligne${plural} sélectionnée${plural}`
        }
    },

    watch: {
        currentPage: function (page) {
            this.apiParams.page = page;
            this.loadItems();
        },
        items(value) {
            this.updateSelectedItems(this.items.data)
        }
    },

    methods: {
        get: function(source, key, def = null) {
            var dynamik = new Dynamik(source);

            const value = dynamik.getValue(key);

            if (value) {
                return value;
            }
            return def;
        },
        loadItems() {
            this.loading = true;
            return this.apiGetAll();
        },
        selectAllItemsHandle(value) {
            if (value) {
                // Ajout de tous les IDs à la liste des selectionné
                this.selectAllItemsFromIds();

                // Convertion des élements avec la liste réel API
                this.updateSelectedItems(this.items.data);
            } else {
                this.selected = [];
            }
        },
        selectedInputHandle(event) {
            this.selectAll = false
        },
        selectAllItemsFromIds() {

            if (this.items.ids && this.items.ids.length > 0) {
                this.selected = [];

                this.items.ids.forEach(itemId => {
                    this.selected.push({id: itemId})
                })
            } else {
                console.error('Impossible de selectionner tous les éléments. Attributs `ids` manquant dans la liste')
            }

        },
        updateSelectedItems(source) {
            if (this.selected && this.selected.length > 0) {

                const currentSelected = _.cloneDeep(this.selected);

                currentSelected.forEach(item => {
                    const apiItem = source.find(i => i.id == item.id);

                    // Mise à jour de l'élément selectionné avec celui de l'API
                    if (apiItem) {
                        this.selected = this.selected.filter(s => s.id != apiItem.id)
                        this.selected.push(apiItem);
                    }
                })
            }
        },
        debounceApiSearchHandler() {
            this.loadItems();
        },
        eventSearchItems: function(search) {

            this.apiParams.search = search;
            this.apiParams.page = 1;
            this.debouncerApiSearch();
        },
        unselectLines() {
            this.selectAll = false;
            this.selected = [];
        },
        eventSortByCol: function(name, direction) {

            if (!this.apiParams.sortBy) {
                this.apiParams.sortBy = {};
            }

            if (direction) {
                this.apiParams.sortBy[name] = direction;
            } else {
                delete this.apiParams.sortBy[name];
            }

            // if (this.apiParams.sortBy[col] && this.apiParams.sortBy[col] == 'asc') {
            //     this.apiParams.sortBy[col] = 'desc'
            // } else if (this.apiParams.sortBy[col] && this.apiParams.sortBy[col] == 'desc') {
            //     delete this.apiParams.sortBy[col];
            // } else {
            //     this.apiParams.sortBy[col] = 'asc'
            // }

            this.loadItems();
        }
    }
});
