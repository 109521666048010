// Classes
require('./Dynamik.js');
require('./Validator');
require('./Render');

// Fonctions
require('./data_get');
require('./sprintf');
require('./trim');

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

// import { dateTime } from "@assets/utils/helpers/helpers.js";
export const dateTime = (dateTime) => {
    let local = dateTime ? new Date(dateTime) : new Date()
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());

    return local.toISOString().substr(0, 10) + ' ' + local.toISOString().substr(11, 8)
}

// import { localeDateTime } from "@assets/utils/helpers/helpers.js";
export const localeDateTime = (dateTime) => {
    let date = new Date(dateTime)
    return date.toLocaleString();
}

export const fixDate = function (date) {
    if (!date) return null
    if (typeof date == 'string') {
        date = date.replace(' ', 'T');
        date = new Date(date);
    }
    if (typeof date == 'number') {
        date = new Date(date);
    }
    if (date instanceof Date) {
        if (isNaN(date.getDate())) {
            debugger
            return new Date();
        } else {
            return date;
        }
    }
    debugger
}

export const throttle = function (callback, delay) {
    let last = null;
    let timeoutTimer = null;
    let lastResult = null;
    let lastParameters = null;

    return function (...params) {
        if (!last || last < Date.now() - delay) {
            if (timeoutTimer) {
                clearTimeout(timeoutTimer);
            }
            last = Date.now();
            return lastResult = callback(...params);
        } else if (!timeoutTimer) {
            timeoutTimer = setTimeout(function () {
                last = Date.now();
                timeoutTimer = null;
                lastResult = callback(...lastParameters);
            }, delay - (Date.now() - last));
        }
        lastParameters = params;

        return lastResult;
    };
}

// import { getQueryParam } from "@assets/utils/helpers/helpers.js";
export const getQueryParam = function (key) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    return params[key];
}

// import { formatDate } from "@assets/utils/helpers/helpers.js";
export const formatDate = function (input, separator = '-') {
    if (input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0],
            month = datePart[1],
            day = datePart[2];
        return day + separator + month + separator+ year;
    } else {
        return null;
    }
}


// import { jsonToFormData } from "@assets/utils/helpers/helpers.js";
export const jsonToFormData = function (json) {
    let formData = new FormData();

    for ( var key in json ) {
        if (json[key]) {
            if (Array.isArray(json[key])) {
                json[key].forEach(item => {
                    formData.append(key + '[]', item);
                })
            } else {
                formData.append(key, json[key]);
            }

        }
    }

    return formData;
}
