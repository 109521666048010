<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-prompt
                    v-if="resourceReadonly"
                    :active.sync="showPopup"
                    title="Consulter une compétence"
                    cancel-text="Fermer"
                    button-accept=""
                    @cancel="resetResource"
                    @close="resetResource"
                >
                    <SkillForm
                        v-model="resource"
                        :readonly="resourceReadonly"
                        @error="setFormError"
                    />
                </vs-prompt>
                <vs-prompt
                    v-else
                    :active.sync="showPopup"
                    title="Modifier une compétence existante"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                    <SkillForm
                        v-model="resource"
                        :readonly="resourceReadonly"
                        @error="setFormError"
                    />
                </vs-prompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            >
            <template slot="thead">
                <vs-th>Image</vs-th>
                <vs-th>
                    <Sort name="admin_skill,title" @sort="eventSortByCol">Compétence</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="admin_skill.category,title" @sort="eventSortByCol">Catégorie</Sort>
                </vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in items.data" :key="i">
                    <vs-td :data="item.photo">
                        <img
                            v-if="getPhotoUrl(item)"
                            :src="getPhotoUrl(item)"
                            width="70"
                            height="70"
                        />
                        <vs-avatar
                            v-else
                            size="70px"
                            :text="get(item, ':admin_skill.title')"
                            :color="generateColor(get(item, ':admin_skill.title'))"
                        />
                    </vs-td>
                    <vs-td v-tooltip="{
                            content: item.description_falc ? item.description_falc : item.description,
                            placement: 'top-start',
                            classes: ['occ-description'],
                        }"
                        :data="get(item, ':admin_skill.title')"
                    >

                        {{ get(item, ':admin_skill.title') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':admin_skill.category.title') }}
                    </vs-td>

                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('read', 'skills', item.id)"
                                :id="'showSkill_' + i"
                                v-tooltip="'Consulter'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-list"
                                @click="showFormRead(item)"
                            />
                            <vs-button
                                v-if="can('update', 'skills', item.id)"
                                :id="'modifySkill_' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="showFormUpdate(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>

import SkillForm from '@components/views/rh/referentiel/skill/SkillForm'

export default {
    name: 'Skills',
    components: {
        SkillForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            config: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            resourceReadonly: true,
            resource: {},
            formError: true
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    methods: {

        showFormRead(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.showPopup = true
        },

        showFormUpdate(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.showPopup = true
        },

        saveResource() {
            let data = this.resource;
            if (this.resource.id) {
                this.apiUpdate(data, this.resource.id,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true,
                    {"Content-Type": "multipart/form-data"}
                );
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                title: null,
                category: null,
                description: null,
                description_falc: null,
                admin_level: {
                    level_0: null,
                    level_1: null,
                    level_2: null,
                    level_3: null
                },
                level: {
                    level_0: null,
                    level_1: null,
                    level_2: null,
                    level_3: null
                },
                photo: null,
                photoBlob: null,
            }
        },

        setFormError (value) {
            this.formError = value;
        },

        getPhotoUrl(item) {
            if (item.photo) return item.photo
            else if(item.admin_skill.photo) return process.env.MIX_ADMIN_URL + item.admin_skill.photo

            return null
        },
    },
    created() {
        this.apiParams.include = ['category', 'level', 'admin_skill.category', 'admin_skill.admin_level'];
        this.apiParams.sortBy = {"admin_skill.category,title":"asc", "admin_skill,title":"asc"};
        this.apiParams.perpage = 20;
        this.resetResource();
        this.loadItems();
    }
}
</script>
