<template>
    <div>
        <vx-card :title="`Gérer la notation`">
            <br />
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <h4>Paramètres des {{ projectNames.plural }}</h4>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre 1 :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="params[1].note"
                        name="param1Note"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="params[1].label"
                        name="param1Label"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre 2 :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="params[2].note"
                        name="param2Note"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="params[2].label"
                        name="param2Label"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre 3 :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="params[3].note"
                        name="param3Note"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="params[3].label"
                        name="param3Label"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre 4 :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="params[4].note"
                        name="param4Note"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="params[4].label"
                        name="param4Label"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre Non mesuré :</span>
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="NonMesuredLevel"
                        name="nonMesuredNote"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre En cours :</span>
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="NonAcquiredLevel"
                        name="nonAcquiredNote"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
            </div>
            <br />
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <h4>Paramètres des savoir-faire</h4>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre Requis :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="RequiredLevel.note"
                        name="requiredLevelNote"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="RequiredLevel.coef"
                        name="requiredLevelCoef"
                        type="number"
                        class="w-full"
                        label-placeholder="Coefficient"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="RequiredLevel.label"
                        name="requiredLevelLabel"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre Indispensable :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="IndispensableLevel.note"
                        name="indispensableLevelNote"
                        type="number"
                        class="w-full"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="IndispensableLevel.coef"
                        name="indispensableLevelCoef"
                        type="number"
                        class="w-full"
                        label-placeholder="Coefficient"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="IndispensableLevel.label"
                        name="indispensableLevelLabel"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre Autonomie :</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="AutonomyLevel.note"
                        name="autonomyLevelNote"
                        class="w-full"
                        type="number"
                        label-placeholder="Note"
                    />
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <vs-input
                        v-model="AutonomyLevel.coef"
                        name="autonomyLevelCoef"
                        class="w-full"
                        type="number"
                        label-placeholder="Coefficient"
                    />
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="AutonomyLevel.label"
                        name="autonomyLevelLabel"
                        class="w-full"
                        label-placeholder="Libellé de la note"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/5 w-full">
                    <span>Paramètre Centre-Radar :</span>
                </div>
                <div class="vx-col sm:w-2/5 w-full">
                    <vs-input
                        v-model="RadarCenter"
                        name="radarCenter"
                        type="number"
                        class="w-full"
                    />
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-button class="mr-3 mb-2" @click="setRatingSettings()">
                        Enregistrer
                    </vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
export default {
    name: "Parameters",
    data() {
        return {
            params: {
                1: {
                    note: "0",
                    label: "Non mesuré"
                },
                2: {
                    note: "1",
                    label: "En cours"
                },
                3: {
                    note: "2",
                    label: "Acquis"
                },
                4: {
                    note: "3",
                    label: "Autonomie"
                }
            },
            NonMesuredLevel: "0",
            NonAcquiredLevel: "1",
            RequiredLevel: {
                id: "1",
                note: "2",
                coef: "1",
                label: "Requis"
            },
            IndispensableLevel: {
                id: "2",
                note: "2",
                coef: "2",
                label: "Indispensable"
            },
            AutonomyLevel: {
                id: "3",
                note: "3",
                coef: "3",
                label: "Indispensable en autonomie"
            },
            RadarCenter: -1,
            loading: false
        };
    },
    computed: {
        ratingSettings() {
            return window.Laravel.settings.find(el => el.name == "ratings")
                .value;
        },
        paramOptions() {
            return [...Object.keys(this.params)].map(i => {
                return {
                    ...this.params[i],
                    param: `Paramètre ${i}`,
                    index: i
                };
            });
        }
    },
    watch: {
        ratingSettings(newValue) {
            if (newValue.params !== undefined) {
                this.setValues();
            }
        },
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },
    mounted() {
        if (this.ratingSettings.params !== undefined) {
            this.setValues();
        }
    },
    methods: {
        setValues() {
            this.params = this.ratingSettings.params;
            this.NonMesuredLevel = this.ratingSettings.NonMesuredLevel;
            this.NonAcquiredLevel = this.ratingSettings.NonAcquiredLevel;
            this.RequiredLevel = this.ratingSettings.RequiredLevel;
            this.IndispensableLevel = this.ratingSettings.IndispensableLevel;
            this.AutonomyLevel = this.ratingSettings.AutonomyLevel;
            this.RadarCenter = this.ratingSettings.RadarCenter;
        },
        setRatingSettings() {
            this.loading = true;

            let data = {
                params: this.params,
                RequiredLevel: this.RequiredLevel,
                IndispensableLevel: this.IndispensableLevel,
                AutonomyLevel: this.AutonomyLevel,
                NonAcquiredLevel: this.NonAcquiredLevel,
                NonMesuredLevel:
                    this.NonMesuredLevel !== "" ? this.NonMesuredLevel : null,
                RadarCenter: this.RadarCenter
            };

            window.axios({
                method: 'patch',
                url: `/api/referentiel/settings/rating`,
                data: data
            })
            .then(response => {

                if (response.status === 200 && response.data != '') {
                    this.notifySuccess('Succès', 'Élement modifié avec succès !')
                } else {

                    this.notifyError('Erreur', `Une erreur est survenue lors de la modification`)
                }

                this.loading = false;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped></style>
