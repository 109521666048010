import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            //formValidationErrors: {},
        }
    },
    methods: {
        notifyError(err, title = 'Erreur !', consoleLog = process.env.MIX_APP_DEBUG === 'true') {

            if (err.response.status != 401) {
                if (consoleLog) {
                    console.log(err)
                }

                this.$vs.notify({
                    title: title,
                    text: err && err.code ? `<h3>Code: ${err.code}</h3><br/><p>${err.message}</p>` : err,
                    time: 10000,
                    color: 'danger',
                    icon: 'error'
                })
            }
        },

        notifyWarning(title = 'Attention !', text = ``) {
            this.$vs.notify({
                title: title,
                text: text,
                color: 'warning',
                time: 10000,
                icon: 'error'
            })
        },

        notifySuccess(title = 'Succès !', text = ``) {
            this.$vs.notify({
                title: title,
                text: text,
                color: 'success',
                time: 10000,
                icon: 'check_box'
            })
        },

        notifyErrorValidation(err) {
            console.log(err)
            if (err.response.status === 422) {
                console.log(err);
                this.$vs.notify({
                    title: 'Erreur de saisie',
                    text: err.response.data.message,
                    time: 10000,
                    color: 'danger',
                    icon: 'error'
                })
            } else {
                this.notifyError(err)
            }

        },

        validationHasError(errorData, field) {
            return !!errorData[field]
        },

        displayValidationError(errorData, field) {
            return errorData[field] ? errorData[field][0] : null
        }
    }
})
