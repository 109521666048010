<template>
    <div class="sort-container">
        <p>Trier par :</p>
        <VueSelect
            style="margin-left:4px;"
            class="style-chooser"
            :options="options"
            :value="selectedSort"
            :clearable="false"
            @input="setSelectedSort"
        />
    </div>
</template>

<script>
import VueSelect from "vue-select";

export default {
    name: "Sort",

    components: {
        VueSelect
    },

    props: {
        employeesList: Array,
        setAllEmployees: Function
    },

    data: function() {
        this.setSelectedSort("Alphabétique");
        return {
            options: [
                "Alphabétique",
                "Alphabétique ascendant",
                "Taux de maitrise",
                "Taux de maitrise ascendant"
            ],
            selectedSort: "Alphabétique"
        };
    },

    methods: {
        setSelectedSort(value) {
            this.selectedSort = value;
            var sorted = this.employeesList;
            switch (value) {
                case "Alphabétique":
                    return this.setAllEmployees(
                        sorted.sort((a, b) =>
                            a.employee.last_name > b.employee.last_name ? 1 : -1
                        )
                    );
                case "Alphabétique ascendant":
                    return this.setAllEmployees(
                        sorted.sort((a, b) =>
                            a.employee.last_name < b.employee.last_name ? 1 : -1
                        )
                    );
                case "Taux de maitrise":
                    return this.setAllEmployees(
                        sorted.sort((a, b) =>
                            a.employee.proficiencyLevel <
                            b.employee.proficiencyLevel
                                ? 1
                                : -1
                        )
                    );
                case "Taux de maitrise ascendant":
                    return this.setAllEmployees(
                        sorted.sort((a, b) =>
                            a.employee.proficiencyLevel >
                            b.employee.proficiencyLevel
                                ? 1
                                : -1
                        )
                    );
                default:
                    this.setAllEmployees(sorted);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";
@import "vue-select/src/scss/vue-select.scss";

.sort-container {
    display: flex;
    margin-left: 20px;
    align-items: center;
}

.v-select {
    min-width: 250px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    border: none;
    color: $dark;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: $dark;
}

.style-chooser .vs__dropdown-option--highlight {
    background: $primary-light;
    color: $dark;
}
</style>
