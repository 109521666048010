var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch-container" }, [
    _c("p", { class: { active: _vm.isLinesView } }, [
      _vm._v("\n        Bulles\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "switch-button-control" }, [
      _c(
        "div",
        {
          staticClass: "switch-button",
          class: { enabled: _vm.isLinesView },
          on: { click: _vm.setIsLinesView }
        },
        [_c("div", { staticClass: "button" })]
      )
    ]),
    _vm._v(" "),
    _c("p", { class: { active: !_vm.isLinesView } }, [
      _vm._v("\n        Lignes\n    ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }