var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "badge-action" },
        [
          _c(
            "vs-button",
            {
              staticClass: "font-weight-bold",
              attrs: {
                color: "success",
                type: "filled",
                "icon-pack": "feather"
              },
              on: {
                click: function($event) {
                  _vm.showPopupUpdate = true
                }
              }
            },
            [_vm._v("\n            Badges\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-prompt",
        {
          attrs: {
            active: _vm.showPopupUpdate,
            title: "Gestion des badges",
            "cancel-text": "Fermer",
            "button-accept": "false"
          },
          on: {
            "update:active": function($event) {
              _vm.showPopupUpdate = $event
            },
            cancel: function($event) {
              _vm.showPopupUpdate = false
            },
            close: function($event) {
              _vm.showPopupUpdate = false
            }
          }
        },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                [
                  _c("EmployeeBadgesAvailable", {
                    attrs: {
                      api:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/badges/available-skills",
                      employee: _vm.employee
                    },
                    on: {
                      asked: function($event) {
                        _vm.refreshAsked++
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mt-20" },
            [
              _c(
                "vs-col",
                [
                  _c("EmployeeBadgesAsked", {
                    attrs: {
                      api:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/badges",
                      employee: _vm.employee,
                      refresh: _vm.refreshAsked,
                      accepted: false
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mt-20" },
            [
              _c(
                "vs-col",
                [
                  _c("EmployeeBadgesAsked", {
                    attrs: {
                      api:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/badges",
                      employee: _vm.employee,
                      refresh: _vm.refreshAsked,
                      accepted: true
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }