<template>
    <div>
        <header cv-header>
            <div class="employee-photo">
                <img :src="employeePhoto(employee)" alt="" />
            </div>
            <div class="employee-info">
                <h1>
                    {{ employee.first_name }}
                    <span>{{ employee.last_name }}</span>
                </h1>
                <p>
                    Date de naissance :
                    {{
                        employee.birthday
                            ? format(
                                    new Date(employee.birthday),
                                    "dd/MM/yyyy"
                                )
                            : "-"
                    }}
                </p>
                <p>
                    Email : {{ employee.email ? employee.email : "-" }}
                </p>
                <p>
                    Télephone :
                    {{ employee.phone ? employee.phone : "-" }}
                </p>
            </div>
        </header>

        <div class="main">
            <!-- CV Left side -->
            <div class="left">
                <div
                    cv-occupations
                    v-if="career['occupations'].length"
                    class="step occupations"
                >
                    <h2 class="round-purple">
                        Métiers<span class="icon"></span>
                    </h2>
                    <div
                        v-for="(item, i) in career['occupations']"
                        :key="'occupation-' + i"
                    >
                        <h3>{{ item.name }}</h3>
                        <p>
                            Du {{ formatDate(item.start) }}
                            {{
                                !formatDate(item.end, true)
                                    ? "à aujourd'hui"
                                    : "au " + formatDate(item.end, true)
                            }}
                        </p>
                    </div>
                </div>

                <div
                    cv-rae
                    v-if="career['RAE'].length"
                    class="step rae"
                >
                    <h2 class="square_rae">
                        RAE / VAE<span class="icon"></span>
                    </h2>
                    <div
                        v-for="(item, i) in career['RAE']"
                        :key="'rae-' + i"
                    >
                        <h3>{{ item.name }}
                            <img
                                v-if="item.isGraduate"
                                class="is-graduate-rae"
                                src="/assets/images/qualification-blue.png"
                                alt="Formation diplômante"
                            />
                        </h3>
                        <p>
                            {{ formatDate(item.start) }}
                        </p>
                        <p class="bold">
                            Organisme {{ item.organisation }}
                        </p>
                    </div>
                </div>

                <div
                    cv-skills
                    v-if="career['skills'].length"
                    class="step skills"
                >
                    <h2 class="round-pink">
                        Compétences<span class="icon"></span>
                    </h2>
                    <div
                        v-for="(item, i) in career['skills']"
                        :key="'skill-' + i"
                    >
                        <p class="bold">{{ item.category_name }}</p>
                        <div
                            :data-rate="item.rate"
                            :class="[
                                'rate-container',
                                { empty: !item.rate }
                            ]"
                        >
                            <div
                                v-for="i in 10"
                                class="rate-circle"
                                :key="i"
                            ></div>
                        </div>
                    </div>
                </div>

                <div
                    cv-occupationSkills
                    v-if="career['occupation_skills'].length"
                    class="step occupation_skills"
                >
                    <h2 class="round-yellow">
                        Savoir-faire<span class="icon"></span>
                    </h2>
                    <div
                        v-for="(item, i) in career['occupation_skills']"
                        :key="'sector-' + i"
                    >
                        <h3 class="addMargin">
                            {{ item.sector.title }}
                        </h3>
                        <br />
                        <template
                            v-if="
                                item.occupationSkills.filter(
                                    el => el.level == 4
                                ).length
                            "
                        >
                            <h4>Acquis en autonomie :</h4>
                            <ul>
                                <li
                                    v-for="(os,
                                    j) in item.occupationSkills.filter(
                                        el => el.level == 4
                                    )"
                                    :key="'os-' + i + j"
                                >
                                    <p>{{ os.title }}</p>
                                </li>
                            </ul>
                        </template>
                        <br />
                        <template
                            v-if="
                                item.occupationSkills.filter(
                                    el => el.level == 3
                                ).length
                            "
                        >
                            <h4>Acquis :</h4>
                            <ul>
                                <li
                                    v-for="(os,
                                    j) in item.occupationSkills.filter(
                                        el => el.level == 3
                                    )"
                                    :key="'os-' + i + j"
                                >
                                    <p>{{ os.title }}</p>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
            </div>

            <!-- CV Right side -->
            <div class="right">
                <div
                    cv-w_jobs
                    v-if="
                        career['employee_rating_job_wishlists'].length
                    "
                    class="step employee_rating_job_wishlists"
                >
                    <h2 class="star">
                        <span class="icon"></span>Postes souhaités
                    </h2>
                    <div
                        v-for="(item, i) in career[
                            'employee_rating_job_wishlists'
                        ]"
                        :key="'job-w-' + i"
                    >
                        <h3>{{ item.names }}</h3>
                        <p>Filière {{ item.sector }}</p>
                    </div>
                </div>

                <div
                    cv-experiences
                    v-if="career['jobs'].length"
                    class="step jobs"
                >
                    <h2 class="circle">
                        <span class="icon"></span>Expériences
                    </h2>
                    <div
                        v-for="(item, i) in career['jobs']"
                        :key="'job-' + i"
                    >
                        <h3>{{ item.names }}</h3>
                        <p>
                            Du {{ formatDate(item.start) }}
                            {{
                                !formatDate(item.end, true)
                                    ? "à aujourd'hui"
                                    : "au " + formatDate(item.end, true)
                            }}
                        </p>
                        <p class="bold">Service {{ item.service }}</p>
                        <p class="bold">
                            Etablissement {{ item.establishment }}
                        </p>
                    </div>
                </div>

                <!-- FIX SCA du 11/07/2023 - Suppression du bloc Immersion qui fait doublon avec Expérience -->
                <!-- <div
                    cv-immersions
                    v-if="career['Immersions'].length"
                    class="step immersions"
                >
                    <h2 class="diamond">
                        <span class="icon"></span>Immersions
                    </h2>
                    <div
                        v-for="(item, i) in career['Immersions']"
                        :key="'immersion-' + i"
                    >
                        <h3>{{ item.name }}</h3>
                        <p>
                            Du {{ formatDate(item.start) }}
                            {{
                                !formatDate(item.end, true)
                                    ? "à aujourd'hui"
                                    : "au " + formatDate(item.end, true)
                            }}
                        </p>
                        <p class="bold">
                            Etablissement {{ item.organisation }}
                        </p>
                    </div>
                </div> -->

                <div
                    cv-trainings
                    v-if="career['trainings'].length"
                    class="step trainings"
                >
                    <h2 class="square">
                        <span class="icon"></span>Formations
                    </h2>
                    <div
                        v-for="(item, i) in career['trainings']"
                        :key="'training-' + i"
                    >
                        <h3>
                            {{ item.name }}
                            <img
                                v-if="item.isGraduate"
                                class="is-graduate"
                                src="/assets/images/qualification-blue.png"
                                alt="Formation diplômante"
                            />
                        </h3>
                        <p>
                            Du {{ formatDate(item.start) }}
                            {{
                                !formatDate(item.end, true)
                                    ? "à aujourd'hui"
                                    : "au " + formatDate(item.end, true)
                            }}
                        </p>
                        <p class="bold">
                            Etablissement {{ item.organisation }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fixDate } from "@assets/utils/date.js";
import { format, isAfter, isSameDay } from "date-fns";

export default {
    name: 'CV',
    props: {
        employee: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            format,
            employeeJobs: [],
            employeeRatings: [],
            employeeOccupations: [],
            employeeExperiences: []
        }
    },
    async created() {

        if (!this.employee.id) {
            this.employee.id = +this.employee._id;
        }

        await Promise.all([
            this.loadEmployeeJobs(),
            this.loadEmployeeOccupations(),
            this.loadEmployeeRatings(),
            this.loadEmployeeExperiences()
        ])

        this.$emit('loaded');
    },
    watch: {
        employee() {
            Promise.all([
                this.loadEmployeeJobs(),
                this.loadEmployeeOccupations(),
                this.loadEmployeeRatings(),
                this.loadEmployeeExperiences()
            ]).then(() => {
                this.$emit('loaded');
            })

        }
    },
    computed: {
        employeeInfo() {
            let e = this.employee;

            // Modélisation
            var ei = {
                employee: {},
                career: []
            };

            var employee = {};
            employee._id = String(e.id);
            employee.first_name = e.first_name;
            employee.last_name = e.last_name;
            employee.birthday = e.birthday;
            employee.photoUrl = e.photoUrl;
            employee.proficiencyLevel = Math.random() * 100;
            employee.establishment = [e.service?.establishment.name];
            employee.jobs = this.employeeJobs.map(j => j.title);
            employee.service = e.service?.title;
            employee.occupations = this.employeeOccupations.map(o => o.title);
            ei.employee = employee;

            var career = [];

            var establishment = {};
            establishment._id = String(e.service?.establishment.id);
            establishment.category = "establishments";
            establishment.name = e.service?.establishment.name;
            establishment.adress = e.service?.establishment.address;
            establishment.type = e.service?.establishment.type;
            establishment.start = fixDate(e.created_at.split(" ")[0]);
            establishment.end = new Date();
            career.unshift(establishment);

            for (let ex of this.employeeExperiences) {
                var experience = {};

                // Exclure les comptes rendu de la liste
                if (ex.experience_type.category === 4 || ex.experience_type.category === 3) {
                    continue;
                }

                switch (ex.experience_type.category) {
                    case 0:
                        experience.category = "trainings"
                        break;
                    case 1:
                        experience.category = "Immersions"
                        break;
                    case 2:
                        experience.category = "RAE"
                        break;
                    case 5:
                        experience.category = "habilitations"
                        break;
                    default:
                        experience.category = "trainings"
                }

                experience._id = String(ex.id);
                experience.name = ex.name;
                experience.type = ex.experience_type.name;
                experience.organisation = ex.establishment ? ex.establishment.name : ex.establishment_name;
                experience.start = fixDate(ex.start_date);
                experience.end = fixDate(ex.end_date || new Date());
                experience.title = ex.title;
                experience.comments = [ex.comment];
                experience.isGraduate = ex.is_graduate;
                experience.service = ex.service_title;
                career.push(experience);
            }

            this.employeeJobs.forEach(j => {
                var job = {};
                job.category = "jobs";
                job._id = String(j.id);
                job.establishment = j.establishment ? j.establishment.name : j.establishment_name;
                job.start = fixDate(j.start_date);
                job.end = fixDate(j.end_date || new Date());
                job.name = j.title;
                job.service = j.service_title;
                career.push(job);
            });

            this.employeeOccupations.forEach(o => {
                var occupation = {};
                occupation.category = "occupations";
                occupation._id = String(o.id);
                occupation.establishment = null; //Pas d'établissement associé
                occupation.start = fixDate(o.start_date);
                occupation.end = fixDate(o.end_date || new Date());
                occupation.name = o.title;
                occupation.intern = true;
                career.push(occupation);
            });


            let er = this.employeeRatings;

            if (er && er.length > 0) {
                // er.sort(
                //     (a, b) => fixDate(b.rating_date) - fixDate(a.rating_date)
                // );
                er[0].wishlist_jobs.forEach(j => {
                    var job = {};
                    job.category = "employee_rating_job_wishlists";
                    job._id = String(j.id);
                    job.name = j.title;
                    job.date = fixDate(er[0].rating_date);
                    job.sector = j.service.sector?.title;
                    career.unshift(job);
                });
                er[0].wishlist_occupations.forEach(o => {
                    var occupation = {};
                    occupation.category =
                        "employee_rating_occupation_wishlists";
                    occupation._id = String(o.id);
                    occupation.name = o.title;
                    occupation.date = fixDate(er[0].rating_date);
                    career.unshift(occupation);
                });
                er.forEach(r => {
                    var rating = {};
                    rating.category = "employee_ratings";
                    rating.job_occupationSkills = this.getAcquiredOccupationSkillsByJobs(
                        r.occupations_skills
                    );
                    rating._id = String(r.id);
                    rating.skills = r.skills;
                    rating.date = fixDate(r.rating_date);
                    rating.status = r.status;
                    career.unshift(rating);
                });
            }

            ei.career = career;

            return ei;
        },

        career() {
            // Modélisation
            let career = {
                jobs: [],
                skills: [],
                trainings: [],
                Immersions: [],
                habilitations: [],
                RAE: [],
                occupations: [],
                employee_ratings: [],
                occupation_skills: [],
                employee_rating_job_wishlists: []
            };

            if (!this.employeeInfo.career) {
                return career;
            }

            // Sort and index career by category
            this.employeeInfo.career.forEach(c => {
                if (!career[c.category]) {
                    career[c.category] = [];
                }
                career[c.category].push(c);
            });

            career["skills"] = [];
            career["occupation_skills"] = [];

            // Sélection uniquement des projets personnalisés en attente de validation et des projets personnalisés validés
            career.employee_ratings = career.employee_ratings.filter(
                rating => rating.status === 0 || rating.status === 1
            );
            let lastRating =
                career.employee_ratings && career.employee_ratings.length
                    ? career.employee_ratings[
                          career.employee_ratings.length - 1
                      ]
                    : null;
            let acquiredSkills = [];
            if (lastRating) {
                // Group ratings skills by category
                lastRating.skills
                    .filter(s => s.admin_skill)
                    .forEach(s => {
                        let adminSkill = s.admin_skill;
                        let category = career.skills.find(
                            el => el.category_id == adminSkill.category_id
                        );
                        if (!category) {
                            career.skills.push({
                                category_id: adminSkill.category_id,
                                category_name: adminSkill.category.title,
                                skills: [s],
                                rate: 0
                            });
                        } else {
                            category.skills.push(s);
                        }
                    });

                // Set a ratio between the number of skills with level > 2 compared with
                // the total of skills for the category
                career.skills = career.skills.map(skillsByCategory => {
                    acquiredSkills.push(
                        ...skillsByCategory.skills.filter(
                            s => s.pivot.level > 2
                        )
                    );
                    let totalAcquiredSkills = skillsByCategory.skills.filter(
                        s => s.pivot.level > 2
                    ).length;
                    skillsByCategory.rate = Math.floor(
                        (totalAcquiredSkills / skillsByCategory.skills.length) *
                            10
                    );
                    return skillsByCategory;
                });

                // Group occupations skills by sector
                for (var id in lastRating.job_occupationSkills) {
                    let _job = lastRating.job_occupationSkills[id];

                    if (
                        this.employeeJobs
                            .map(j => j.id)
                            .indexOf(parseInt(id)) === -1
                    ) {
                        continue;
                    }

                    let occSkills = _job.occupation_skills.filter(
                        os => os.level >= 3
                    );
                    Object.values(occSkills).forEach(os => {
                        let sector = career.occupation_skills.find(
                            el => el.sector.id == os.sector.id
                        );
                        if (!sector) {
                            career.occupation_skills.push({
                                sector: os.sector,
                                occupationSkills: [os]
                            });
                        } else {
                            let exists = sector.occupationSkills.find(
                                el => el.title == os.title
                            );
                            if (!exists) {
                                sector.occupationSkills.push(os);
                            }
                        }
                    });
                }
            }

            // Group wished jobs by sector
            let jobsBySector = [];
            if (career["employee_rating_job_wishlists"]) {
                career["employee_rating_job_wishlists"].forEach(job => {
                    let sector = jobsBySector.find(
                        el => el.sector == job.sector
                    );
                    if (!sector) {
                        jobsBySector.push({
                            sector: job.sector,
                            names: [job.name]
                        });
                    } else {
                        sector.names.push(job.name);
                    }
                });
                jobsBySector = jobsBySector.map(job => {
                    job.names = job.names.join(", ");
                    return job;
                });
            }
            career["employee_rating_job_wishlists"] = jobsBySector;

            // Group experiences by date, sector and establishment
            let experiences = [];
            if (career["jobs"]) {
                career["jobs"].forEach(job => {
                    let sector = experiences.find(el => {
                        let sameStartDay = isSameDay(
                            new Date(el.start),
                            new Date(job.start)
                        );
                        let sameEndDay = isSameDay(
                            new Date(el.end),
                            new Date(job.end)
                        );

                        return (
                            el.service == job.service &&
                            el.establishment == job.establishment &&
                            sameStartDay &&
                            sameEndDay
                        );
                    });
                    if (!sector) {
                        experiences.push({
                            service: job.service,
                            start: job.start,
                            end: job.end,
                            establishment: job.establishment,
                            names: [job.name]
                        });
                    } else {
                        sector.names.push(job.name);
                    }
                });
                experiences = experiences.map(job => {
                    job.names = job.names.join(", ");
                    return job;
                });
            }
            career["jobs"] = experiences;

            return career;
        }
    },
    methods: {
        async loadEmployeeJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/" + this.employee.id + "/jobs",
                    params: { perpage: 999, sortBy: {start_date: 'desc'} }
                })
                .then(response => {
                    this.employeeJobs = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                    return Promise.reject(err);
                });
        },

        async loadEmployeeOccupations() {
            return window
                .axios({
                    method: "get",
                    url:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/occupations",
                    params: { perpage: 999, sortBy: {start_date: 'desc'} }
                })
                .then(response => {
                    this.employeeOccupations = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                    return Promise.reject(err);
                });
        },

        async loadEmployeeRatings() {
            return window
                .axios({
                    method: "get",
                    url:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/ratings",
                    params: {
                        perpage: 999,
                        include: [
                            "wishlistJobs.service.sector",
                            "wishlistOccupations",
                            "occupationSkills.jobs",
                            "occupationSkills.sector",
                            "skills.admin_skill.category"
                        ],
                        sortBy: {rating_date: 'desc'}
                    }
                })
                .then(response => {
                    this.employeeRatings = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                    return Promise.reject(err);
                });
        },

        async loadEmployeeExperiences() {
            return window
                .axios({
                    method: "get",
                    url:
                        "/api/gestion/employees/" +
                        this.employee.id +
                        "/experiences",
                    params: { perpage: 999, sortBy: {start_date: 'desc'} }
                })
                .then(response => {
                    this.employeeExperiences = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                    return Promise.reject(err);
                });
        },

        getAcquiredOccupationSkillsByJobs(occupationSkills) {
            let acquiredOccupationSkills = {};
            occupationSkills.forEach(currentOs => {
                currentOs.pivots.forEach(pivot => {
                    if (pivot.level > 2) {
                        if (!acquiredOccupationSkills[pivot.job_id]) {
                            acquiredOccupationSkills[pivot.job_id] = {
                                jobTitle: currentOs.jobs.find(
                                    j => j.id == pivot.job_id
                                )?.title,
                                occupation_skills: []
                            };
                        }
                        acquiredOccupationSkills[
                            pivot.job_id
                        ].occupation_skills.push({
                            title: currentOs.title,
                            level: pivot.level,
                            sector: currentOs.sector
                        });
                    }
                });
            });
            Object.values(acquiredOccupationSkills).forEach(os => {
                if (os.occupation_skills.length > 1) {
                    os.occupation_skills.sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                }
            });
            return acquiredOccupationSkills;
        },
        formatDate(date, checkDay = false) {
            let sameDay = false;
            if (checkDay) {
                sameDay = isSameDay(new Date(date), Date.now());
            }
            if (!date || (isAfter(date, Date.now()) && sameDay) || sameDay) {
                return false;
            }
            return format(new Date(date), "dd/MM/yyyy");
        },
        employeePhoto(employee) {
            if (employee && employee.photoUrl) {
                return employee.photoUrl;
            } else {
                return (
                    window.location.origin + "/assets/images/employee-default.jpg"
                );
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";
$grey: #7a7a7a;
header {
        display: flex;
        align-items: flex-start;

        .employee-photo {
            width: 110px;
            img {
                width: 100%;
            }
        }
        .employee-info {
            text-align: left;
            padding-left: 20px;

            h1 {
                font-weight: 900;
                font-size: 22px;
                margin-bottom: 10px;
                span {
                    text-transform: uppercase;
                }
            }
            p {
                font-size: 14px;
            }
        }
    }

    .star {
        position: relative;
        .icon {
            content: "";
            background: transparent
                url("/assets/images/icons/starPostes.svg") no-repeat;
            position: absolute;
            top: -20%;
            left: -13.6%;
            height: 24px;
            width: 24px;
            z-index: 1;
            background-size: contain;
        }
    }

    .round-purple {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: 0%;
            right: -15.5%;
            height: 16px;
            width: 16px;
            background-color: $color-metiers;
            border-radius: 15px;
            z-index: 1;
        }
    }

    .round-yellow {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: 0%;
            right: -15.5%;
            height: 16px;
            width: 16px;
            background-color: $color-bilan;
            border-radius: 15px;
            z-index: 1;
        }
    }

    .round-pink {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: 0%;
            right: -15.5%;
            height: 16px;
            width: 16px;
            border-radius: 15px;
            background-color: $primary;
            z-index: 1;
        }
    }
    .circle {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: 0%;
            left: -12.5%;
            height: 16px;
            width: 16px;
            border: 1px solid $color-etablissement;
            border-radius: 15px;
            background-color: #ffffff;
            z-index: 1;
        }
    }
    .square {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: -15%;
            left: -13.5%;
            margin-top: 4px;
            margin-left: 6px;
            height: 15px;
            width: 15px;
            background-color: $color-formations;
        }
    }
    .square_rae {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: -15%;
            left: -13.5%;
            margin-top: 4px;
            margin-left: 6px;
            height: 15px;
            width: 15px;
            background-color: $color-formations;
        }
    }
    .diamond {
        position: relative;
        .icon {
            content: "";
            position: absolute;
            top: -15%;
            left: -13.7%;
            margin-top: 4px;
            margin-left: 6px;
            height: 15px;
            width: 15px;
            transform: rotate(45deg);
            background-color: $color-immersions;
        }
    }

    .main {
        display: flex;
        > * {
            line-height: 1.2;
        }
        h2 {
            font-weight: 900;
            text-transform: uppercase;
            font-size: 16px;
            margin-bottom: 20px;
        }
        h3 {
            font-weight: 900;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 5px;
        }
        h4 {
            font-weight: 900;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 5px;
        }
        p {
            color: black;
            font-size: 12px;
            &.bold {
                color: $grey;
                font-weight: 900;
                text-transform: uppercase;
            }
        }

        .left {
            width: 45%;
            padding-right: 5%;
            padding-top: 50px;
        }
        .right {
            width: 55%;
            padding-left: 5%;
            border-left: 2px solid #dfdfdf;
        }

        .step {
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-color: currentColor;
            padding: 20px 0;
            box-sizing: border-box;
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            h2,
            h3 {
                color: currentColor;
            }
            &.occupations {
                color: $color-metiers;
                h2,
                h3,
                p {
                    text-align: right;
                }
            }
            &.rae {
                color: $color-formations;
                h2,
                h3,
                p {
                    text-align: right;
                }
            }
            &.skills {
                color: $primary;
                h2,
                h3,
                p {
                    text-align: right;
                }

                p.bold {
                    text-align: left;
                    margin-bottom: 10px;
                }

                .rate-container {
                    display: flex;
                    > div.rate-circle {
                        background-color: $primary;
                        width: 15px;
                        height: 15px;
                        border: 1px solid $primary;
                        border-radius: 50%;
                    }
                    &.empty {
                        > div.rate-circle {
                            background-color: white;
                        }
                    }
                    > div + div {
                        margin-left: 10px;
                    }
                }
                @for $i from 1 through 10 {
                    div[data-rate="#{$i}"] {
                        div:nth-child(#{$i}) ~ div {
                            background-color: white;
                        }
                    }
                }

                .cloud-word {
                    width: 100%;
                    border: 1px solid red;
                    ::v-deep > div {
                        width: 100%;
                        border: 1px solid blue; // TODO:
                    }
                }
            }
            &.employee_rating_job_wishlists {
                color: $color-etablissement;
                h2,
                h3,
                p {
                    text-align: left;
                }
            }
            &.jobs {
                color: $color-etablissement;
                h2,
                h3,
                p {
                    text-align: left;
                }
            }
            &.immersions {
                color: $color-immersions;
                h2,
                h3,
                p {
                    text-align: left;
                }
            }
            &.trainings {
                color: $color-formations;
                h2,
                h3,
                p {
                    text-align: left;
                }
            }
            &.occupation_skills {
                color: $color-bilan;
                h2,
                h3,
                h4,
                ul,
                li,
                p {
                    text-align: right;
                }
                h4,
                ul {
                    color: $grey;
                }
                p {
                    line-height: 1.2;
                }
                ul {
                    li + li {
                        margin-top: 6px;
                    }
                }
            }

            > div {
                margin-top: 25px;
            }
        }
    }
</style>
