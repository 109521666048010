<template>
    <div>
        <h3>Badges {{accepted ? 'acceptés' : `demandés en attente d'acceptation par la personne` }}</h3>
        <vs-table
            :sst="true"
            :data="items.data"
            no-data-text="Aucune donnée à afficher"
            @sort="eventSortByCol"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th>
                    <Sort name="badgeable.admin_skill,title" @sort="eventSortByCol" :value="apiParams.sortBy['badgeable.admin_skill,title']">Libellé</Sort>
                </vs-th>
                <vs-th class="text-center">
                    Évaluation
                </vs-th>
                <vs-th class="text-center">
                    Auto-évaluation
                </vs-th>
                <vs-th class="text-center">
                    Évaluation tiers
                </vs-th>
                <vs-th v-if="accepted">
                    Accepté le
                </vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td>
                        <feather-icon icon="AwardIcon" title="Compétence"></feather-icon>
                    </vs-td>
                    <vs-td :data="item.badgeable.admin_skill.title">
                        <span class="badge-title">{{ item.badgeable.admin_skill.title }}</span>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.external_rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>

                    <vs-td v-if="accepted">
                        {{ formatDate(item.accepted_at) }}
                    </vs-td>

                    <vs-td v-if="can('read', 'employees') && can('read', 'badges')" class="text-right">
                        <vs-button
                            v-if="!accepted"
                            v-tooltip="'Se connecter au portail de la Personne pour l’acceptation du badge'"
                            color="primary"
                            type="filled"
                            @click="acceptClickHandle()"
                        >
                            Se connecter
                        </vs-button>


                        <div class="action-button ml-3" @click="clickPreviewBadgeHandle(item)">
                            <feather-icon icon="EyeIcon"></feather-icon>
                        </div>

                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
import CheckGreenIcon from '@components/svg/CheckGreenIcon';

import { formatDate } from "@assets/utils/helpers/helpers.js";

export default {
    components: {
        CheckGreenIcon
    },
    props: {
        api: {
            type: String
        },
        employee: {
            type: Object,
            required: true
        },
        refresh: {
            type: Number,

        },
        accepted: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            items: {
                data: [],
                loading: false
            },
        }
    },
    watch: {
        refresh(value) {
            this.loadItems();
        }
    },
    methods: {
        async acceptClickHandle() {
            window.axios({
                method: "get",
                url: `/api/gestion/employees/${this.employee.id}/portailAutoLoginUrl`,
            })
            .then(response => {
                console.log('URL autoLogin', response)
                const link = document.createElement("a")
                link.href = response.data.autoLoginUrl
                link.setAttribute('target', '_blank')
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
                this.notifyError(err, `Une erreur est survenue`);
            });
        },
        clickPreviewBadgeHandle(badge) {
            this.$swal({
                title: `<img src="${badge.rh_image_url}" alt="badge" style="max-width: 350px" />`,
                confirmButtonText: 'Fermer',
                showCloseButton: true,
            });
        }
    },
    created() {
        this.apiParams = {
            sortBy: {"badgeable.admin_skill.title": "asc"},
            perpage: 10,
            accepted: this.accepted,
            include: ["badgeable.admin_skill", "rating", "external_rating"],
            type: 'Skill'
        };
        this.loadItems();
    },
}
</script>

<style scoped>
.badge-title {
    font-size: 1.4em;
    font-weight: 600;
    color: #7f7f7f;
}

.action-button {
    float: right;
}

.action-button .feather-icon {
    margin-top: 5px
}
</style>
