<template>
    <div class="row">
        <div class="col-20">
            <div class="user-name">
                <p>
                    {{ person.employee.first_name }}
                    {{ person.employee.last_name }}
                </p>
                <div
                    class="btn-cards-toggle"
                    :class="{ active: cardsTimeLineIsActive }"
                    @click="setCardsTimelineIsActive"
                >
                    <img :src="arrow" alt="arrow" />
                </div>
            </div>

            <div v-if="cardsTimeLineIsActive" class="cards-nav">
                <button class="prev" @click="prevCard">
                    <img :src="arrow" alt="arrow" />
                </button>
                <button class="next" @click="nextCard">
                    <img :src="arrow" alt="arrow" />
                </button>
            </div>
        </div>

        <div class="col-80">
            <div class="steps-timeline">
                <ul>
                    <div v-if="!isLinesView" class="full-line" />
                    <CareerStep
                        v-for="(step, index) in career"
                        :id="person.employee._id"
                        :key="index"
                        :selected-years="selectedYears"
                        :active-legend="activeLegend"
                        :index="index"
                        :step="step"
                        :all-postes="allPostes"
                        :is-lines-view="isLinesView"
                        :set-one-tooltip-is-active="setOneTooltipIsActive"
                        :one-tooltip-is-active="oneTooltipIsActive"
                        :cards-time-line-is-active="cardsTimeLineIsActive"
                        :set-active-card="setActiveCard"
                        :active-card="activeCard"
                    />
                </ul>
            </div>

            <div v-if="cardsTimeLineIsActive" class="cards-timeline">
                <ul
                    :style="{
                        transform: cardsTimeLinePosition
                    }"
                >
                    <li v-for="(card, index) in allCards" :key="index">
                        <CareerCard
                            :is-tooltip="false"
                            :card="card"
                            :active-card="activeCard"
                            :selected-card="selectedCard"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import arrow from "@assets/images/icons/arrow.svg";

import * as moment from "moment";
import isWithinInterval from "date-fns/isWithinInterval";
import CareerStep from "./CareerStep";
import CareerCard from "./CareerCard";

export default {
    name: "UserCareer",

    components: {
        CareerStep,
        CareerCard
    },

    props: {
        person: Object,
        selectedYears: Array,
        activeLegend: Array,
        isLinesView: Boolean
    },

    data() {
        var fullCareer = this.calcCumulByPost(this.person.career); // Tableau avec une entrée pour chaque poste ou métier souhaité
        var career = []; // Tableau avec concaténation des postes souhaités et des métiers souhaités

        fullCareer = fullCareer.filter(card => {
            if (card.category === "pageBreak") {
                return false;
            }
            return true;
        });

        career = fullCareer.filter(card => {
            if (
                card.category === "employee_rating_job_wishlists" ||
                card.category === "employee_rating_occupation_wishlists"
            ) {
                return false;
            }
            return true;
        });

        // Ajout de la carte des postes souhaités
        var arrayName = [];
        let jobWishlists = fullCareer.filter(
            card => card.category === "employee_rating_job_wishlists"
        );

        jobWishlists.forEach(j => {
            arrayName.push(j.name);
        });
        if (jobWishlists.length) {
            var jobsWishlist = {};
            jobsWishlist.category = jobWishlists[0].category;
            jobsWishlist._id = career.length + 1;
            jobsWishlist.name = arrayName;
            jobsWishlist.date = jobWishlists[0].date;
            jobsWishlist.sector = jobWishlists[0].sector;
            career.push(jobsWishlist);
        }

        // Ajout de la carte des métiers souhaités
        arrayName = [];
        let occupationWishlists = fullCareer.filter(
            card => card.category === "employee_rating_occupation_wishlists"
        );

        occupationWishlists.forEach(j => {
            arrayName.push(j.name);
        });
        if (occupationWishlists.length) {
            var occupationsWishlist = {};
            occupationsWishlist.category = occupationWishlists[0].category;
            occupationsWishlist._id = career.length + 1;
            occupationsWishlist.name = arrayName;
            occupationsWishlist.date = occupationWishlists[0].date;
            career.push(occupationsWishlist);
        }

        return {
            allPostes: fullCareer.filter(
                post =>
                    (post.category === "Immersions" && post.type === "Poste") ||
                    post.category === "jobs" ||
                    post.category === "occupation" ||
                    post.category === "employee_rating_job_wishlists" ||
                    post.category === "employee_rating_occupation_wishlists"
            ),
            allCards: fullCareer,
            career: career,
            selectedCard: null,
            activeCard: null,
            oneTooltipIsActive: false,
            cardsTimeLineIsActive: false,
            cardsTimeLinePosition: "translateX(0px)",
            arrow: arrow
        };
    },

    watch: {
        selectedYears: function() {
            this.years = this.selectedYears;
            this.setAllCards();
        },

        activeLegend: function() {
            this.setAllCards();
        },

        activeCard: function() {
            this.setCardsTimelinePostion();
        }
    },

    methods: {
        nextCard: function() {
            if (
                this.allCards.indexOf(this.activeCard) + 1 <
                this.allCards.length
            ) {
                this.activeCard = this.allCards[
                    this.allCards.indexOf(this.activeCard) + 1
                ];
            }
        },

        prevCard: function() {
            if (this.allCards.indexOf(this.activeCard) - 1 >= 0) {
                this.activeCard = this.allCards[
                    this.allCards.indexOf(this.activeCard) - 1
                ];
            }
        },

        setOneTooltipIsActive: function(value) {
            this.oneTooltipIsActive = value;
        },

        setCardsTimelineIsActive: function() {
            this.cardsTimeLineIsActive = !this.cardsTimeLineIsActive;
            if (!this.cardsTimeLineIsActive) {
                this.activeCard = null;
            }
        },

        setActiveCard: function(value) {
            this.activeCard = value;
        },

        setCardsTimelinePostion: function() {
            if (
                (this.activeCard,
                document.getElementsByClassName("card").length)
            ) {
                var cardSize =
                    document.getElementsByClassName("card")[0].offsetWidth + 20;
                var timelineWidth = cardSize * this.allCards.length;
                var activeIndex = this.allCards.indexOf(this.activeCard);

                var translateX = 0;

                if (activeIndex !== -1) {
                    translateX =
                        (timelineWidth / this.allCards.length) * activeIndex;
                }

                this.cardsTimeLinePosition = `translateX(${-translateX}px)`;
            }
        },

        setAllCards: function() {
            var allCards = [...this.career];

            this.calcCumulByPost(this.person.career);

            allCards = allCards
                .filter(card =>
                    this.activeLegend.find(legend => legend === card.category)
                )
                .filter(card => card.category !== "pageBreak");

            allCards = allCards.filter(
                card =>
                    new Date(card.end) >
                        new Date("01/01/" + this.selectedYears[0]) &&
                    new Date(card.start) <
                        new Date("01/01/" + (this.selectedYears[1] + 1))
            );

            this.activeCard = allCards[0];
            this.allCards = allCards;
        },

        calcCumulByPost: function(career) {
            career.forEach(step => {
                step.linePosition = -6;

                if (step.end === "") {
                    step.end = moment().format("L");
                }

                if (
                    step.category === "trainings" ||
                    step.category === "Immersions"
                ) {
                    step.linePosition = -18;
                }

                if (step.category === "occupations") {
                    step.linePosition = -12;
                }

                if (
                    step.category === "employee_ratings" ||
                    step.category === "employee_rating_job_wishlists" ||
                    step.category === "employee_rating_occupation_wishlists"
                ) {
                    step.start = step.date;
                    step.end = step.date;
                }

                var stepStart = new Date(step.start);
                var stepEnd = new Date(step.end);

                if (step.category === "occupations") {
                    var cumulatedOccupation = career
                        .filter(
                            post =>
                                post !== step && post.category === "occupations"
                        )
                        .filter(post => {
                            var postStart = new Date(post.start);
                            var postEnd = new Date(post.end);

                            if (post.end === "") {
                                postEnd = new Date(moment().format("L"));
                            }

                            var startIsOnIntervals = isWithinInterval(
                                stepStart,
                                {
                                    start: postStart,
                                    end: postEnd
                                }
                            );

                            if (startIsOnIntervals) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                    step.cumulatedOccupation = cumulatedOccupation.length + 1;
                }

                if (step.category === "jobs") {
                    //Décalage de la date de début de l'établissement sur la plus ancienne date de début de poste
                    var cumulatedEstablishments = career.filter(
                        post => post.category === "establishments"
                    );
                    cumulatedEstablishments.map(e => {
                        if (
                            e.name == step.establishment &&
                            e.start > step.start
                        ) {
                            e.start = step.start;
                        }
                        return e;
                    });
                    var cumulatedPostes = career
                        .filter(
                            post => post !== step && post.category === "jobs"
                        )
                        .filter(post => {
                            var postStart = new Date(post.start);
                            var postEnd = new Date(post.end);
                            var today = new Date();

                            if (
                                postEnd.toDateString() ===
                                stepStart.toDateString()
                            ) {
                                return postEnd.toDateString() ===
                                    today.toDateString()
                                    ? true
                                    : false;
                            } else {
                                var startIsOnIntervals = isWithinInterval(
                                    stepStart,
                                    {
                                        start: postStart,
                                        end: postEnd
                                    }
                                );

                                if (startIsOnIntervals) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        });
                    var endCumulatedPostes = career
                        .filter(
                            post =>
                                post !== step &&
                                stepEnd !== new Date(post.end) &&
                                post.category === "jobs"
                        )
                        .filter(post => {
                            var postStart = new Date(post.start);
                            var postEnd = new Date(post.end);

                            /*if (post.end === '') {
                postEnd = new Date(moment().format('L'))
              }*/
                            if (
                                postStart.toDateString() ===
                                postEnd.toDateString()
                            ) {
                                return false;
                            } else {
                                var endIsOnIntervals = isWithinInterval(
                                    stepEnd,
                                    { start: postStart, end: postEnd - 1 } //- 1 },
                                );

                                if (endIsOnIntervals) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        });
                    step.endCumulatedPostes = endCumulatedPostes.length;
                    step.lineOpacity = 1;
                    step.cumulatedPostes = cumulatedPostes.length + 1;
                }
            });
            var isLooping = true;

            while (isLooping) {
                var areOnSameLines = [];
                career
                    .sort(
                        (a, b) =>
                            new Date(a.start).getTime() -
                            new Date(b.start).getTime()
                    )
                    .forEach(step => {
                        if (
                            step.category === "jobs" ||
                            step.category === "occupations" ||
                            step.category === "establishments" ||
                            step.category === "trainings" ||
                            step.category === "Immersions"
                        ) {
                            var stepStart = new Date(step.start).getTime();
                            var stepEnd = new Date(step.end).getTime();

                            // Fix - Quand la date de fin est à la date et l'heure du jour (non défini), et que la date du début est à la date du jour aussi
                            if (stepStart > stepEnd) {
                                var tempEnd = new Date(step.end);
                                tempEnd.setUTCHours(23,59,59,999);
                                stepEnd = tempEnd.getTime();
                            }

                            career
                                .filter(event =>
                                    isWithinInterval(
                                        new Date(event.start).getTime(),
                                        {
                                            start: stepStart,
                                            end: stepEnd
                                        }
                                    )
                                )
                                .sort(
                                    (a, b) =>
                                        new Date(a.start).getTime() -
                                        new Date(b.start).getTime()
                                )
                                .forEach(event => {
                                    if (
                                        event !== step &&
                                        step.category !== "employee_ratings" &&
                                        step.category !==
                                            "employee_rating_job_wishlists" &&
                                        step.category !==
                                            "employee_rating_occupation_wishlists"
                                    ) {
                                        if (
                                            event.linePosition ===
                                            step.linePosition
                                        ) {
                                            areOnSameLines.push(true);
                                            if (
                                                step.category ===
                                                    "occupations" ||
                                                step.category === "trainings" ||
                                                step.category === "Immersions"
                                            ) {
                                                event.linePosition =
                                                    event.linePosition - 6;
                                            } else {
                                                event.linePosition =
                                                    event.linePosition + 6;
                                            }
                                            if (step.category == "jobs") {
                                                event.lineOpacity =
                                                    event.lineOpacity - 0.25;
                                            }
                                        }
                                    }
                                });
                        }
                    });

                if (!areOnSameLines.length) {
                    isLooping = false;
                }
            }
            return career;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.row {
    width: 100%;
    display: flex;
    text-align: left;
    .col-20 {
        width: 20%;
        .user-name {
            min-height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-cards-toggle {
                margin: 10px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                &:hover {
                    background-color: rgb(238, 238, 238);
                }
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .col-80 {
        width: 80%;
    }
}

.steps-timeline {
    width: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    .full-line {
        width: 100%;
        height: 2px;
        top: 39px;
        position: absolute;
        background-color: $dark;
        opacity: 0.15;
    }
    ul {
        width: 100%;
        min-height: 130px;
        position: relative;
        display: flex;
        list-style: none;
        padding: 0px;
        overflow-y: visible;
        li {
            overflow-y: visible;
        }
    }
}

.cards-timeline {
    width: 100%;
    overflow-x: hidden;
    transform: translateY(-30px);
    ul {
        max-width: 100%;
        position: relative;
        overflow: visible;
        display: flex;
        list-style: none;
        padding: 0px;
        transition: all 0.2s ease-in-out;
        li {
            overflow-y: visible;
        }
    }
}

.cards-nav {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: 53px;
        min-height: 53px;
        margin: 10px;
        border-radius: 30px;
        background-color: $white;
        border: 2px solid #d2d2d2;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            border: 2px solid $primary;
        }

        &.prev {
            transform: rotate(90deg);
        }

        &.next {
            transform: rotate(-90deg);
        }
    }
}
</style>
