<template>
    <div class="rating-export">
        <!-- Chart - Skills (Main chart) -->
        <div class="vx-col w-full">
            <div class="chart main">
                <rating-chart
                    chart-id="skillsAll"
                    :employee="employee"
                    class="rating"
                    :options="skillRatingChart.options"
                    :chart-data="skillRatingChart.data"
                    :styles="{ width: '750px', height: '750px' }"
                />
            </div>
        </div>

        <!-- Chart - Auto-evaluation compare to the last evaluation -->
        <div class="vx-col w-full" v-if="autoEvalChart">
            <div class="chart main">
                <rating-chart
                    chart-id="auto-eval"
                    :employee="employee"
                    class="rating"
                    :options="skillRatingChart.options"
                    :chart-data="autoEvalChart.data"
                    :styles="{ width: '750px', height: '750px' }"
                />
            </div>
        </div>

        <!-- Charts - Occupations -->
        <vs-row
            v-if="occupationCharts && occupationCharts.length"
            style="width: 800px;"
            class="secondary-charts"
        >
            <vs-col>
                <p v-if="concernedRating.rating_date" class="title">
                    Métiers occupés ou en préparation :
                </p>
                <vs-row>
                    <vs-col
                        v-for="(chart, i) in occupationCharts"
                        :key="`pdf-chart-occupations-${i}`"
                        class="mt-6 chart"
                    >
                        <rating-chart
                            :chart-id="'chart-occupations-' + i"
                            :employee="employee"
                            class="rating"
                            :options="chart.options"
                            :chart-data="chart.data"
                            :styles="{ width: '750px', height: '750px' }"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Charts - Jobs -->
        <vs-row
            v-if="jobCharts && jobCharts.length"
            style="width: 800px;"
            class="secondary-charts"
        >
            <vs-col>
                <p v-if="concernedRating.rating_date" class="title">
                    Postes ou stages occupés :
                </p>
                <vs-row>
                    <vs-col
                        v-for="(chart, i) in jobCharts"
                        :key="`chart-job-${i}`"
                        class="mt-6 chart"
                    >
                        <rating-chart
                            :chart-id="'chart-jobs-' + i"
                            :employee="employee"
                            class="rating"
                            :options="chart.options"
                            :chart-data="chart.data"
                            :styles="{ width: '750px', height: '750px' }"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Charts - Occupations wishes -->
        <vs-row
            v-if="wishOccupationCharts && wishOccupationCharts.length"
            style="width: 800px;"
            class="secondary-charts"
        >
            <vs-col>
                <p v-if="concernedRating.rating_date" class="title">
                    Métiers souhaités :
                </p>
                <vs-row>
                    <vs-col
                        v-for="(chart, i) in wishOccupationCharts"
                        :key="`chart-woccupation-${i}`"
                        class="mt-6 chart"
                    >
                        <rating-chart
                            :chart-id="'chart-occ-wishes-' + i"
                            :employee="employee"
                            class="rating"
                            :options="chart.options"
                            :chart-data="chart.data"
                            :styles="{ width: '750px', height: '750px' }"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Charts - Jobs wishes -->
        <vs-row
            v-if="wishJobCharts && wishJobCharts.length"
            style="width: 800px;"
            class="secondary-charts"
        >
            <vs-col>
                <p v-if="concernedRating.rating_date" class="title">
                    Postes souhaités :
                </p>
                <vs-row>
                    <vs-col
                        v-for="(chart, i) in wishJobCharts"
                        :key="`chart-wjob-${i}`"
                        class="mt-6 chart"
                    >
                        <rating-chart
                            :chart-id="'chart-jobs-wishes-' + i"
                            :employee="employee"
                            class="rating"
                            :options="chart.options"
                            :chart-data="chart.data"
                            :styles="{ width: '750px', height: '750px' }"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Comments -->
        <vs-row v-if="concernedRating" style="width: 800px;">
            <div class="comments" id="comments">
                <template v-if="comments.length">
                    <div
                        class="comment"
                        v-for="({ name, value }, i) in comments"
                        :key="'com-' + i"
                    >
                        <p class="comment-title" style="margin:10px 0 4px 0;">
                            <b>{{ name }}</b>
                        </p>
                        <p
                            class="ql-editor comment-text"
                            v-if="value"
                            v-html="value"
                        ></p>
                        <p class="comment-text" v-else>/</p>
                    </div>
                </template>
                <template v-else>
                    <div class="comment">
                        <b class="comment-text">Aucun commentaire</b>
                    </div>
                </template>
            </div>
        </vs-row>
    </div>
</template>

<script>
import RatingChart from "@components/app/RatingChart";

import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import html2canvas from "html2canvas";
import { format } from "date-fns";
import { mapGetters, mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";

export default {
    name: "export-ratings-pdf",
    components: {
        RatingChart
    },
    props: {
        employee: {
            required: true,
            type: Object
        },
        skillRatingChart: {
            required: true,
            type: Object
        },
        autoEvalChart: {
            required: false,
            type: Object
        },
        occupationCharts: {
            required: false,
            type: Array
        },
        jobs: {
            required: false,
            type: Array
        },
        jobCharts: {
            required: false,
            type: Array
        },
        wishOccupationCharts: {
            required: false,
            type: Array
        },
        wishJobCharts: {
            required: false,
            type: Array
        },
        concernedRating: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            pdf: null,
            nextSidePositionY: 0,
            ratingFields: [],
            adminOccupationSkills: []
        };
    },

    async mounted() {
        //Only way found to wait for the end of the construction of the graphs
        //==> else, sometime, graphics are not well drawn (not right scale, bad points values, ...)
        await this.loadRatingFields();
        await this.loadAdminOccupationSkills();

        /*console.log('ratingFields', window._.cloneDeep(this.ratingFields))
        console.log('concernedRating', window._.cloneDeep(this.concernedRating))
        console.log('ratingHasOccSkillGoaledOrCommented', this.ratingHasOccSkillGoaledOrCommented)*/
        setTimeout(() => {
            this.exportPDF();
        }, 2000);
    },

    computed: {
        //...mapState(["ratingSettings", "ratingFields", "jobs"]),
        //...mapGetters(["establishmentById", "sectorById"]),
        ratingHasOccSkillGoaledOrCommented() {
            return (
                this.concernedRating.occupation_skills_goals.length
                || this.concernedRating.admin_occupation_skills_goals.length
                || this.concernedRating.occupations_skills
                    .map(os => os.pivots)
                    .filter(p => !!p.comment)
                    .length
            )
        },

        ratingSkillGoaledOrCommented() {
            let goaledOrCommentedSkills = []
            if (this.concernedRating.skills_goals.length) {
                this.concernedRating.skills_goals.forEach(gs => {
                    let associatedSkill = this.concernedRating.skills.find(s => s.id === gs.id)
                    if (associatedSkill) {
                        associatedSkill.goal_level = gs.pivot.level + 1 //TODO Enlever le +1 lorsque les objectifs seront remis sur les ids à la place des notes
                        goaledOrCommentedSkills.push(associatedSkill)
                    }
                })
            }
            this.concernedRating.skills
                .filter(s => !!s.pivot.comment)
                .forEach(cs => {
                    if (!goaledOrCommentedSkills.find(gcs => gcs.id === cs.id)) goaledOrCommentedSkills.push(cs)
                })

            return goaledOrCommentedSkills
        },
        acquiredOccupationSkillsByJobs() {
            let occupationSkills = this.concernedRating.occupations_skills;
            let acquiredOccupationSkills = {};
            occupationSkills.forEach(currentOs => {
                currentOs.pivots.forEach(pivot => {
                    if (pivot.level > 1 && pivot.job_id) {
                        // Only rated occupations skills from jobs
                        if (!acquiredOccupationSkills[pivot.job_id]) {
                            acquiredOccupationSkills[pivot.job_id] = {
                                jobTitle: currentOs.jobs.find(
                                    j => j.id == pivot.job_id
                                )
                                    ? currentOs.jobs.find(
                                          j => j.id == pivot.job_id
                                      ).title
                                    : this.jobs.find(j => j.id == pivot.job_id)
                                          .title,
                                occupation_skills: []
                            };
                        }
                        acquiredOccupationSkills[
                            pivot.job_id
                        ].occupation_skills.push({
                            title: currentOs.title,
                            level: pivot.level,
                            levelText: this.ratingSettings.params[pivot.level]
                                .label,
                            sector: currentOs.sector
                        });
                    }
                });
            });
            Object.values(acquiredOccupationSkills).forEach(os => {
                if (os.occupation_skills.length > 1) {
                    os.occupation_skills.sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                }
            });

            return acquiredOccupationSkills;
        },

        // Used to preserve comments order
        comments() {
            if (!this.concernedRating.comment) return [];

            //if (this.ratingFields.length == 0) return [];

            let comments = [];
            // let keys = this.ratingFields.map(r => r.name);
            // let lastOrder = keys.length + 1;
            // Object.keys(this.concernedRating.comment).forEach(el => {
            //     if (!keys.find(key => el == key)) {
            //         keys.push(el);
            //     }
            // });
            // for (let key of keys) {
            //     comments.push({
            //         order: this.ratingFields.find(r => r.name == key)
            //             ? this.ratingFields.find(r => r.name == key).order
            //             : lastOrder++,
            //         name: key,
            //         value: this.concernedRating.comment[key]
            //     });
            // }
            // return comments.sort((a, b) => (a.order > b.order ? 1 : -1));

            Object.keys(this.concernedRating.comment).forEach(name => {
                comments.push({
                    name: name,
                    value: this.concernedRating.comment[name]
                })
            });

            return comments;
        }
    },

    methods: {
        async loadRatingFields() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/rating-fields",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {
                    this.ratingFields = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {
                    this.adminOccupationSkills = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getAdminOccupationSkillById(id) {
            return this.adminOccupationSkills.find(aos => aos.id == id);
        },

        formatDate(input) {
            if (input) {
                var datePart = input.match(/\d+/g),
                    year = datePart[0],
                    month = datePart[1],
                    day = datePart[2];
                return day + "-" + month + "-" + year;
            } else {
                return null;
            }
        },
        async exportPDF() {
            let logoImg;
            let logoMYS;
            if (this.employee.services[0].establishment.logo) {
                let img = this.employee.services[0].establishment.logo;
                logoImg = await this.getImageFromUrl(
                    window.location.origin + img
            );
            } else {
                logoMYS = await this.getImageFromUrl(
                    window.location.origin + "/assets/images/logo-neutre.png"
                );
            }
            let employeePhoto = null;
            let employeeDefault = null;

            if (this.employee.photoUrl) {
                employeePhoto = await this.getImageFromUrl(
                    window.location.origin + this.employee.photoUrl
                );
            } else {
                employeeDefault = await this.getImageFromUrl(
                    window.location.origin + "/assets/images/employee-default.jpg"
                );
            }

            const a4_width = 210;
            const a4_height = 297;
            const margin_top = 25;
            const margin_bottom = 15;
            const margin_left = 10;
            const margin_right = 10;
            const startPosX = a4_width / 5;
            const startPos = margin_top + 5;
            const fontHeight = 5;
            const maxCanvasHeight = 240;
            const addHeaders = (doc, employee) => {
                const pageCount = doc.internal.getNumberOfPages();
                doc.setFont("helvetica", "normal");
                doc.setFontSize(10);
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFillColor(255, 255, 255);
                    doc.rect(0, 0, a4_width, margin_top, "F");
                    //doc.addImage(document.querySelector('.logo img.main-logo-image'), margin_left, 5, margin_left + 5, 10)
                    if (logoImg) {
                        doc.addImage(logoImg, "JPEG", margin_left, 5, 15, 15);
                    } else {
                        doc.addImage(logoMYS, "JPEG", margin_left, 5, 46, 10);
                    }
                    doc.text(
                        `${this.concernedRating.skill_review ? this.skillRatingNames.singular:this.projectNames.singular} du ${this.formatDate(
                            this.concernedRating.rating_date
                        )}`,
                        doc.internal.pageSize.width / 2,
                        10 + 2.5,
                        {
                            align: "center"
                        }
                    );
                    doc.text(
                        `de ${employee.first_name} ${employee.last_name}`,
                        doc.internal.pageSize.width / 2,
                        15 + 2.5,
                        {
                            align: "center"
                        }
                    );
                    doc.text(
                        `Export du ${new Date().toLocaleDateString()}`,
                        doc.internal.pageSize.width - 30,
                        10 + 2.5,
                        {
                            align: "center"
                        }
                    );
                    doc.setDrawColor(100, 100, 100);
                    doc.setLineWidth(0.1);
                    doc.line(10, margin_top - 5, a4_width - 10, margin_top - 5);
                }
            };
            const addFooters = doc => {
                const pageCount = doc.internal.getNumberOfPages();
                doc.setFont("helvetica", "normal");
                doc.setFontSize(8);
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFillColor(255, 255, 255);
                    doc.rect(
                        0,
                        a4_height - margin_bottom,
                        a4_width,
                        margin_bottom,
                        "F"
                    );
                    doc.text(
                        "Produit par MySkilliz RH ®",
                        margin_bottom,
                        a4_height - margin_bottom / 2
                    );
                    doc.text(
                        "Document à usage restreint",
                        doc.internal.pageSize.width / 2,
                        a4_height - margin_bottom / 2,
                        {
                            align: "center"
                        }
                    );
                    doc.text(
                        "Page " + String(i) + " / " + String(pageCount),
                        doc.internal.pageSize.width - 27,
                        a4_height - margin_bottom / 2,
                        {
                            align: "center"
                        }
                    );
                }
            };
            let options = {
                orientation: "p",
                unit: "mm",
                putOnlyUsedFonts: true
            };

            this.pdf = new jsPDF(options);

            // Employee info
            this.pdf.setFontSize(12);
            let birthday = this.employee.birthday
                ? format(
                      new Date(this.employee.birthday).getTime(),
                      "dd/MM/yyyy"
                  )
                : "-";
            this.pdf.text(
                `Nom : ${this.employee.last_name} `,
                startPosX,
                startPos
            );
            this.pdf.text(
                `Prénom : ${this.employee.first_name} `,
                startPosX,
                startPos + fontHeight
            );
            this.pdf.text(
                `Date de naissance : ${birthday}`,
                startPosX,
                startPos + 2 * fontHeight
            );
            let establishment = `Établissement : ${
                this.employee.services[0].establishment.name
            }, ${
                this.employee.services[0].establishment.address !== {}
                    ? this.employee.services[0].establishment.address.place_name
                    : " - "
            }`;
            let splitTitle = this.pdf.splitTextToSize(establishment, 140);
            this.pdf.text(splitTitle, startPosX, startPos + 3 * fontHeight);
            let lineheight = 1;
            if (splitTitle.length > 1) {
                lineheight = splitTitle.length;
            }
            this.pdf.text(
                `Service : ${this.employee.services[0].title} `,
                startPosX,
                startPos + (3 + lineheight) * fontHeight
            );
            if (employeePhoto) {
                this.pdf.addImage(
                    employeePhoto,
                    "JPEG",
                    margin_left,
                    startPos - 4,
                    25,
                    25
                );
            } else {
                this.pdf.addImage(
                    employeeDefault,
                    "JPEG",
                    margin_left,
                    startPos - 4,
                    25,
                    25
                );
            }

            // Rating chart
            this.pdf.setFontSize(11);
            this.pdf.setFont("helvetica", "bold");
            this.pdf.setTextColor(151, 47, 95);
            this.pdf.text(
                "Evaluation :",
                10,
                startPos + (5 + lineheight) * fontHeight
            );
            let canvasSkills = document.querySelector("#skillsAll");
            let ctx = canvasSkills.getContext("2d");
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvasSkills.width, canvasSkills.height);
            let canvasSkillsWidth = a4_width - margin_left - margin_right;
            let canvasFullHeight =
                canvasSkillsWidth * (canvasSkills.height / canvasSkills.width);
            this.pdf.addImage(
                canvasSkills,
                "JPEG",
                (a4_width - canvasSkillsWidth) / 2,
                startPos + (6 + lineheight) * fontHeight,
                canvasSkillsWidth,
                canvasFullHeight
            );

            // Auto eval chart
            if (!this.concernedRating.skill_review) {
                this.pdf.addPage();
                this.pdf.setFontSize(11);
                this.pdf.setFont("helvetica", "bold");
                this.pdf.setTextColor(151, 47, 95);
                this.pdf.text("Auto-évaluation :", 10, startPos);
                let canvasAutoEval = document.querySelector("#auto-eval");
                let ctx2 = canvasAutoEval.getContext("2d");
                ctx2.globalCompositeOperation = "destination-over";
                ctx2.fillStyle = "white";
                ctx2.fillRect(0, 0, canvasAutoEval.width, canvasAutoEval.height);

                if (this.autoEvalChart) {
                    this.pdf.addImage(
                        canvasAutoEval,
                        "JPEG",
                        (a4_width - canvasSkillsWidth) / 2,
                        startPos + lineheight * fontHeight,
                        canvasSkillsWidth,
                        canvasFullHeight
                    );
                }
            }

            // One page or more by secondary charts (occupations, jobs, occ-wishes, jobs-wishes)
            let secondaryCharts = document.querySelectorAll(
                ".secondary-charts"
            );
            canvasFullHeight = (a4_height - startPos - margin_bottom) / 2;
            secondaryCharts.forEach(el => {
                let canvasList = el.querySelectorAll("canvas");
                let title = el.querySelector(".title")
                    ? el.querySelector(".title").textContent
                    : "";

                if (canvasList.length) {
                    this.pdf.addPage();
                    this.pdf.setFontSize(10);
                    this.pdf.setFont("helvetica", "bold");
                    this.pdf.setTextColor(151, 47, 95);
                    this.pdf.text(title, 10, startPos - 4);
                }
                let left = margin_left;
                let top = margin_top + 6;
                let tmpCanvasHeight = 0;
                canvasList.forEach((canvas, index) => {
                    let ctx = canvas.getContext("2d");
                    ctx.globalCompositeOperation = "destination-over";
                    ctx.fillStyle = "white";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    let canvasWidth =
                        canvas.width * (canvasFullHeight / canvas.height);

                    left = (a4_width - canvasWidth) / 2;
                    if (index != 0) {
                        if (top + canvasFullHeight > maxCanvasHeight) {
                            this.pdf.addPage();
                            top = startPos;
                        } else {
                            top += tmpCanvasHeight + 5;
                        }
                    }
                    tmpCanvasHeight = canvasFullHeight;

                    this.pdf.addImage(
                        canvas,
                        "JPEG",
                        left,
                        top,
                        canvasWidth,
                        canvasFullHeight
                    );
                });
            });
            this.pdf.setTextColor(0, 0, 0);
            let content_width = a4_width - margin_left - margin_right;


            //SF évalués et avec objectif ou commentaire
            this.pdf.addPage();
            this.pdf.setFontSize(11);
            this.pdf.setFont("helvetica", "bold");
            this.pdf.setTextColor(151, 47, 95);
            this.pdf.text("Savoir-faire évalués ou avec un objectif ou un commentaire :", 10, startPos);
            let thisGlobal = this
            finalY = this.pdf.lastAutoTable.finalY || margin_top + fontHeight
            let head = [['Savoir-faire', 'Evalué', 'Objectif fixé', 'Commentaire']]
            //... For Jobs
            this.concernedRating.jobs.forEach(function(job) {
                let body = []
                if (!thisGlobal.jobHasCommentedOrGoaledOccupationSkills(job.id).length)
                    return;

                thisGlobal.jobHasCommentedOrGoaledOccupationSkills(job.id).forEach(function(occupationSkill) {
                    let row = [
                        {
                            content: occupationSkill.title,
                        },
                        {
                            content: thisGlobal.getRatingLevelLabel(thisGlobal.getOccSkillJobPivot(occupationSkill, job.id)?.level),
                        },
                        {
                            content: occupationSkill.goal_level ? thisGlobal.getRatingLevelLabel(occupationSkill.goal_level) : '',
                        },
                        {
                            content: (thisGlobal.getOccSkillJobPivot(occupationSkill, job.id)?.comment || ''),
                        },
                    ]
                    body.push(row)
                })

                thisGlobal.pdf.setFont("helvetica", "normal");
                thisGlobal.pdf.text(job.title+' (Poste)', 10, finalY + 2*fontHeight);
                thisGlobal.pdf.autoTable({
                    startY: finalY + 2*fontHeight + 5,
                    margin: margin_left,
                    head: head,
                    body: body,
                    theme: 'grid',
                    styles: { cellPadding: 0.68, fontSize: 8 },
                    headStyles: { fillColor: [147, 42, 84] },
                    columnStyles: {
                        0: {cellWidth: 55},
                        1: {cellWidth: 20},
                        2: {cellWidth: 20},
                        3: {cellWidth: 95},
                    },
                })
                finalY = thisGlobal.pdf.lastAutoTable.finalY || 10
            })
            //... For Occupations
            this.concernedRating.occupations.forEach(function(occupation) {
                let body = []
                if (!thisGlobal.occupationHasCommentedOrGoaledOccupationSkills(occupation.id).length)
                    return;

                thisGlobal.occupationHasCommentedOrGoaledOccupationSkills(occupation.id).forEach(function(occupationSkill) {
                    let row = [
                        {
                            content: occupationSkill.title,
                        },
                        {
                            content: thisGlobal.getRatingLevelLabel(thisGlobal.getOccSkillOccupationPivot(occupationSkill, occupation.id)?.level),
                        },
                        {
                            content: occupationSkill.goal_level ? thisGlobal.getRatingLevelLabel(occupationSkill.goal_level) : '',
                        },
                        {
                            content: (thisGlobal.getOccSkillOccupationPivot(occupationSkill, occupation.id)?.comment || ''),
                        },
                    ]
                    body.push(row)
                })

                thisGlobal.pdf.setFont("helvetica", "normal");
                thisGlobal.pdf.text(occupation.title+' (Métier)', 10, finalY + 2*fontHeight);
                thisGlobal.pdf.autoTable({
                    startY: finalY + 2*fontHeight + 5,
                    margin: margin_left,
                    head: head,
                    body: body,
                    theme: 'grid',
                    styles: { cellPadding: 0.68, fontSize: 8 },
                    headStyles: { fillColor: [147, 42, 84] },
                    columnStyles: {
                        0: {cellWidth: 55},
                        1: {cellWidth: 20},
                        2: {cellWidth: 20},
                        3: {cellWidth: 95},
                    },
                })
                finalY = thisGlobal.pdf.lastAutoTable.finalY || 10
            })

            //Compétences avec objectif ou commentaire
            this.pdf.addPage();
            this.pdf.setFontSize(11);
            this.pdf.setFont("helvetica", "bold");
            this.pdf.setTextColor(151, 47, 95);
            this.pdf.text("Compétences avec un objectif ou un commentaire :", 10, startPos);
            let finalY = margin_top + fontHeight
            head = [['Compétence', 'Evaluée', 'Objectif fixé', 'Commentaire']]
            let body = []
            this.ratingSkillGoaledOrCommented.forEach(function(skill) {
                let row = [
                    {
                        content: skill.admin_skill.title,
                    },
                    {
                        content: thisGlobal.getRatingLevelLabel(skill.pivot.level),
                    },
                    {
                        content: skill.goal_level ? thisGlobal.getRatingLevelLabel(skill.goal_level) : '',
                    },
                    {
                        content: (skill.pivot.comment || ''),
                    },
                ]
                body.push(row)
            })

            thisGlobal.pdf.setFont("helvetica", "normal");
            thisGlobal.pdf.autoTable({
                startY: finalY + 2*fontHeight,
                margin: margin_left,
                head: head,
                body: body,
                theme: 'grid',
                styles: { cellPadding: 0.68, fontSize: 8 },
                headStyles: { fillColor: [147, 42, 84] },
                columnStyles: {
                    0: {cellWidth: 55},
                    1: {cellWidth: 20},
                    2: {cellWidth: 20},
                    3: {cellWidth: 95},
                },
            })
            finalY = thisGlobal.pdf.lastAutoTable.finalY || 10

            // Comments pages
            this.pdf.addPage();
            this.nextSidePositionY = startPos;
            this.pdf.setFontSize(11);
            this.pdf.setFont("helvetica", "bold");
            this.pdf.setTextColor(151, 47, 95);
            this.pdf.text("Observations :", 10, this.nextSidePositionY);
            this.pdf.setTextColor(0, 0, 0);
            this.nextSidePositionY += 6;

            // Comments
            this.pdf.setFont("helvetica", "normal");
            this.pdf.setTextColor(151, 47, 95);
            this.pdf.setFontSize(9);
            let text =
                (this.concernedRating.skill_review ? this.skillRatingNames.singular:this.projectNames.singular) + " du " +
                this.formatDate(this.concernedRating.rating_date) +
                ", réalisé par " +
                this.concernedRating.creator.first_name +
                " " +
                this.concernedRating.creator.last_name;
            this.addText(text, content_width, margin_left);

            this.pdf.setTextColor(0, 0, 0);
            this.nextSidePositionY += 15;

            this.pdf.setFontSize(10.5);
            let comments = document.querySelector("#comments");

            for (let el of comments.children) {
                let comm = el.children[1];
                if (comm) {
                    // Title
                    this.pdf.setFont("helvetica", "bold");
                    this.addText(
                        el.children[0].innerText,
                        content_width,
                        margin_left
                    );
                    this.nextSidePositionY += 3;
                    this.pdf.setDrawColor("#e2cbd6");
                    this.pdf.setLineWidth(0.6);
                    this.pdf.line(
                        margin_left,
                        this.nextSidePositionY,
                        17,
                        this.nextSidePositionY,
                        "FD"
                    );
                    this.nextSidePositionY += 3;

                    // Content
                    await html2canvas(comm, {}).then(canvas => {
                        canvas.getContext("2d");
                        let canvasHeight = Math.ceil(
                            (canvas.height * content_width) / canvas.width
                        );
                        if (
                            this.nextSidePositionY + canvasHeight >
                            maxCanvasHeight
                        ) {
                            this.pdf.addPage();
                            this.nextSidePositionY = startPos;
                        }
                        this.pdf.addImage(
                            canvas,
                            "JPEG",
                            margin_left,
                            this.nextSidePositionY,
                            content_width,
                            canvasHeight
                        );
                        this.nextSidePositionY += canvasHeight + 15;
                    });
                }
            }

            this.pdf.setDrawColor("#626262");
            this.pdf.setLineWidth(0.1);

            // Signatures
            let signaturesHeight = 30;
            if (this.nextSidePositionY > a4_height - signaturesHeight) {
                this.pdf.addPage();
            }
            this.pdf.text("Signatures :", 10, 240);
            this.pdf.setFont("helvetica", "normal");

            this.pdf.setFontSize(8);
            const thirdSpace = (a4_width - 10) / 3;
            let x = 12;
            let xb = x - 2;
            let y = 250;
            const yb = y - 0.8 * fontHeight;
            let w = thirdSpace - 10;
            let h = 30;
            this.pdf.rect(xb, yb, w, h, "S");
            this.pdf.text(
                `${this.employee.first_name} ${this.employee.last_name}`,
                x,
                y
            );
            x += thirdSpace;
            xb += thirdSpace;
            this.pdf.rect(xb, yb, w, h, "S");
            this.pdf.text("Encadrant", x, y);
            x += thirdSpace;
            xb += thirdSpace;
            this.pdf.rect(xb, yb, w, h, "S");
            this.pdf.text("Référent", x, y);

            addHeaders(this.pdf, this.employee);
            addFooters(this.pdf);
            this.pdf.save(
                `Export ${this.concernedRating.skill_review ? this.skillRatingNames.singular:this.projectNames.singular} ${this.employee.last_name.toUpperCase()} ${
                    this.employee.first_name
                } du ${this.formatDate(this.concernedRating.rating_date)}.pdf`
            );
            this.$emit("exported");
            this.pdf = null;
        },

        addText(innerText, width, position, align = "left") {
            let text = this.pdf.splitTextToSize(innerText, width);
            this.pdf.text(text, position, this.nextSidePositionY, {
                align: align
            });
            if (text.length > 1) {
                this.nextSidePositionY += 5 * (text.length - 1) - text.length;
            }
        },

        jobHasCommentedOrGoaledOccupationSkills(job_id) {
            let goaledOrCommentedOccSkills = []
            let job = this.concernedRating.jobs.find(j => j.id === job_id)
            if (this.concernedRating.occupation_skills_goals.length) {
                this.concernedRating.occupation_skills_goals.forEach(gos => {
                    if (gos.pivot.job_id === job_id) {
                        let associatedOccSkill = this.concernedRating.occupations_skills.find(os => os.id === gos.id)
                        if (associatedOccSkill) {
                            associatedOccSkill.goal_level = gos.pivot.level + 1 //TODO Enlever le +1 lorsque les objectifs seront remis sur les ids à la place des notes
                            associatedOccSkill.order = job.occupation_skills?.find(jos => jos.id === gos.id)?.pivot.order || 0
                            goaledOrCommentedOccSkills.push(associatedOccSkill)
                        }
                    }
                })
            }
            this.concernedRating.occupations_skills
                .filter(os => os.pivots.find(osp => (osp.job_id === job_id && !!osp.comment)))
                .forEach(cos => {
                    if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === cos.id)) {
                        cos.order = job.occupation_skills?.find(jos => jos.id === cos.id)?.pivot.order || 0
                        goaledOrCommentedOccSkills.push(cos)
                    }
                })

            this.concernedRating.occupations_skills
                .filter(os => os.pivots.find(osp => osp.job_id === job_id && osp.level > 1))
                .forEach(cos => {
                    if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === cos.id)) {
                        cos.order = job.occupation_skills?.find(jos => jos.id === cos.id)?.pivot.order || 0
                        goaledOrCommentedOccSkills.push(cos)
                    }
                })

            return goaledOrCommentedOccSkills.sort((a, b) => a.order > b.order ? 1 : -1)
        },
        occupationHasCommentedOrGoaledOccupationSkills(occupation_id) {
            let goaledOrCommentedOccSkills = []
            let occupation = this.concernedRating.occupations.find(o => o.id === occupation_id)
            if (!occupation)
                return []

            let isAdminOccupation = !!occupation.admin_id
            // SF des métiers maison avec un objectif
            if (!isAdminOccupation && this.concernedRating.occupation_skills_goals.length) {
                this.concernedRating.occupation_skills_goals.forEach(gos => {
                    if (gos.pivot.occupation_id === occupation_id) {
                        let associatedOccSkill = this.concernedRating.occupations_skills.find(os => os.id === gos.id)
                        if (associatedOccSkill) {
                            associatedOccSkill.goal_level = gos.pivot.level + 1 //TODO Enlever le +1 lorsque les objectifs seront remis sur les ids à la place des notes
                            associatedOccSkill.order = occupation.occupation_skills?.find(oos => oos.id === gos.id)?.pivot.order || 0
                            goaledOrCommentedOccSkills.push(associatedOccSkill)
                        }
                    }
                })
            }
            // SF des métiers centralisés avec un objectif
            if (isAdminOccupation && this.concernedRating.admin_occupation_skills_goals.length) {
                this.concernedRating.admin_occupation_skills_goals.forEach(gos => {
                    if (gos.pivot.admin_occupation_id === occupation.admin_id) {
                        gos.goal_level = gos.pivot.level + 1 //TODO Enlever le +1 lorsque les objectifs seront remis sur les ids à la place des notes
                        gos.comment = this.concernedRating.admin_occupation_skills.find(caos => caos.admin_occupation_skill_id === gos.id)?.comment
                        gos.order = occupation.admin_occupation_skills?.find(oaos => oaos.id === gos.id)?.pivot.order || 0
                        goaledOrCommentedOccSkills.push(gos)
                    }
                })
            }
            // SF des métiers métier maison avec un commentaire
            this.concernedRating.occupations_skills
                .filter(os => os.pivots.find(osp => (osp.occupation_id === occupation_id && !!osp.comment)))
                .forEach(cos => {
                    if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === cos.id)) {
                        cos.order = occupation.occupation_skills?.find(oos => oos.id === cos.id)?.pivot.order || 0
                        goaledOrCommentedOccSkills.push(cos)
                    }
                })
            // SF des métiers centralisés avec un commentaire
            this.concernedRating.admin_occupation_skills
                .filter(aos => !!aos.comment && aos.admin_occupation_id === occupation.id)
                .forEach(aos => {
                    //Récup de l'adminOccupationSkill sur l'adminOccupation car le modèle lui-même n'est pas complet
                    if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === aos.admin_occupation_skill_id)) {
                        let adminOccupationSkill = occupation.admin_occupation_skills.find(oaos => oaos.id === aos.admin_occupation_skill_id)
                        adminOccupationSkill.comment = aos.comment
                        adminOccupationSkill.level = aos.level
                        adminOccupationSkill.order = adminOccupationSkill.pivot.order || 0
                        goaledOrCommentedOccSkills.push(adminOccupationSkill)
                    }
                })

            if (!isAdminOccupation) {
                // SF des métiers maison évalués et sans objectif ou commentaire
                this.concernedRating.occupations_skills
                    .filter(os => os.pivots.find(osp => (osp.occupation_id === occupation_id)))
                    .forEach(cos => {
                        if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === cos.id)) {
                            cos.order = occupation.occupation_skills?.find(oos => oos.id === cos.id)?.pivot.order || 0
                            goaledOrCommentedOccSkills.push(cos)
                        }
                    })
            }
            else {
                // SF des métiers centralisés évalués et sans objectif ou commentaire
                this.concernedRating.admin_occupation_skills
                    .filter(aos => aos.admin_occupation_id === occupation.id && aos.level > 1)
                    .forEach(async aos => {
                        if (!goaledOrCommentedOccSkills.find(gcs => gcs.id === aos.admin_occupation_skill_id)) {
                            let adminOccupationSkill = occupation.admin_occupation_skills.find(oaos => oaos.id === aos.admin_occupation_skill_id)

                            // Le savoir-faire n'est plus associé au métier centralisé
                            if (!adminOccupationSkill) {
                                adminOccupationSkill = this.getAdminOccupationSkillById(aos.admin_occupation_skill_id);
                            }

                            adminOccupationSkill.level = aos.level
                            adminOccupationSkill.order = adminOccupationSkill.pivot?.order || 1
                            goaledOrCommentedOccSkills.push(adminOccupationSkill)
                        }
                    })
            }

            return goaledOrCommentedOccSkills.sort((a, b) => a.order > b.order ? 1 : -1)
        },

        getOccSkillJobPivot(OccSkill, job_id) {
            return OccSkill.pivots?.find(p => p.job_id === job_id)
        },
        getOccSkillOccupationPivot(OccSkill, occupation_id) {
            let occupation = this.concernedRating.occupations.find(o => o.id === occupation_id)
            let isAdminOccupation = !!occupation.admin_id

            return isAdminOccupation ?
                OccSkill
                :
                OccSkill.pivots?.find(p => p.occupation_id === occupation_id)
        },
        getRatingLevelLabel(skillLevel) {
            return Object.values(this.ratingSettings.params).find(p => p.id == (skillLevel || 1))?.label || ''
        }
    }
};
</script>

<style lang="scss" scoped>
    .rating-export {
        transform: translateY(-100%);
        z-index: -1;
        position: absolute;
        pointer-events: none;
        opacity: 0;
        top: 0;
        left: 0;
    }

    #comments {
        width: 100%;

        .comment {
            font-size: 12px;
            line-height: 1.2;

            .ql-editor {
                padding: 0;
                p {
                    color: black;
                    text-align: left;
                }
            }
        }
    }

    ::v-deep .vs-con-table {
        color: #000;
        font-size: 1.5rem;
        border: 1px solid #000;
        tr {
            border-bottom: 1px solid #000;
            font-size: 1.5rem;
        }
    }
</style>
