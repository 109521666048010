var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider-container" },
    [
      _c("vue-slider", {
        attrs: { "enable-cross": false, data: _vm.allYears, marks: true },
        on: { change: _vm.setSelectedYear },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var active = ref.active
              var value = ref.value
              return [
                _c(
                  "div",
                  {
                    class: [
                      "vue-slider-mark-label",
                      "custom-label",
                      _vm.isSelectedYear({ value: value })
                    ]
                  },
                  [_vm._v("\n        " + _vm._s(value) + "\n      ")]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }