<template>
    <div id="geocoder" />
</template>

<script>
export default {
    props: ["default", "placeholder"],
    mounted() {
        let geocoder = new MapboxGeocoder({
            accessToken:
                "pk.eyJ1Ijoic2Fsc2lmYWIiLCJhIjoiY2thdzRoYWx1MDRqazJzbXV1enN4enppcyJ9.C4YoHTNGB672NNgF3sgTvw",
            placeholder: this.placeholder,
            countries: "fr",
            types: "country,region,place,postcode,locality,neighborhood,address"
        });
        geocoder.addTo("#geocoder");
        if (this.default) {
            geocoder.setInput(this.default);
        }
        geocoder.on("result", geocoder =>
            this.$emit("result", geocoder.result)
        );
        geocoder.on("clear", () => this.$emit("result", ""));
    }
};
</script>
