var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { staticClass: "overflow-hidden pb-6" }, [
    _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
      _c(
        "div",
        {
          staticClass: "p-6 pb-0",
          class: {
            "flex justify-between flex-row-reverse items-center": _vm.iconRight
          }
        },
        [
          _vm.div || _vm.chip
            ? [
                _vm.div
                  ? _c(
                      "div",
                      { staticClass: "circle", style: _vm.backgroundColor },
                      [_vm._v(_vm._s(_vm.statistic))]
                    )
                  : _vm.chip
                  ? _c(
                      "vs-chip",
                      {
                        staticClass: "min-chip",
                        attrs: { color: _vm.customColor, transparent: "" }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.statistic) +
                            "\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "1.2rem", "font-weight": "600" }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.statisticTitle))])]
                )
              ]
            : [
                _c("feather-icon", {
                  staticClass: "p-3 inline-flex rounded-full",
                  class: [_vm.textColor, { "mb-4": !_vm.iconRight }],
                  style: _vm.backgroundColor,
                  attrs: { icon: _vm.icon }
                }),
                _vm._v(" "),
                _c("div", [
                  _c("h2", { staticClass: "mb-1 font-bold" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.statistic) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.statisticTitle))])
                ])
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.chartData
        ? _c(
            "div",
            { staticClass: "line-area-chart", attrs: { id: _vm.chartData.id } },
            [
              _c("vue-apex-charts", {
                ref: "apexChart",
                attrs: {
                  type: _vm.type,
                  height: "100",
                  width: "100%",
                  options: _vm.chartData.chartOptions,
                  series: _vm.chartData.series
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }