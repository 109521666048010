var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: _vm.stepClass, attrs: { id: _vm.id } }, [
    _c("div", { staticClass: "timeline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("span", { attrs: { "v-if": _vm.step.cumulatedPost } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.step.cumulatedPostes) +
              "\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.star
        ? _c("img", { attrs: { src: _vm.star, alt: "star" } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "end" })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "details" }, [
      _vm.step.end && _vm.step.date
        ? _c("h4", [
            _vm._v("\n            " + _vm._s(_vm.step.start) + "\n        ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step.end && !_vm.step.date && !_vm.step.isToday
        ? _c("h4", [
            _vm._v(
              "\n            Du " +
                _vm._s(_vm.step.start) +
                " au " +
                _vm._s(_vm.step.end) +
                "\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step.end && !_vm.step.date && _vm.step.isToday
        ? _c("h4", [
            _vm._v(
              "\n            Du " +
                _vm._s(_vm.step.start) +
                " à aujourd'hui\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [
        _vm._v("\n            " + _vm._s(_vm.step.name) + "\n        ")
      ]),
      _vm._v(" "),
      _vm.step.category === "employee_ratings"
        ? _c("h4", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.projectNames.singular) +
                "\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n            " + _vm._s(_vm.step.description) + "\n        ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }