<template>
    <li
        :id="'step' + id + index"
        class="step"
        :class="stepClass"
        :style="{
            width: stepWidth + '%',
            opacity: stepOpacity,
            display: stepDisplay,
            marginLeft: stepMarginLeft
        }"
    >
        <div
            class="icon"
            :style="isEndCard ? 'opacity: 0.3' : ''"
            @mouseover="e => setCardIsActive(e, true, false)"
            @mouseleave="e => setCardIsActive(e, false, false)"
        >
            <span :v-if="stepProp.cumulatedPost">
                {{ stepProp.cumulatedPostes }}
            </span>
            <span :v-if="stepProp.cumulatedOccupation">
                {{ stepProp.cumulatedOccupation }}
            </span>
        </div>
        <div
            v-if="step.category !== 'employee_ratings'"
            class="line-hover-area"
            :style="{
                transform: lineTransform
            }"
            @mouseover="e => setCardIsActive(e, true, false)"
            @mouseleave="e => setCardIsActive(e, false, false)"
        >
            <div
                class="line"
                :style="{
                    opacity: lineOpacity
                }"
            />
        </div>
        <div
            v-if="!currentJob"
            class="end"
            :style="tooltipIsActive ? (isEndCard ? '' : 'opacity: 0.3') : ''"
            @mouseover="e => setCardIsActive(e, true, true)"
            @mouseleave="e => setCardIsActive(e, false, false)"
        >
            <span :v-if="stepProp.endCumulatedPost">
                {{ stepProp.endCumulatedPostes }}
            </span>
        </div>

        <img
            v-if="star"
            :src="star"
            alt="star"
            @mouseover="e => setCardIsActive(e, true, false)"
            @mouseleave="e => setCardIsActive(e, false, false)"
        />

        <div class="card-container" :style="{ top: cardTop, left: cardLeft }">
            <div
                v-if="!cardsTimeLineIsActive && tooltipCards.length"
                :id="'card' + id + index"
                :class="{
                    'slideInCard cards': tooltipIsActive,
                    card: !tooltipIsActive
                }"
            >
                <CareerCard
                    v-for="(card, i) in tooltipCards"
                    :key="i"
                    :is-tooltip="true"
                    :card="card"
                    :is-end-card="isEndCard"
                />
            </div>
        </div>
    </li>
</template>

<script>
import CareerCard from "./CareerCard";

import starMetier from "@assets/images/icons/starMetiers.svg";
import starPostes from "@assets/images/icons/starPostes.svg";

export default {
    name: "CareerStep",

    components: {
        CareerCard
    },

    props: {
        step: Object,

        selectedYears: Array,
        activeLegend: Array,

        id: String,
        index: Number,
        isLinesView: Boolean,
        allPostes: Array,

        setOneTooltipIsActive: Function,
        oneTooltipIsActive: Boolean,

        cardsTimeLineIsActive: Boolean,
        setActiveCard: Function,
        activeCard: Object
    },

    data: function() {
        return {
            stepWidth: 0,
            stepMarginLeft: "0%",
            stepOpacity: 1,
            lineOpacity: 1,
            stepDisplay: "flex",
            star: null,
            lineTransform: "translate(0px,0px)",
            stepProp: this.step,
            stepClass: this.step.category,
            tooltipCards: [],
            tooltipIsActive: false,
            cardTop: "-1000px",
            cardLeft: "-1000px",
            isEndCard: false,
            currentJob: false
        };
    },

    watch: {
        activeCard: function() {
            this.setStepStyle(this.step);
        },

        oneTooltipIsActive: function() {
            this.setStepStyle(this.step);
        },

        selectedYears: function() {
            this.setStepStyle(this.step);
        },

        isLinesView: function() {
            this.setStepStyle(this.step);
        },

        activeLegend: function() {
            if (this.activeLegend.find(item => item === this.step.category)) {
                this.stepDisplay = "flex";
            } else {
                this.stepDisplay = "none";
            }
        }
    },

    created: function() {
        this.setStepStyle(this.step);
    },

    methods: {
        setCardIsActive: function(e, bool, isEndCard) {
            this.isEndCard = isEndCard;
            if (bool && !this.tooltipIsActive && !this.cardsTimeLineIsActive) {
                var element = document.getElementById(
                    "card" + this.id + this.index
                );
                this.cardTop = e.clientY - element.clientHeight - 80 + "px";
                this.cardLeft = e.clientX - element.clientWidth / 2 + "px";
                if (this.tooltipCards.length > 1) {
                    this.cardTop =
                        e.clientY -
                        element.clientHeight / this.tooltipCards.length -
                        80 +
                        "px";
                    this.cardLeft =
                        e.clientX -
                        ((element.clientWidth + 10) / 2) *
                            this.tooltipCards.length +
                        "px";
                }
            }
            if (bool) {
                this.setOneTooltipIsActive(true);
                this.setActiveCard(this.step);
            } else {
                this.setOneTooltipIsActive(false);
                this.cardTop = "-1000px";
                this.cardLeft = "-1000px";
            }
            this.tooltipIsActive = bool;
        },

        setTooltipCards: function() {
            var today = new Date().toDateString();
            var star =
                this.step.category === "employee_rating_job_wishlists" ||
                this.step.category === "employee_rating_occupation_wishlists";
            if (
                this.allPostes.filter(
                    poste =>
                        poste.category === this.step.category &&
                        poste.start === this.step.start
                ).length &&
                (!this.isLinesView || star) &&
                this.step.category === "jobs"
            ) {
                var cardArray = [];
                cardArray.push(this.step);
                if (star) {
                    this.tooltipCards = this.allPostes.filter(
                        poste => poste.category === this.step.category
                    );
                } else {
                    var onSamePeriod;
                    if (this.isEndCard) {
                        onSamePeriod = this.allPostes.filter(
                            poste =>
                                poste.category === "jobs" &&
                                poste.start <= this.step.end &&
                                poste.end > this.step.end
                        );
                    } else {
                        onSamePeriod = this.allPostes.filter(poste => {
                            var posteEnd = new Date(poste.end).toDateString();
                            var stepStart = new Date(
                                this.step.start
                            ).toDateString();
                            if (posteEnd === today && stepStart === today) {
                                return true;
                            }
                            return (
                                poste.category === "jobs" &&
                                poste.start <= this.step.start &&
                                poste.end > this.step.start
                            );
                        });
                    }
                    this.tooltipCards = onSamePeriod.length
                        ? onSamePeriod
                        : cardArray;
                }
            } else {
                var card = [];
                card.push(this.step);
                this.tooltipCards = card;
            }
        },

        setOpacity: function() {
            if (this.oneTooltipIsActive && !this.tooltipIsActive) {
                this.stepOpacity = 0.3;
            } else if (
                this.cardsTimeLineIsActive &&
                this.activeCard !== this.stepProp
            ) {
                this.stepOpacity = 0.3;
            } else {
                this.stepOpacity = 1;
            }
        },

        setStepStyle: function(step) {
            var today = new Date().toDateString();
            var end = new Date(this.stepProp.end).toDateString();

            //Si le poste se finit aujourd'hui alors c'est un poste actuel
            if (end === today) {
                this.currentJob = true;
            }

            this.setOpacity();
            this.setTooltipCards();

            var dayTime = 86400000;
            var numberOfYears =
                this.selectedYears[1] - this.selectedYears[0] + 1;

            var yearSize = 100 / numberOfYears;
            var daySize = yearSize / 365;

            var firstDayOfYear = new Date("01/01/" + this.selectedYears[0]);

            var translateValue;

            var className = step.category;

            if (this.isLinesView) {
                className = className + " lines";

                var maxCumulatedPostes = 0;
                this.allPostes.forEach(post => {
                    if (post.cumulatedPostes > maxCumulatedPostes) {
                        maxCumulatedPostes = post.cumulatedPostes;
                    }
                });

                var mainAxe = (maxCumulatedPostes / 9) * 6;
                //CALC LINE POSITION INLINE VIEW
                if (
                    step.category !== "employee_ratings" &&
                    step.category !== "employee_rating_job_wishlists" &&
                    step.category !== "employee_rating_occupation_wishlists"
                ) {
                    this.lineTransform = `translate( 0px, ${mainAxe -
                        step.linePosition}px)`;
                }
            } else {
                this.lineTransform = "translate( 0px, 0px)";
                this.stepMinHeight = 80;
            }

            if (
                step.category === "trainings" &&
                step.isInitial === false &&
                !this.isLinesView
            ) {
                this.stepClass = className + " not-initial";
            } else {
                this.stepClass = className;
            }

            if (
                step.category !== "employee_ratings" &&
                step.category !== "employee_rating_job_wishlists" &&
                step.category !== "employee_rating_occupation_wishlists"
            ) {
                var startTime = new Date(step.start);
                var endTime = new Date(step.end);

                // CALC DURATION SIZE
                var duration = endTime.getTime() - startTime.getTime();
                var stepWidth = (duration / dayTime) * daySize;

                this.stepWidth = stepWidth;

                // CALC START POSITION

                translateValue =
                    ((startTime.getTime() - firstDayOfYear.getTime()) /
                        dayTime) *
                    daySize;
                this.stepMarginLeft = translateValue + "%";
                if (this.isLinesView && this.step.lineOpacity) {
                    this.lineOpacity = this.step.lineOpacity;
                    // this.stepOpacity = step.linePosition / 100
                }
            } else {
                var dateTime = new Date(step.date);

                if (step.category === "employee_rating_job_wishlists") {
                    this.star = starPostes;
                    dateTime = new Date();
                }

                if (step.category === "employee_rating_occupation_wishlists") {
                    this.star = starMetier;
                    dateTime = new Date();
                }

                translateValue =
                    ((dateTime.getTime() - firstDayOfYear.getTime()) /
                        dayTime) *
                    daySize;
                this.stepMarginLeft = translateValue + "%";
                if (step.category === "employee_ratings") {
                    this.stepMarginLeft = "calc(" + translateValue + "% - 8px)";
                }

                this.stepWidth = 3;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.step {
    transition: all 0.2s ease-in-out;
    position: absolute;
    align-items: center;
    min-height: 80px;
    pointer-events: none;
    overflow: visible;
    .icon {
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: all;
        transition: all 0.2s ease-in-out;
    }
    .line-hover-area {
        display: flex;
        align-items: center;
        height: 20px;
        width: 100%;
        cursor: pointer;
        pointer-events: all;
        transition: all 0.2s ease-in-out;
    }
    .line {
        height: 2px;
        width: 100%;
        transition: all 0.2s ease-in-out;
    }
    .end {
        height: 32px;
        min-width: 2px;
        transition: all 0.2s ease-in-out;
    }

    &.lines {
        .line-hover-area {
            display: flex;
            align-items: center;
            height: 6px;
            width: 100%;
            cursor: pointer;
            pointer-events: all;
        }
        .line {
            height: 6px;
            width: 100%;
        }
        .end {
            display: none;
        }
    }

    img {
        cursor: pointer;
        pointer-events: all;
        width: 100%;
        height: 100%;
        z-index: 7;
        transform: translateX(20px);
    }

    .card-container {
        position: fixed;
        z-index: 1009;
        .cards {
            display: flex;
            align-items: flex-end;
            .tooltip {
                margin-right: 10px;
            }
        }
    }
}

.trainings {
    .icon {
        width: 20px;
        height: 20px;
        position: absolute;
        left: -8px;
        background-color: $color-formations;
    }
    .line {
        background-color: $color-formations;
    }
    .end {
        background-color: $color-formations;
    }
    &.lines {
        .icon {
            display: none;
        }
    }
    &.not-initial {
        top: 42px;
        .line {
            height: 40px;
            border-style: solid;
            border-width: 0px 2px 2px 0px;
            border-color: transparent $color-formations $color-formations
                transparent;
            border-bottom-right-radius: 8px;
            background-color: transparent;
            transform: translate(3px, -20px);
        }
        .end {
            display: none;
        }
    }
}

.Immersions {
    top: 42px;
    .icon {
        width: 18px;
        height: 18px;
        position: absolute;
        left: -7px;
        background-color: $color-immersions;
        transform: rotate(45deg);
    }
    .line {
        height: 40px;
        border-style: solid;
        border-width: 0px 2px 2px 0px;
        border-color: transparent $color-immersions $color-immersions
            transparent;
        border-bottom-right-radius: 8px;
        transform: translate(4px, -20px);
    }
    .end {
        display: none;
    }
    &.lines {
        top: 0px;
        .icon {
            display: none;
        }
        .line {
            border-style: none;
            border-width: 0px;
            border-color: transparent;
            border-bottom-right-radius: 0px;
            background-color: $color-immersions;
            transform: translate(0px, 0px);
            height: 6px;
            width: 100%;
        }
    }
}

.occupations {
    .icon {
        width: 26px;
        height: 26px;
        z-index: 13;
        position: absolute;
        border-radius: 18px;
        margin-left: 2px;
        background-color: $color-metiers;
        transform: translateX(-13px);
        color: $white;
    }
    .line-hover-area {
        display: none;
    }
    &.lines {
        .icon {
            display: none;
        }
        .line-hover-area {
            display: flex;
            .line {
                background-color: $color-metiers;
                margin-left: 0px;
            }
        }
    }
    .end {
        display: none;
    }
}

.establishments {
    .icon {
        display: none;
    }
    .line {
        background-color: $color-etablissement;
        margin-left: 0px;
    }
    .end {
        background-color: $color-etablissement;
    }
}

.jobs {
    .icon {
        width: 26px;
        height: 26px;
        position: absolute;
        border-radius: 18px;
        left: 0;
        border: 2px solid $color-etablissement;
        color: $color-etablissement;
        background-color: $white;
        transform: translateX(-13px);
        pointer-events: all;
    }
    .end {
        width: 26px;
        height: 26px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        right: 0;
        border: 2px solid $color-etablissement;
        color: $color-etablissement;
        background-color: $white;
        font-size: 12px;
        transform: translateX(12px);
        pointer-events: all;
        cursor: pointer;
        &:hover {
            z-index: 200;
        }
    }
    .line-hover-area {
        pointer-events: none;
        width: 0;
        opacity: 0;
        .line {
            background-color: $color-etablissement;
        }
    }
    &.lines {
        .icon {
            display: none;
        }
        .line-hover-area {
            display: flex;
            opacity: 1;
            pointer-events: all;
            height: 6px;
            .line {
                background-color: #4ec2bd;
                opacity: 0.5;
                margin-left: 0px;
            }
        }
        .end {
            display: none;
        }
    }
}

.employee_ratings {
    .icon {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 8px 0px;
        border-color: $color-bilan transparent transparent transparent;
        z-index: 14;
        position: absolute;
        left: 0;
        transform: translateY(-2px);
    }
    .line-hover-area {
        display: none;
    }
    .end {
        display: none;
    }
    &.lines {
        .icon {
            transform: translateY(-22px);
        }
    }
}

.employee_rating_job_wishlists {
    img {
        transform: translate(15px, 15px);
    }
    .line-hover-area {
        display: none;
    }
    .end {
        display: none;
    }
}

.employee_rating_occupation_wishlists {
    img {
        transform: translate(15px, -15px);
    }
    .line-hover-area {
        display: none;
    }
    .end {
        display: none;
    }
}

.slideInCard {
    animation-duration: 0.2s;
    animation-name: slidein;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
    transform: translate(0px, -50px);
    opacity: 0;
}

@keyframes slidein {
    from {
        transform: translate(0px, 50px) scale(0);
        opacity: 0;
    }

    to {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}
</style>
