<template>
    <div>
        <vs-row>
            <vs-col vs-w="6">
                <vx-card :title="`Radar d'une personne`">
                    <vs-list-item v-for="listItem in entityValues.slice(0, 16)" :key="listItem.id"
                        :title="listItem.title">
                        <label> {{ listItem.rgb }}</label>
                        <input :id="'color_' + listItem.id" v-model="entityValues[listItem.id - 1].rgb" type="color" />
                    </vs-list-item>
                </vx-card>
            </vs-col>
            <vs-col vs-w="6">
                <vx-card :title="`Radar des postes/métiers`">
                    <vs-list-item v-for="listItem in entityValues.slice(16)" :key="listItem.id" :title="listItem.title">
                        <label> {{ listItem.rgb }}</label>
                        <input :id="'color_' + listItem.id" v-model="entityValues[listItem.id - 1].rgb" type="color" />
                    </vs-list-item>
                </vx-card>

                <vx-card class="mt-10">
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="center" style="display: inline-flex; align-items: center">
                            <vs-col vs-w="6">
                                <label style="font-weight: bold">Enregistrer les modifications</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="center" vs-w="6">
                                <vs-button id="saveButton" @click="storeColors()">
                                    <i class="fa fa-floppy-o mr-2"></i>
                                    Enregistrer
                                </vs-button>
                            </vs-col>
                        </vs-col>
                    </vs-row>
                </vx-card>
                <vx-card class="mt-10">
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="center" style="display: inline-flex; align-items: center">
                            <vs-col vs-w="6">
                                <label style="font-weight: bold">Réinitialiser les paramètres</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="center" vs-w="6">
                                <vs-button id="initButton" @click="resetColors()">
                                    <i class="fa fa-refresh mr-2"></i>
                                    Réinitialiser
                                </vs-button>
                            </vs-col>
                        </vs-col>
                    </vs-row>
                </vx-card>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>

export default {
    name: "Colors",
    props: {
        api: {
            type: String,
            required: true,
        },
        colors: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            err: false,
            //If we modifiy the number of color, we would have to modify the indexes in : "Graphics.vue", "ratingChartsHelper.js", "EmployeeInfo.vue"
            defaultColors: [],
        };
    },

    computed: {
        entityValues() {
            if (this.items.length > 0) {
                return this.items;
            }
            return this.defaultColors;
        }
    },

    methods: {

        /**
         * Initialisation des couleurs après que le computed `projectNames` soit disponible
         */
        initDefaultColors() {
            this.defaultColors = this.colors;
        },

        storeColors() {
            this.loading = true;
            let count = 0;
            let error = 0;
            this.entityValues.forEach((item) => {
                window
                    .axios({
                        method: "patch",
                        url: `${this.api}/${item.id}`,
                        data: item,
                    })
                    .then((response) => {
                        count++;

                        if (count == this.entityValues.length) {
                            if (error === 0) {
                                this.loading = false;
                                this.notifySuccess("Paramètres enregistrés !");
                            }
                        }
                    })
                    .catch((err, data) => {
                        this.notifyErrorValidation(err);
                        this.err = err;
                        error++;
                        count++;
                    });
            });
        },

        resetColors() {
            this.loading = true;
            let count = 0;
            let error = 0;

            this.$swal({
                title: `Êtes-vous sûr de vouloir réinitialiser les paramètres ?`,
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.value) {
                    this.defaultColors.forEach((item) => {
                        window
                            .axios({
                                method: "patch",
                                url: `${this.api}/${item.id}`,
                                data: item,
                            })
                            .then((response) => {
                                count++;

                                if (count == this.entityValues.length) {
                                    if (error === 0) {
                                        this.loading = false;
                                        this.notifySuccess("Paramètres enregistrés !");
                                    }
                                }
                            })
                            .catch((err, data) => {
                                this.notifyErrorValidation(err);
                                this.err = err;
                                error++;
                                count++;
                            });
                    });

                    this.loadItems();
                }
            });
        },
    },
    created() {
        this.initDefaultColors();
        this.loadItems();
    },
};
</script>
