<template>
    <div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Titre* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.title"
                    name="title"
                    class="w-full"
                />
                <b v-else>{{ resource.title }}</b>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Type* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-select
                    v-if="type != 'show'"
                    v-model="resource.type_id"
                    name="title"
                    class="w-full"
                >
                    <vs-select-item v-for="(type, i) in types" :key="i" :value="type.id" :text="type.name" />
                </vs-select>
                <b v-else>{{ resource.title }}</b>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Modèle<span v-if="type == 'create'">*</span> :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    type="file"
                    name="model"
                    class="w-full"
                    @change="changeFileHandle"
                />
                <b>
                    <a href="#" @click="downloadFileClick">
                        {{ resource.original_filename }}
                    </a>
                </b>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Visibilité hors admin :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-switch
                    v-model="resource.visibility"
                    name="visibility"
                    :disabled="type == 'show'"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
        type: {
            required: true,
            type: String
        },
        types: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            resource: {},
            log: console.log
        };
    },
    watch: {
        resource: {
            handler() {
                this.$emit("input", this.resource);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        }
    },
    computed: {
        hasError() {
            let error = !this.resource.title || !this.resource.type_id;

            if (this.type == 'create') {
                error = error || !this.resource.model;
            }

            return error;
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },
        changeFileHandle(event) {
            this.resource.model = event.target.files[0];
        },
        downloadFileClick() {
            window.axios({
                method: "get",
                url: `/api/referentiel/parameters/exports/download/${this.resource.original_filename}`,
                responseType: 'blob',
            }).then((response) => {
                let blob = new Blob([response.data], { type: response.headers['content-type'] } );
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = this.resource.original_filename;
                link.dispatchEvent(new MouseEvent('click'));
            })
        }
    },
    created() {
        if (this.value) {
            this.initResource();
        }
    }
}
</script>
