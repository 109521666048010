<template>
    <div class="timeline-container">
        <div class="options-container">
            <br />
            <Legend :set-active-legend="setActiveLegend" :names="projectNames" />
            <br />

            <div class="row">
                <ExportModale :all-employees="allEmployees" />

                <div class="right">
                    <DisplaySwitch
                        :is-lines-view="isLinesView"
                        :set-is-lines-view="setIsLinesView"
                    />
                    <Sort
                        :employees-list="employeesList"
                        :set-all-employees="setAllEmployees"
                    />
                </div>
            </div>
        </div>

        <br />

        <div class="timeline-grid">
            <div class="years-line" />

            <ul class="years-grid">
                <li
                    v-for="year in yearsGrid"
                    :key="year"
                    :style="{ width: yearWidth + '%' }"
                >
                    <p>
                        Janvier
                        <br />
                        {{ year }}
                    </p>
                </li>
                <li
                    v-if="displayToday"
                    class="today"
                    :style="{ width: todayWidth + '%' }"
                >
                    <p>
                        Aujourd'hui
                    </p>
                </li>
            </ul>

            <ul class="users-careers-list">
                <li v-for="employee in allEmployees" :key="employee.employee._id">
                    <UserCareer
                        :is-lines-view="isLinesView"
                        :active-legend="activeLegend"
                        :selected-years="years"
                        :person="employee"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import UserCareer from "./UserCareer";
import Legend from "./Legend.vue";
import ExportModale from "./ExportModale.vue";
import DisplaySwitch from "./DisplaySwitch.vue";
import Sort from "./Sort.vue";

export default {
    name: "TimeLine",

    components: {
        UserCareer,
        Legend,
        ExportModale,
        DisplaySwitch,
        Sort
    },

    props: {
        selectedYears: Array,
        employeesList: Array
    },

    data: function() {
        return {
            log: console.log,
            yearsGrid: this.setYearsGrid(),
            yearWidth: this.setYearWidth(),
            years: this.selectedYears,
            todayWidth: this.setTodayWidth(),
            displayToday: true,
            activeLegend: [],
            isLinesView: true,
            allEmployees: this.employeesList
        };
    },

    watch: {
        employeesList: function() {
            this.allEmployees = this.employeesList;
        },

        selectedYears: function() {
            this.setYearsGrid();
            this.setYearWidth();
            this.setTodayWidth();
            this.years = this.selectedYears;
        },

        todayWidth: function() {
            if (this.todayWidth > 0 && this.todayWidth < 80) {
                this.displayToday = true;
            } else {
                this.displayToday = false;
            }
        }
    },

    methods: {
        setIsLinesView: function() {
            return (this.isLinesView = !this.isLinesView);
        },

        setYearWidth: function() {
            return (this.yearWidth =
                80 / (this.selectedYears[1] - this.selectedYears[0] + 1));
        },

        setTodayWidth: function() {
            var yearWidth =
                80 / (this.selectedYears[1] - this.selectedYears[0] + 1);
            var today = new Date();
            var distFromToday = this.selectedYears[1] - today.getFullYear() + 1;
            return (this.todayWidth =
                yearWidth * distFromToday -
                0.00001 -
                today.getMonth() * (yearWidth / 12) -
                (today.getDate() - 1) * (yearWidth / 365));
        },

        setYearsGrid: function() {
            var year = this.selectedYears[0];
            var endYear = this.selectedYears[1];
            var allYears = [];

            while (year < endYear + 1) {
                allYears.push(year);
                year++;
            }
            return (this.yearsGrid = allYears);
        },

        setActiveLegend: function(array) {
            this.activeLegend = array;
        },

        setAllEmployees: function(array) {
            this.allEmployees = array;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";
@include scrollbar;

.timeline-container {
    width: 100%;
    height: 80%;
    position: relative;
}

.options-container {
    width: 100%;
    .row {
        display: flex;
        justify-content: space-between;
        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
        }
    }
}

.timeline-grid {
    height: 80%;
    position: relative;
    .years-line {
        width: 100%;
        border-bottom: 1px solid $grid-gray;
        margin-top: 50px;
        position: absolute;
    }
    .years-grid {
        padding: 0;
        margin: 0;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 90%;
        border-bottom: 1px solid $grid-gray;
        transition: all 0.2s ease-in-out;
        li {
            transition: all 0.2s ease-in-out;
            list-style: none;
            color: #9ea5aa;
            background-image: linear-gradient(
                $grid-gray 40%,
                rgba(255, 255, 255, 0) 0%
            );
            background-position: left;
            background-size: 1px 8px;
            background-repeat: repeat-y;
            p {
                margin: 0 10px;
                text-align: left;
            }
            &.today {
                position: absolute;
                margin-top: 50px;
                height: calc(100% - 50px);
                background-image: none;
                background-repeat: none;
                background-color: none;
                background: rgb(141, 31, 84);
                background: linear-gradient(
                    90deg,
                    rgba(141, 31, 84, 0.1) 0%,
                    rgba(255, 255, 255, 0) 17%
                );
                border-left: 1px solid $primary;
                pointer-events: none;
                p {
                    position: absolute;
                    bottom: -30px;
                    left: -50px;
                    color: $primary;
                }
            }
        }
    }
}

.users-careers-list {
    position: absolute;
    margin-top: 50px;
    padding: 0;
    width: calc(100% + 10px);
    height: calc(90% - 50px);
    overflow-y: scroll;
    li {
        margin: 1% 0;
        list-style: none;
        display: flex;
    }
}
</style>
