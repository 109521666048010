import { create } from "lodash";

export class Render {

    constructor(source) {
        this.source = source;
    }

    make(value, type) {
        if (!type) {
            return value;
        }

        let rendered = value;

        // Gestion des multi-rendus
        type.split('|').forEach(_render => {

            let method;
            let args;
            let has_args = false;

            // Détection d'arguments
            if (has_args = _render.match(/^([^()]*)\((.*)\)$/)) {
                method = has_args[1];
                args = has_args[2].split(',').map(item => item.replace(' ', ''));
            } else {
                method = _render;
            }

            // Traitement des types de rendu
            switch(method) {
                case 'date_fr':
                    rendered = this.renderDateFr(rendered);
                    break;
                case 'date_time_fr':
                    rendered = this.renderDateTimeFr(rendered);
                    break;
                case 'date_human_fr':
                    rendered = this.renderDateHumanFr(rendered);
                    break;
                case 'published_html':
                    rendered = this.renderPublishedHtml(rendered);
                    break;
                case 'boolean_html':
                    rendered = this.renderBooleanHtml(rendered);
                    break;
                case 'position':
                    rendered = this.renderPosition(rendered, args);
                    break;
                case 'limit':
                    rendered = this.renderLimit(rendered, args);
                    break;
                case 'strip_tags':
                    rendered = this.renderStripTags(rendered);
                    break;
                case 'badge':
                    rendered = this.renderBadge(rendered, args);
                    break;
                case 'component':
                  rendered = this.renderComponent(rendered, args);
                  break;
                case 'thumb':
                    rendered = this.renderThumb(rendered);
                    break;
                case 'monetary':
                    rendered = this.renderMonetary(rendered);
                    break;

            }
        });

        return rendered;
    }

    renderDateFr(value) {
        if (!value) {
            return null;
        }
        var date = new Date(value);
        return date.toLocaleDateString();
    }

    renderDateTimeFr(value) {
        if (!value) {
            return null;
        }
        var date = new Date(value);
        return date.toLocaleString();
    }

    renderDateHumanFr(value) {
        if (!value) {
            return null;
        }

        var date = new Date(value);
        var delta = Math.round((+new Date - date) / 1000);
        var message;

        var minute = 60,
            hour   = minute * 60,
            day    = hour * 24,
            week   = day * 7,
            month  = day * 31,
            year   = day * 365;

        if (delta >= 0) { // Dates passées
            if (delta < 30) {
                message = "À l'instant";
            } else if (delta < minute) {
                message = "Il y a " + delta + ' secondes';
            } else if (delta < 2 * minute) {
                message = 'Il y a 1 minute'
            } else if (delta < hour) {
                message = "Il y a " + Math.floor(delta / minute) + ' minutes';
            } else if (Math.floor(delta / hour) == 1) {
                message = 'Il y a 1 heure'
            } else if (delta < day) {
                message = "Il y a " + Math.floor(delta / hour) + ' heures';
            } else if (delta < day * 2) {
                message = 'Hier';
            } else if (delta < week) {
                message = "Il y a " + Math.floor(delta / day) + ' jours';
            }else if (delta < 2 * week) {
                message = "Il y a 1 semaine";
            } else if (delta < month) {
                message = "Il y a " + Math.floor(delta / week) + ' semaines';
            } else if (delta < 2 * month) {
                message = "Il y a 1 mois";
            } else if (delta < year) {
                message = "Il y a " + Math.floor(delta / month) + ' mois';
            } else if (delta < 2 * year) {
                message = "Il y a 1 an";
            } else {
                message = "Il y a " + Math.floor(delta / year) + ' ans';
            }
        } else { // Dates futures
            if (delta > -30) {
                message = "Dans peu de temps";
            } else if (delta > -minute) {
                message = "Dans " + -delta + ' secondes';
            } else if (delta > 2 * -minute) {
                message = 'Dans 1 minute'
            } else if (delta > -hour) {
                message = "Dans " + Math.floor(-delta / minute) + ' minutes';
            } else if (Math.floor(delta / -hour) == 1) {
                message = 'Dans 1 heure'
            } else if (delta > -day) {
                message = "Dans " + Math.floor(-delta / hour) + ' heures';
            } else if (delta > -day * 2) {
                message = 'Demain';
            } else if (delta > -week) {
                message = "Dans " + Math.floor(-delta / day) + ' jours';
            }else if (delta > 2 * -week) {
                message = "Dans 1 semaine";
            } else if (delta > -month) {
                message = "Dans " + Math.floor(-delta / week) + ' semaines';
            } else if (delta > 2 * -month) {
                message = "Dans 1 mois";
            } else if (delta > -year) {
                message = "Dans " + Math.floor(-delta / month) + ' mois';
            } else if (delta > 2 * -year) {
                message = "Dans 1 an";
            } else {
                message = "Dans " + Math.floor(-delta / year) + ' ans';
            }
        }

        return message;
    }

    renderPublishedHtml(value) {
        if (value == 1 || value == true) {
            return '<i class="fa fa-globe text-success"></i>';
        } else {
            return '<i class="fa fa-eye-slash text-warning"></i>';
        }
    }

    renderBooleanHtml(value) {
        if (value == 1 || value == true) {
            return '<i class="fa fa-check text-success"></i>';
        } else {
            return '<i class="fa fa-times text-danger"></i>';
        }
    }

    renderPosition(value, args) {
        if (args.length != 2) {
            console.error('Type « position » : Nombre d\'arguments invalide.')
        }

        var params = new URLSearchParams(window.location.search);
        var dyn    = new Dynamik(this.source);
        const id   = dyn.getValue(args[0]);
        const pos  = dyn.getValue(args[1]);

        if (params.get('sort') == 'position' && params.get('direction') == 'asc') {
            var html =  '<div class="position-wrap text-nowrap">';
            html += '<i class="fa fa-arrows" aria-hidden="true"></i>';
            html += vsprintf('<span class="position" data-sort="%s" data-id="%s">%s</span>', [pos, id, pos]);

            return html;
        }
        return value;
    }

    renderLimit(value, args) {
        if (args && args.length != 1) {
            args = [70]
        }

        if (value && value.length > args[0]) {
            return value.substring(0, args[0]) + '...';
        }

        return value
    }

    renderStripTags(value) {
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        var comments_and_php_tags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        if (value) {
            return value.replace(comments_and_php_tags, '').replace(tags, '');
        }

        return value;
    }

    /**
     * Conversion d'un texte ou d'une collection en badge(s)
     * @param {collect|string} source
     * @param {array} args
     */
    renderBadge(source, args) {
        var html = '';

        if (typeof source == 'object' && args && args.length >= 2) {
            for (var i in source) {
                var dyn = new Dynamik(source[i]);
                var params = [dyn.getValue(args[0]), dyn.getValue(args[1])];
                html += vsprintf('<span class="badge %s mr-1">%s</span>', params)
            }
        } else if (typeof source == 'string') {
            html = vsprintf('<span class="badge %s mr-1">%s</span>', [args[0], source]);
        }

        return html
    }

    renderComponent(source, args) {
      let html = '';
      let el;
      let component;

      if (typeof source == 'object' && args && args.length >= 2) {
          for (var i in source) {
              var dyn = new Dynamik(source[i]);
              var params = [dyn.getValue(args[0]), dyn.getValue(args[1])];

              component = params[0];
              let value  = params[1];
              el = Vue.compile(`<${component}>${value}</${component}>`)

              el = new Vue({
                  render: el.render,
                  staticRenderFns: el.staticRenderFns
              }).$mount()

              html += el.$el.innerHTML;
          }
      } else if (typeof source == 'string') {
        composent = args[0];
        el = Vue.compile(`<${component}>${source}</${component}>`)

        el = new Vue({
            render: el.render,
            staticRenderFns: el.staticRenderFns
        }).$mount()

        html = el.$el.innerHTML;
      } 

      return html;
    }

    renderThumb(value) {
        if (!value) {
            value = '/images/default.png';
        }

        return sprintf('<img class="thumb-list" src="%s" alt="thumb">', value);
    }

    renderMonetary(value) {
        if (!value) {
            return value;
        }

        if (isNaN(value)) {
            return numeral(0).format('0,0.00 $');
        } else {
            return numeral(value).format('0,0.00 $');
        }

    }

}


if (typeof exports !== 'undefined') {
    exports['Render'] = Render
}
if (typeof window !== 'undefined') {
    window['Render'] = Render

    if (typeof define === 'function' && define['amd']) {
    define(function() {
        return {
            'Render': Render
        }
    })
    }
}

