<template>
  <div  :style="getStyle" class="bg-white" id="lastSelfRatingsExternalCard">
    <vx-card>
    <h2 class="mb-2">
        Dernières auto-évaluations transmises
      </h2>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-table
          no-data-text="Aucune donnée à afficher"
          style="width:100%"
          :data="ratings"
        >
          <template slot="thead">
            <vs-th>Nom</vs-th>
            <vs-th>Prénom</vs-th>
            <vs-th>Service</vs-th>
            <vs-th>Etablissement</vs-th>
            <vs-th>Date transmission</vs-th>
          </template>

          <template slot-scope="{ data }">
              <vs-tr v-for="(item, i) in ratings" :key="i" :data="item">
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + get(item, ':employee.id') + '/bilans'">
                            {{ get(item, ":employee.last_name") }}
                        </a>
                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + get(item, ':employee.id') + '/bilans'">
                            {{ get(item, ":employee.first_name") }}
                        </a>
                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + get(item, ':employee.id') + '/bilans'">
                            {{ get(item, ":employee.services").map(s => s.title).join(", ") }}
                        </a>
                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + get(item, ':employee.id') + '/bilans'">
                            {{ get(item, ":employee.services").map(s => s.establishment.name).join(", ") }}
                        </a>
                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + get(item, ':employee.id') + '/bilans'">
                            {{ get(item, ":updated_at") }}
                        </a>
                    </vs-td>
              </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
    </vx-card>
  </div>
</template>
<script>
import EmployeeRatingForm from '@components/views/rh/gestion/employee/rating/RatingForm'
export default {
  components: {
    EmployeeRatingForm
  },
  data () {
    return {
      ratings:'',
    }
  },
  computed: {
    getStyle () {
      return 'height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;'
    }
  },
  created() {
    this.loadRatings();
  },
  methods: {
    showResource(resource) {
      this.resetResource();
      this.setResource(resource);
      this.showPopup = true;
    },
    setResource(data) {
      this.resource = JSON.parse(JSON.stringify(data));
    },
    resetResource() {
      this.resource = {
          title: null,
          description: null
      };
    },
    loadRatings() {
        window.axios({
            method: 'get',
            url: '/api/gestion/employees/-1/ratings/statuses',
            params: {statuses:[2], perpage: 4, sortBy: {'updated_at': 'desc'}, include: ['employee.services.establishment']},
        })
        .then(response => {
          response.data.data.forEach(element =>
            {
              element['updated_at'] = new Date(element['updated_at']).toLocaleString('fr-FR');
            });
            this.ratings = response.data.data;
        })
        .catch((err) => {
            console.log(err)
            this.notifyError(err, `Une erreur est survenue`)
        })
    },
  }
}
</script>
