<script>
import { Radar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import zoom from "chartjs-plugin-zoom";

const addBackground = {
    // Used to avoid black background on chart export
    id: "custom_canvas_background_color",
    beforeDraw: chart => {
        const ctx = chart.canvas.getContext("2d");
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
};

export default {
    extends: Radar,
    mixins: [reactiveProp],
    props: {
        employee: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Object,
            default: null
        }
    },

    watch: {
        options() {
            this.renderChart(this.chartData, this.options);
            //this.$data._chart.update()
        }
    },

    mounted() {
        this.addPlugin(zoom);
        this.addPlugin(addBackground);
        this.renderChart(this.chartData, this.options);
    }
};
</script>
