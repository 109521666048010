var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { title: "Paramétrer le projet personnalisé" } }, [
        _c(
          "div",
          { staticClass: "mb-10" },
          [
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  { attrs: { "vs-w": "12" } },
                  [
                    _c("p", [
                      _vm._v(
                        "Modifier le nom donné aux projets personnalisés dans l'application (singulier & pluriel):"
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "vx-input-group",
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "textZone",
                            placeholder: "Nom au singulier",
                            "label-placeholder": "Nom au singulier",
                            required: ""
                          },
                          model: {
                            value: _vm.ppNames.singular,
                            callback: function($$v) {
                              _vm.$set(_vm.ppNames, "singular", $$v)
                            },
                            expression: "ppNames.singular"
                          }
                        }),
                        _vm._v(" "),
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "textZone",
                            placeholder: "Nom au pluriel",
                            "label-placeholder": "Nom au pluriel",
                            required: ""
                          },
                          model: {
                            value: _vm.ppNames.plural,
                            callback: function($$v) {
                              _vm.$set(_vm.ppNames, "plural", $$v)
                            },
                            expression: "ppNames.plural"
                          }
                        }),
                        _vm._v(" "),
                        _c("template", { slot: "append" }, [
                          _c(
                            "div",
                            { staticClass: "append-text btn-addon" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    "icon-pack": "feather",
                                    icon: "icon-edit",
                                    disabled: _vm.ppNamesError
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.updateNames.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    Modifier\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-10" },
          [
            _c(
              "vs-row",
              { staticClass: "mb-3" },
              [_c("vs-col", [_c("h5", [_vm._v("Objectifs")])])],
              1
            ),
            _vm._v(" "),
            _vm.items.length < 10
              ? _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-w": "12" } },
                      [
                        _c("p", [
                          _vm._v("Ajouter un état (Autres objectifs) :")
                        ]),
                        _vm._v(" "),
                        _c(
                          "vx-input-group",
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                name: "textZone",
                                placeholder: "Nom",
                                required: ""
                              },
                              model: {
                                value: _vm.state.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.state, "name", $$v)
                                },
                                expression: "state.name"
                              }
                            }),
                            _vm._v(" "),
                            _c("template", { slot: "append" }, [
                              _c(
                                "div",
                                { staticClass: "append-text btn-addon" },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        id: "addTextZone",
                                        "icon-pack": "feather",
                                        icon: "icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createState.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Ajouter\n                                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-w": "12"
                    }
                  },
                  [
                    _vm.states.length > 0
                      ? _c(
                          "vs-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "max-items": "10",
                              data: _vm.statesOrdered,
                              "no-data-text": _vm.$constants.TABLE_NODATA_TEXT
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var data = ref.data
                                    return _vm._l(data, function(item, i) {
                                      return _c(
                                        "tr",
                                        {
                                          key: i,
                                          class:
                                            !!_vm.dragging &&
                                            _vm.dragging.id == item.id
                                              ? "dragging"
                                              : "waiting-drag",
                                          attrs: { data: item }
                                        },
                                        [
                                          _c(
                                            "vs-td",
                                            { attrs: { data: item.id } },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.id) +
                                                  "\n                                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "vs-td",
                                            { attrs: { data: item.name } },
                                            [
                                              _vm.stateEditing == item.id
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "vx-input-group",
                                                        [
                                                          _c("vs-input", {
                                                            staticClass:
                                                              "w-full",
                                                            attrs: {
                                                              name: "textZone",
                                                              placeholder:
                                                                "Nom",
                                                              required: ""
                                                            },
                                                            model: {
                                                              value: item.name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.name"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "append-text btn-addon"
                                                                },
                                                                [
                                                                  _c(
                                                                    "vs-button",
                                                                    {
                                                                      attrs: {
                                                                        "icon-pack":
                                                                          "feather",
                                                                        icon:
                                                                          "icon-edit"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.updateState(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        Enregistrer\n                                                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(item.name) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.items.length > 1
                                            ? _c(
                                                "vs-td",
                                                [
                                                  _c("vs-button", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: "Modifier",
                                                        expression: "'Modifier'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                      "icon-pack": "feather",
                                                      icon: "icon-edit"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.stateEditing =
                                                          item.id
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("vs-button", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: "Supprimer",
                                                        expression:
                                                          "'Supprimer'"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "1rem"
                                                    },
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                      "icon-pack": "feather",
                                                      icon: "icon-trash"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteState(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              25161048
                            )
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v(
                                    "\n                                #\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("vs-th", [
                                  _vm._v(
                                    "\n                                Nom\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "vs-th",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.states.length > 1,
                                        expression: "states.length > 1"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Action\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-10" },
          [
            _c(
              "vs-row",
              { staticClass: "mb-3" },
              [_c("vs-col", [_c("h5", [_vm._v("Synthèse")])])],
              1
            ),
            _vm._v(" "),
            _vm.items.length < 10
              ? _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-w": "12" } },
                      [
                        _c("p", [_vm._v("Ajouter une zone de texte :")]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "vx-input-group",
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                name: "textZone",
                                placeholder: "Nom",
                                required: ""
                              },
                              model: {
                                value: _vm.resource.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.resource, "name", $$v)
                                },
                                expression: "resource.name"
                              }
                            }),
                            _vm._v(" "),
                            _c("template", { slot: "append" }, [
                              _c(
                                "div",
                                { staticClass: "append-text btn-addon" },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        id: "addTextZone",
                                        "icon-pack": "feather",
                                        icon: "icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.createResource.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Ajouter\n                                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-w": "12"
                    }
                  },
                  [
                    _vm.items.length > 0
                      ? _c(
                          "vs-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "max-items": "10",
                              data: _vm.itemsOrdered,
                              "no-data-text": _vm.$constants.TABLE_NODATA_TEXT
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var data = ref.data
                                    return _vm._l(data, function(item, i) {
                                      return _c(
                                        "tr",
                                        {
                                          key: i,
                                          class:
                                            !!_vm.dragging &&
                                            _vm.dragging.id == item.id
                                              ? "dragging"
                                              : "waiting-drag",
                                          attrs: { data: item }
                                        },
                                        [
                                          _c("vs-td", [
                                            _c("div", {
                                              staticClass: "drag-icon",
                                              attrs: { draggable: "true" },
                                              on: {
                                                dragstart: function($event) {
                                                  return _vm.dragStart(
                                                    item,
                                                    $event
                                                  )
                                                },
                                                dragover: function($event) {
                                                  return _vm.dragOver(
                                                    item.order,
                                                    $event
                                                  )
                                                },
                                                drop: function($event) {
                                                  return _vm.drop(
                                                    item.order,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "vs-td",
                                            { attrs: { data: item.order } },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.order) +
                                                  "\n                                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "vs-td",
                                            { attrs: { data: item.name } },
                                            [
                                              _vm.resourceEditing == item.id
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "vx-input-group",
                                                        [
                                                          _c("vs-input", {
                                                            staticClass:
                                                              "w-full",
                                                            attrs: {
                                                              name: "textZone",
                                                              placeholder:
                                                                "Nom",
                                                              required: ""
                                                            },
                                                            model: {
                                                              value: item.name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.name"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "append-text btn-addon"
                                                                },
                                                                [
                                                                  _c(
                                                                    "vs-button",
                                                                    {
                                                                      attrs: {
                                                                        "icon-pack":
                                                                          "feather",
                                                                        icon:
                                                                          "icon-edit"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.updateResource(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        Enregistrer\n                                                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(item.name) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.items.length > 1
                                            ? _c(
                                                "vs-td",
                                                [
                                                  _c("vs-button", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: "Modifier",
                                                        expression: "'Modifier'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                      "icon-pack": "feather",
                                                      icon: "icon-edit"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editResource(
                                                          item.id
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("vs-button", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: "Supprimer",
                                                        expression:
                                                          "'Supprimer'"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "1rem"
                                                    },
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                      "icon-pack": "feather",
                                                      icon: "icon-trash"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteResource(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              1927838882
                            )
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", { staticStyle: { width: "15px" } }),
                                _vm._v(" "),
                                _c("vs-th", [
                                  _vm._v(
                                    "\n                                Ordre\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("vs-th", [
                                  _vm._v(
                                    "\n                                Nom\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "vs-th",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.items.length > 1,
                                        expression: "items.length > 1"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Action\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "vs-row",
              { staticClass: "mb-3" },
              [_c("vs-col", [_c("h5", [_vm._v("Divers")])])],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-row",
              {
                staticClass: "mb-10",
                attrs: { "vs-type": "flex", "vs-w": "12" }
              },
              [
                _c("vs-col", { attrs: { "vs-lg": "3", "vs-sm": "10" } }, [
                  _c("p", [_vm._v("Activer la notation des savoir-faire")])
                ]),
                _vm._v(" "),
                _vm.occupationsSkillsRating
                  ? _c(
                      "vs-col",
                      { attrs: { "vs-lg": "9", "vs-sm": "2" } },
                      [
                        _c("vs-switch", {
                          on: { input: _vm.updateOccupationsSkillsRating },
                          model: {
                            value: _vm.enabledOccupationsSkillsRating,
                            callback: function($$v) {
                              _vm.enabledOccupationsSkillsRating = $$v
                            },
                            expression: "enabledOccupationsSkillsRating"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-row",
              {
                staticClass: "mb-10",
                attrs: { "vs-type": "flex", "vs-w": "12" }
              },
              [
                _c("vs-col", { attrs: { "vs-lg": "3", "vs-sm": "10" } }, [
                  _c("p", [
                    _vm._v(
                      "Activer le tri des compétences dans une Catégorie par ordre de description alphabétique "
                    ),
                    _c("small", [
                      _vm._v("(sinon par id croissant comme sur le Portail)")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "vs-col",
                  { attrs: { "vs-lg": "9", "vs-sm": "2" } },
                  [
                    _c("vs-switch", {
                      on: { input: _vm.updateSkillSortByDescription },
                      model: {
                        value: _vm.enabledSkillSortByDescription,
                        callback: function($$v) {
                          _vm.enabledSkillSortByDescription = $$v
                        },
                        expression: "enabledSkillSortByDescription"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mt-5", attrs: { title: "Paramétrer le bilan" } },
        [
          _c(
            "vs-row",
            { staticClass: "mb-5" },
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "12" } },
                [
                  _c("p", [
                    _vm._v(
                      "Modifier le nom donné aux bilans dans l'application (singulier & pluriel):"
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "vx-input-group",
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          name: "textZone",
                          placeholder: "Nom au singulier",
                          "label-placeholder": "Nom au singulier",
                          required: ""
                        },
                        model: {
                          value: _vm.srNames.singular,
                          callback: function($$v) {
                            _vm.$set(_vm.srNames, "singular", $$v)
                          },
                          expression: "srNames.singular"
                        }
                      }),
                      _vm._v(" "),
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          name: "textZone",
                          placeholder: "Nom au pluriel",
                          "label-placeholder": "Nom au pluriel",
                          required: ""
                        },
                        model: {
                          value: _vm.srNames.plural,
                          callback: function($$v) {
                            _vm.$set(_vm.srNames, "plural", $$v)
                          },
                          expression: "srNames.plural"
                        }
                      }),
                      _vm._v(" "),
                      _c("template", { slot: "append" }, [
                        _c(
                          "div",
                          { staticClass: "append-text btn-addon" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-edit",
                                  disabled: _vm.srNamesError
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.updateNames.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                Modifier\n                            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }