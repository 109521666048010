import Vue from "vue";

Vue.mixin({
    methods: {
        acceptExtensions(extensionList) {
            let accept = []
            extensionList.split(',').forEach(function(ext) {
                if (ext === 'all') {
                    return '*.*'
                }
                else accept.push('.'+ext)
            })

            return accept.join()
        },

        getReadableFileSizeString(fileSizeInBytes) {
            var i = -1;
            var byteUnits = [' ko', ' Mo', ' Go', ' To', 'Po'];
            do {
                fileSizeInBytes /= 1024;
                i++;
            } while (fileSizeInBytes > 1024);

            return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
        },
    }
});
