<template>
    <div class="input-password">
        <vs-input
            v-model="password"
            :success="success"
            :danger="danger"
            :type="type"
            icon="icon icon-lock"
            icon-pack="feather"
            :label-placeholder="placeholder"
            :class="inputClasses"
            :name="name"
            @input="update"
        />
        <span :class="['icon', { active: hasVisiblePassword }]" @click="hasVisiblePassword = !hasVisiblePassword">
            <vs-icon size="15px" :icon="icon" color="rgba(0, 0, 0, 0.4)"></vs-icon>
        </span>
    </div>
</template>

<script>

export default {
    name: 'input-password',
    props: {
        value: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        placeholder: {
            type: [String, null],
            default: null,
        },
        success: {
            type: Boolean,
            default: null,
        },
        danger: {
            type: Boolean,
            default: null,
        },
        inputClasses: {
            type: String,
        }
    },
    created () {
        this.password = this.value
    },
    watch : {
        value (val) {
            this.password = val
        }
    },
    data() {
        return {
            password: null,
            hasVisiblePassword: false
        }
    },
    computed : {
        type () {
            if (this.hasVisiblePassword) {
                return 'text';
            }
            return 'password';
        },
        icon () {
            if (this.hasVisiblePassword) {
                return 'visibility';
            }
            return 'visibility_off';
        },
    },
    methods: {
        update: function () {
            this.$emit('input', this.password)
        },
    }
}

</script>

<style lang="scss" scoped>
.input-password {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .vs-con-input-label {
        width: 100%;
    }

    .vs-con-input-label.is-label-placeholder {
        margin-top: 0;
    }
    .icon {
        user-select: none;
        cursor: pointer;
        line-height: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-100%, -50%);
    }
}
</style>
