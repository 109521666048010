var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timeline-container" }, [
    _c(
      "div",
      { staticClass: "options-container" },
      [
        _c("br"),
        _vm._v(" "),
        _c("Legend", {
          attrs: {
            "set-active-legend": _vm.setActiveLegend,
            names: _vm.projectNames
          }
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ExportModale", {
              attrs: { "all-employees": _vm.allEmployees }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("DisplaySwitch", {
                  attrs: {
                    "is-lines-view": _vm.isLinesView,
                    "set-is-lines-view": _vm.setIsLinesView
                  }
                }),
                _vm._v(" "),
                _c("Sort", {
                  attrs: {
                    "employees-list": _vm.employeesList,
                    "set-all-employees": _vm.setAllEmployees
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "timeline-grid" }, [
      _c("div", { staticClass: "years-line" }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "years-grid" },
        [
          _vm._l(_vm.yearsGrid, function(year) {
            return _c(
              "li",
              { key: year, style: { width: _vm.yearWidth + "%" } },
              [
                _c("p", [
                  _vm._v("\n                    Janvier\n                    "),
                  _c("br"),
                  _vm._v(
                    "\n                    " +
                      _vm._s(year) +
                      "\n                "
                  )
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.displayToday
            ? _c(
                "li",
                {
                  staticClass: "today",
                  style: { width: _vm.todayWidth + "%" }
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n                    Aujourd'hui\n                "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "users-careers-list" },
        _vm._l(_vm.allEmployees, function(employee) {
          return _c(
            "li",
            { key: employee.employee._id },
            [
              _c("UserCareer", {
                attrs: {
                  "is-lines-view": _vm.isLinesView,
                  "active-legend": _vm.activeLegend,
                  "selected-years": _vm.years,
                  person: employee
                }
              })
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }