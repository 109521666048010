var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select" },
    [
      _c("h4", [
        _vm._v(
          "\n        Identifier les personnes en capacité de répondre aux attendus d’un\n        métier, d’un poste ou d’un savoir-faire\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        Pour chaque métier, poste ou savoir-faire sélectionné, ce tableau de\n        bord permet d’identifier les personnes en capacité d’y accéder en\n        sécurité.\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4 mt-3" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12"
                  }
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un métier")
                          ]),
                          _vm._v(" "),
                          _vm.occupationsList.length > 0
                            ? _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.occupationsList,
                                    multiple: false,
                                    "group-values": "occupations",
                                    "group-label": "type",
                                    "group-select": false,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "title",
                                    "select-label": "",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    disabled: _vm.disabledOccupationFilter
                                  },
                                  model: {
                                    value: _vm.occupationFilter,
                                    callback: function($$v) {
                                      _vm.occupationFilter = $$v
                                    },
                                    expression: "occupationFilter"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un poste")
                          ]),
                          _vm._v(" "),
                          _vm.groupedJobsByService.length > 0
                            ? _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.groupedJobsByService,
                                    multiple: false,
                                    "group-values": "jobs",
                                    "group-label": "service",
                                    "group-select": false,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "title",
                                    "select-label": "",
                                    "select-group-label":
                                      "Etablissement/service",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": "",
                                    disabled: _vm.disabledJobFilter
                                  },
                                  model: {
                                    value: _vm.jobFilter,
                                    callback: function($$v) {
                                      _vm.jobFilter = $$v
                                    },
                                    expression: "jobFilter"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un savoir-faire")
                          ]),
                          _vm._v(" "),
                          _vm.shownOccupationSkills
                            ? _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.shownOccupationSkills,
                                    multiple: false,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "titleEstablishment",
                                    "select-label": "",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": ""
                                  },
                                  model: {
                                    value: _vm.occupationskillFilter,
                                    callback: function($$v) {
                                      _vm.occupationskillFilter = $$v
                                    },
                                    expression: "occupationskillFilter"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "vs-row",
                    { staticClass: "checkboxes-container" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.wishlistOnly,
                                callback: function($$v) {
                                  _vm.wishlistOnly = $$v
                                },
                                expression: "wishlistOnly"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Souhaités uniquement\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.checkOnly,
                                callback: function($$v) {
                                  _vm.checkOnly = $$v
                                },
                                expression: "checkOnly"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Accessibles en sécurité\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: {
                                disabled: _vm.disabledFilterByUserRelated
                              },
                              model: {
                                value: _vm.filterByUserRelated,
                                callback: function($$v) {
                                  _vm.filterByUserRelated = $$v
                                },
                                expression: "filterByUserRelated"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Mes savoir-faire uniquement\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12"
                  }
                },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un service")
                          ]),
                          _vm._v(" "),
                          _vm.groupedServicesByEstablishments.length > 0
                            ? _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options:
                                      _vm.groupedServicesByEstablishments,
                                    multiple: true,
                                    "group-values": "services",
                                    "group-label": "establishment",
                                    "group-select": true,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "title",
                                    "select-label": "",
                                    "select-group-label": "L'établissement",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": "",
                                    disabled: _vm.disabledServiceFilter
                                  },
                                  model: {
                                    value: _vm.selectedServices,
                                    callback: function($$v) {
                                      _vm.selectedServices = $$v
                                    },
                                    expression: "selectedServices"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner une personne")
                          ]),
                          _vm._v(" "),
                          _vm
                            .groupedEmployeesByEstablishmentsAndFilteredByServices
                            .length > 0
                            ? _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options:
                                      _vm.groupedEmployeesByEstablishmentsAndFilteredByServices,
                                    multiple: true,
                                    "group-values": "employees",
                                    "group-label": "establishment",
                                    "group-select": true,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "name",
                                    "select-label": "",
                                    "select-group-label": "L'établissement",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": "",
                                    disabled: _vm.disabledEmployeeFilter
                                  },
                                  model: {
                                    value: _vm.employeeFilter,
                                    callback: function($$v) {
                                      _vm.employeeFilter = $$v
                                    },
                                    expression: "employeeFilter"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mt-6" },
        [
          _vm.orderedEmployeesAccessiblityCharts.length > 0
            ? _c("vs-row", [
                _c(
                  "ul",
                  {
                    staticStyle: {
                      columns: "4",
                      "-webkit-columns": "4",
                      "-moz-columns": "4"
                    },
                    attrs: { id: "employee-jobs-show" }
                  },
                  _vm._l(_vm.orderedEmployeesAccessiblityCharts, function(
                    chart
                  ) {
                    return _c(
                      "li",
                      { key: chart.employee.id + Math.random() },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.scrollTo(
                                  "chart-" + chart.employee.id
                                )
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(chart.options.title.text.split(":")[0])
                              )
                            ]),
                            _vm._v(":\n                        "),
                            _c("b", { staticClass: "text-primary" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.round(chart.scoreNumber * 100, 0) + "% "
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        chart.check
                          ? _c("b", { staticStyle: { color: "green" } }, [
                              _vm._v("✓")
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mt-6" },
        [
          _vm.orderedEmployeesAccessiblityCharts.length > 0
            ? _c(
                "vs-row",
                _vm._l(_vm.orderedEmployeesAccessiblityCharts, function(chart) {
                  return _c(
                    "vs-col",
                    {
                      key: chart.employee.id + Math.random(),
                      staticClass: "mt-6",
                      attrs: {
                        id: "chart-" + chart.employee.id,
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "6"
                      }
                    },
                    [
                      _c("rating-chart", {
                        staticClass: "rating",
                        attrs: {
                          employee: chart.employee,
                          options: chart.options,
                          "chart-data": chart.data
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }