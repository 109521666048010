var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            active: _vm.activePrompt,
            "is-valid":
              _vm.password.length > 0 &&
              _vm.validatePassword() &&
              _vm.confirm === _vm.newPassword,
            title: "Compte",
            "cancel-text": "Annuler",
            "accept-text": "Enregistrer"
          },
          on: {
            "update:active": function($event) {
              _vm.activePrompt = $event
            },
            accept: _vm.updatePassword
          }
        },
        [
          _c(
            "vs-row",
            {
              staticClass: "mb-10",
              staticStyle: { "font-size": "1.2em" },
              attrs: { "vs-w": "12" }
            },
            [
              _vm.window.Laravel.user.cgu_accepted_at
                ? _c("vs-col", { staticClass: "text-success" }, [
                    _c("b", [_vm._v("CGU acceptées le :")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDateTime(
                            _vm.window.Laravel.user.cgu_accepted_at
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _c("vs-col", { staticClass: "text-danger" }, [
                    _c("strong", [_vm._v("CGU en attente d'acceptation.")])
                  ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mb-10", attrs: { "vs-w": "12" } },
            [
              _c("vs-col", [
                _c("p", [
                  _vm._v(
                    "\n                Votre mot de passe doit contenir :\n                "
                  ),
                  _c("ul", { staticStyle: { "list-style": "inside" } }, [
                    _c("li", [_vm._v("12 caractères minimum")])
                  ]),
                  _vm._v("\n                Au moins :\n                "),
                  _c("ul", { staticStyle: { "list-style": "inside" } }, [
                    _c("li", [_vm._v("1 majuscule")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("1 minuscule")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("1 chiffre")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("1 caractère spécial")])
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mb-10 mt-10", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-xs": "12"
                  }
                },
                [_c("span", [_vm._v("Mot de passe actuel *:")])]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("input-password", {
                    staticClass: "no-icon-border",
                    attrs: {
                      "vs-lg": "4",
                      success: !!_vm.password.length,
                      danger: !!_vm.localErrors.length,
                      inputClasses: "w-full"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mb-10", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-xs": "12"
                  }
                },
                [_c("span", [_vm._v("Nouveau mot de passe *:")])]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("input-password", {
                    staticClass: "no-icon-border",
                    attrs: {
                      success: !!(
                        _vm.validatePassword() && !_vm.localErrors.length
                      ),
                      danger: !!(
                        _vm.newPassword !== _vm.confirm ||
                        _vm.localErrors.length
                      ),
                      inputClasses: "w-full"
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            { staticClass: "mb-10", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-xs": "12"
                  }
                },
                [_c("span", [_vm._v("Confirmer le nouveau mot de passe *:")])]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-align": "center",
                    "vs-lg": "8",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("input-password", {
                    staticClass: "no-icon-border",
                    attrs: {
                      success: !!(
                        _vm.validatePassword() &&
                        _vm.confirm === _vm.newPassword &&
                        !_vm.localErrors.length
                      ),
                      danger: !!(
                        _vm.newPassword !== _vm.confirm ||
                        _vm.localErrors.length
                      ),
                      inputClasses: "w-full"
                    },
                    model: {
                      value: _vm.confirm,
                      callback: function($$v) {
                        _vm.confirm = $$v
                      },
                      expression: "confirm"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-navbar-wrapper" },
        [
          _c(
            "vs-navbar",
            { staticClass: "navbar-custom", attrs: { color: _vm.navbarColor } },
            [
              _c("feather-icon", {
                staticClass:
                  "sm:inline-flex xl:hidden cursor-pointer mr-1 text-primary",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.showSidebar.apply(null, arguments)
                  }
                }
              }),
              _vm._v(" "),
              _vm.breakpoint != "md" && _vm.user
                ? [
                    _c("ul", { staticClass: "vx-navbar__starred-pages" }, [
                      _c("li", [_vm._v("MON ESPACE SECURISÉ")])
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("vs-spacer"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "the-navbar__user-meta flex items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "text-right leading-tight hidden sm:block" },
                    [
                      _c("p", { staticClass: "font-semibold" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.user
                                ? _vm.user.first_name
                                  ? _vm.user.first_name +
                                    " " +
                                    _vm.user.last_name.toUpperCase()
                                  : _vm.user.name
                                : ""
                            )
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
                    },
                    [
                      _c("div", { staticClass: "con-img ml-3" }, [
                        _c("img", {
                          staticClass:
                            "rounded-full shadow-md cursor-pointer block",
                          attrs: {
                            src: _vm.user.image,
                            alt: "",
                            width: "40",
                            height: "40"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "vs-dropdown-menu",
                        { staticClass: "vx-navbar-dropdown" },
                        [
                          _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                on: {
                                  click: function($event) {
                                    return _vm.changePassword()
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "Edit3Icon",
                                    "svg-classes": "w-4 h-4"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v("Compte")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                on: {
                                  click: function($event) {
                                    return _vm.logout()
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "LogOutIcon",
                                    "svg-classes": "w-4 h-4"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v("Déconnexion")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "form",
                              {
                                staticStyle: { display: "none" },
                                attrs: {
                                  id: "logout-form",
                                  action: _vm.logoutUrl,
                                  method: "POST"
                                }
                              },
                              [
                                _c("input", {
                                  attrs: { type: "hidden", name: "_token" },
                                  domProps: {
                                    value: _vm.window.Laravel.csrfToken
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }