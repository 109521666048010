var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-20" }, [
      _c("div", { staticClass: "user-name" }, [
        _c("p", [
          _vm._v(
            "\n                " +
              _vm._s(_vm.person.employee.first_name) +
              "\n                " +
              _vm._s(_vm.person.employee.last_name) +
              "\n            "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-cards-toggle",
            class: { active: _vm.cardsTimeLineIsActive },
            on: { click: _vm.setCardsTimelineIsActive }
          },
          [_c("img", { attrs: { src: _vm.arrow, alt: "arrow" } })]
        )
      ]),
      _vm._v(" "),
      _vm.cardsTimeLineIsActive
        ? _c("div", { staticClass: "cards-nav" }, [
            _c("button", { staticClass: "prev", on: { click: _vm.prevCard } }, [
              _c("img", { attrs: { src: _vm.arrow, alt: "arrow" } })
            ]),
            _vm._v(" "),
            _c("button", { staticClass: "next", on: { click: _vm.nextCard } }, [
              _c("img", { attrs: { src: _vm.arrow, alt: "arrow" } })
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-80" }, [
      _c("div", { staticClass: "steps-timeline" }, [
        _c(
          "ul",
          [
            !_vm.isLinesView
              ? _c("div", { staticClass: "full-line" })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.career, function(step, index) {
              return _c("CareerStep", {
                key: index,
                attrs: {
                  id: _vm.person.employee._id,
                  "selected-years": _vm.selectedYears,
                  "active-legend": _vm.activeLegend,
                  index: index,
                  step: step,
                  "all-postes": _vm.allPostes,
                  "is-lines-view": _vm.isLinesView,
                  "set-one-tooltip-is-active": _vm.setOneTooltipIsActive,
                  "one-tooltip-is-active": _vm.oneTooltipIsActive,
                  "cards-time-line-is-active": _vm.cardsTimeLineIsActive,
                  "set-active-card": _vm.setActiveCard,
                  "active-card": _vm.activeCard
                }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.cardsTimeLineIsActive
        ? _c("div", { staticClass: "cards-timeline" }, [
            _c(
              "ul",
              {
                style: {
                  transform: _vm.cardsTimeLinePosition
                }
              },
              _vm._l(_vm.allCards, function(card, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    _c("CareerCard", {
                      attrs: {
                        "is-tooltip": false,
                        card: card,
                        "active-card": _vm.activeCard,
                        "selected-card": _vm.selectedCard
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }