var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-type-container" },
    [
      !_vm.showForm
        ? [
            _vm.options.length
              ? _c(
                  "multiselect",
                  {
                    class: _vm.classes,
                    attrs: {
                      options: _vm.options,
                      multiple: false,
                      placeholder: "Recherche ...",
                      "select-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      loading: _vm.loading
                    },
                    model: {
                      value: _vm.fieldValue,
                      callback: function($$v) {
                        _vm.fieldValue = $$v
                      },
                      expression: "fieldValue"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "newOption-link",
                on: {
                  click: function($event) {
                    _vm.showForm = true
                  }
                }
              },
              [
                _vm._v(
                  "\n            + Ajouter un " +
                    _vm._s(_vm.fieldLabel) +
                    "\n        "
                )
              ]
            )
          ]
        : [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { autofocus: "", name: "fieldValue" },
              model: {
                value: _vm.newOption,
                callback: function($$v) {
                  _vm.newOption = $$v
                },
                expression: "newOption"
              }
            }),
            _vm._v(" "),
            _c("vs-button", {
              attrs: {
                color: "primary",
                type: "border",
                icon: "done",
                disabled: !_vm.newOption
              },
              on: { click: _vm.add }
            }),
            _vm._v(" "),
            _c("vs-button", {
              attrs: { color: "primary", type: "border", icon: "clear" },
              on: { click: _vm.cancel }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }