<template>
    <div>
        <vs-row class="mt-20">
            <h3>Licences</h3>
            <table class="table" id="main-licence">
                <thead>
                    <tr>
                        <th>Nb total</th>
                        <th>Nb de licences affectées</th>
                        <th>Nb de licences restant à affecter</th>
                        <th>Date anniversaire du contrat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ licence.quantity }}</td>
                        <td>{{ licence.affected }}</td>
                        <td>{{ licence.still_to_affected }}</td>
                        <td>{{ formatDate(licence.anniversary) }}</td>
                    </tr>
                </tbody>
            </table>
        </vs-row>

        <vs-row class="mt-10">
            <h3>Liste de mes établissements</h3>

            <vs-col vs-align="left" vs-w="6">

                <vs-prompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @accept="storeResource"
                    @close="resetResource"
                >
                    <EstablishmentForm
                        v-model="resource"
                        :type="formType"
                        @error="setFormError"
                    />
                </vs-prompt>

            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-row class="mt-5">
            <vs-col class="w-full p-0">
                <vs-button
                    v-if="can('create', 'establishments')"
                    id="btn-create"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="createResource()"
                >
                    Ajouter un nouvel établissement
                </vs-button>
            </vs-col>
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="items.data"
        >
            <template slot="thead">
                <vs-th width="160"></vs-th>
                <vs-th>
                    <Sort name="name" @sort="eventSortByCol">Établissement</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="type" @sort="eventSortByCol">Type</Sort>
                </vs-th>
                <vs-th>Nb licences affectées</vs-th>
                <vs-th>Nb licences utilisées</vs-th>
                <vs-th>Nb licences disponibles</vs-th>
                <vs-th class="align-end" v-if="!types.loading" v-for="(type, index) in types.data" :key="index">
                    Licence {{ type.name }}
                </vs-th>
                <vs-th ></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                    <vs-td>
                        <img v-if="item.logo" :src="get(item, ':logo')" alt="logo" width="70" height="70">
                        <vs-avatar
                            v-else
                            size="70px"
                            :text="item.name"
                            :color="generateColor(item.name)"
                        />
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":name") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":type") }}
                    </vs-td>
                    <vs-td>
                        <vs-input
                            v-if="canUpdateLicence()"
                            type="text"
                            :value="items.data[i].establishment_licence.quantity"
                            class="w-full"
                            @change="changeLicenceQuantityHandle(item, $event)"
                        />
                        <span v-else>{{ items.data[i].establishment_licence.quantity }}</span>
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":establishment_licence.consumed") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":establishment_licence.available") }}
                    </vs-td>
                    <vs-td align="right" v-if="!types.loading" v-for="(type, index) in types.data" :key="index">
                        <vs-switch
                            v-if="licences[i]"
                            v-model="licences[i][type.slug].active"
                            :disabled="!isASuperAdmin() || type.slug == 'rh' ? true : false"
                            @input="activeLicenceHandle(item, licences[i])"
                        />
                    </vs-td>

                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="isEditable(item)"
                                :id="'btn-update-' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="updateResource(item)"
                            />

                            <vs-button
                                v-if="isDeletable(item)"
                                :id="'btn-delete-' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="deleteResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination
                    :total="Math.ceil(items.total / apiParams.perpage)"
                    v-model="currentPage"
                >
                </vs-pagination>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import EstablishmentForm from "@components/views/rh/referentiel/establishment/EstablishmentForm";

export default {
    name: "Establishment",
    components: {
        EstablishmentForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            licence: window.Laravel.mainLicence,
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            resourceReadonly: true,
            resource: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un établissement',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un établissement',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un établissement',
                    btnAccept: 'filled'
                }
            },
            types: {
                data: [],
                loading: true
            },
            licences: []
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        },

        items() {
            this.loadAdminTypes().then(() => {
                this.parseLicencesWithTypes(this.types.data);
                this.types.loading = false;
            });
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
    },

    methods: {
        async loadAdminTypes() {
            this.types.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/parameters/types",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {
                    this.types.data = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        parseLicencesWithTypes(types) {

            this.items.data.forEach((establishement) => {
                var licenceTypes = {};

                types.forEach((type) => {
                    const currentType = establishement.establishment_licence.types.find(t => t.id == type.id);
                    type.active = currentType ? currentType.pivot.active ? true : false : false;
                    licenceTypes[type.slug] = window._.cloneDeep(type);
                })

                this.licences.push(licenceTypes);

            })
        },

        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'show';
            this.showPopup = true;
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = 'create';
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'update';
            this.showPopup = true;
        },

        storeResource() {
            let data = this.resource;
            data.reference_id = data.reference.id;

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => this.loadItems(),
                    err => (this.showPopup = true),
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => (this.showPopup = true),
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                logoBlob: null,
                photoBlob: null,
                name: null,
                logo: null,
                photo: null,
                address: {},
                reference: null,
                public_announcements: 1,
                visibility: 1,
                description: null,
                establishment_licence: null
            };
        },

        setFormError(value) {
            this.formError = value;
        },

        canUpdateLicence() {
            return this.isASuperAdmin() || ((this.isAnAdmin() || this.isADrh()) && this.user.establishments.length == 0)
        },

        isEditable (item) {
            return (
                this.can('update', 'establishments', item.services.map(s => s.id))
                && (!this.user.establishments.length || this.user.establishments.map(e => e.id).indexOf(item.id) > -1)
            )
        },

        isDeletable (item) {
            return this.can('delete', 'establishments')
        },

        changeLicenceQuantityHandle(item, event) {

            const value = +event.srcElement.value;
            const oldValue = item.establishment_licence.quantity;
            item.establishment_licence.quantity = value;

            // Limite en rapport avec le nombre de licences déjà consommées
            if (value < item.establishment_licence.consumed) {
                item.establishment_licence.quantity = item.establishment_licence.consumed;
            }

            if (this.currentLicencesExceeds()) {
                this.notifyWarning(`Vous ne pouvez pas attribuer plus de licence que disponible (max: ${this.licence.quantity}).`);
                item.establishment_licence.quantity = oldValue;
            } else {
                // Mise à jour du nombre des licences
                axios({
                    method: "patch",
                    url: `/api/referentiel/establishments/${item.id}/licence`,
                    data: { quantity: +item.establishment_licence.quantity }
                })
                .then(response => {
                   this.updateMainLicence(response.data);
                   this.updateQuantityLicences();
                   this.notifySuccess('Nombre de licence ajusté avec succès')
                })
                .catch(err => {
                    console.log(err);
                    this.notifyErrorValidation(err, `Une erreur est survenue`);
                });
            }

        },

        currentLicencesExceeds() {
            let currentLicences = 0;

            this.items.data.forEach(item => {
                currentLicences += item.establishment_licence.quantity;

            })

            return currentLicences > this.licence.quantity;
        },

        updateMainLicence(licence) {
            this.licence = window._.cloneDeep(licence);
        },

        updateQuantityLicences() {
            this.items.data.forEach(item => {
                let available = item.establishment_licence.quantity - item.establishment_licence.consumed

                if (available < 0) {
                    available = 0;
                }
                item.establishment_licence.available = available;
            })
        },

        activeLicenceHandle(item, types) {

            return window
                .axios({
                    method: "patch",
                    url: `/api/referentiel/establishments/${item.id}/licence-types`,
                    data: {
                        types: types
                    }
                })
                .then(response => {
                    this.notifySuccess(`Licence mise à jour avec succès`);
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        }

    },
    created() {
        this.apiParams.include = [
            "reference",
            "services",
            "establishment_licence.types"
        ];
        this.resetResource();
        this.loadItems()
    }
};
</script>

<style scope>
    #main-licence {
        width: 100%;
        border: 3px solid #dbdbdb;
        padding: 10px;
    }

    #main-licence th, #main-licence td {
        font-size: 1.2em;
        text-align: right;
        padding: 6px 10px;
    }
</style>
