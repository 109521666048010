<template>
  <div  :style="getStyle" id="jobsCard">
    <div class="overlayed" :style="images[0][1].exists ? images[0][1].custom : images[0][1].default"></div>
    <vx-card>
        <h2 class="mb-2">
          Métiers & Postes
        </h2>
        <ul style="min-height: 50px;">
          <li>
            <a
              name="cardMetier"
              class="link"
              href="/rh/referentiel/metiers"
            >Consulter un métier</a>
          </li>
          <li>
            <a
              name="cardPoste"
              class="link"
              href="/rh/referentiel/postes"
            >Consulter un poste</a>
          </li>
          <li>
            <a
              name="cardEntreeMetierPoste"
              class="link"
              href="/rh/gestion/tableaux/metiers-postes"
            >Rechercher un profil adapté</a>
          </li>
        </ul>
      </vx-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        api: {
            type: String
        }
    },
    data() {
        return {
            images: [],
            loading: true,
        };
    },
    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
        items(value) {
            this.images = this.getServicesImage(value);
        }
    },
    computed: {
        getStyle () {
            return 'height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;'
        }
    },
    methods: {
    },
    created() {
        this.images = this.servicesImage;
        this.loadItems();
    }
}
</script>

<style lang="scss" scoped>
.overlayed {
    min-height: 200px;
    background-size: cover;
}
</style>
