var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "_x32_",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 512 512",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          staticStyle: { fill: "#098001" },
          attrs: {
            d:
              "M469.402,35.492C334.09,110.664,197.114,324.5,197.114,324.5L73.509,184.176L0,254.336l178.732,222.172\n        l65.15-2.504C327.414,223.414,512,55.539,512,55.539L469.402,35.492z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }