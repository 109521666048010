import Vue from 'vue'

Vue.mixin({
  methods: {
    localLoading(event, container = '#router-content', type = 'default', text = '') {
      let target = event ? event.currentTarget : null
      if (target && target.className.includes('router-link') && target.className.includes('-active')) {
        return
      } else if (target) {
        for (var i = 0; i < target.childNodes.length; i++) {
          if (target.childNodes[i].className.includes('router-link') && target.className.includes('-active') ) {
            break;
          }
        }
      }
      if (document.getElementById('router-content').classList.contains('vs-con-loading__container')) {
        return
      }
      document.getElementById('router-content').classList.add('vs-con-loading__container')
      this.$vs.loading({
        container: container,
        type: type,
        text:text
      })
    },

    endLocalLoading(event, container = '#router-content') {
      setTimeout(() => {
        this.$vs.loading.close(container + ' > .con-vs-loading')
        let routerContent = document.getElementById('router-content')
        if(routerContent) {
          routerContent.classList.remove('vs-con-loading__container')
        } else {
          this.$vs.loading.close()
        }
      }, 50)

    },

    goBack(route) {
      if (route)
        this.$router.push(route)
      else
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    globals() {
      return {
        TABLE_NODATA_TEXT: "Aucune donnée disponible",
      }
    },

    initialState() {
      return JSON.parse(window.__INITIAL_STATE__)
    },

    /**
         * Vérifie si un métier a au moins un savoir-faire avec des compétences
         * @param {Object} occupation
         * @returns Boolean
         */
    occupationWithOnlyOccupationSkillsWithoutSkill(occupation) {
        let withoutSkill = true;

        if (occupation.admin_occupation_skills) {
            occupation.admin_occupation_skills.forEach((aos) => {
                if (aos.adminskills.length > 0) {
                    withoutSkill = false;
                }
            });
        }

        if (occupation.occupation_skills) {

            occupation.occupation_skills.forEach((aos) => {
                if (aos.skills.length > 0) {
                    withoutSkill = false;
                }
            });
        }

        return withoutSkill;
    },
  },

  created() {
    this.$constants = this.globals()
  }
})
