<template>
    <div>
        <vx-card :title="`Paramétrer les types d'expériences professionnelles`">
            <br />
            <br />

            <vs-row>
                <vs-col vs-w="12">
                    <h2>Formations :</h2>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="newShortName0"
                            name="trainingShortName"
                            class="w-full"
                            placeholder="Abbréviation (*)"
                            required
                        />
                        <vs-input
                            v-model="newName0"
                            name="trainingName"
                            class="w-full"
                            placeholder="Nom du type de formation"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    id="addTraining"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    :disabled="!newName0 || !newShortName0"
                                    @click.prevent="createResource(0)"
                                >
                                    Ajouter
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        v-if="trainingTypes.length > 0"
                        :data="trainingTypes"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                        style="width: 100%;"
                    >
                        <template slot="thead">
                            <vs-th>
                                Abbréviation
                            </vs-th>
                            <vs-th>
                                Nom
                            </vs-th>
                            <vs-th>
                                Actions
                            </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in data"
                                :key="i"
                                :data="item"
                            >
                                <vs-td :data="item.short_name">
                                    {{ item.short_name }}
                                </vs-td>
                                <vs-td :data="item.name">
                                    {{ item.name }}
                                </vs-td>
                                <vs-td style="width: 300px;">
                                    <vs-button
                                        :id="'addTraining_' + i"
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        style="margin-left: 1rem;"
                                        @click="
                                            updateResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                    <vs-button
                                        :id="'deleteTraining_' + i"
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        style="margin-left: 1rem;"
                                        @click="
                                            deleteResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col vs-w="12">
                    <h2>Stages / Immersions :</h2>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="newShortName1"
                            name="internshipShortName"
                            class="w-full"
                            placeholder="Abbréviation (*)"
                            required
                        />
                        <vs-input
                            v-model="newName1"
                            name="internshipName"
                            class="w-full"
                            placeholder="Nom du type de stage/immersion"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    id="addInternship"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    :disabled="!newName1 || !newShortName1"
                                    @click.prevent="createResource(1)"
                                >
                                    Ajouter
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        v-if="internshipTypes.length > 0"
                        :data="internshipTypes"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                        style="width: 100%;"
                    >
                        <template slot="thead">
                            <vs-th>
                                Abbréviation
                            </vs-th>
                            <vs-th>
                                Nom
                            </vs-th>
                            <vs-th>
                                Actions
                            </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in data"
                                :key="i"
                                :data="item"
                            >
                                <vs-td :data="item.short_name">
                                    {{ item.short_name }}
                                </vs-td>
                                <vs-td :data="item.name">
                                    {{ item.name }}
                                </vs-td>
                                <vs-td style="width: 300px;">
                                    <vs-button
                                        :id="'addInternship_' + i"
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        style="margin-left: 1rem;"
                                        @click="
                                            updateResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                    <vs-button
                                        :id="'deleteInternship_' + i"
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        style="margin-left: 1rem;"
                                        @click="
                                            deleteResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col vs-w="12">
                    <h2>RAE / VAE :</h2>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="newShortName2"
                            name="raeShortName"
                            class="w-full"
                            placeholder="Abbréviation (*)"
                            required
                        />
                        <vs-input
                            v-model="newName2"
                            name="raeName"
                            class="w-full"
                            placeholder="Nom du type de RAE/VAE (*)"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    id="addRae"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    :disabled="!newName2 || !newShortName2"
                                    @click.prevent="createResource(2)"
                                >
                                    Ajouter
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        v-if="raeTypes.length > 0"
                        :data="raeTypes"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                        style="width: 100%;"
                    >
                        <template slot="thead">
                            <vs-th>
                                Abbréviation
                            </vs-th>
                            <vs-th>
                                Nom
                            </vs-th>
                            <vs-th>
                                Actions
                            </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in data"
                                :key="i"
                                :data="item"
                            >
                                <vs-td :data="item.short_name">
                                    {{ item.short_name }}
                                </vs-td>
                                <vs-td :data="item.name">
                                    {{ item.name }}
                                </vs-td>
                                <vs-td style="width: 300px;">
                                    <vs-button
                                        :id="'addRae_' + i"
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        style="margin-left: 1rem;"
                                        @click="
                                            updateResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                    <vs-button
                                        :id="'deleteRae_' + i"
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        style="margin-left: 1rem;"
                                        @click="
                                            deleteResource(item)
                                        "
                                        :disabled="!canModifiy(item)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col vs-w="12">
                    <h2>Suivi d'accompagnement :</h2>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="newShortName4"
                            name="suiviShortName"
                            class="w-full"
                            placeholder="Abbréviation (*)"
                            required
                        />
                        <vs-input
                            v-model="newName4"
                            name="suiviName"
                            class="w-full"
                            placeholder="Nom du type de Suivi (*)"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    id="addSuivi"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    :disabled="!newName4 || !newShortName4"
                                    @click.prevent="createResource(4)"
                                >
                                    Ajouter
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        v-if="suiviTypes.length > 0"
                        :data="suiviTypes"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                        style="width: 100%;"
                    >
                        <template slot="thead">
                            <vs-th>
                                Abbréviation
                            </vs-th>
                            <vs-th>
                                Nom
                            </vs-th>
                            <vs-th>
                                Actions
                            </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in data"
                                :key="i"
                                :data="item"
                            >
                                <vs-td :data="item.short_name">
                                    {{ item.short_name }}
                                </vs-td>
                                <vs-td :data="item.name">
                                    {{ item.name }}
                                </vs-td>
                                <vs-td style="width: 300px;">
                                    <vs-button
                                        :id="'addSuivi_' + i"
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        style="margin-left: 1rem;"
                                        @click="updateResource(item)"
                                        :disabled="!canModifiy(item)"
                                    />
                                    <vs-button
                                        :id="'deleteSuivi_' + i"
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        style="margin-left: 1rem;"
                                        @click="deleteResource(item)"
                                        :disabled="!canModifiy(item)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
            <!--------------------------------->

            <br />
            <br />

            <vs-row>
                <vs-col vs-w="12">
                    <h2>Habilitations & Certificats :</h2>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="newShortName5"
                            name="certificateShortName"
                            class="w-full"
                            placeholder="Abbréviation (*)"
                            required
                        />
                        <vs-input
                            v-model="newName5"
                            name="certificateName"
                            class="w-full"
                            placeholder="Nom du type d'habilitation / certificat (*)"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    id="addCertificate"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    :disabled="!newName5 || !newShortName5"
                                    @click.prevent="createResource(5)"
                                >
                                    Ajouter
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>

            <br />
            <br />

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        v-if="habilitationTypes.length > 0"
                        :data="habilitationTypes"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                        style="width: 100%;"
                    >
                        <template slot="thead">
                            <vs-th>
                                Abbréviation
                            </vs-th>
                            <vs-th>
                                Nom
                            </vs-th>
                            <vs-th>
                                Actions
                            </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in data"
                                :key="i"
                                :data="item"
                            >
                                <vs-td :data="item.short_name">
                                    {{ item.short_name }}
                                </vs-td>
                                <vs-td :data="item.name">
                                    {{ item.name }}
                                </vs-td>
                                <vs-td style="width: 300px;">
                                    <vs-button
                                        :id="'addSuivi_' + i"
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        style="margin-left: 1rem;"
                                        @click="updateResource(item)"
                                        :disabled="!canModifiy(item)"
                                    />
                                    <vs-button
                                        :id="'deleteSuivi_' + i"
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        style="margin-left: 1rem;"
                                        @click="deleteResource(item)"
                                        :disabled="!canModifiy(item)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-prompt
                    :active.sync="displayPrompt"
                    :is-valid="true"
                    :title="
                        `Modifier ${
                            resource.category === 0
                                ? 'la formation'
                                : (resource.category === 1 ?
                                    'le stage / immersion'
                                    : (resource.category === 2 ?
                                    'la RAE/VAE'
                                    : (resource.category === 4 ? 'le Suivi d\'accompagnement' : 'l\'habilitation/certificat')))
                        }`
                    "
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    @cancel="initResource()"
                    @accept="storeResource()"
                >
                    <div class="con-exemple-prompt">
                        <div class="vx-row mb-6">
                            <div class="vx-col sm:w-1/3 w-full">
                                <span>Abbréviation *:</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <vs-input
                                    v-model="resource.short_name"
                                    name="experienceShortName"
                                    class="w-full"
                                />
                            </div>
                        </div>
                        <div class="vx-row mb-6">
                            <div class="vx-col sm:w-1/3 w-full">
                                <span>Nom *:</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <vs-input
                                    v-model="resource.name"
                                    name="experienceName"
                                    class="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </vs-prompt>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>

export default {
    name: "Parameters",
    props: {
        api: {
            type: String
        }
    },
    data() {
        return {
            resource: {},
            newName0: null,
            newName1: null,
            newName2: null,
            newName4: null,
            newName5: null,
            newShortName0: null,
            newShortName1: null,
            newShortName2: null,
            newShortName4: null,
            newShortName5: null,
            displayPrompt: false,
            loading: false,
            trainingTypes: [],
            internshipTypes: [],
            raeTypes: [],
            suiviTypes: [],
            habilitationTypes: [],
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
        items(value) {
            this.trainingTypes = value.filter(i => i.category === 0)
            this.internshipTypes = value.filter(i => i.category === 1)
            this.raeTypes = value.filter(i => i.category === 2)
            this.suiviTypes = value.filter(i => i.category === 4)
            this.habilitationTypes = value.filter(i => i.category === 5)
        }
    },

    methods: {

        initResource() {
            this.resource = {
                id: null,
                name: null,
                category: null
            };

            this.newName0 = null;
            this.newName1 = null;
            this.newName2 = null;
            this.newName4 = null;
            this.newName5 = null;
            this.newShortName0 = null;
            this.newShortName1 = null;
            this.newShortName2 = null;
            this.newShortName4 = null;
            this.newShortName5 = null;
        },

        canModifiy(item) {
            return this.isASuperAdmin() || !item.readonly;
        },

        createResource(category) {
            if (category === 0) {
                this.resource.name = this.newName0
                this.resource.short_name = this.newShortName0
            }
            else if (category === 1) {
                this.resource.name = this.newName1
                this.resource.short_name = this.newShortName1
            }
            else if (category === 2) {
                this.resource.name = this.newName2
                this.resource.short_name = this.newShortName2
            }
            else if (category === 4) {
                this.resource.name = this.newName4
                this.resource.short_name = this.newShortName4
            }
            else if (category === 5) {
                this.resource.name = this.newName5
                this.resource.short_name = this.newShortName5
            }
            this.resource.category = category;
            this.storeResource();
        },

        updateResource(item) {
            this.resource = JSON.parse(JSON.stringify(item));
            this.displayPrompt = true;
        },

        storeResource() {
            this.loading = true;

            let data = JSON.parse(JSON.stringify(this.resource));

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => {
                        this.loadItems()
                        this.loading = false;
                    },
                    err => (this.showPopup = true)
                );
            } else {
                this.apiCreate(
                    data,
                    resp => {
                        this.loadItems()
                        this.loading = false;
                    },
                    err => (this.showPopup = true)
                );
            }

            this.initResource();
        },

        deleteResource(item) {
            this.apiDelete(item.id, () => this.loadItems());
        }
    },

    created() {
        this.initResource();
        this.loadItems();
    }
};
</script>
