var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.errors.length > 0
          ? _c(
              "vs-alert",
              { attrs: { color: "danger", icon: "new_releases" } },
              _vm._l(_vm.errors, function(error) {
                return _c("span", { key: _vm.errors.indexOf(error) }, [
                  _vm._v(_vm._s(error)),
                  _c("br")
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.success
          ? _c("vs-alert", { attrs: { color: "success", icon: "check" } }, [
              _c("span", [
                _vm._v("Votre mot de passe a été réinitialisé avec succès !")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("vs-input", {
          staticClass: "w-full no-icon-border",
          attrs: {
            name: "email",
            success: _vm.validateEmail() && _vm.errors.length === 0,
            danger:
              _vm.localEmail !== "" &&
              (_vm.errors.length > 0 || !_vm.validateEmail()),
            icon: "icon icon-user",
            "icon-pack": "feather",
            "label-placeholder": "Email"
          },
          model: {
            value: _vm.localEmail,
            callback: function($$v) {
              _vm.localEmail = $$v
            },
            expression: "localEmail"
          }
        }),
        _vm._v(" "),
        _c("input-password", {
          staticClass: "mt-6 no-icon-border",
          attrs: {
            name: "password",
            success: !!(_vm.validatePassword() && !_vm.errors.length),
            danger: !!(_vm.password !== _vm.confirm || _vm.errors.length),
            placeholder: "Mot de passe",
            inputClasses: "w-full"
          },
          model: {
            value: _vm.password,
            callback: function($$v) {
              _vm.password = $$v
            },
            expression: "password"
          }
        }),
        _vm._v(" "),
        _c("input-password", {
          staticClass: "mt-6 no-icon-border",
          attrs: {
            name: "password_confirmation",
            success: !!(
              _vm.validatePassword() &&
              _vm.confirm === _vm.password &&
              !_vm.errors.length
            ),
            danger: !!(_vm.password !== _vm.confirm || _vm.errors.length),
            placeholder: "Confirmer Mot de passe",
            inputClasses: "w-full"
          },
          model: {
            value: _vm.confirm,
            callback: function($$v) {
              _vm.confirm = $$v
            },
            expression: "confirm"
          }
        }),
        _vm._v(" "),
        _c("vs-input", {
          attrs: { type: "hidden", name: "token", value: _vm.token }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-wrap justify-between my-5" }, [
          _c(
            "div",
            { staticClass: "my-5 placeholder" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "float-right",
                  attrs: {
                    id: "reset-password-submit",
                    button: "submit",
                    disabled: !_vm.isValidResetForm
                  }
                },
                [_vm._v("Réinitialiser")]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-card__title mb-8" }, [
      _c("h4", { staticClass: "mb-4" }, [
        _vm._v("Réinitialiser le mot de passe")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Entrez votre email et votre nouveau mot de passe.\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n            Votre mot de passe doit contenir :\n            "
        ),
        _c("ul", [
          _c("li", [_vm._v("12 caractères minimum")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("\n                    au moins :\n                    "),
            _c("ul", [
              _c("li", [_vm._v("1 majuscule")]),
              _vm._v(" "),
              _c("li", [_vm._v("1 minuscule")]),
              _vm._v(" "),
              _c("li", [_vm._v("1 chiffre")]),
              _vm._v(" "),
              _c("li", [_vm._v("1 caractère spécial")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }