<template>
    <div>
        <!-- Objectifs sur savoir faire -->
        <vs-row class="mt-20">
            <vs-col>
                <h5>Objectifs fixés sur les compétences :</h5>
            </vs-col>
        </vs-row>
        <vs-row class="mt-10" v-if="resources.length > 0">
            <vs-col class="w-full">
                <vs-row v-for="(res, i) in resources" :key="i" class="py-5 border-bottom">
                    <vs-col vs-justify="center" vs-align="center" vs-w="4" class="mb-3">
                        <div class="font-bold">Compétence</div>
                        <h4 v-if="res.skill" class="pt-3"><i class="fa fa-hand-o-right mr-2"></i>{{ res.skill.title }}</h4>
                        <Multiselect
                            v-else
                            v-model="res.skill"
                            :options="skillsAvailables"
                            track-by="id"
                            label="title_with_level"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Choisir..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""

                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Niveau actuel</div>
                        {{ getRatingLevelLabel(res.skill) }}
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Objectif</div>
                        <Multiselect
                            v-model="res.level"
                            :options="levelsAvailables"
                            class="multiselect-sm"
                            track-by="note"
                            label="label"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Choisir..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                            :disabled="readonly"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="3" class="mb-3">
                        <div class="font-bold">État</div>
                        <Multiselect
                            v-model="res.state"
                            :options="states"
                            class="multiselect-sm"
                            track-by="id"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Choisir..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                            :disabled="readonly"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="1" class="mb-3">
                        <div class="font-bold ">&nbsp;</div>
                        <vs-button
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            class="mt-1"
                            :disabled="readonly"
                            @click="deleteResource(i)"
                        >
                        </vs-button>
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="4" class="mb-3">
                        <div class="font-bold">Thème</div>
                        <SemiSelectForm
                            v-model="res.theme"
                            classes="multiselect-sm"
                            :availables="themes"
                            field-label="thème"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Date d'échéance</div>
                        <vs-input
                            type="date"
                            v-model="res.due_at"
                            name="due_at"
                            class="w-full"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="2" class="mb-3">
                        <div class="font-bold">Date de notification</div>
                        <vs-input
                            type="date"
                            v-model="res.notify_at"
                            name="notify_at"
                            class="w-full"
                        />
                    </vs-col>

                    <vs-col vs-justify="center" vs-align="center" vs-w="4" class="mb-3">
                        <div class="font-bold">Utilisateurs à notifier</div>
                        <Multiselect
                            v-model="res.notify_users_field"
                            class="multiselect-sm"
                            :options="users.data"
                            :multiple="true"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="full_name"
                            select-label
                            selected-label
                            deselect-label
                            :loading="users.loading"
                        />
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>
        <vs-row class="mt-5">
            <vs-col vs-offset="9" vs-w="3" vs-align="flex-end">
                <vs-button
                    v-if="planAllow('suivi-objectifs')"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    :disabled="!canAdd"
                    @click="addResource"
                >
                    Ajouter un objectif
                </vs-button>
                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        disabled="true"
                    >
                        Ajouter un objectif
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import SemiSelectForm from "@components/form/SemiSelectForm.vue";
import { loadUsers as loadUsersService } from "@components/services/resources.js";
import CrownIcon from '@components/svg/CrownIcon';
export default {
    components: {
        Multiselect, SemiSelectForm, CrownIcon
    },
    props: {
        skills: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean
        },
        themes: {
            type: Array,
            require: true
        },
        states: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            log: console.log,
            resources: [],
            loadUsersService,
            users: {
                data: [],
                loading: false
            }
        }
    },
    created() {
        this.loadUsers().then(() => {
            this.initResources();
        });
    },
    watch: {
        resources: {
            handler: function() {
                this.$emit('input', this.resources)
            },
            deep: true
        }
    },
    computed: {

        /**
         * Liste des notes disponibles
         */
         levelsAvailables() {
            return Object.values(this.ratingSettings.params);
        },

        /**
         * Liste des compétences disponibles
         */
        skillsAvailables() {
            const currentGoalIds = this.resources.map(r => r.skill?.id);
            return this.skills
                .filter(os => !currentGoalIds.includes(os.id))
                .map(os => ({...os, title_with_level: os.title+' ('+this.getShortRatingLevelLabel(os)+')'}))
        },

        canAdd() {
            const hasSkills = this.skills.length > 0;

            return !this.readonly && hasSkills;
        }
    },
    methods: {
        addResource() {
            this.resources.push({
                skill: null,
                level: null,
                state: this.states.find(s => s.id == 1),
                theme: 'Compétence',
                due_at: null,
                notify_at: null,
            });
        },
        deleteResource(indice) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    delete this.resources[indice];
                    this.resources = this.resources.filter(r => r != null);
                }
            })
        },
        initResources() {
            if (this.value && this.value.length > 0) {
                this.resources = this.value.map(r => {
                    if (r.pivot && r.pivot.level) {
                        const level = this.levelsAvailables.find(l => l.note == +r.pivot.level);
                        const skill = this.skills.find(s => s.id == r.id);
                        const notifyUsers = this.users.data.filter(u => {
                            if (r.pivot.notify_users) {
                                return JSON.parse(r.pivot.notify_users).includes(u.id);
                            } else {
                                return false;
                            }
                        });

                        return {
                            level: level,
                            skill: skill,
                            notify_users_field: notifyUsers,
                            theme: r.pivot.theme,
                            due_at: r.pivot.due_at,
                            notify_at: r.pivot.notify_at,
                            state: this.states.find(s => s.id == r.pivot.state)
                        }
                    }
                    return r;
                })
            }
        },

        /**
         * Récupère l'évaluation courante depuis une compétence
         * @param {Object} skill
         */
        getRatingLevelLabel(skill) {
            let currentLevel = 0;
            if (skill) {

                currentLevel = skill.level ? skill.level : this.ratingSettings.NonAcquiredLevel;

                const level = this.levelsAvailables.find(l => l.id == currentLevel);

                if (level) {
                    this.setDefaultGoal(skill);
                    return level.label
                }
            }

            return 'N/A';
        },
        getShortRatingLevelLabel(occSkill) {
            return this.ratingSettings.params[occSkill.level || 1]?.label || ''
        },

        /**
         * Assignement d'un objectif par defaut en fonction de la note en cours
         * @param {Object} skill
         */
        setDefaultGoal(skill) {
            let defaultGoalLevel = 1;
            const currentRating = skill.level
            const currentGoalLevel = this.resources.find(r => r.skill.uid == skill.uid).level;

            if (!currentGoalLevel) {
                if (currentRating) {
                    defaultGoalLevel = currentRating + 1;
                }

                if (defaultGoalLevel > 4) {
                    defaultGoalLevel = 4;
                }

                this.resources.find(r => r.skill.uid == skill.uid).level = this.levelsAvailables.find(l => l.id == defaultGoalLevel);
            }
        },

        /**
         * Changement des utilisateurs pour notifications
         */
         async loadUsers() {
            this.users.loading = true;

            const response = await this.loadUsersService()
            this.users.data = response;
            this.users.loading = false
        }
    }
}
</script>
