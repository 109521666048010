var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "AwardIcon",
              "icon-right": "",
              statistic: _vm.skills.length,
              "statistic-title": "Référentiel Compétences",
              "custom-color": "#FF3399"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "ApertureIcon",
              "icon-right": "",
              statistic: _vm.adminoccupationskills.length,
              "statistic-title": "Référentiel Savoir-faire",
              "custom-color": "#FF3399"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "TargetIcon",
              "icon-right": "",
              statistic: _vm.occupations.length,
              "statistic-title": "Référentiel Métiers",
              "custom-color": "#FF3399"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vx-row mt-10" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "ApertureIcon",
              "icon-right": "",
              statistic: _vm.occupationskills.length,
              "statistic-title": "Savoir-faire",
              "custom-color": "#048B9A"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "ClipboardIcon",
              "icon-right": "",
              statistic: _vm.jobs.length,
              "statistic-title": "Postes",
              color: "success"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vx-row mt-10" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "UsersIcon",
              "icon-right": "",
              statistic: _vm.employees.length,
              "statistic-title": "Personnes",
              "custom-color": "#5A5E6B"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "LayersIcon",
              "icon-right": "",
              statistic: _vm.services.length,
              "statistic-title": "Services",
              color: "warning"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "GridIcon",
              "icon-right": "",
              statistic: _vm.establishments.length,
              "statistic-title": "Établissements",
              color: "danger"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vx-row mt-10" },
      [
        _c("vx-card", [
          _c("h3", [_vm._v("Indicateurs d'activité")]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("p", [_c("b", [_vm._v("Périmètre:")])]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        name: "establishments",
                        options: _vm.establishments,
                        multiple: true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "name",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        loading: _vm.establishmentsLoading
                      },
                      model: {
                        value: _vm.establishmentsFilter,
                        callback: function($$v) {
                          _vm.establishmentsFilter = $$v
                        },
                        expression: "establishmentsFilter"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col sm:w-2/12 w-full pt-2" }, [
                    _c("span", [_vm._v("Du :")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-10/12 w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { type: "date", name: "start_date" },
                        model: {
                          value: _vm.startDateFilter,
                          callback: function($$v) {
                            _vm.startDateFilter = $$v
                          },
                          expression: "startDateFilter"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col sm:w-2/12 w-full pt-2" }, [
                    _c("span", [_vm._v("au :")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-10/12 w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { type: "date", name: "start_date" },
                        model: {
                          value: _vm.endDateFilter,
                          callback: function($$v) {
                            _vm.endDateFilter = $$v
                          },
                          expression: "endDateFilter"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mt-5" }, [
              _c("div", { staticClass: "vx-col sm:w-1/2 w-full" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/12 w-full" },
                    [
                      _c("vs-switch", {
                        model: {
                          value: _vm.includeEmployees,
                          callback: function($$v) {
                            _vm.includeEmployees = $$v
                          },
                          expression: "includeEmployees"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                    _vm._v(
                      "\n                                Personnes\n                            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/12 w-full" },
                    [
                      _c("vs-switch", {
                        model: {
                          value: _vm.includeNotInStaff,
                          callback: function($$v) {
                            _vm.includeNotInStaff = $$v
                          },
                          expression: "includeNotInStaff"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                    _vm._v(
                      "\n                                Stagiaires / Hors effectif\n                            "
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.NbEmployeesInPerimeterAndDate,
                      "statistic-title":
                        "Nombre total de personnes sur la période",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithJobReceptionInEA,
                      "statistic-title":
                        "Nombre de personnes cumulant travail dans un établissement et en entreprise adaptée",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithJobReceptionInClassicalStructure,
                      "statistic-title":
                        "Nombre de personnes cumulant travail dans un établissement et en milieu ordinaire",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithStageHML,
                      "statistic-title":
                        "Nombre de personnes ayant effectué une activité hors les murs",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithStageMAD,
                      "statistic-title":
                        "Nombre de personnes ayant effectué un MAD (Mise à Disposition)",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithStagePMSMP,
                      "statistic-title":
                        "Nombre de personnes ayant effectué une PMSMP (Période de Mise à Situation en Milieu Professionnel)",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c(
                    "ms-tooltip",
                    {
                      attrs: {
                        text: _vm.TextTrainingFranceTravail,
                        position: "top"
                      }
                    },
                    [
                      _c("statistics-card-line", {
                        attrs: {
                          div: true,
                          "icon-right": "",
                          statistic: _vm.WithTrainingFranceTravail,
                          "statistic-title":
                            "Nombre de personnes inscrites à France Travail",
                          "custom-color": "#972F5F"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: "-",
                      "statistic-title": "Durée moyenne d'une MAD",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: "-",
                      "statistic-title": "Durée moyenne d'une PMSMP",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForOrdinary,
                      "statistic-title":
                        "Nombre de personnes parties pour le milieu ordinaire",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForOrdinaryCDD,
                      "statistic-title":
                        "Nombre de personnes parties pour le milieu ordinaire en CDD",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForOrdinaryCDI,
                      "statistic-title":
                        "Nombre de personnes parties pour le milieu ordinaire en CDI",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForOrdinaryInterim,
                      "statistic-title":
                        "Nombre de personnes parties pour le milieu ordinaire en intérim",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPrivate,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur privé",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPrivateCDD,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur privé en CDD",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPrivateCDI,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur privé en CDI",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPrivateInterim,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur privé en intérim",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPublic,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur public",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPublicCDD,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur public en CDD",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPublicCDI,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur public en CDI",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForPublicInterim,
                      "statistic-title":
                        "Nombre de personnes parties pour un employeur public en intérim",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForAdapted,
                      "statistic-title":
                        "Nombre de personnes parties pour une entreprise adaptée",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForAdaptedCDD,
                      "statistic-title":
                        "Nombre de personnes parties pour une entreprise adaptée en CDD",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForAdaptedCDI,
                      "statistic-title":
                        "Nombre de personnes parties pour une entreprise adaptée en CDI",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.LeftForAdaptedInterim,
                      "statistic-title":
                        "Nombre de personnes parties pour une entreprise adaptée en intérim",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: "-",
                      "statistic-title":
                        "Nombre de personnes ayant bénéficié d'une formation prise en charge par l'OPCO",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: "-",
                      "statistic-title":
                        "Nombre de personnes ayant bénéficié d'une formation au titre de leur CPF",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: "-",
                      "statistic-title":
                        "Nombre de personnes ayant bénéficié d'une formation en interne",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
                [
                  _c("statistics-card-line", {
                    attrs: {
                      div: true,
                      "icon-right": "",
                      statistic: _vm.WithTrainingAuto,
                      "statistic-title":
                        "Nombre de personnes ayant bénéficié d'une sensibilisation à l'autodétermination",
                      "custom-color": "#972F5F"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }