var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt mb-6 scrollBug" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "title" },
                  model: {
                    value: _vm.resource.title,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "title", $$v)
                    },
                    expression: "resource.title"
                  }
                })
              : _c("b", [_vm._v(_vm._s(_vm.resource.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? [
                  _c(
                    "div",
                    {
                      ref: "tooltip",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title:
                          "Cette donnée permet de catégoriser vos métiers (Exemple : ROME, RSFP, …) . Vous pouvez sélectionner une valeur existante ou en ajouter une nouvelle."
                      }
                    },
                    [
                      _c("TypeForm", {
                        attrs: { availableTypes: _vm.formOccupationTypes },
                        model: {
                          value: _vm.resource.type,
                          callback: function($$v) {
                            _vm.$set(_vm.resource, "type", $$v)
                          },
                          expression: "resource.type"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _c("span", [_vm._v(_vm._s(_vm.resource.type))])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.sectors.length > 0 && _vm.type != "show"
              ? _c(
                  "multiselect",
                  {
                    attrs: {
                      options: _vm.sectors,
                      multiple: false,
                      placeholder: "Recherche ...",
                      "track-by": "id",
                      label: "title",
                      "select-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      loading: _vm.sectorsLoading
                    },
                    on: { close: _vm.onTouchService },
                    model: {
                      value: _vm.resource.sector,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "sector", $$v)
                      },
                      expression: "resource.sector"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _c("span", [_vm._v(_vm._s(_vm.resource.sector.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-textarea", {
                  attrs: { name: "description" },
                  model: {
                    value: _vm.resource.description,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "description", $$v)
                    },
                    expression: "resource.description"
                  }
                })
              : _c("span", [_vm._v(_vm._s(_vm.resource.description))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.type != "show"
        ? _c(
            "div",
            { staticClass: "vx-row mb-6" },
            [
              _c(
                "vs-checkbox",
                {
                  attrs: { name: "addJobFilterBySector" },
                  model: {
                    value: _vm.filterBySector,
                    callback: function($$v) {
                      _vm.filterBySector = $$v
                    },
                    expression: "filterBySector"
                  }
                },
                [_vm._v("\n            Filière uniquement\n        ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type != "show"
        ? _c("SkillsSelector", {
            attrs: {
              sector: _vm.resource.sector,
              filterBySector: _vm.filterBySector
            },
            model: {
              value: _vm.resource.occupation_skills,
              callback: function($$v) {
                _vm.$set(_vm.resource, "occupation_skills", $$v)
              },
              expression: "resource.occupation_skills"
            }
          })
        : _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
              _c("span", [_vm._v("Compétences appelées par le Métier :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: {
                      "max-height": "300px",
                      search: "",
                      data: _vm.orderedSkills
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(item, i) {
                            return _c(
                              "vs-tr",
                              { key: i, attrs: { data: item } },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: item.description,
                                          placement: "top-start",
                                          classes: ["occ-description"]
                                        },
                                        expression:
                                          "{\n                                content: item.description,\n                                placement: 'top-start',\n                                classes: ['occ-description']\n                            }"
                                      }
                                    ],
                                    attrs: { data: item.title }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.title) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "vs-td",
                                  { attrs: { data: item.category_title } },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.category_title) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "vs-td",
                                  [
                                    _c("vs-radio", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            content: item.level["level_1"],
                                            classes: ["occ-level"]
                                          },
                                          expression:
                                            "{\n                                    content: item.level['level_1'],\n                                    classes: ['occ-level']\n                                }"
                                        }
                                      ],
                                      key: item.id,
                                      attrs: {
                                        color: "primary",
                                        "vs-name": "viewRequiredLevel" + i,
                                        disabled: "",
                                        "vs-value":
                                          _vm.ratingSettings.RequiredLevel.id
                                      },
                                      model: {
                                        value:
                                          _vm.resource.shownSkills[item.id],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.resource.shownSkills,
                                            item.id,
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "\n                                    resource.shownSkills[item.id]\n                                "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "vs-td",
                                  [
                                    _c("vs-radio", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            content: item.level["level_2"],
                                            classes: ["occ-level"]
                                          },
                                          expression:
                                            "{\n                                    content: item.level['level_2'],\n                                    classes: ['occ-level']\n                                }"
                                        }
                                      ],
                                      key: item.id,
                                      attrs: {
                                        disabled: "",
                                        "vs-name": "viewIndispensableLevel" + i,
                                        color: "primary",
                                        "vs-value":
                                          _vm.ratingSettings.IndispensableLevel
                                            .id
                                      },
                                      model: {
                                        value:
                                          _vm.resource.shownSkills[item.id],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.resource.shownSkills,
                                            item.id,
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "\n                                    resource.shownSkills[item.id]\n                                "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "vs-td",
                                  [
                                    _c("vs-radio", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            content: item.level["level_3"],
                                            classes: ["occ-level"]
                                          },
                                          expression:
                                            "{\n                                    content: item.level['level_3'],\n                                    classes: ['occ-level']\n                                }"
                                        }
                                      ],
                                      key: item.id,
                                      attrs: {
                                        disabled: "",
                                        "vs-name": "viewAutonomyLevel" + i,
                                        color: "primary",
                                        "vs-value":
                                          _vm.ratingSettings.AutonomyLevel.id
                                      },
                                      model: {
                                        value:
                                          _vm.resource.shownSkills[item.id],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.resource.shownSkills,
                                            item.id,
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "\n                                    resource.shownSkills[item.id]\n                                "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c(
                          "vs-th",
                          [
                            _c(
                              "Sort",
                              {
                                attrs: { name: "title" },
                                on: { sort: _vm.eventSortByCol }
                              },
                              [_vm._v("Compétence")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-th",
                          [
                            _c(
                              "Sort",
                              {
                                attrs: { name: "category_title" },
                                on: { sort: _vm.eventSortByCol }
                              },
                              [_vm._v("Catégorie")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("vs-th", { staticClass: "space" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.ratingSettings.RequiredLevel
                                  ? _vm.ratingSettings.RequiredLevel.label
                                  : ""
                              ) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("vs-th", { staticClass: "space" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.ratingSettings.IndispensableLevel
                                  ? _vm.ratingSettings.IndispensableLevel.label
                                  : ""
                              ) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("vs-th", { staticClass: "space" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.ratingSettings.AutonomyLevel
                                  ? _vm.ratingSettings.AutonomyLevel.label
                                  : ""
                              ) +
                              "\n                    "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Libellé* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Type* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Filière* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Description :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }