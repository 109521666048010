<template>
    <div class="con-exemple-prompt mb-6 scrollBug">
        <!-- Type -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Type* :</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-if="type != 'show'"
                    v-model="resource.type"
                    :options="['Poste', 'Stage']"
                    :multiple="false"
                    placeholder="Recherche ..."
                    select-label
                    select-group-label
                    selected-label
                    deselect-label
                    deselect-group-label
                >
                    <span slot="noResult">Aucun résultat.</span>
                </multiselect>
                <b v-else>{{ resource.type }}</b>
            </div>
        </div>

        <!-- Titre -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Libellé* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.title"
                    name="title"
                    class="w-full"
                />
                <b v-else>{{ resource.title }}</b>
            </div>
        </div>

        <!-- Service -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Service* :</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-if="servicesList.length > 0 && type != 'show'"
                    v-model="resource.service"
                    :options="servicesList"
                    :multiple="false"
                    group-label="group"
                    group-values="items"
                    :group-select="false"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label
                    select-group-label
                    selected-label
                    deselect-label
                    deselect-group-label
                    @close="onTouchService"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </multiselect>
                <b v-else>{{ resource.service.title }}</b>
            </div>
        </div>

        <!-- Description -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Description :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea
                    v-model="resource.description"
                    name="description"
                    v-if="type != 'show'"
                />
                <b v-else>{{ resource.description }}</b>
            </div>
        </div>

        <!-- Filtres -->
        <div class="vx-row mb-6">
            <vs-checkbox v-model="filterBySector" name="addJobFilterBySector" :disabled="type == 'show'">
                Filière uniquement
            </vs-checkbox>
            <vs-checkbox v-model="filterByUserRelated" name="addJobFilterByUserRelated" :disabled="type == 'show'">
                Mes savoir-faire uniquement
            </vs-checkbox>
        </div>

        <!-- Choix des savoir-faire associés -->
        <skills-selector
            v-model="resource.occupation_skills"
            :service="resource.service"
            :filterBySector="filterBySector"
            :filterByUserRelated="filterByUserRelated"
        />
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SkillsSelector from "@components/form/SkillsSelector";

export default {
    name: "JobForm",
    components: {
        Multiselect,
        SkillsSelector
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            resource : {},
            filterBySector : false,
            filterByUserRelated: false,
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.initResource();
        },
        resource: {
            handler() {
                this.value.title = this.resource.title;
                this.value.type = this.resource.type;
                this.value.description = this.resource.description;
                this.value.service = this.resource.service;
                this.value.occupation_skills = this.resource.occupation_skills;
                this.$emit("input", this.value);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        },
    },
    computed: {
        hasError() {
            let error =
                !this.resource.type ||
                !this.resource.title ||
                !this.resource.service;
            return error;
        },

        servicesList() {
            let servicesUpdatable = this.services.filter(s => this.listOfServicesUpdatableFor("jobs").map(id => id).includes(s.id));
            return this.groupForMultiselect(
                servicesUpdatable,
                ["establishment.name"]
            )
        },
    },
    methods: {
        initResource() {
            this.resource = {
                title: this.value.title,
                type: this.value.type,
                service: this.value.service,
                description: this.value.description,
                occupation_skills: this.value.occupation_skills
            };
        },

        onTouchService() {
            /*if (this.resource.service) {
                this.filterBySector = true;
            }
            else {
                this.filterBySector = false;
            }*/
        }
    },
    created() {
        this.initResource();

        /*if (this.resource.service) {
            this.filterBySector = true;
        }*/
    }
};
</script>

<style lang="scss" scoped></style>
