<template>
    <div>
        <!-- Projets personnalisés -->
        <template v-if="can('read', 'ratings')">
            <vx-card class="mt-10">
                <RatingToPerform :api="urlToperform" />
            </vx-card>

            <vx-card class="mt-10">
                <RatingList
                    :api="urlInprogress"
                    :type-name="projectNames.singular.toLowerCase()"
                    :title="`${projectNames.plural} en cours`"
                    filter="skill_review|=|0"
                    :action="[
                        {
                            permission: (item) => canMulti('update', 'ratings', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/poursuivre/${item.id}`,
                            tooltip: 'Poursuivre',
                            icon: 'icon-edit'
                        }
                    ]"
                />
            </vx-card>

            <vx-card class="mt-10">
                <RatingList
                    :api="urlTovalidate"
                    :type-name="projectNames.singular.toLowerCase()"
                    :title="`${projectNames.plural}  en attente de validation`"
                    filter="skill_review|=|0"
                    :action="[
                        {
                            permission: (item) => canMulti('update', 'rating_validations', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/valider/${item.id}`,
                            tooltip: `Valider le ${projectNames.singular.toLowerCase()}`,
                            icon: 'icon-check-square'
                        },
                        {
                            permission: (item) => can('read', 'ratings'),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/consulter/${item.id}`,
                            tooltip: 'Consulter',
                            icon: 'icon-eye'
                        }
                    ]"
                />
            </vx-card>

            <vx-card class="mt-10">
                <RatingList
                    :api="urlValidated"
                    :type-name="projectNames.singular.toLowerCase()"
                    :title="`${projectNames.plural} validés`"
                    filter="skill_review|=|0"
                    :action="[
                        {
                            permission: (item) => canMulti('read', 'ratings', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/consulter/${item.id}`,
                            tooltip: 'Consulter',
                            icon: 'icon-eye'
                        }
                    ]"
                />
            </vx-card>
        </template>

        <!-- Bilans -->
        <template v-if="can('read', 'skill_reviews')">
            <vx-card class="mt-10">
                <RatingList
                    :api="urlInprogress"
                    :type-name="skillRatingNames.singular.toLowerCase()"
                    :title="`${skillRatingNames.plural} en cours`"
                    filter="skill_review|=|1"
                    :action="[
                        {
                            permission: (item) => canMulti('update', 'skill_reviews', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/poursuivre/${item.id}?skill-review=true`,
                            tooltip: 'Poursuivre',
                            icon: 'icon-edit'
                        }
                    ]"
                />
            </vx-card>

            <vx-card class="mt-10">
                <RatingList
                    :api="urlTovalidate"
                    :type-name="skillRatingNames.singular.toLowerCase()"
                    :title="`${skillRatingNames.plural} en attente de validation`"
                    filter="skill_review|=|1"
                    :action="[
                        {
                            permission: (item) => canMulti('update', 'skill_review_validations', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/valider/${item.id}?skill-review=true`,
                            tooltip: `Valider le ${skillRatingNames.singular.toLowerCase()}`,
                            icon: 'icon-check-square'
                        },
                        {
                            permission: (item) => can('read', 'skill_reviews'),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/consulter/${item.id}?skill-review=true`,
                            tooltip: 'Consulter',
                            icon: 'icon-eye'
                        }
                    ]"
                />
            </vx-card>

            <vx-card class="mt-10">
                <RatingList
                    :api="urlValidated"
                    :type-name="skillRatingNames.singular.toLowerCase()"
                    :title="`${skillRatingNames.plural} validés`"
                    filter="skill_review|=|1"
                    :action="[
                        {
                            permission: (item) => canMulti('read', 'skill_reviews', item.employee.services.map(s => s.id)),
                            href: (item) => `/rh/gestion/personnes/${item.employee.id}/bilans/consulter/${item.id}?skill-review=true`,
                            tooltip: 'Consulter',
                            icon: 'icon-eye'
                        }
                    ]"
                />
            </vx-card>
        </template>

    </div>
</template>

<script>
import RatingToPerform from './partials/RatingsToPerform.vue'
import RatingList from './partials/RatingList.vue';

export default {
    name: 'Ratings',
    components: {
        RatingToPerform,
        RatingList
    },
    props: {
        urlToperform: {
            type: String,
            required: true
        },
        urlInprogress: {
            type: String,
            required: true
        },
        urlTovalidate: {
            type: String,
            required: true
        },
        urlValidated: {
            type: String,
            required: true
        },
    }
}
</script>
