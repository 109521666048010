import Vue from 'vue'

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	let arr = value.split(" ")
	let capitalized_array = []
	arr.forEach((word) => {
		let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
		capitalized_array.push(capitalized)
	})
	return capitalized_array.join(" ");
})

Vue.filter('truncate', function(value, limit) {
	return value.substring(0, limit)
})

Vue.filter('tailing', function(value, tail) {
	return value + tail;
})

Vue.filter('time', function(value, is24HrFormat = false) {
	if(value) {
		const date = new Date(Date.parse(value));
		let hours = date.getHours();
		const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
		if(!is24HrFormat) {
			const time = hours > 12 ? 'AM' : 'PM';
			hours = hours % 12 || 12;
			return hours + ':' + min + ' ' + time
		}
		return hours + ':' + min
	}
})

Vue.filter('date', function(value) {
  let date = new Date(Date.parse(value))
  if (date instanceof Date && isNaN(date))
    return value

  let day = ("0" + date.getDate()).slice(-2)
  let month = ("0" + (date.getMonth() + 1)).slice(-2) //january gives 0
  let year = date.getFullYear()

	return day + '-' + month + '-' + year;
})

Vue.filter('dateTimeAMPM', function(value) {
  let date = new Date(Date.parse(value))
  if (date instanceof Date && isNaN(date))
    return value

  let day = ("0" + date.getDate()).slice(-2)
  let month = ("0" + (date.getMonth() + 1)).slice(-2) //january gives 0
  let year = date.getFullYear()
  let hours = 'am'
  if (date.getHours() >= 12)
    hours = 'pm'

  return day + '-' + month + '-' + year + ' ' + hours;
})

Vue.filter('month', function(val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if(!showYear) {
    return regx.exec(val)[1];
  }else {
    return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
  }

})

Vue.filter('csv', function(value) {
	return value.join(', ')
})

Vue.filter('filter_tags', function(value) {
	return value.replace(/<\/?[^>]+(>|$)/g, "")
})

Vue.filter('k_formatter', function(num) {
	return num > 999 ? (num/1000).toFixed(1) + 'k' : num
})

Vue.filter('currency', function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
})
