// MAIN COLORS - VUESAX THEME COLORS
let colors = {
    primary: '#972F5F',
    success: '#28C76F',
    danger: '#EA5455',
    warning: '#FF9F43',
    dark: '#1E1E1E',
    secondary: '#c3c3c3'
};

import Vue from 'vue';
import Vuesax from 'vuesax';
Vue.use(Vuesax, { theme: { colors } });

const themeConfig = {
    theme: 'light', // options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: '#972F5F', // options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
    navbarType: 'floating', // options[String]: floating(default) / static / sticky / hidden
    footerType: 'static', // options[String]: static(default) / sticky / hidden
    routerTransition: 'fade', // options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
    disableCustomizer: true, // options[Boolean]: true, false(default)
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    disableThemeTour: true // options[Boolean]: true, false(default)
};

export default themeConfig;
