var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt mb-6 scrollBug" },
    [
      _c(
        "vs-row",
        { staticClass: "vx-row mb-6" },
        [
          _c("vs-col", { staticStyle: { width: "calc(37% - 30px)" } }, [
            _c("span", [_vm._v("Savoir-faire* :")])
          ]),
          _vm._v(" "),
          _c("vs-col", { staticStyle: { width: "calc(35% - 30px)" } }, [
            _c("span", [_vm._v("Source :")])
          ]),
          _vm._v(" "),
          _c("vs-col", { staticStyle: { width: "calc(27% - 30px)" } }, [
            _c("span", [_vm._v("Filière* :")])
          ]),
          _vm._v(" "),
          _c("vs-col", { staticStyle: { "min-width": "105px" } })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.resources, function(resource, i) {
        return _c(
          "vs-row",
          { key: i, staticClass: "mb-5" },
          [
            _c(
              "vs-col",
              { staticStyle: { width: "calc(37% - 30px)" } },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large", name: "title" },
                  model: {
                    value: resource.title,
                    callback: function($$v) {
                      _vm.$set(resource, "title", $$v)
                    },
                    expression: "resource.title"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-col",
              { staticStyle: { width: "calc(35% - 30px)" } },
              [
                _c(
                  "Multiselect",
                  {
                    attrs: {
                      options: _vm.sources.data,
                      "track-by": "id",
                      label: "title",
                      "group-values": "items",
                      "group-label": "group",
                      name: "source",
                      placeholder: "Aucune",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": "",
                      loading: _vm.sources.loading
                    },
                    on: {
                      select: function($event) {
                        return _vm.selectSourceHandle($event, resource)
                      }
                    },
                    model: {
                      value: resource.source,
                      callback: function($$v) {
                        _vm.$set(resource, "source", $$v)
                      },
                      expression: "resource.source"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-col",
              { staticStyle: { width: "calc(27% - 30px)" } },
              [
                _c(
                  "Multiselect",
                  {
                    attrs: {
                      options: _vm.sectors.data,
                      "track-by": "id",
                      label: "title",
                      multiple: false,
                      "group-select": false,
                      placeholder: "Recherche ...",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": "",
                      loading: _vm.sectors.loading
                    },
                    model: {
                      value: resource.sector,
                      callback: function($$v) {
                        _vm.$set(resource, "sector", $$v)
                      },
                      expression: "resource.sector"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "vs-col",
              {
                staticClass: "text-center text-nowrap",
                staticStyle: { width: "88px" }
              },
              [
                resource.edit
                  ? _c(
                      "vs-button",
                      {
                        attrs: {
                          radius: "",
                          color: "primary",
                          type: "border",
                          disabled: !_vm.canAddNewLine(resource)
                        },
                        on: { click: _vm.addNewLine }
                      },
                      [_c("i", { staticClass: "fa fa-plus mx-1" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "vs-button",
                  {
                    staticClass: "px-5 py-3",
                    attrs: {
                      color: "primary",
                      type: "border",
                      disabled: !_vm.canRemoveLine()
                    },
                    on: {
                      click: function($event) {
                        return _vm.removeLine(i)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }