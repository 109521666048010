<template>
    <div class="form-authorizations-container vx-row mb-6">
        <div v-if="hasContent" class="vx-col w-full ml-4">
            <div class="vx-row mb-6">
                <b>Habilitations avancées</b>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>En consultation</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>En création / modification</span>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Personnes :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.employees.read"
                        name="employeesRead"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.employees.update"
                        name="employeesUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Gestion des {{ projectNames.plural }} :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.ratings.read"
                        name="ratingsRead"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.ratings.update"
                        name="ratingsUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Validation de {{ projectNames.singular }} :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.validation.read"
                        name="isRatingValidator"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.validation.update"
                        name="validationsUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Gestion des {{ skillRatingNames.plural }} :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.skill_review.read"
                        name="isSkillReview"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.skill_review.update"
                        name="skillReviewUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Validation de {{ skillRatingNames.singular }} :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.skill_review_validation.read"
                        name="isSkillReviewValidator"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.skill_review_validation.update"
                        name="skillReviewValidationsUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Postes :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.jobs.read"
                        name="jobsRead"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="groupedServicesByEstablishments.length > 0"
                        v-model="value.jobs.update"
                        name="jobsUpdate"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="label"
                        select-label=""
                        select-group-label=""
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Compétences :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch v-model="value.skills.read" name="skillsRead" />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.skills.update"
                        name="skillsUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Savoir-Faire :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.occupation_skills.read"
                        name="occupationSkillsRead"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.occupation_skills.update"
                        name="occupationSkillsUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Établissements :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.establishments.read"
                        name="establishmentsRead"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.establishments.update"
                        name="establishmentsUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Services :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.services.read"
                        name="servicesRead"
                    />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.services.update"
                        name="servicesUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Utilisateurs :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch v-model="value.users.read" name="usersRead" />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.users.update"
                        name="usersUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Formation / ressources :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch v-model="value.trainings.read" name="trainingsRead" />
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.trainings.update"
                        name="trainingsUpdate"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Destinataire formulaire de contact :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-switch
                        v-model="value.contact_form.read"
                        name="isFormContact"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "UserAuthorizationsForm",
    components: {
        Multiselect
    },
    props: {
        value: {
            required: true,
            type: Object
        },
        establishments: {
            required: false,
            default: () => []
        },
        serviceslist: {
            required: false,
            default: () => []
        }
    },
    data() {
        return {};
    },
    created() {

    },
    computed: {

        groupedServicesByEstablishments() {
            let toShow = [];
            const establishments = this.establishments;
            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.serviceslist
                        ? this.serviceslist
                              .filter(
                                  service => service.establishment_id === e.id
                              )
                              .map(s => ({
                                  ...s,
                                  label: e.name + " / " + s.title
                              }))
                        : []
                });
            });
            return toShow;
        },

        hasContent() {

            return Object.keys(this.value).length > 0;
        }
    },
    watch: {
        value: {
            handler() {
                this.$emit("input", this.value);
            },
            deep: true
        }
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped></style>
