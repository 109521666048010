<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
            :evaluatedOccupationSkills="value.occupations_skills"
            :display-collapses="true"
            :occupation-skills-goals="value.occupation_skills_goals"

        />

        <vs-row class="mb-6 mt-10">
            <vs-col class="sm:w-1/5 w-full">
                <span>Postes souhaités :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    id="wantedJobs"
                    v-model="resource.wishlist_jobs"
                    :options="jobs"
                    :multiple="true"
                    group-values="jobs"
                    group-label="establishmentService"
                    :group-select="true"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6">
            <vs-col class="sm:w-1/5 w-full">
                <span>Métiers souhaités :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    id="wantedOccupations"
                    v-model="resource.wishlist_occupations"
                    multiple
                    :options="occupationsList"
                    group-label="type"
                    group-values="occupations"
                    :group-select="false"
                    label="title"
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                    placeholder="Recherche ..."
                    track-by="id"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :loading="referentialOccupations.loading"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>
        </vs-row>

        <!-- Objectifs sur savoir-faire -->
        <OccupationSkillsGoal
            :occupation-skills="occupationSkillsAvailables"
            v-model="resource.occupation_skills_goals"
            :readonly="typeForm === 'consultation'"
            :themes="themes.data.filter(t => t != 'Compétence')"
            :states="states.data"
        />

        <!-- Objectifs sur compétences-->
        <SkillsGoal
            :skills="value.skills"
            v-model="resource.skills_goals"
            :readonly="typeForm === 'consultation'"
            :themes="themes.data.filter(t => t != 'Savoir-faire')"
            :states="states.data"
        />

        <!-- Autres objectifs -->
        <OthersGoal
            v-model="resource.others_goals"
            :readonly="typeForm === 'consultation'"
            :states="states.data"
            :themes="themes.data"
        />
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import ratingService from "@components/services/ratings";
import Multiselect from "vue-multiselect";
import OccupationSkillsGoal from "../OccupationSkillsGoal.vue";
import SkillsGoal from "../SkillsGoal.vue";
import OthersGoal from "../OthersGoal.vue";

export default {
    components: {
        Multiselect,
        JobsOccupationsResume,
        OccupationSkillsGoal,
        SkillsGoal,
        OthersGoal
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        jobs: {
            type: Array,
            required: true
        },
        occupations: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            resource: {
                wishlist_jobs: [],
                wishlist_occupations: [],
                occupation_skills_goals: [],
                skills_goals: [],
                others_goals: []
            },
            loading: true,
            referentialOccupations: {
                data: [],
                loading: true
            },
            themes: {
                data: [],
                loading: false
            },
            states: {
                data: [],
                loading: false
            }
        };
    },
    created() {
        this.loadThemes();
        this.loadStates();
        this.initResource();
        this.loadReferentialOccupations();

        if (this.typeForm == 'creation' && this.resource.wishlist_jobs.length == 0 && this.resource.wishlist_occupations.length == 0) {
            this.loadDefaultWishlist();
        }

    },
    computed: {
        occupationsList() {
            const occupationsTypes = window._.cloneDeep(this.occupations);

            // TODO -
            // const centralOccupations = [
            //     ...occupationsTypes.find(o => o.type === 'Centralisé').occupations,
            //     ...this.referentialOccupations.data
            // ];

            // occupationsTypes.find(o => o.type === 'Centralisé').occupations = centralOccupations;

            return occupationsTypes;
        },
        occupationSkillsAvailables() {
            return this.value.occupations_skills.filter(os => os.is_deleted == false)
        }
    },
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.wishlist_jobs = val.wishlist_jobs;
                data.wishlist_occupations = val.wishlist_occupations;
                data.occupation_skills_goals = val.occupation_skills_goals;
                data.admin_occupation_skills_goals = [];
                data.skills_goals = val.skills_goals;
                data.others_goals = val.others_goals;

                this.$emit('input', data);
            },
            deep: true
        }
    },
    methods: {
        initResource() {
            this.resource.wishlist_jobs = window._.cloneDeep(this.value.wishlist_jobs);
            this.resource.wishlist_occupations = window._.cloneDeep(this.value.wishlist_occupations);

            const occupation_skills_goals = window._.cloneDeep(this.value.occupation_skills_goals);
            const admin_occupation_skills_goals = window._.cloneDeep(this.value.admin_occupation_skills_goals);

            this.resource.occupation_skills_goals = occupation_skills_goals.concat(admin_occupation_skills_goals);

            this.resource.skills_goals = window._.cloneDeep(this.value.skills_goals);

            this.resource.others_goals = window._.cloneDeep(this.value.others_goals);
        },

        loadDefaultWishlist() {
            ratingService.getRatingsByStatus({
                employee_id: this.value.employee.id,
                statuses: [-1, 0, 1], // Not in 2, 3
                perpage: 1,
                orderBy: "rating_date",
                direction: "desc",
                include: ['wishlistJobs', 'wishlistOccupations']
            })
                .then(response => {

                    if (response.data && response.data.length > 0) {
                        const lastRating = response.data[0];

                        this.resource.wishlist_jobs = lastRating.wishlist_jobs;
                        this.resource.wishlist_occupations = lastRating.wishlist_occupations;
                    }
                })
        },

        async loadReferentialOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations/referential",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let referentialOccupations = response.data.data
                    this.referentialOccupations.data = referentialOccupations;
                    this.referentialOccupations.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadThemes() {
            this.themes.loading = true;

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/goals/themes`,
                    params: {}
                })
                .then(response => {
                    this.themes.data = response.data;
                    this.themes.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadStates() {
            this.states.loading = true;

            return window
                .axios({
                    method: "get",
                    url: `/api/referentiel/rating-states`,
                    params: {}
                })
                .then(response => {
                    this.states.data = response.data;
                    this.states.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        }
    }
};
</script>
