var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { staticClass: "mb-4", attrs: { "vs-w": "12" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "sm:w-1/5 w-full mb-4",
              attrs: { "vs-type": "flex", "vs-align": "flex-start" }
            },
            [
              _c("span", { staticClass: "font-bold text-lg" }, [
                _vm._v("Postes ou stages occupés :")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "vs-col",
            { staticClass: "sm:w-4/5 w-full" },
            [
              _vm.jobs.length && !_vm.displayCollapses
                ? _vm._l(_vm.jobs, function(job) {
                    return _c(
                      "div",
                      { key: "job-" + job.id, staticClass: "job-container" },
                      [_c("vs-chip", [_c("b", [_vm._v(_vm._s(job.title))])])],
                      1
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.jobs.length && _vm.displayCollapses
                ? [
                    _c(
                      "vs-collapse",
                      _vm._l(_vm.jobs, function(job) {
                        return _c(
                          "vs-collapse-item",
                          {
                            key: "job-" + job.id,
                            staticClass: "job-container"
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("vs-chip", [
                                  _c("b", [_vm._v(_vm._s(job.title))])
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.evaluatedOccupationSkills &&
                            _vm.jobHasEvaluatedOccupationSkills(job.id)
                              ? _c(
                                  "div",
                                  { staticClass: "os-wrapper all-os-wrapper" },
                                  [
                                    _vm.jobHasEvaluatedOccupationSkillsNotMesured(
                                      job.id
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau Non mesuré"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsNotMesured(
                                      job.id
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.jobHasEvaluatedOccupationSkillsNotMesured(
                                                job.id
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsOnGoing(
                                      job.id
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau En cours"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsOnGoing(
                                      job.id
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.jobHasEvaluatedOccupationSkillsOnGoing(
                                                job.id
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsAcquired(
                                      job.id
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v("Savoir-faire niveau Acquis")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsAcquired(
                                      job.id
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.jobHasEvaluatedOccupationSkillsAcquired(
                                                job.id
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsAutonomy(
                                      job.id
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau Autonomie"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasEvaluatedOccupationSkillsAutonomy(
                                      job.id
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.jobHasEvaluatedOccupationSkillsAutonomy(
                                                job.id
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.jobHasCommentedOrGoaledOccupationSkills(
                                      job.id
                                    ).length
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "vs-row",
                                              {
                                                staticClass: "mb-4",
                                                attrs: { "vs-w": "12" }
                                              },
                                              [
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Synthèse des objectifs et commentaires"
                                                    )
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "vs-table",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "min-width": "100%"
                                                    },
                                                    attrs: {
                                                      data: _vm.jobHasCommentedOrGoaledOccupationSkills(
                                                        job.id
                                                      )
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var data = ref.data
                                                            return _vm._l(
                                                              data,
                                                              function(
                                                                occupationSkill,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "vs-tr",
                                                                  {
                                                                    key:
                                                                      "row-os-" +
                                                                      job.id +
                                                                      "-" +
                                                                      i
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            occupationSkill.title
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getRatingLevelLabel(
                                                                              occupationSkill.level
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm.getOccSkillGoal(
                                                                          occupationSkill
                                                                        ).level
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                        " +
                                                                                    _vm._s(
                                                                                      _vm.getRatingLevelLabel(
                                                                                        _vm.getOccSkillGoal(
                                                                                          occupationSkill
                                                                                        )
                                                                                          .level
                                                                                          .id
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                    "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            occupationSkill.comment
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "thead" },
                                                      [
                                                        _c("vs-th", [
                                                          _vm._v("Savoir-faire")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v("Evalué")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v(
                                                            "Objectif fixé"
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v("Commentaires")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.jobs.length
                ? [_c("p", { staticClass: "empty" }, [_vm._v("/")])]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-4", attrs: { "vs-w": "12" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "sm:w-1/5 w-full",
              attrs: { "vs-type": "flex", "vs-align": "flex-start" }
            },
            [
              _c("span", { staticClass: "font-bold text-lg" }, [
                _vm._v("Métiers occupés ou en préparation :")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "vs-col",
            {
              staticClass: "sm:w-4/5 w-full",
              attrs: { "vs-type": "flex", "vs-align": "center" }
            },
            [
              _vm.occupations.length && !_vm.displayCollapses
                ? _vm._l(_vm.occupations, function(occupation) {
                    return _c(
                      "div",
                      {
                        key: "occupation-" + occupation.id,
                        staticClass: "occupation-container"
                      },
                      [
                        _c("vs-chip", [
                          _c("b", [_vm._v(_vm._s(occupation.title))])
                        ])
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.occupations.length && _vm.displayCollapses
                ? [
                    _c(
                      "vs-collapse",
                      _vm._l(_vm.occupations, function(occupation) {
                        return _c(
                          "vs-collapse-item",
                          {
                            key: "occupation-" + occupation.id,
                            staticClass: "occupation-container"
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("vs-chip", [
                                  _c("b", [_vm._v(_vm._s(occupation.title))])
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.evaluatedOccupationSkills &&
                            _vm.occupationHasEvaluatedOccupationSkills(
                              occupation
                            )
                              ? _c(
                                  "div",
                                  { staticClass: "os-wrapper all-os-wrapper" },
                                  [
                                    _vm.occupationHasEvaluatedOccupationSkillsNotMesured(
                                      occupation
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau Non mesuré"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsNotMesured(
                                      occupation
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.occupationHasEvaluatedOccupationSkillsNotMesured(
                                                occupation
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsOnGoing(
                                      occupation
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau En cours"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsOnGoing(
                                      occupation
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.occupationHasEvaluatedOccupationSkillsOnGoing(
                                                occupation
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsAcquired(
                                      occupation
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v("Savoir-faire niveau Acquis")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsAcquired(
                                      occupation
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.occupationHasEvaluatedOccupationSkillsAcquired(
                                                occupation
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsAutonomy(
                                      occupation
                                    ).length
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Savoir-faire niveau Autonomie"
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasEvaluatedOccupationSkillsAutonomy(
                                      occupation
                                    ).length
                                      ? _c(
                                          "div",
                                          { staticClass: "os-container" },
                                          [
                                            _vm._l(
                                              _vm.occupationHasEvaluatedOccupationSkillsAutonomy(
                                                occupation
                                              ),
                                              function(os, j) {
                                                return [
                                                  _c("p", { key: os.uid }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(os.title) +
                                                        "\n                                    "
                                                    )
                                                  ])
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.occupationHasCommentedOrGoaledOccupationSkills(
                                      occupation
                                    ).length
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "vs-row",
                                              {
                                                staticClass: "mb-4",
                                                attrs: { "vs-w": "12" }
                                              },
                                              [
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Synthèse des objectifs et commentaires"
                                                    )
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "vs-table",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      data: _vm.occupationHasCommentedOrGoaledOccupationSkills(
                                                        occupation
                                                      )
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var data = ref.data
                                                            return _vm._l(
                                                              data,
                                                              function(
                                                                occupationSkill,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "vs-tr",
                                                                  {
                                                                    key:
                                                                      "row-os-" +
                                                                      occupation.id +
                                                                      "-" +
                                                                      i
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            occupationSkill.title
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getRatingLevelLabel(
                                                                              occupationSkill.level
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getRatingLevelLabel(
                                                                              _vm.getOccSkillGoal(
                                                                                occupationSkill
                                                                              )
                                                                                .level
                                                                                .id
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            occupationSkill.comment
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "thead" },
                                                      [
                                                        _c("vs-th", [
                                                          _vm._v("Savoir-faire")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v("Evalué")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v(
                                                            "Objectif fixé"
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("vs-th", [
                                                          _vm._v("Commentaires")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.occupations.length
                ? [_c("p", { staticClass: "empty" }, [_vm._v("/")])]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.displayWishes
        ? _c(
            "vs-row",
            { staticClass: "mb-4", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:w-1/5 w-full",
                  attrs: { "vs-type": "flex", "vs-align": "flex-start" }
                },
                [
                  _c("span", { staticClass: "font-bold text-lg" }, [
                    _vm._v("Postes souhaités :")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                { staticClass: "sm:w-4/5 w-full" },
                [
                  _vm.jobsWish.length
                    ? _vm._l(_vm.jobsWish, function(jobWish) {
                        return _c("vs-chip", { key: "jobWish-" + jobWish.id }, [
                          _c("b", [_vm._v(_vm._s(jobWish.title))])
                        ])
                      })
                    : [_c("p", { staticClass: "empty" }, [_vm._v("/")])]
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayWishes
        ? _c(
            "vs-row",
            { staticClass: "mb-4", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:w-1/5 w-full",
                  attrs: { "vs-type": "flex", "vs-align": "flex-start" }
                },
                [
                  _c("span", { staticClass: "font-bold text-lg" }, [
                    _vm._v("Métiers souhaités :")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                { staticClass: "sm:w-4/5 w-full" },
                [
                  _vm.occupationsWish.length
                    ? _vm._l(_vm.occupationsWish, function(occupationWish) {
                        return _c(
                          "vs-chip",
                          { key: "occupationWish-" + occupationWish.id },
                          [_c("b", [_vm._v(_vm._s(occupationWish.title))])]
                        )
                      })
                    : [_c("p", { staticClass: "empty" }, [_vm._v("/")])]
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }