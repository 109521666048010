<template>
    <div>
        <vs-row id="ratingWrapper">
            <vs-col class="w-full">
                <vs-table
                    search
                    :data="resources"
                    :no-data-text="loading ? 'Changement en cours...' : 'Aucun élément disponible'"
                    class="rating-table"
                    :expanded="expandedChange"
                >
                    <template slot="header">
                        <slot />
                        <vs-checkbox v-model="expandAll">
                            Afficher tous les commentaires saisis
                        </vs-checkbox>
                    </template>

                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th :sort-key="col1.value">{{ col1.label }}</vs-th>
                        <vs-th :sort-key="col2.value">{{ col2.label }}</vs-th>
                        <vs-th :sort-key="col3.value" v-if="col3">{{ col3.label }}</vs-th>
                        <vs-th v-for="(param, i) in ratingParams" :key="i">
                            {{ param.label }}
                        </vs-th>
                        <vs-th></vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <template v-for="(item, i) in data" >
                            <vs-tr :key="i" :data="item" :class="[(item.multiple || item.is_deleted) ? 'prefilled': '']" :data-uid="item.uid">
                                <template v-if="(!item.is_deleted || showDeleted) && (!item.is_hidden || showHidden)">
                                    <vs-td>
                                        <feather-icon v-if="expanded[item.uid]" icon="ChevronDownIcon" class="expand-icon" @click="clickExpandHandle(item)" title="Cliquer pour acceder au commentaire"/>
                                        <feather-icon v-else icon="ChevronRightIcon" class="expand-icon" @click="clickExpandHandle(item)" title="Cliquer pour dérouler"/>
                                    </vs-td>
                                    <vs-td>
                                        <vx-tooltip v-if="col1 && col1.title" :text="item[col1.title]" position="top" v-html="item[col1.value]"></vx-tooltip>
                                        <span v-else-if="col1" v-html="item[col1.value]"></span>
                                    </vs-td>
                                    <vs-td>
                                        <vx-tooltip v-if="col2 &&col2.title" :text="item[col2.title]" position="top" v-html="item[col2.value]"></vx-tooltip>
                                        <span v-else-if="col2" v-html="item[col2.value]"></span>
                                    </vs-td>
                                    <vs-td v-if="col3">
                                        <vx-tooltip v-if="col3 && col3.title" :text="item[col3.title]" position="top" v-html="item[col3.value]"></vx-tooltip>
                                        <span v-else-if="col3" v-html="item[col3.value]" @click="clickExpandHandle(item)"></span>
                                    </vs-td>
                                    <vs-td v-for="(param, index) in ratingParams" :key="`os-tenant-${i}-level-${index + 1}`" class="center">
                                        <vs-radio
                                            :id="`${name}-level-${param.id}_${item.uid}`"
                                            :key="item.uid"
                                            v-model="item.level"
                                            v-tooltip="(item.level == index + 1 && item.to_validate) ? item.message :
                                                (name == 'occupation-skills' ? param.description : getSkillLevelMessageById(item.id, param.id))"
                                            :vs-name="item.uid"
                                            :color="item.to_validate ? 'warning' : 'primary'"
                                            :data-occskillid="item.id"
                                            :vs-value="parseFloat(param.id)"
                                            :disabled="typeForm === 'validation' || typeForm === 'consultation' || item.is_deleted"
                                            @input="inputNotationHandle(item.uid)"
                                        />
                                    </vs-td>
                                    <vs-td>
                                        {{ item.is_multiple ? 'Multiple' : ''}}
                                        {{ item.is_deleted  ? 'Supprimé' : ''}}
                                        {{ item.is_updated ? 'Mise à jour' : '' }}
                                        {{ (item.to_validate && !skillReview) ? 'A valider' : '' }}
                                    </vs-td>
                                </template>
                            </vs-tr>

                            <vs-tr v-if="expanded[item.uid] && (!item.is_deleted || showDeleted) && (!item.is_hidden || showHidden)">
                                <vs-td colspan="8">
                                    <vs-textarea v-model="item.comment" label="Commentaire" @blur="focusOutCommentHandle(item)"/>
                                </vs-td>
                            </vs-tr>
                        </template>

                    </template>
                </vs-table>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        name: {
            type: String,
            required: true
        },
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            required: true
        },
        col1: {
            type: Object,
            required: true
        },
        col2: {
            type: Object,
            required: true
        },
        col3: {
            type: Object,
            require: false
        },
        showDeleted: {
            type: Boolean,
            required: false,
            default: false
        },
        showHidden: {
            type: Boolean,
            require: false,
            default: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            resources: [],
            expanded: {},
            expandedChange: 0,
            expandAll: false,
            skills: []
        };
    },
    created() {
        if (this.loading) {
            setTimeout(() => {
                if(document.getElementById('ratingWrapper') && this.loading) {
                    this.$vs.loading({
                        container: document.getElementById('ratingWrapper'),
                    });
                }
            }, 1);
        }

        if (this.name != 'occupation-skills') {
            this.loadSkills();
        }

        this.initResource();
    },
    computed: {
        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {

                const params = Object.keys(this.ratingSettings.params).map(id => {
                    var param = this.ratingSettings.params[id];
                    param.id = id;
                    if (this.name == 'occupation-skills') {
                        switch (param.id) {
                            case "1":
                                param.description =
                                    `Le savoir faire n'est pas évalué`;
                                break;
                            case "2":
                                param.description =
                                `Le savoir faire n'est pas ou peu maitrisé`;
                                break;
                            case "3":
                                param.description =
                                `Le savoir faire est maitrisé avec l'aide de l'encadrant`;
                                break;
                            case "4":
                                param.description =
                                `Le savoir faire est maitrisé sans l'aide d'un encadrant mais un gabarit, un modèle... peut être mis en place`;
                                break;
                            default:
                                param.description =
                                `Niveau de notation du savoir faire`;
                                break;
                        }

                    }
                    return param;
                });

                return params;
            }
            return [];
        }
    },
    watch: {
        value(value) {
            this.initResource();
        },
        loading(value) {
            if (value) {
                this.$vs.loading(document.getElementById('ratingWrapper'));
            }
            this.$vs.loading.close(document.getElementById('ratingWrapper'));
        },
        expandAll(value) {
            this.resources.forEach(r => {

                if (r.comment) {
                    this.expanded[r.uid] = value;
                    this.expandedChange++;
                }

            })
        }

    },
    methods: {
        initResource() {
            this.resources = JSON.parse(JSON.stringify(this.value));
        },

        clickExpandHandle(item) {
            this.expanded[item.uid] = this.expanded[item.uid] ? false : true
            this.expandedChange++;
        },

        async loadSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: ['level'] }
                })
                .then(response => {
                    this.skills = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getSkillLevelMessageById(id, levelId) {
            const level = levelId -1

            return data_get(this.skills.find(s => s.id == id), `level.level_${level}`);
        },

        /**
         * Évenement d'une notation
         * @param {string} uid
         */
        inputNotationHandle(uid) {

            let item = this.resources.find(i => i.uid == uid);

            // Traitement de la validation
            if (item.to_validate) {
                item.to_validate = false;
                item.message = this.ratingParams.find(i => i.id == item.level).description;
            }

            // Traitement du multiple
            if (item.is_multiple) {
                this.resources.filter(i => i.id == item.id).map(r => {
                    r.level = item.level;
                })
            }

            this.$emit('input', this.resources)
        },

        focusOutCommentHandle(item) {

            if (item.comment && item.comment.length > 1) {
                this.$emit('input', this.resources)
            }
        }
    }
};
</script>

<style scoped>
    .expand-icon {
        width: 16px;
    }
</style>
