<template>
    <div id="resourceForm" class="con-exemple-prompt">
        <vs-row>
            <vs-col vs-w="12">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Service* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.title"
                            name="addServiceTitle"
                            class="w-full"
                            placeholder="Nom du service"
                            required
                        />
                    </div>
                </div>

                <!-- <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Filière* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.sector"
                            name="addServiceSector"
                            :options="sectors"
                            track-by="id"
                            label="title"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div> -->

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Établissement* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- TODO:  add an alert to make sure admins have assigned an establishement to the current user  -->
                        <!-- TODO: auto select current user establishement if they don't have the appropriate permissions  -->
                        <multiselect
                            id="addServiceEstablishment"
                            v-model="resource.establishment"
                            :options="establishments"
                            name="establishments"
                            label="name"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Référents :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.referents"
                            name="addServiceReferents"
                            :options="referents"
                            track-by="id"
                            label="full_name"
                            :multiple="true"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>
                <!-- <div class="vx-row mb-6">
                    <vs-checkbox
                        v-if="type == 'create'"
                        v-model="resource.isInitialisedOccupationSkill"
                        name="addServiceInitOccupationSkill"
                        :disabled="isNotActivated"
                    >
                        Initialiser les savoir-faire de la filière
                    </vs-checkbox>
                </div> -->
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'ServiceForm',
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        isCreation: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            resource: {},
            // sectors: [],
            // sectorsLoading: true,
            establishments: [],
            establishmentsLoading: true,
            referents: [],
            referentsLoading: true
        }
    },
    watch: {
        value (newValue, oldValue) {
            if (newValue === oldValue) return
            this.initResource()
        },
        'resource': {
            handler () {
                this.value.type                         = this.resource.type;
                this.value.title                        = this.resource.title
                this.value.sector                       = this.resource.sector
                this.value.establishment                = this.resource.establishment
                this.value.referents                    = this.resource.referents
                this.value.isInitialisedOccupationSkill = this.resource.isInitialisedOccupationSkill
                this.$emit('input', this.value)
            }, deep: true
        },
        hasError (value) {
            this.$emit('error', value)
        }
    },
    computed: {

        hasError() {
            let error = !this.resource.title
                //|| !this.resource.sector
                || !this.resource.establishment
            return error;
        },

        isNotActivated () {
            return (
                this.resource.sector ?
                this.resource.sector.occupation_skills.length > 0 : true
            )
        },
    },
    methods: {
        // async loadSectors() {
        //     return window.axios({
        //         method: 'get',
        //         url: '/api/admin/sectors',
        //         params: {perpage: 9999, sortBy: {"title": "asc"}, include: ['occupation_skills']},
        //     })
        //     .then(response => {
        //         this.sectors = response.data.data;
        //         this.sectorsLoading = false;
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         this.notifyError(err, `Une erreur est survenue`)
        //     })
        // },
        async loadEstablishments() {
            return window.axios({
                method: 'get',
                url: '/api/referentiel/establishments',
                params: {perpage: 9999, include: []},
            })
            .then(response => {
                this.establishments = response.data.data;
                this.establishmentsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        async loadReferents() {
            return window.axios({
                method: 'get',
                url: '/api/referentiel/users',
                params: {perpage: 9999, include: ['roles']},
            })
            .then(response => {
                this.referents = response.data.data.filter(user => user.roles[0] && user.roles[0].id !== 1);
                this.referentsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        initResource() {
            this.resource = {
                type: this.value.type,
                title: this.value.title,
                sector: this.value.sector,
                establishment: this.value.establishment,
                referents: this.value.referents,
                isInitialisedOccupationSkill: false
            }
        },

    },
    async created() {
        this.initResource();

        this.$vs.loading();
        await Promise.all([
            //this.loadSectors(),
            this.loadEstablishments(),
            this.loadReferents()
        ]);
        this.$vs.loading.close();
    }
}
</script>

<style lang="scss" scoped>
#resourceForm {
    display: block;
    > div {
        padding: 1rem;
    }

    ::v-deep input {
        min-height: 43px;
        padding: 1rem 1.3rem;
        font-size:1.1rem;
    }
    .multiselect {
        min-height: 43px;
        &.multiselect--disabled {
            border-radius: 5px;
            ::v-deep .multiselect__select {
                border-radius: 0 4px 4px 0;
                height: 41px;
            }
        }
    }
}
</style>
