var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-authorizations-container vx-row mb-6" },
    [
      _vm.hasContent
        ? _c("div", { staticClass: "vx-col w-full ml-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "employeesRead",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.employees.read,
                            callback: function($$v) {
                              _vm.$set(_vm.value.employees, "read", $$v)
                            },
                            expression: "value.employees.read"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "employeesUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.employees.update,
                            callback: function($$v) {
                              _vm.$set(_vm.value.employees, "update", $$v)
                            },
                            expression: "value.employees.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [
                  _vm._v(
                    "Gestion des " + _vm._s(_vm.projectNames.plural) + " :"
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "ratingsRead" },
                    model: {
                      value: _vm.value.ratings.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.ratings, "read", $$v)
                      },
                      expression: "value.ratings.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "ratingsUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.ratings.update,
                            callback: function($$v) {
                              _vm.$set(_vm.value.ratings, "update", $$v)
                            },
                            expression: "value.ratings.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [
                  _vm._v(
                    "Validation de " + _vm._s(_vm.projectNames.singular) + " :"
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "isRatingValidator" },
                    model: {
                      value: _vm.value.validation.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.validation, "read", $$v)
                      },
                      expression: "value.validation.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "validationsUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.validation.update,
                            callback: function($$v) {
                              _vm.$set(_vm.value.validation, "update", $$v)
                            },
                            expression: "value.validation.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [
                  _vm._v(
                    "Gestion des " + _vm._s(_vm.skillRatingNames.plural) + " :"
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "isSkillReview" },
                    model: {
                      value: _vm.value.skill_review.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.skill_review, "read", $$v)
                      },
                      expression: "value.skill_review.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "skillReviewUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.skill_review.update,
                            callback: function($$v) {
                              _vm.$set(_vm.value.skill_review, "update", $$v)
                            },
                            expression: "value.skill_review.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [
                  _vm._v(
                    "Validation de " +
                      _vm._s(_vm.skillRatingNames.singular) +
                      " :"
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "isSkillReviewValidator" },
                    model: {
                      value: _vm.value.skill_review_validation.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.skill_review_validation, "read", $$v)
                      },
                      expression: "value.skill_review_validation.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "skillReviewValidationsUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.skill_review_validation.update,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.value.skill_review_validation,
                                "update",
                                $$v
                              )
                            },
                            expression: "value.skill_review_validation.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "jobsRead",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.jobs.read,
                            callback: function($$v) {
                              _vm.$set(_vm.value.jobs, "read", $$v)
                            },
                            expression: "value.jobs.read"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _vm.groupedServicesByEstablishments.length > 0
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "jobsUpdate",
                            options: _vm.groupedServicesByEstablishments,
                            multiple: true,
                            "group-values": "services",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "label",
                            "select-label": "",
                            "select-group-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.jobs.update,
                            callback: function($$v) {
                              _vm.$set(_vm.value.jobs, "update", $$v)
                            },
                            expression: "value.jobs.update"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "skillsRead" },
                    model: {
                      value: _vm.value.skills.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.skills, "read", $$v)
                      },
                      expression: "value.skills.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "skillsUpdate" },
                    model: {
                      value: _vm.value.skills.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.skills, "update", $$v)
                      },
                      expression: "value.skills.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "occupationSkillsRead" },
                    model: {
                      value: _vm.value.occupation_skills.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.occupation_skills, "read", $$v)
                      },
                      expression: "value.occupation_skills.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "occupationSkillsUpdate" },
                    model: {
                      value: _vm.value.occupation_skills.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.occupation_skills, "update", $$v)
                      },
                      expression: "value.occupation_skills.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "establishmentsRead" },
                    model: {
                      value: _vm.value.establishments.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.establishments, "read", $$v)
                      },
                      expression: "value.establishments.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "establishmentsUpdate" },
                    model: {
                      value: _vm.value.establishments.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.establishments, "update", $$v)
                      },
                      expression: "value.establishments.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "servicesRead" },
                    model: {
                      value: _vm.value.services.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.services, "read", $$v)
                      },
                      expression: "value.services.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "servicesUpdate" },
                    model: {
                      value: _vm.value.services.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.services, "update", $$v)
                      },
                      expression: "value.services.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(8),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "usersRead" },
                    model: {
                      value: _vm.value.users.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.users, "read", $$v)
                      },
                      expression: "value.users.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "usersUpdate" },
                    model: {
                      value: _vm.value.users.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.users, "update", $$v)
                      },
                      expression: "value.users.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(9),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "trainingsRead" },
                    model: {
                      value: _vm.value.trainings.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.trainings, "read", $$v)
                      },
                      expression: "value.trainings.read"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "trainingsUpdate" },
                    model: {
                      value: _vm.value.trainings.update,
                      callback: function($$v) {
                        _vm.$set(_vm.value.trainings, "update", $$v)
                      },
                      expression: "value.trainings.update"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _vm._m(10),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c("vs-switch", {
                    attrs: { name: "isFormContact" },
                    model: {
                      value: _vm.value.contact_form.read,
                      callback: function($$v) {
                        _vm.$set(_vm.value.contact_form, "read", $$v)
                      },
                      expression: "value.contact_form.read"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row mb-6" }, [
      _c("b", [_vm._v("Habilitations avancées")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }),
      _vm._v(" "),
      _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
        _c("span", [_vm._v("En consultation")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
        _c("span", [_vm._v("En création / modification")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Personnes :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Postes :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Compétences :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Savoir-Faire :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Établissements :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Services :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Utilisateurs :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Formation / ressources :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Destinataire formulaire de contact :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }