<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    id="btn-create"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="createResource"
                >
                    Ajouter un nouveau poste/stage
                </vs-button>

                <vs-prompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource(); resetSearchParams()"
                    @accept="storeResource"
                    @close="resetResource(); resetSearchParams()"
                >
                    <JobForm
                        v-model="resource"
                        :type="formType"
                        @error="setFormError"
                    />
                </vs-prompt>

                <vs-prompt
                    :active.sync="showPopupOccupationSkill"
                    title="Consulter un savoir faire"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    button-accept="false"
                    @cancel="resetResource"
                    @close="resetResource"
                >
                    <OccupationSkillForm
                        v-model="resourceOccupationSkill"
                        type="show"
                    />
                </vs-prompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="items.data"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol">Poste/Stage</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="type" @sort="eventSortByCol">Type</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="service,title" @sort="eventSortByCol">Service</Sort>
                </vs-th>
                <vs-th><Sort name="service.establishment,name" @sort="eventSortByCol">Établissement</Sort></vs-th>
                <vs-th><Sort name="updated_at" @sort="eventSortByCol" :value="apiParams.sortBy.updated_at">Modifié le</Sort></vs-th>
                <vs-th>Savoir-faire</vs-th>
                <vs-th width="110"></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                    <vs-td
                        v-tooltip="{
                            content: item.description,
                            placement: 'top-start',
                            classes: ['job-description']
                        }"
                    >
                        {{ get(item, ":title") }}
                    </vs-td>
                    <vs-td>
                        {{ ucfirst(get(item, ":type")) }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":service.title") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":service.establishment.name") }}
                    </vs-td>
                    <vs-td>
                        {{ localeDateTime(item.updated_at) }}
                    </vs-td>
                    <vs-td>
                        <vs-chip v-for="(item, index) in get(item, ':occupation_skills')" :key="index" color="primary">
                            <span name="zone" style="cursor:pointer !important; min-height: 26px;min-width: 26px; padding-top: 6px;" @click="showOccupationSkill(item)">
                                {{ item.title }}
                            </span>
                        </vs-chip>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('update', 'jobs', get(item, ':service.id'))"
                                :id="'btn-update-' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="updateResource(item)"
                            />

                            <vs-button
                                :id="'btn-exportOS-' + i"
                                v-tooltip="'Exporter les Savoir-Faire'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                                target="_blank"
                                :href="makeExportOSUrl(item)"
                            />

                            <vs-button
                                v-if="can('delete', 'jobs', get(item, ':service.id'))"
                                :id="'btn-delete-' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="deleteResource(item)"
                            />

                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination
                    :total="Math.ceil(items.total / apiParams.perpage)"
                    v-model="currentPage"
                >
                </vs-pagination>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import JobForm from "@components/views/rh/referentiel/job/JobForm";
import OccupationSkillForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillForm";
import { localeDateTime } from "@assets/utils/helpers/helpers.js";
import contentDisposition from "content-disposition"

export default {
    name: "Job",
    components: {
        JobForm, OccupationSkillForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            localeDateTime,
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            showPopupOccupationSkill: false,
            resourceReadonly: true,
            resource: {},
            resourceOccupationSkill: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un poste/stage',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un poste/stage',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un poste/stage',
                    btnAccept: 'filled'
                }
            }
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
    },

    methods: {
        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'show';
            this.showPopup = true;
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = 'create';
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'update';
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.service_id = data.service.id;

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            }
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        makeExportOSUrl(resource) {

            let url = `/rh/referentiel/savoir-faire/export-pdf`;
            for (var i in resource.occupation_skills) {
                url += i == 0 ? '?' : '&'
                url += `occupation_skills[]=${resource.occupation_skills[i].id}`
            }

            return url;
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                title: null,
                type: null,
                description: null,
                sector: null,
                skills: {}
            };
        },

        resetSearchParams() {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('create');
            searchParams.delete('os');
            window.location.search = '?' + searchParams.toString();
        },

        setFormError(value) {
            this.formError = value;
        },

        showOccupationSkill(item) {
            this.resourceOccupationSkill = JSON.parse(JSON.stringify(item));
            this.showPopupOccupationSkill = true;
        },

        ucfirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
    created() {
        this.apiParams.include = [
            "service.establishment",
            "occupation_skills.jobs.service.establishment",
            "occupation_skills.sector",
            "occupation_skills.skills",
            "occupation_skills.establishment"
        ];
        this.apiParams.sortBy = {updated_at: "desc"};
        this.resetResource();
        this.loadItems();

        // Ouvrir le formulaire de création au chargement de la page si argument create
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('create')) {
            this.createResource();
        }

    }
};
</script>
