<template>
    <div>
        <vs-tabs class="mt-10">
            <vs-tab label="Savoir-faire" class="pt-10">
                <OccupationSkillsTab
                    :api="api"
                    :perpage="perpage"
                />
            </vs-tab>
            <vs-tab v-if="canSeeReferentiel()" label="Référentiel" class="pt-10">
                <AdminOccupationSkillsTab
                    :api="`${api}/referential`"
                    :perpage="perpage"
                />
            </vs-tab>
        </vs-tabs>

    </div>
</template>

<script>
import OccupationSkillsTab from "@components/views/rh/referentiel/occupation-skill/OccupationSkillsTab";
import AdminOccupationSkillsTab from "@components/views/rh/referentiel/occupation-skill/AdminOccupationSkillsTab";

export default {
    name: "OccupationSkill",
    components: {
        OccupationSkillsTab, AdminOccupationSkillsTab
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    methods: {
        canSeeReferentiel() {
            return this.isASuperAdmin() || this.isAnAdmin()
        }
    }
};
</script>

