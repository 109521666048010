<template>
    <section class="experience">
        <h3 class="mb-8">Stage / Immersion :</h3>

        <div>
            <vs-button
                v-if="canUpdate()"
                id="addInternShip"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                @click="createResource()"
                style="margin-bottom: 2em;"
            >
                Ajouter un nouveau stage / immersion
            </vs-button>
        </div>

        <vs-prompt
            :active.sync="showPopup"
            :title="getDataPrompt.title"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            :button-accept="getDataPrompt.btnAccept"
            :is-valid="!hasError"
            @cancel="resetResource"
            @close="resetResource"
            @accept="storeResource"
        >
            <div class="con-exemple-prompt">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Type *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-if="internshipTypes.length > 0"
                            v-model="resource.experience_type"
                            name="internshipTypes"
                            :options="internshipTypes"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="name"
                            select-label
                            selected-label
                            deselect-label
                            :loading="internshipTypesLoading"
                        >
                            <span slot="noResult">Aucun résultat.</span>
                        </multiselect>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Nom *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.name"
                            name="internshipName"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date de début *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!--datepicker
                            v-model="resource.start_date"
                            name="internshipStartDate"
                            class="w-full"
                            :language="fr"
                            monday-first
                        /-->
                        <vs-input
                            type="date"
                            v-model="resource.start_date"
                            name="internshipStartDate"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date de fin *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!--datepicker
                            v-model="resource.end_date"
                            name="internshipEndDate"
                            class="w-full"
                            :language="fr"
                            monday-first
                        /-->
                        <vs-input
                            type="date"
                            v-model="resource.end_date"
                            name="internshipEndDate"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Entreprise / Etablissement *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.establishment"
                            name="internshipEstablishment"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Commentaire :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.comment"
                            name="internshipComment"
                            class="w-full"
                        />
                    </div>
                </div>
            </div>
        </vs-prompt>

        <vs-table
            :sst="true"
            :data="items.data"
            no-data-text="Aucune donnée à afficher"
            @sort="eventSortByCol"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="experience_type.name" @sort="eventSortByCol">Type</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="name" @sort="eventSortByCol">Libellé</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="start_date" @sort="eventSortByCol">Date de début</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="end_date" @sort="eventSortByCol">Date de fin</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="establishment" @sort="eventSortByCol">Entreprise / Etablissement</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="comment" @sort="eventSortByCol">Commentaire</Sort>
                </vs-th>
                <vs-th v-if="canUpdate()">
                    Actions
                </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td :data="item.type">
                        {{ item.experience_type.name }}
                    </vs-td>
                    <vs-td :data="item.name">
                        {{ item.name }}
                    </vs-td>
                    <vs-td :data="item.start_date">
                        {{ item.start_date | date }}
                    </vs-td>
                    <vs-td :data="item.end_date">
                        {{ item.end_date | date }}
                    </vs-td>
                    <vs-td :data="item.establishment">
                        {{ item.establishment }}
                    </vs-td>
                    <vs-td :data="item.comment">
                        {{ item.comment }}
                    </vs-td>
                    <vs-td v-if="canUpdate()">
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'modifyIntership_' + indextr"
                            v-tooltip="'Modifier'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="updateResource(item)"
                        />
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'deleteInterShip_' + indextr"
                            v-tooltip="'Supprimer'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="margin-left: 1rem;"
                            @click="deleteResource(item)"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </section>
</template>

<script>
import Multiselect from "vue-multiselect";
//import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { format } from 'date-fns'
export default {
    name: "EmployeeInternShips",
    components: {
        Multiselect,
        //Datepicker
    },
    props: {
        api: {
            type: String
        },
        employee: {
            required: false,
            type: Object
        }
    },
    data() {
        return {
            fr: fr,
            resource: {},
            showPopup: false,
            loading: true,
            formError: true,
            formType: "create",
            internshipTypes: [],
            internshipTypesLoading: true,
            prompt: {
                show: {
                    title: "Consulter un stage / immersion",
                    btnAccept: "false"
                },
                create: {
                    title: "Ajouter un stage / immersion",
                    btnAccept: "filled"
                },
                update: {
                    title: "Modifier un stage / immersion existant",
                    btnAccept: "filled"
                }
            }
        };
    },
    watch: {},
    computed: {
        hasError() {
            let error =
                !this.resource.experience_type ||
                !this.resource.name ||
                !this.resource.start_date ||
                !this.resource.end_date ||
                !this.resource.establishment;
            return error;
        }
    },
    methods: {
        loadInternshipTypes() {
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/experience-types",
                    params: { perpage: 100, filter: "category|=|1" }
                })
                .then(response => {
                    this.internshipTypes = response.data.data;
                    this.internshipTypesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        canUpdate() {
            return this.canMulti("update", "employees", this.employee.services.map(s => s.id));
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = "create";
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.experience_type_id = this.resource.experience_type.id;

            console.log(data);
            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => {
                        this.loadItems(),
                        this.$emit('change')
                    },
                    err => (this.showPopup = true)
                );
            } else {
                this.apiCreate(
                    data,
                    resp => {
                        this.loadItems(),
                        this.$emit('change')
                    },
                    err => (this.showPopup = true)
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => {
                this.loadItems(),
                this.$emit('change')
            });
        },

        setResource(data) {
            let dataTemp = JSON.parse(JSON.stringify(data));
            dataTemp.start_date = this.getDate(dataTemp.start_date);
            dataTemp.end_date = dataTemp.end_date ? this.getDate(dataTemp.end_date) : dataTemp.end_date;
            this.resource = dataTemp;
        },

        resetResource() {
            this.resource = {
                experienceType: null,
                name: null,
                start_date: format(new Date(), "yyyy-MM-dd"),
                end_date: null,
                establishment: null,
                comment: null
            };
        }
    },
    created() {
        this.apiParams = {
            sortBy: {"end_date": 'asc'},
            perpage: 100,
            filter: "experienceType.category|=|1",
            include: []
        };
        this.resetResource();
        this.loadItems();
        this.loadInternshipTypes();
    }
};
</script>

<style lang="scss" scoped></style>
