import Vue from 'vue'
import objectToFormData from "object-to-formdata"
Vue.mixin({
    data() {
        return {
            items: {
                data: []
            },
            apiParams: {
                perpage: 10
            },
        }
    },
    methods: {

        /**
         * Récupère la totalitée des resources depuis l'API
         */
        apiGetAll() {
            this.loading = true

            return window.axios({
                method: 'get',
                url: this.api,
                params: this.apiParams
            })
            .then(response => {
                this.loading = false
                this.items = response.data;
            })
            .catch((err) => {
                this.loading = false
                this.notifyError(err, `Une erreur est survenue`)
            })
        },

        /**
         * Création d'une resource
         * @param {Object} data
         * @param {Function} callback
         * @param {Function} callbackError
         * @param {Object} headers Entêtes à ajoutées. Ex: {'Content-Type': 'multipart/form-data'}
         * @param onlyCallbackError
         */
        apiCreate(data, callback, callbackError, headers, onlyCallbackError = false) {
            let dataform;

            if (headers && headers['Content-Type'] == 'multipart/form-data') {
                dataform = objectToFormData(data);
            } else {
                dataform = data;
            }

            window.axios({
                method: 'post',
                url: this.api,
                data: dataform,
                headers: headers
            })
            .then(response => {

                if (response.status === 200 && response.data != '') {
                    this.notifySuccess('Succès', 'Élement ajouté avec succès !')
                } else {
                    this.notifyError('Erreur', `Une erreur est survenue lors de la création`)
                }

                if (callback) {
                    callback(response);
                }
            })
            .catch((err) => {
                if (!onlyCallbackError) this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },

        /**
         * Mise à jour d'une resource
         * @param {Object} data
         * @param {Integer} id
         * @param {Function} callback
         * @param {Function} callbackError
         * @param {Object} headers Entêtes à ajoutées. Ex: {'Content-Type': 'multipart/form-data'}
         * @param onlyCallbackError
         */
        apiUpdate(data, id, callback, callbackError, headers, onlyCallbackError = false, withoutNotification = false) {
            let method, dataform;

            if (headers && headers['Content-Type'] == 'multipart/form-data') {
                method = 'post';
                data['_method'] = 'PATCH';
                dataform = objectToFormData(data);
            } else {
                method = 'patch';
                dataform = data;
            }

            window.axios({
                method: method,
                url: `${this.api}/${id}`,
                data: dataform,
                headers: headers
            })
            .then(response => {

                if (!withoutNotification) {
                    if (response.status === 200 && response.data != '') {
                        this.notifySuccess('Succès', 'Élement modifié avec succès !')
                    } else {

                        this.notifyError('Erreur', `Une erreur est survenue lors de la modification`)
                    }
                }

                if (callback) {
                    callback(response);
                }
            })
            .catch((err, data) => {
                if (!onlyCallbackError) this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },

        /**
         * Duplique une resource
         * @param {Integer} id
         * @param {Function} callback
         * @param {Function} callbackError
         */
        apiDuplicate(id, callback, callbackError) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir dupliquer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: 'post',
                        url: `${this.api}/${id}/duplicate`
                    })
                    .then(response => {

                        if (response.status === 200 && response.data != '') {
                            this.notifySuccess('Succès', 'Élement dupliqué avec succès !')
                        } else {

                            this.notifyError('Erreur', `Une erreur est survenue lors de la duplication`)
                        }

                        if (callback) {
                            callback(response);
                        }
                    })
                    .catch((err, data) => {
                        this.notifyErrorValidation(err)
                        if (callbackError) {
                            callbackError(err.response)
                        }
                    })
                }
            })
        },

        /**
         * Supprime une resource
         * @param {Integer} id
         * @param {Function} callback
         */
        apiDelete(id, callback) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: 'delete',
                        url: `${this.api}/${id}`,
                    })
                    .then(response => {

                        if (response.status === 204) {
                            this.notifySuccess('Élement supprimé avec succès !')
                        } else {
                            this.notifyError('Erreur', `Une erreur est survenue lors de la suppression`)
                        }

                        if (callback) {
                            callback(response);
                        }
                    })
                    .catch((err) => {
                        this.notifyError(err, `Une erreur est survenue`)
                    })
                }
            })
        },

        /**
         * Restaure une resource
         * @param {Integer} id
         * @param {Function} callback
         */
        apiRestore(id, callback) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir restaurer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: 'patch',
                        url: `${this.api}/${id}/restore`,
                    })
                    .then(response => {

                        if (response.status === 200) {
                            this.notifySuccess('Élement restauré avec succès !')
                        } else {
                            this.notifyError('Erreur', `Une erreur est survenue lors de la restauration`)
                        }

                        if (callback) {
                            callback(response);
                        }
                    })
                    .catch((err) => {
                        this.notifyError(err, `Une erreur est survenue`)
                    })
                }
            })
        },

        forceFileDownload(response) {
            if (response.headers["content-disposition"].startsWith('attachment')) {
                let fileName = response.headers["content-disposition"].match(/filename=\\?"([^"\\]*)\\?"/)[1];

                console.log(fileName)
                const type = response.headers['content-type'];
                const encoding = response.headers['content-encoding'];

                const url = window.URL.createObjectURL(new Blob([response.data], {type: type, encoding: encoding}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            } else {
                console.error('Impossible de télécharger le fichier !!')
            }
        },
    },
    computed: {
        listNoDataText() {
            return this.loading ? `Chargement de vos données...` : `Aucune donnée à afficher`
        }
    },
})
