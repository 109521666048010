<template>

    <vx-card>

        <dir>
            <vs-row>
                <vs-col vs-align="left" vs-w="6">
                    <!-- <vs-button
                        id="btn-select"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-sliders"
                        @click="selectSectors"
                    >
                        Sélectionner les filières
                    </vs-button> -->

                    <vs-prompt
                        :active.sync="showPopup"
                        :title="getDataPrompt.title"
                        cancel-text="Fermer"
                        accept-text="Enregistrer"
                        :button-accept="getDataPrompt.btnAccept"
                        :is-valid="!formError"
                        @cancel="resetResource"
                        @accept="storeResource"
                        @close="resetResource"
                    >
                        <SectorForm
                            v-if="formType == 'select'"
                            v-model="resource"
                            :type="formType"
                            @error="setFormError"
                        />

                        <OccupationSkillForm
                            v-else-if="formType == 'show'"
                            v-model="resource"
                            :type="formType"
                        />
                    </vs-prompt>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6" />
            </vs-row>

            <vs-table
                :sst="true"
                search
                multiple
                v-model="selected"
                @search="eventSearchItems"
                @input="selectedInputHandle"
                :no-data-text="
                    loading
                        ? `Chargement de vos données...`
                        : `Aucune donnée à afficher`
                "
                style="width:100%"
                :data="items.data"
            >
                <template slot="header">
                    <vs-row>

                        <vs-col v-if="isASuperAdmin() || isAnAdmin()" vs-type="flex" vs-align="left" vs-w="6" >
                            <vs-checkbox
                                v-model="filterByNew"
                                name="filterByNew"
                            >
                                Nouveautés
                            </vs-checkbox>

                            <vs-checkbox
                                v-model="filterByUsed"
                                name="filterByUsed"
                            >
                                Utilisé
                            </vs-checkbox>
                        </vs-col>
                    </vs-row>
                </template>

                <template v-if="!selected.length" slot="thead">
                    <vs-th>
                        <Sort name="title" @sort="eventSortByCol">Libellé</Sort>
                    </vs-th>
                    <vs-th>
                        <Sort name="type" @sort="eventSortByCol">Type</Sort>
                    </vs-th>
                    <vs-th>
                        <Sort name="sector,title" @sort="eventSortByCol">Filière</Sort>
                    </vs-th>
                    <vs-th></vs-th>
                </template>

                <template v-else slot="thead">
                    <vs-th colspan="2">
                        <span class="selected-lines mr-5">
                            {{ selectedLinesText }}
                        </span>
                        <vs-checkbox
                                v-model="selectAll"
                                name="selectAll"
                                @input="selectAllItemsHandle"
                            >
                            Sélectionner la totalité des lignes
                        </vs-checkbox>
                    </vs-th>

                    <vs-th colspan="1" style="font-weight: 400;">
                            <Multiselect
                                v-model="massAction"
                                :options="massActionList"
                                :multiple="false"
                                :group-select="false"
                                placeholder="Choisir une action..."
                                track-by="id"
                                label="label"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                                open-direction="bottom"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </Multiselect>
                    </vs-th>

                    <vs-th>
                        <vs-button
                            v-if="canDoMassAction()"
                            color="secondary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-check"
                            size="large"
                            @click="massActionHandle"
                        >
                            Valider
                        </vs-button>
                    </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr v-for="(item, i) in data" :key="i" :data="item" :data-id="item.id">
                        <vs-td :data="get(item, ':title')" :title="get(item, ':description')">
                            <div style="text-wrap: nowrap; display: flex">
                                <div class="float-left mr-5" style="line-height: 25px;">{{ get(item, ":title") }}</div>
                                <vs-chip v-if="item.is_new" color="warning" class="bg-chip-transparent">
                                    Nouveau
                                </vs-chip>

                                <vs-chip v-if="item.beta_status == true">
                                    Bêta
                                </vs-chip>

                                <vs-chip v-if="item.tenant_used == true" color="success" class="bg-chip-transparent">
                                    Utilisé
                                </vs-chip>
                            </div>
                        </vs-td>
                        <vs-td :data="get(item, ':type')" style="min-width: 152px;">
                            {{ get(item, ":type") }}
                        </vs-td>
                        <vs-td :data="get(item, ':sector')" style="min-width: 270px;">
                            {{ get(item, ":sector.title") }}
                        </vs-td>
                        <vs-td class="action" style="min-width: 160px;">
                            <vs-button
                                v-tooltip="'Consulter'"
                                size="small"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-list"
                                @click.stop="showResource(item)"
                            />

                            <vs-button
                                v-tooltip="'Télécharger le savoir-faire'"
                                size="small"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                                @click.stop="downloadParent(item)"
                            />
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <vs-row>
                <vs-col class="mt-4" v-if="items.total > 0">
                    <vs-pagination
                        :total="Math.ceil(items.total / apiParams.perpage)"
                        v-model="currentPage"
                    ></vs-pagination>
                </vs-col>
            </vs-row>
        </dir>
    </vx-card>
</template>

<script>
import OccupationSkillForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillForm";
import SectorForm from "@components/views/rh/referentiel/occupation-skill/SectorForm";
import Multiselect from "vue-multiselect";

export default {
    name: "Savoir-faires",
    components: { SectorForm, OccupationSkillForm, Multiselect},
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            selected: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un savoir-faire',
                    btnAccept: 'false'
                },
                select: {
                    title: 'Selectionner des filières',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un savoir-faire',
                    btnAccept: 'filled'
                }
            },
            filterByNew: false,
            filterByUsed: false,
            selectAll: false,
            massAction: null,
            massActionList: [
                {id: 1, label: 'Télécharger les lignes sélectionnées', action: 'massDowloadParents', type: 'download'},
            ]
        };
    },

    watch: {
        filterByNew(value) {
            this.apiParams.only_new = value;
            this.loadItems();
        },
        filterByUsed(value) {
            this.apiParams.only_used = value;
            this.loadItems();
        },
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'select':
                    return this.prompt.select
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },

    },

    methods: {
        canDoMassAction() {
            return !!this.massAction;
        },

        massActionHandle() {
            this[this.massAction.action](this.selected);
        },

        selectSectors() {
            this.resetResource();
            this.formType = "select";
            this.showPopup = true
        },

        showResource(resource) {
            this.resetResource();
            this.setResource(resource);
            this.formType = "show";
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        downloadParent(parent) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir télécharger cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    this.selectAll = false;
                    this.massDowloadParents([parent])
                }
            });
        },

        massDowloadParents(parents) {
            window.axios({
                method: 'post',
                url: `${this.api}`,
                data: {
                    items: parents.map(p => p.id),
                    all: this.selectAll
                },
                params: this.apiParams
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élement téléchargé avec succès !');
                this.unselectLines();
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },

        storeResource() {
            this.loading = true;
            this.selectAll = false;

            window.axios({
                method: 'post',
                url: `/api/referentiel/sector-availables`,
                data: {
                    sectors: this.resource.sectors.map(s => s.id)
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Secteurs sélectionnés avec succès !')
                this.loadItems();
            })
            .catch((err) => {
                this.loading = false;
                this.showPopup = true;
                this.notifyErrorValidation(err)
            })
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
            this.resource.admin_occupation_skill = this.resource;
            this.resource.skills = this.resource.adminskills
        },

        resetResource() {
            this.resource = {
                sectors: [],
                admin_occupation_skills: []
            };
        },

        setFormError(value) {
            this.formError = value;
        }

     },
    created() {
        this.apiParams.include = ['sector', 'adminskills'];
        this.apiParams.append = ['is_new', 'tenant_used'];
        this.loadItems();
    }
};
</script>
