import Vue from "vue";

Vue.mixin({
    methods: {
        slugify(text) {
            return text
                .toString()
                .normalize("NFD") // split an accented letter in the base letter and the acent
                .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
                .toLowerCase()
                .trim()
                .replace(/\s+/g, "-")
                .replace(/[^\w\-]+/g, "")
                .replace(/\-\-+/g, "-");
        },

        replaceBreakLine(text) {
            return text.replace(/\r\n|\r|\n/g, "<br />");
        },

        lower: function(value) {
            return value.toLowerCase();
        },
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
});
