var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.cardClass }, [
    _c("p", [
      _vm.card.category === "employee_ratings"
        ? _c("span", { staticClass: "bold" }, [
            _vm._v("\n      " + _vm._s(_vm.projectNames.singular)),
            _c("br")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.card.name instanceof Array
        ? _c(
            "span",
            { staticClass: "bold" },
            _vm._l(_vm.card.name, function(item) {
              return _c("span", [
                _vm._v("\n        " + _vm._s(item) + "\n        "),
                _vm.card.isGraduate
                  ? _c("img", {
                      staticClass: "is-graduate",
                      attrs: {
                        src: "/assets/images/qualification-white.png",
                        alt: "Formation diplômante"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("br")
              ])
            }),
            0
          )
        : _vm.card.name
        ? _c("span", { staticClass: "bold" }, [
            _vm._v("\n      " + _vm._s(_vm.card.name) + "\n      "),
            _vm.card.isGraduate
              ? _c("img", {
                  staticClass: "is-graduate",
                  attrs: {
                    src: "/assets/images/qualification-white.png",
                    alt: "Formation diplômante"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("br")
          ])
        : _vm._e()
    ]),
    _vm.card.category === "employee_ratings"
      ? _c(
          "div",
          _vm._l(_vm.card.job_occupationSkills, function(job, i) {
            return _c(
              "div",
              { key: i, staticClass: "mt-2" },
              [
                _c("p", [_vm._v("- " + _vm._s(job.jobTitle))]),
                _vm._v(" "),
                _vm._l(job.occupation_skills, function(os, j) {
                  return _c("div", { key: i + "-" + j }, [
                    _c("p", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v("✓ " + _vm._s(os.title))
                    ])
                  ])
                })
              ],
              2
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.card.establishment
      ? _c("span", [_vm._v(_vm._s(_vm.card.establishment)), _c("br")])
      : _vm._e(),
    _vm._v(" "),
    _vm.card.organisation
      ? _c("span", [_vm._v(_vm._s(_vm.card.organisation)), _c("br")])
      : _vm._e(),
    _c("br"),
    _vm._v(" "),
    _vm.card.start && !_vm.card.date
      ? _c("span", [_vm._v(_vm._s(_vm.card.start.toLocaleDateString()))])
      : _vm._e(),
    _vm._v(" "),
    _vm.card.end &&
    !_vm.card.date &&
    _vm.card.end.toLocaleDateString() !== _vm.today
      ? _c("span", [
          _vm._v(
            "\n      " + _vm._s(" - " + _vm.card.end.toLocaleDateString())
          ),
          _c("br")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.card.end &&
    !_vm.card.date &&
    _vm.card.end.toLocaleDateString() === _vm.today
      ? _c("span", [_vm._v("\n      " + _vm._s(" - aujourd'hui")), _c("br")])
      : _vm._e(),
    _vm._v(" "),
    _vm.card.date
      ? _c("span", [_vm._v(_vm._s(_vm.card.date.toLocaleDateString()))])
      : _vm._e(),
    _vm._v(" "),
    _c("p")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }