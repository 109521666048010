<template>
    <div>
        <vs-row>
            <vs-col vs-align="right" class="mb-10">

                <vs-button
                    id="saveQuit"
                    v-if="typeForm !== 'validation' && typeForm !== 'consultation'"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-save"
                    @click.native="saveProjectClickHandle"
                >
                    Sauvegarder
                </vs-button>

                <vs-button
                    id="goBack"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-arrow-left"
                    class="float-right"
                    :href="backUrl"
                >
                    Retour
                </vs-button>
            </vs-col>
        </vs-row>
        <div v-if="employee">
            <form-wizard
                color="rgba(var(--vs-primary), 1)"
                error-color="rgba(var(--vs-danger), 1)"
                :startIndex="0"
                :title="title"
                :subtitle="null"
                @on-complete="endFormHandle"
                @on-validate="validateFormHandle"
                @on-error="errorFormHandle"
                @on-change="changePageHandle"
            >
                <!-- Alerte erreur de validation -->
                <div v-if="errorValidateMessage" class="mb-10">
                    <vs-alert color="danger" active="true">
                        {{ errorValidateMessage }}
                    </vs-alert>
                </div>

                <!-- Tab: Périmètre -->
                <tab-content
                    title="Périmètre"
                    class="mb-5"
                    icon="feather icon-clock"
                    :before-change="validateStep1"
                >
                    <form data-vv-scope="step-1">
                        <Perimeter-Tab
                            :type-form="typeForm"
                            :skill-review="skillReview"
                            v-model="resource"
                            :jobs="orderedJobs"
                            :occupations="orderedOccupations"
                            @career-updated="careerUpdated = true"
                        ></Perimeter-Tab>
                    </form>
                </tab-content>

                <!-- Tab: Evaluation des savoir-faire -->
                <tab-content
                    title="Évaluation des savoir-faire"
                    class="mb-5"
                    icon="feather icon-aperture"
                    :before-change="validateStep2"
                >
                    <form data-vv-scope="step-2">
                        <Occupation-Skills-Rating-Tab
                            v-if="currentStep == 1"
                            :type-form="typeForm"
                            :skill-review="skillReview"
                            v-model="resource"
                        ></Occupation-Skills-Rating-Tab>
                    </form>
                </tab-content>

                <!-- Évaluation des compétences -->
                <tab-content
                    title="Évaluation des compétences"
                    class="mb-5"
                    icon="feather icon-award"
                    :before-change="validateStep3"
                >
                    <form data-vv-scope="step-3">
                        <Skills-Rating-Tab
                            v-if="currentStep == 2"
                            :type-form="typeForm"
                            :skill-review="skillReview"
                            v-model="resource"
                            :admin-skills="adminSkills"
                            :colors="colors.data"
                        ></Skills-Rating-Tab>
                    </form>
                </tab-content>

                <!-- Auto-évaluation -->
                <tab-content
                    v-if="!skillReview"
                    title="Auto-évaluation"
                    class="mb-5"
                    icon="feather icon-user-check"
                    :before-change="validateStep4"
                >
                    <form data-vv-scope="step-4">
                        <Self-Rating-Tab
                            v-if="!skillReview && currentStep == 3"
                            :type-form="typeForm"
                            :skill-review="skillReview"
                            v-model="resource"
                            :self-rating-source-values="selfRatingSourceValues"
                            :admin-skills="adminSkills"
                            :colors="colors.data"
                        ></Self-Rating-Tab>
                    </form>
                </tab-content>

                <!-- Tab: Souhaits d'évolution et objectifs-->
                <tab-content
                    title="Objectifs"
                    class="mb-5"
                    icon="feather icon-briefcase"
                    :before-change="validateStep5"
                >
                    <form :data-vv-scope="skillReview ? 'step-4':'step-5'">
                        <Goals-Tab
                            v-if="currentStep == 4 || (skillReview && currentStep == 3)"
                            :type-form="typeForm"
                            v-model="resource"
                            :jobs="orderedJobs"
                            :occupations="orderedOccupations"
                        ></Goals-Tab>
                    </form>
                </tab-content>

                <!-- Synthèse du projet personnalisé -->
                <tab-content
                    title="Synthèse"
                    class="mb-5"
                    icon="feather icon-align-justify"
                    :before-change="validateStep6"
                >
                    <form :data-vv-scope="skillReview ? 'step-5':'step-6'">
                        <Synthesis-Tab
                            v-if="currentStep == 5 || (skillReview && currentStep == 4)"
                            :type-form="typeForm"
                            v-model="resource"
                        ></Synthesis-Tab>
                    </form>
                </tab-content>


                <!-- Actions -->
                <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-left" onclick="window.scrollTo(0,0)">
                        <vs-button
                            v-if="props.activeTabIndex > 0"
                            id="prev"
                            color="primary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-chevron-left"
                            @click.native="props.prevTab()"
                        >
                            Précédent
                        </vs-button>
                    </div>

                    <div class="wizard-footer-right" onclick="window.scrollTo(0,0)">
                        <vs-button
                            v-if="!props.isLastStep"
                            id="next"
                            class="wizard-footer-right"
                            color="primary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-chevron-right"
                            @click.native="props.nextTab()"
                        >
                            Suivant
                        </vs-button>
                        <vs-button
                            v-else
                            id="submit"
                            class="wizard-footer-right finish-button"
                            color="primary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-check"
                            @click.native="props.nextTab()"
                        >
                            <span v-if="typeForm === 'consultation'">
                                Retour
                            </span>
                            <span v-else-if="skillReview">
                                <template v-if="canValidateSkillReview">
                                    <div>
                                        Valider le {{ skillRatingNames.singular.toLowerCase() }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        Soumettre pour validation
                                    </div>
                                </template>
                            </span>
                            <span v-else>
                                <template v-if="canValidateProject">
                                    <div>
                                        Valider le {{ projectNames.singular.toLowerCase() }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        Soumettre pour validation
                                    </div>
                                </template>
                            </span>
                        </vs-button>
                    </div>
                </template>

            </form-wizard>
        </div>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import PerimeterTab from './partials/tabs/PerimeterTab.vue';
import OccupationSkillsRatingTab from './partials/tabs/OccupationSkillsRatingTab.vue';
import SkillsRatingTab from './partials/tabs/SkillsRatingTab.vue';
import SelfRatingTab from './partials/tabs/SelfRatingTab.vue';
import GoalsTab from './partials/tabs/GoalsTab.vue';
import SynthesisTab from './partials/tabs/SynthesisTab.vue';
import ratingService from "@components/services/ratings.js";

import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
    components: {
        FormWizard,
        TabContent,
        PerimeterTab, OccupationSkillsRatingTab, SkillsRatingTab, SelfRatingTab, GoalsTab, SynthesisTab
    },
    props: {
        baseUrl: {
            type: String,
            required: true
        },
        backUrl: {
            type: String,
            required: true
        },
        typeForm: {
            type: String,
            required: true
        },
        employee: {
            type: Object,
            required: true
        },
        employeeRating: {
            type: Object,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            init: true,
            log: console.log,
            currentStep: 0,
            maxStep: 0,
            errorValidateMessage: null,
            resource: {},
            initalResource: {},
            jobs: {
                loading: true,
                data: []
            },
            occupations: {
                loading: true,
                data: []
            },
            establishments: {
                loading: true,
                data: []
            },
            adminSkills: {
                loading: true,
                data: []
            },
            colors: {
                loading: true,
                data: []
            },
            careerUpdated: false,
            ratingState: null,
            selfRatingSourceValues: {
                LAST_SELF: 1,
                MOBILITY: 2
            },
            lastRating: null
        };
    },

    created() {
        let start = Date.now()
        this.initResource();
        
        if (this.typeForm == 'creation') {
            this.loadLastRating().then(() => {
                this.initResourceFromLastRating();
            });
        }


        // Chargement de base pour la partie « Périmètre »
        Promise.all([
            this.loadJobs(),
            this.loadOccupations(),
            this.loadEstablishments(),
        ]).then(() => {
            console.info('=> Après chargement des listes : +' + (Date.now() - start) + 'ms');
            this.init = false;

            let start2 = Date.now()
            Promise.all([
                this.loadAdminSkill(),
                this.loadColors()
            ]).then(() => {
                console.info('=> Chargement des données supplémentaires : +' + (Date.now() - start2) + 'ms');
            })
        })

        console.info('=> Chargement de base : ' + (Date.now() - start) + 'ms');

        this.ratingState = this.typeForm;
    },
    computed: {
        canValidateSkillReview() {
            return this.canMulti('update', 'skill_review_validations', this.employee.active_services.map(s => s.id));
        },
        canValidateProject() {
            return this.canMulti('update', 'rating_validations', this.employee.active_services.map(s => s.id));
        },
        title() {

            let word = this.skillReview ? this.skillRatingNames.singular : this.projectNames.singular;

            switch (this.ratingState) {
                case "consultation":
                    word = "Consultation du "+word;
                    break;
                case "validation":
                    word = "Validation du "+word;
                    break;
                case "edition":
                    word = "Modification du "+word;
                    break;
                case "poursuite":
                    word = "Poursuite du "+word;
                    break;
                default:
                    word = "Nouveau "+word;
                    break;
            }
            const date = this.$moment(this.employeeRating.rating_date).format("l");
            const complement = this.typeForm === "consultation" ? "(validé)" : "";
            const name = `${this.employee.last_name} ${this.employee.first_name}`;
            const establishmentsServicesNames = this.employee.active_services.map(s => s.establishment.name+': '+s.title).join(' | ');

            return `${word} du ${date} ${complement} de ${name}  (${establishmentsServicesNames})`;
        },
        currentJobs() {
            return this.employee.current_jobs;
        },
        currentOccupations() {
            return this.employee.current_occupations;
        },

        orderedJobs() {
            let jobsSorted = [];

            this.establishments.data.forEach(e => {
                e.services.forEach(s => {

                    const jobs = this.jobs.data
                                    .filter(j => j.service_id == s.id)
                                    .filter(j => !j.deleted_at)
                                    .sort(function(a, b) {
                                        if (a.title > b.title) return 1;
                                        if (a.title < b.title) return -1;
                                        return 0;
                                    });

                    jobsSorted.push({
                        establishmentService: `${e.name} - ${s.title}`,
                        jobs: jobs
                    });
                });
            });

            return jobsSorted.sort(function(a, b) {
                if (a.establishmentService > b.establishmentService) return 1;
                if (a.establishmentService < b.establishmentService) return -1;
                return 0;
            });
        },
        orderedOccupations() {
            let occupationsTypes = [];

            this.occupations.data.forEach(o => {
                if (!o.deleted_at) {
                    let index = occupationsTypes.findIndex(t => t.type === o.type);
                    if (index == -1) {
                        occupationsTypes.push({
                            type: o.type,
                            occupations: [o]
                        });
                    } else {
                        occupationsTypes[index].occupations.push(o);
                    }
                }
            });

            return occupationsTypes
                .map(type => {
                    type.occupations = type.occupations.sort(function(a, b) {
                        if (a.type > b.type) return 1;
                        if (a.type < b.type) return -1;
                        return 0;
                    });
                    return type;
                })
                .sort(function(a, b) {
                    if (a.type > b.type) return 1;
                    if (a.type < b.type) return -1;
                    return 0;
                });
        }
    },
    watch: {
        resource: {
            handler: function(value) {
                //console.log(value)
            },
            deep: true
        },
    },
    methods: {

        initResource() {

            this.resource = window._.cloneDeep(this.employeeRating);

            if (!this.resource.comment || Object.values(this.resource.comment).length == 0) {
                this.resource.comment = {};
            }

            if (!this.resource.employee) {
                this.resource.employee = window._.cloneDeep(this.employee);
            }

            if (!this.resource.externals) {
                this.resource.externals = [];
            }

            if (!this.resource.jobs) {
                this.resource.jobs = window._.cloneDeep(this.employee.current_jobs);
            }

            if (!this.resource.occupations) {
                this.resource.occupations = window._.cloneDeep(this.employee.current_occupations);
            }

            if (!this.resource.personals) {
                this.resource.personals = [];
            }

            if (!this.resource.occ_skills) {
                this.resource.occupations_skills = [];
            } else {
                // Fix - pour le problème de relation pivot (Morph)
                this.resource.occupations_skills = this.resource.occ_skills;
            }

            if (!this.resource.skills) {
                this.resource.skills = [];
            }

            if (!this.resource.self_skills) {
                this.resource.self_skills = [];
            }

            if (!this.resource.comment) {
                this.resource.comment = {};
            }

            if (!this.resource.wishlist_jobs) {
                this.resource.wishlist_jobs = [];
            }

            if (!this.resource.wishlist_occupations) {
                this.resource.wishlist_occupations = [];
            }

            if (!this.resource.occupation_skills_goals) {
                this.resource.occupation_skills_goals = [];
            }

            if (!this.resource.admin_occupation_skills_goals) {
                this.resource.admin_occupation_skills_goals = [];
            }

            if (!this.resource.skills_goals) {
                this.resource.skills_goals = [];
            }

            if (!this.resource.others_goals) {
                this.resource.others_goals = [];
            }

            if (!this.resource.employee.services) {
                this.resource.employee.services = this.resource.employee.active_services
            }

            if (!this.resource.employee.establishments) {
                this.resource.employee.establishments = this.resource.employee.active_establishments
            }

            this.resource.allSkills = true;

            this.initalResource = window._.cloneDeep(this.resource);
        },

        resetResourceEmployee() {
            this.resource.employee = JSON.parse(JSON.stringify(this.employee));
            this.resource.employee.services = this.resource.employee.active_services;
            this.resource.employee.establishments = this.resource.employee.active_establishments;
        },

        async loadEstablishments() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: {
                        perpage: 9999,
                        include: [
                            'service.establishment',
                            'occupation_skills'
                        ],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let jobs = response.data.data
                    this.jobs.data = jobs
                    this.jobs.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "occupation_skills",
                            "admin_occupation_skills",
                        ],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    this.occupations.data = occupations
                    this.occupations.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },

        async loadAdminSkill() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/admin-skills",
                    params: {
                        perpage: 9999,
                        include: [],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let adminSkills = response.data.data
                    this.adminSkills.data = adminSkills
                    this.adminSkills.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },

        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    let colors = response.data;

                    this.colors.data = colors
                    this.colors.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        changePageHandle(prevIndex, nextIndex) {
            //console.log('prevIndex', prevIndex, 'nextIndex', nextIndex)
            this.currentStep = nextIndex;

            if (this.maxStep < nextIndex) {
                this.maxStep = nextIndex;
            }
        },

        /**
         * Bouton Sauvegarder
         */
        saveProjectClickHandle() {

            const hasOccupationsSkillToValidate = this.resource.occupations_skills.filter(os => os.to_validate == true).length > 0;
            const hasSkillToValidate = this.resource.occupations_skills.filter(os => os.to_validate == true).length > 0;

            // if (this.skillReview) {
            //     this.resource.status = 0
            //     this.employeeRating.status = 0; //force
            // }

            if (this.typeForm == "edition" && this.employeeRating.status == -1) {
                this.$swal(
                    "Sauvegarde impossible car un "+this.projectNames.singular+" est déjà en cours",
                    "",
                    "warning"
                );
                return;
            } else if (hasOccupationsSkillToValidate || hasSkillToValidate) {

                // Tous les éléments de la page n'ont pas été validés
                this.$swal({
                    title: `La sauvegarde va valider toutes les différences. Souhaitez-vous continuer ?`,
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annuler"
                }).then(result => {
                    if (result.value) {
                        this.saveRating({ redirect: false });
                        this.resource.occupations_skills.map(os => os.to_validate = false);
                        this.resource.skills.map(os => os.to_validate = false)
                    }
                });
            } else if (
                !this.skillReview &&
                (this.typeForm == "creation" ||
                    this.typeForm == "edition" ||
                    this.typeForm == "poursuite") &&
                this.selfRatingSource === this.selfRatingSourceValues.MOBILITY
            ) {
                this.$swal({
                    title: `Confirmez-vous vouloir utiliser l'auto-évaluation Portail de l'usager pour l'auto-évaluation ?`,
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annuler"
                }).then(result => {
                    if (result.value) {
                        this.saveRating({redirect: false});
                    }
                });
            } else if (this.skillReview && false) {
                this.saveRating({ redirect: false, waitingValidation: true });
            } else {
                this.saveRating({ redirect: false });
            }
        },

        /**
         * Evénement à la fin du formulaire
         */
        endFormHandle() {
            if (this.typeForm == "consultation") {

                // Retour
                this.redirectBack();
            }  else if (this.canValidateSkillReview && this.skillReview) {

                // Validation du bilan
                this.$swal({
                    title: `Êtes-vous sûr de vouloir valider le ${this.skillRatingNames.singular} ? Il ne sera plus modifiable.`,
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annuler"
                }).then(result => {
                    if (result.value) {
                        this.localLoading();
                        this.saveRating({redirect: true, validation: true});
                    }
                });
            } else if (this.canValidateProject && !this.skillReview) {

                // Validation du projet personnalisé
                this.$swal({
                    title: `Êtes-vous sûr de vouloir valider le ${this.projectNames.singular} ? Il ne sera plus modifiable.`,
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annuler"
                }).then(result => {
                    if (result.value) {
                        this.localLoading();
                        this.saveRating({redirect: true, validation: true});
                    }
                });
            } else {
                // Soumettre pour validation
                this.localLoading();
                this.saveRating({ redirect: true, waitingValidation: true});
            }
        },

        /**
         * Sauvegarde
         */
        saveRating({ redirect = true, validation = false, waitingValidation = false }) {
           //console.log('redirect', redirect, 'validation', validation, 'waitingValidation', waitingValidation)
            let successMessage = '';

            if (validation) {
                this.resource.status = 1;
                this.ratingState = 'validation';
            } else if (waitingValidation) {
                this.resource.status = 0;
                this.ratingState = 'edition';
            } else {
                if (!this.resource.status) {
                    this.resource.status = -1;
                }
            }

            // if (this.skillReview) {
            //     this.resource.status = 0; //force
            // }

            // Filtrage des objectifs non choisis
            this.resource.occupation_skills_goals = this.resource.occupation_skills_goals.filter(s => s.occupationSkill != null)
            this.resource.skills_goals = this.resource.skills_goals.filter(s => s.skill != null)

            switch (this.ratingState) {
                case "creation":
                    successMessage = `${this.skillReview ? this.skillRatingNames.singular : this.projectNames.singular} créé et passé au statut "En cours"`;
                    break;
                case "poursuite":
                    successMessage = "Progression enregistrée";
                    break;
                case "edition":
                    successMessage = "Modification enregistrée"
                    break;
                case "validation":
                    successMessage = `${this.skillReview ? this.skillRatingNames.singular : this.projectNames.singular} validé avec succès !`
                    break;
                default:
                    break;
            }

            // Enregistrement du projet en BDD
            this.store().then(response => {

                const result = response.data;
                this.resource.id = result.id;

                if (this.ratingState == 'creation') {
                    this.ratingState = 'poursuite';
                }

                this.notifySuccess("Succès !", successMessage);

                if (redirect) {
                    this.redirectBack();
                }
            });

        },

        store() {

            let method = 'post';
            let url = `/api/gestion/employees/${this.employee.id}/ratings`;

            let data = window._.cloneDeep(this.resource);

            if (this.resource.id) {
                method = 'patch';
                url += `/${this.resource.id}`;
            }

            return axios({
                method: method,
                url: url,
                data: data
            })
            .catch(error => {
                console.error(error);
                this.notifyErrorValidation(error);
                this.endLocalLoading()
            });
        },

        redirectBack() {
            document.location.href = this.backUrl
        },

        validateFormHandle(isValid, tabIndex) {
            this.errorValidateMessage = null;
        },

        errorFormHandle(errorValidateMessage) {
            this.errorValidateMessage = errorValidateMessage;
        },

        /**
         * Met à jour le parcours de la personne
         */
        updateCareerValues(resolve, reject) {
            this.$swal({
                title: `Attention`,
                text: "Vous allez modifier le parcours de la personne. Confirmez-vous cette modification ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Annuler"
            }).then(result => {

                if (result.value == true) {
                    let employee = JSON.parse(JSON.stringify(this.resource.employee));
                    employee.jobs = this.resource.jobs.map(el => el.id);
                    employee.occupations = this.resource.occupations.map(el => el.id);

                    let establishments = {};
                    this.resource.employee.establishments.forEach((item, i) => {
                        establishments[i] = {
                            id: item.id,
                            start_date: item.pivot?.start_date,
                            pivot_id: item.pivot?.id
                        }
                    });

                    let services = {};
                    this.resource.employee.services.forEach((item, i) => {
                        services[i] = {
                            id: item.id,
                            etp: item.pivot?.etp,
                            pivot_id: item.pivot?.id
                        };
                    });

                    employee.services = services;
                    employee.establishments = establishments;

                    window.axios({
                        method: 'patch',
                        url: '/api/gestion/employees/' + employee.id,
                        data: employee
                    })
                    .then(response => {

                        if (response.status === 200 && response.data != '') {
                            this.notifySuccess('Succès', 'Élement ajouté avec succès !')
                            this.carrerUpdated = true;
                            resolve(true);
                        } else {
                            this.notifyError('Erreur', `Une erreur est survenue lors de la création`)
                        }
                    })
                    .catch((err) => {
                        this.notifyErrorValidation(err)
                    })

                } else {
                    this.resetResourceEmployee();
                    this.careerUpdated = false;

                    if (this.typeForm == 'creation') {
                        this.resource.jobs = window._.cloneDeep(this.employee.current_jobs);
                        this.resource.occupations = window._.cloneDeep(this.employee.current_occupations);
                    } else {
                        this.resource.jobs = window._.cloneDeep(this.initalResource.jobs);
                        this.resource.occupations = window._.cloneDeep(this.initalResource.occupations);
                    }

                    reject("Vos modifications sur le parcours de la personne, viennent d'être annulées");
                }
            });
        },

        validateStep1() {
            return new Promise((resolve, reject) => {
                if (!this.resource.rating_date) {
                    reject("Veuillez renseigner tous les champs obligatoires");
                }

                if ((this.typeForm == "creation" ||
                    this.typeForm == "edition" ||
                    this.typeForm == "poursuite") && this.careerUpdated) {
                    this.updateCareerValues(resolve, reject);
                } else {
                    resolve(true);
                }
            });
        },
        validateStep2() {
            document.getElementById("content-area").scrollTop = 0;
            return new Promise((resolve, reject) => {

                const toValidateOccupationSkills = this.resource.occupations_skills.filter(os => os.to_validate === true);
                if (!this.skillReview && toValidateOccupationSkills.length) {
                    reject("Veuillez valider les éléments requis");
                } else {

                    // Cas d'un retour en arrière sur les étapes, on demande s'il faut réinitialiser les compétences pour
                    // regénérer les valeurs par défaut à partir des savoir-faire
                    if (this.maxStep > this.currentStep) {
                        this.$swal({
                            title: `Retour en arrière détecté`,
                            text: "Souhaitez-vous recalculer l'initialisation des compétences à partir des savoir-faire ?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Oui",
                            cancelButtonText: "Non"
                        }).then(result => {

                            if (result.value) {
                                this.resource.skills = [];
                            }
                            resolve(true)
                        });
                    } else {
                        //this.resource.skills = [];
                        resolve(true)
                    }

                }
            });
        },
        validateStep3() {
            document.getElementById("content-area").scrollTop = 0;
            return new Promise((resolve, reject) => {

                const toValidateSkills = this.resource.skills.filter(os => os.to_validate === true);
                if (!this.skillReview && toValidateSkills.length) {
                    reject("Veuillez valider les éléments requis");
                } else {
                    resolve(true)
                }
            });
        },
        validateStep4() {
            return new Promise((resolve, reject) => {

                // Si source mobility (source == 2)
                if (this.resource.self_skills_source == 2) {
                    this.$swal({
                        title: `Confirmez-vous vouloir utiliser l'auto-évaluation Portail de l'usager pour l'auto-évaluation ?`,
                        text: "",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                        cancelButtonText: "Annuler"
                    }).then(result => {
                        resolve(!!result.value);
                    });
                }

                resolve(true)
            });
        },
        validateStep5() {
            return new Promise((resolve, reject) => {
                const notSelectedOccupationSkillGoal = this.resource.occupation_skills_goals.filter(s => s.occupationSkill == null).length > 0;
                const notSelectedSkillGoal = this.resource.skills_goals.filter(s => s.skill == null).length > 0;

                if (notSelectedOccupationSkillGoal || notSelectedSkillGoal) {
                    reject("Veuillez choisir ou supprimer la ligne d'objectif");
                }

                resolve(true);
            });
        },
        validateStep6() {
            return new Promise((resolve, reject) => {
                resolve(true)
            });
        },

        isUserPerimeterService(service) {
            return !!this.user.services.find(s => s.id == service.id)
        },

        loadLastRating() {
            return ratingService.getLastRatings({employee_ids: [this.employee.id], count: 1}).then(response => {
                if (Object.values(response).length > 0) {
                    this.lastRating = response[this.employee.id][0]
                }
                
            })
        },
        initResourceFromLastRating() {

            if (this.lastRating) {
                if (this.resource.occupation_skills_goals.length == 0) {
                    this.resource.occupation_skills_goals = [...this.lastRating.occupation_skills_goals];
                }

                if (this.resource.admin_occupation_skills_goals.length == 0) {
                    this.resource.admin_occupation_skills_goals = [...this.lastRating.admin_occupation_skills_goals];
                }

                if (this.resource.skills_goals.length == 0) {
                    this.resource.skills_goals = [...this.lastRating.skills_goals];
                }

                if (this.resource.others_goals.length == 0) {
                    this.resource.others_goals = [...this.lastRating.others_goals];
                    this.resource.others_goals.id == null;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
form {
    padding-top: 1.5em;
}
.switch {
    margin-bottom: -2rem;
}
.bold {
    font-weight: bold;
}
.prefilled {
    background-color: #fafafa;
    border-bottom: 1px solid white;
    p i {
        color: #727272;
    }
}

::v-deep .vue-form-wizard {
    .wizard-title {
        font-weight: bold !important;
    }
}

#saveQuit {
    position: fixed; right: 56px;
    z-index: 99999
}

#goBack {
    margin-right: 135px;
}

// Modification de la position du bouton suivant pour DEV
// #next {
//     position: absolute;
//     right: 32px;
//     top: 50px;
// }
</style>
