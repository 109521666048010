var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "sort-list", on: { click: _vm.handleClick } },
    [
      _vm.withoutDirection ? _c("i", { staticClass: "fa fa-sort" }) : _vm._e(),
      _vm._v(" "),
      _vm.ascDirection
        ? _c("i", { staticClass: "fa fa-sort-amount-desc" })
        : _vm._e(),
      _vm._v(" "),
      _vm.descDirection
        ? _c("i", { staticClass: "fa fa-sort-amount-asc" })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }