var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sort-container" },
    [
      _c("p", [_vm._v("Trier par :")]),
      _vm._v(" "),
      _c("VueSelect", {
        staticClass: "style-chooser",
        staticStyle: { "margin-left": "4px" },
        attrs: {
          options: _vm.options,
          value: _vm.selectedSort,
          clearable: false
        },
        on: { input: _vm.setSelectedSort }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }