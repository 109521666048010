<template>

    <div class="parentx-static">

        <vs-sidebar
            ref="mainSidebar"
            v-model="isSidebarActive"
            color="primary"
            class="sidebarx main-menu-sidebar items-no-padding"
            parent=".layout--main"
            :hidden-background="true"
            :reduce="false"
            :default-index="currentIndex"
            :click-not-close="false"
            :reduce-not-rebound="false"
            :reduce-not-hover-expand="true"
        >

            <!-- Logo -->
            <div class="header-sidebar" slot="header">
                <div class="logo flex items-center">
                <img class="main-logo-image" src="/assets/images/logo-dark-icon.png" height="32px" />
                <img class="main-logo-image truncate" src="/assets/images/logo-dark-text.png" height="32px" />
                </div>
            </div>

            <div class="scroll-container">
                <div class="shadow-bottom"></div>
                <div class="scroll-area--main-sidebar" ref="sideBar">

                    <vs-sidebar-item href="/rh" ref="sidebarLink" index="/rh" title="Accueil">
                        <feather-icon icon="HomeIcon" class="" />
                        <span class="truncate">Accueil</span>
                    </vs-sidebar-item>

                    <!-- GESTION -->
                    <span class="navigation-header truncate">Espace Utilisateur</span>

                    <!-- TODO - Compte portail -->
                    <vs-sidebar-item v-if="can('read', 'employees')" href="/rh/gestion/personnes" ref="sidebarLink" index="/rh/gestion/personnes" title="Personnes">
                        <feather-icon icon="UsersIcon" class="" />
                        <span class="truncate">Personnes</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'employees')" href="/rh/gestion/parcours" ref="sidebarLink" index="/rh/gestion/parcours" title="Parcours">
                        <feather-icon icon="AlignRightIcon" class="" />
                        <span class="truncate">Parcours</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'employee_ratings')" href="/rh/gestion/bilans" ref="sidebarLink" index="/rh/gestion/bilans" :title="projectNames.plural">
                        <feather-icon icon="CheckSquareIcon" class="" />
                        <span class="truncate">Evaluations</span>
                    </vs-sidebar-item>

                    <app-menu-group 
                        v-if="isASuperAdmin() || isAnAdmin() || isADrh() || isAMonitor()" 
                        icon="PenToolIcon" 
                        name="Suivis" 
                        base-index="/rh/gestion/suivis*" 
                        title="Suivi"
                    >
                        <item 
                            href="/rh/gestion/suivis/objectifs" 
                            icon="TrendingUpIcon" 
                            label="Objectifs" 
                            title="Suivi des objectifs" 
                            required-plan="suivi-objectifs"
                        />
                    </app-menu-group>

                    <app-menu-group 
                        v-if="isASuperAdmin() || isAnAdmin() || isADrh() || isAMonitor()" 
                        icon="PieChartIcon" 
                        name="Tableau de bord" 
                        base-index="/rh/gestion/tableaux*" 
                        title="Tableau de bord" 
                    >
                        <item 
                            href="/rh/gestion/tableaux/personnes" 
                            icon="UserIcon" 
                            label="Personnes" 
                            title="Tableau de bord - Personnes" 
                            required-plan="dashbords"
                        />
                        <item 
                            href="/rh/gestion/tableaux/metiers-postes" 
                            icon="BookmarkIcon" 
                            label="Métiers / postes" 
                            title="Tableau de bord - Métiers / poste" 
                            required-plan="dashbords"
                        />
                        <item 
                            href="/rh/gestion/tableaux/competences" 
                            icon="BoxIcon" 
                            label="Compétences" 
                            title="Tableau de bord - Compétences" 
                            required-plan="dashbords"
                        />
                        <item 
                            href="/rh/gestion/tableaux/savoir-faire" 
                            icon="ApertureIcon" 
                            label="Savoir-faire" 
                            title="Tableau de bord - Savoir-faire" 
                            required-plan="dashbords" 
                        />
                    </app-menu-group>

                    <!-- REFERENTIEL -->
                    <span class="navigation-header truncate">Référentiel</span>

                    <vs-sidebar-item v-if="isAbleTo('read-services', undefined, user)" href="/rh/referentiel/services" ref="sidebarLink" index="/rh/referentiel/services" title="Services">
                        <feather-icon icon="LayersIcon" class="" />
                        <span class="truncate">Services</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'skills')" href="/rh/referentiel/competences" ref="sidebarLink" index="/rh/referentiel/competences" title="Compétences">
                        <feather-icon icon="AwardIcon" class="" />
                        <span class="truncate">Compétences</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'occupation_skills')" href="/rh/referentiel/savoir-faire" ref="sidebarLink" index="/rh/referentiel/savoir-faire" title="Savoir-faire">
                        <feather-icon icon="ApertureIcon" class="" />
                        <span class="truncate">Savoir-faire</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'jobs')" href="/rh/referentiel/postes" ref="sidebarLink" index="/rh/referentiel/postes" title="Postes">
                        <feather-icon icon="ClipboardIcon" class="" />
                        <span class="truncate">Postes/stages</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'occupations')" href="/rh/referentiel/metiers" ref="sidebarLink" index="/rh/referentiel/metiers" title="Métiers">
                        <feather-icon icon="TargetIcon" class="" />
                        <span class="truncate">Métiers</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="isASuperAdmin()" href="/laratrust" ref="sidebarLink" index="/rh/referentiel/laratrust" title="Rôles & Permissions">
                        <feather-icon icon="UnlockIcon" class="" />
                        <span class="truncate">Rôles & Permissions</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'establishments')"
                                    href="/rh/referentiel/etablissements" ref="sidebarLink" index="/rh/referentiel/etablissements" title="Établissements">
                        <feather-icon icon="GridIcon" class="" />
                        <span class="truncate">Établissements</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="can('read', 'users')" href="/rh/referentiel/utilisateurs" ref="sidebarLink" index="/rh/referentiel/utilisateurs" title="Utilisateurs">
                        <feather-icon icon="UsersIcon" class="" />
                        <span class="truncate">Utilisateurs</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item href="/rh/referentiel/contacts" ref="sidebarLink" index="/rh/referentiel/contacts" title="Contacts">
                        <feather-icon icon="MailIcon" class="" />
                        <span class="truncate">Contacts</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item v-if="isASuperAdmin()" href="#" data-href="/rh/referentiel/alertes" ref="sidebarLink" index="/rh/referentiel/alertes" style="opacity: 0.5" title="Message d'informations">
                        <feather-icon icon="InfoIcon" class="" />
                        <span class="truncate">Message d'informations</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item href="/rh/referentiel/statistiques" ref="sidebarLink" index="/rh/referentiel/statistiques" title="Statistiques">
                        <feather-icon icon="TrelloIcon" class="" />
                        <span class="truncate">Statistiques</span>
                        <vs-icon 
                            v-if="!planAllow('indicateurs-activite')" 
                            class="menu-bullet" 
                            bg="#FF9B3A"
                            round 
                        >
                            <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                        </vs-icon>
                    </vs-sidebar-item>

                    <app-menu-group v-if="isASuperAdmin() || isAnAdmin()" icon="SettingsIcon" name="Paramètres" base-index="/rh/referentiel/parametres*" title="Paramètres">
                        <item 
                            v-if="isASuperAdmin()" 
                            href="/rh/referentiel/parametres/parcours" 
                            icon="AlignRightIcon" 
                            label="Parcours" 
                            title="Paramètres - Parcours"
                        />
                        <item 
                            v-if="isASuperAdmin()" 
                            href="/rh/referentiel/parametres/notation" 
                            icon="TagIcon" 
                            label="Notation" 
                            title="Paramètres - Notation"
                        />
                        <item 
                            v-if="isASuperAdmin()" 
                            href="/rh/referentiel/parametres/bilans" 
                            icon="CheckSquareIcon" 
                            label="Evaluations" 
                            title="Paramètres - Evaluations" 
                        />
                        <item 
                            v-if="isASuperAdmin() || isAnAdmin()" 
                            href="/rh/referentiel/parametres/images" 
                            icon="ImageIcon" 
                            label="Images" 
                            title="Paramètres - Images" 
                        />
                        <item 
                            v-if="isASuperAdmin() || isAnAdmin()" 
                            href="/rh/referentiel/parametres/graphisme" 
                            icon="ApertureIcon" 
                            label="Graphisme" 
                            title="Paramètres - Graphisme" 
                        />
                        <item 
                            v-if="isASuperAdmin() || isAnAdmin()" 
                            href="/rh/referentiel/parametres/imports" 
                            icon="ApertureIcon" 
                            label="Imports" 
                            title="Paramètres - Imports" 
                        />
                        <item 
                            v-if="isASuperAdmin()" 
                            href="/rh/referentiel/parametres/exports" 
                            icon="DownloadIcon" 
                            label="Exports" 
                            title="Paramètres - Exports" 
                        />
                        <item 
                            v-if="can('read', 'trainings')" 
                            href="/rh/referentiel/parametres/trainings" 
                            icon="FileIcon" 
                            label="Ressources" 
                            title="Paramètres - Ressources" 
                            required-plan="publication-contenu-peda"
                        />
                    </app-menu-group>

                    <span class="navigation-header truncate" v-if="isASuperAdmin() || isAnAdmin() || isADrh()">Archives</span>
                    <vs-sidebar-item v-if="isASuperAdmin() || isAnAdmin() || isADrh()" href="/rh/gestion/personnes/archives" ref="sidebarLink" index="/rh/gestion/personnes/archives" title="Liste des archivés">
                        <feather-icon icon="UserXIcon" class="" />
                        <span class="truncate">Archivés</span>
                    </vs-sidebar-item>

                    <!-- MOBILITE -->
                    <!-- <span class="navigation-header truncate">Mobilité</span>

                    <vs-sidebar-item href="/rh/mobilite/annonces" ref="sidebarLink" index="/rh/mobilite/annonces">
                        <feather-icon icon="UserCheckIcon" class="" />
                        <span class="truncate">Annonces</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item href="/rh/mobilite/demandes" ref="sidebarLink" index="/rh/mobilite/demandes">
                        <feather-icon icon="TruckIcon" class="" />
                        <span class="truncate">Demandes</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item href="/rh/mobilite/liste-attente" ref="sidebarLink" index="/rh/mobilite/liste-attente">
                        <feather-icon icon="ListIcon" class="" />
                        <span class="truncate">Liste d'attente</span>
                    </vs-sidebar-item>

                    <vs-sidebar-item href="/rh/mobilite/tableau-de-bord-candidats" ref="sidebarLink" index="/rh/mobilite/tableau-de-bord-candidats">
                        <feather-icon icon="BarChart2Icon" class="" />
                        <span class="truncate">Tableau de bord candidats</span>
                    </vs-sidebar-item> -->

                </div>
            </div>

        </vs-sidebar>
    </div>

</template>

<script>
import CrownIcon from '@components/svg/CrownIcon';


export default {
    name: "Menu",
    components: { CrownIcon },
    props: [],
    data() {
        return {
            isSidebarActive: true,
            currentIndex: null,
            group: {
                manageDashboard: {
                    index: 1,
                    name: "Tableau de bord",
                    icon: "PieChartIcon",
                    submenu: [{}, {}]
                }
            }
        }
    },
    computed: {

    },

    methods: {

    },

    created() {
        this.currentIndex = document.location.pathname;
    }
}
</script>

<style lang="scss">
    @import '../../../sass/vuesax/components/vxSidebar.scss';
</style>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f8f8f8;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 3;
  border-radius: 5px;
}

.dropmenu:hover{
  color: rgb(151, 47, 95);
  cursor: pointer;
}

.vs-sidebar--item:last-child {
    margin-bottom: 30px;
}
</style>

<style lang="scss" scoped>
#btnSidebarToggler {
  color: rgb(151, 47, 95);
  padding-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  &.active {
    circle {
      fill:rgb(151, 47, 95);
    }
  }
}
#btnSidebarToggler + .vs-sidebar--item {
  margin-top: 2rem;
}


</style>

<style>

@media screen and (max-width: 1200px) {
    .header-sidebar .truncate,
    .vs-sidebar--item .truncate,
    .vs-sidebar-group .truncate {
        display: none !important;
    }

    .main-menu-sidebar .vs-sidebar {
        max-width: 80px;
    }

    .vs-sidebar--item i.material-icons {
        margin-right: 0;
    }
    .main-menu-sidebar .feather-icon {
        margin-right: 0px;
    }
    .main-menu-sidebar .scroll-container .scroll-area--main-sidebar > .vs-sidebar--item {
        padding: 0 9px;
    }
    .main-menu-sidebar .scroll-container .scroll-area--main-sidebar > .vs-sidebar-group {
        padding: 0 4px;
    }

    .main-menu-sidebar .scroll-container .scroll-area--main-sidebar .vs-sidebar-group-items {
        margin-left: 5px;
    }
}

</style>
