<template>
    <footer v-if="footerDisplay == 'true'" class="the-footer flex-wrap justify-between" :class="classes">
        <span v-if="debug && debug.database !== '' && !fullpage">
            © {{ date }} MySkilliz RH <b>{{ version.split("-")[0].toUpperCase() }}</b> par
            <a href="https://www.quadrare.fr/" target="_blank">
                <b>SAS QUADRARE MYSKILLIZ</b>
            </a>
            (DB:
            <b>{{ debug.database }}</b>, Branch: <b>{{ branch }}</b>, LastUpdate: <b>{{ $moment(commitDate) }}</b>)
        </span>
        <span v-if="debug && debug.database === '' && !fullpage">
            © {{ date }} MySkilliz RH <b>{{ version.split("-")[0].toUpperCase() }}</b> par
            <a href="https://www.quadrare.fr/" target="_blank">
                <b>SAS QUADRARE MYSKILLIZ</b>
            </a>
        </span>
        <span v-if="fullpage">
            © {{ date }} MySkilliz RH par
            <a href="https://www.quadrare.fr/" target="_blank">
                <b>SAS QUADRARE MYSKILLIZ</b>
            </a>
        </span>
        <span class="md:flex hidden items-center footer_links">
            <span>
                <a href="/legal" target="_blank">Mentions Légales</a>
            </span>
            <span>
                <a href="/privacy" target="_blank">Protection des données personnelles</a>
            </span>
            <span>
                <a href="/cgu" target="_blank">CGU</a>
            </span>
            <span>
                <a href="/cgv" target="_blank">CGV</a>
            </span>
        </span>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    props: {
        classes: {
            type: String,
            default: () => "",
        },
        fullpage: {
            type: Boolean,
            default: () => false,
        },
        debug: {
            type: Object,
        },
    },
    data() {
        return {
            footerDisplay:
                process.env.MIX_FOOTER_DISPLAY != undefined
                    ? process.env.MIX_FOOTER_DISPLAY
                    : "true",
            version: VERSION,
            commit: COMMITHASH,
            branch: BRANCH,
            commitDate: LASTCOMMITDATETIME,
        };
    },
    computed: {
        date() {
            const year = Number(new Date().getFullYear());
            return year > 2019 ? `${year} - 2019` : year;
        },
        domain() {
            return document.domain; //this.debug.domain ? this.debug.domain : this.getDomain()
        },
    },
    methods: {
        getDomain() {
            // TODO: get rid of this
            if (document.domain.length) {
                let parts = document.domain.replace(/^(www\.)/, "").split(".");
                while (parts.length > 2) {
                    const subdomain = parts.shift();
                }
                const domain = parts.join(".");
                return `${window.location.protocol}//${domain.replace(/(^\.*)|(\.*$)/g, "")}`;
            }
            return "";
        },
    },
    created() { },
};
</script>
<style lang="scss" scoped>
.footer_links {
    span {
        margin-right: 1rem;
    }
}
</style>
