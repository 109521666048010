<template>
    <div class="select">
        <!-- <vx-card v-if="errors.length > 0" class="mb-10">
        <vs-row>
          <vs-alert color="warning" vs-icon="new_releases" style="height:auto;">
            Les personnes suivantes n'ont pas de {{ projectNames.singular }} :<br />
            <ul v-for="(error, index) in errors" :key="index">
              <li>  - {{ error.last_name }} {{ error.first_name }}</li>
            </ul>
          </vs-alert>
        </vs-row>
      </vx-card>-->
        <vx-card class="mb-4" :title="`Rechercher les compétences`">
            <vs-row class="mb-6">
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Métier</label>
                            <multiselect
                                v-if="shownOccupations.length > 0"
                                v-model="selectedOccupation"
                                :options="occupationsList"
                                :multiple="false"
                                group-values="occupations"
                                group-label="type"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label
                                selected-label
                                deselect-label
                                :disabled="disabledOccupationFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Poste</label>
                            <multiselect
                                v-if="groupedJobsByService.length > 0"
                                v-model="selectedJob"
                                :options="groupedJobsByService"
                                :multiple="false"
                                group-values="jobs"
                                group-label="service"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label
                                select-group-label="Etablissement/service"
                                selected-label
                                deselect-label
                                deselect-group-label
                                :disabled="disabledJobFilter"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Savoir-faire</label>
                            <multiselect
                                v-if="shownOccupationSkills"
                                v-model="selectedOccupationSkill"
                                :options="shownOccupationSkills"
                                :multiple="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="titleEstablishment"
                                select-label
                                selected-label
                                deselect-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                                <span slot="noOptions">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label">Compétence</label>
                            <multiselect
                                v-if="shownSkills.length > 0"
                                v-model="selectedSkills"
                                :options="shownSkills"
                                :multiple="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label
                                selected-label
                                deselect-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <vs-row class="checkboxes-container" vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                    <vs-checkbox
                        v-model="filterByUserRelated"
                        :disabled="disabledFilterByUserRelated"
                    >
                        Mes savoir-faire uniquement
                    </vs-checkbox>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un établissement</label
                            >
                            <multiselect
                                v-if="shownEstablishments.length > 0"
                                v-model="selectedEstablishments"
                                :options="shownEstablishments"
                                :multiple="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label
                                selected-label
                                deselect-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un service</label
                            >
                            <multiselect
                                v-if="
                                    groupedServicesByEstablishments.length > 0
                                "
                                v-model="selectedServices"
                                :options="groupedServicesByEstablishments"
                                :multiple="true"
                                group-values="services"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label
                                select-group-label=" L'établissement"
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card
            v-if="selectedSkills && selectedSkills.length == 1"
            class="mt-10 mb-4"
        >
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-table
                        v-if="ratingParams"
                        :data="employeesFilteredByServicesAndEstablishments"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                    >
                        <template slot="thead">
                            <vs-th>Nom</vs-th>
                            <vs-th>Prénom</vs-th>
                            <vs-th
                                v-for="param in ratingParams"
                                :key="`param-${param.id}`"
                            >
                                {{ param.label }}
                            </vs-th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                                <vs-td :data="data[indextr].first_name">
                                    {{ data[indextr].first_name }}
                                </vs-td>
                                <vs-td :data="data[indextr].last_name">
                                    {{ data[indextr].last_name }}
                                </vs-td>
                                <vs-td
                                    v-for="param in ratingParams"
                                    :key="`param-${param.id}`"
                                    :data="data[indextr]"
                                >
                                    <vs-radio
                                        :key="
                                            `param-${data[indextr].id}-${param.id}`
                                        "
                                        :disabled="true"
                                        :value="
                                            employeeGradePerSkill(
                                                data[indextr],
                                                selectedSkills[0]
                                            )
                                        "
                                        :vs-name="
                                            `param-${data[indextr].id}-${param.id}`
                                        "
                                        color="primary"
                                        :vs-value="parseFloat(param.id)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="6">
                            <label class="typo__label"
                                >Sélectionner l'indicateur à afficher</label
                            >
                            <multiselect
                                v-if="indicators.length > 0"
                                v-model="selectedIndicator"
                                :options="indicators"
                                :multiple="false"
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                selected-label
                                deselect-label
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-table
                        v-if="ratingParams"
                        :data="
                            selectedSkills && selectedSkills.length > 0
                                ? selectedSkills
                                : shownSkills.slice()
                        "
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                    >
                        <template slot="thead">
                            <vs-th style="width:15%;">
                                <Sort name="title" @sort="eventSortByCol">Compétences</Sort>
                            </vs-th>
                            <vs-th
                                v-if="
                                    selectedJob ||
                                        selectedOccupation ||
                                        selectedOccupationSkill
                                "
                                style="width:15%;"
                            >
                                Niveau appelé
                            </vs-th>
                            <vs-th style="width:20%">
                                <Sort name="categoryTitle" @sort="eventSortByCol">Catégorie</Sort>
                            </vs-th>
                            <!-- We used a <th> instead of a <vs-th> because text-align center didn't work with it
                  It may be a problem if we want to use sort-key on the percentage

                  It is complicated to sort on percentages because we don't have access to them in the object selectedSkills or ShownSkills  -->
                            <th
                                v-for="item in tableGroupedServices"
                                :key="
                                    `${
                                        item.id == undefined
                                            ? 'establishment'
                                            : 'service_list'
                                    }-${
                                        item.id == undefined
                                            ? item.title
                                            : item.id
                                    }`
                                "
                                :style="
                                    (item.id == undefined
                                        ? 'color:#972F5F;'
                                        : 'color: #636b6f;') +
                                        'width: 6%; text-align: center'
                                "
                            >
                                {{ item.title }}
                            </th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(tr, indextr) in data"
                                :key="`skill-${indextr}`"
                            >
                                <vs-td
                                    v-tooltip="{
                                        content: data[indextr].description,
                                        placement: 'top-start',
                                        classes: ['occ-description']
                                    }"
                                    :data="data[indextr].title"
                                >
                                    {{ data[indextr].title }}
                                </vs-td>
                                <vs-td
                                    v-if="
                                        selectedJob ||
                                            selectedOccupation ||
                                            selectedOccupationSkill
                                    "
                                >
                                    {{
                                        requiredEntitySkillLevel(
                                            selectedOccupationSkill ||
                                                selectedJob ||
                                                selectedOccupation,
                                            data[indextr],
                                            (type = selectedOccupationSkill
                                                ? "occupation_skill"
                                                : selectedJob
                                                ? "job"
                                                : selectedOccupation
                                                ? "occupation"
                                                : "occupation_skill")
                                        )
                                    }}
                                </vs-td>
                                <vs-td :data="categoryTitle(data[indextr])">
                                    {{ categoryTitle(data[indextr]) }}
                                </vs-td>

                                <vs-td
                                    v-for="item in tableGroupedServices"
                                    :key="
                                        `${
                                            item.id == undefined
                                                ? 'establishment'
                                                : 'service_list'
                                        }-acquisition-${
                                            item.id == undefined
                                                ? item.title
                                                : item.id
                                        }`
                                    "
                                    :style="
                                        item.id == undefined
                                            ? 'color:#972F5F'
                                            : 'color: #636b6f'
                                    "
                                >
                                    <div
                                        v-if="item.id == undefined"
                                        style="font-weight:bold; text-align: center;"
                                    >
                                        {{
                                            acquisitionPerSkillPerEstablishment(
                                                item.services,
                                                data[indextr]
                                            )
                                        }}
                                    </div>
                                    <div v-else style="text-align: center;">
                                        {{
                                            acquisitionPerSkillPerService(
                                                item,
                                                data[indextr]
                                            )
                                        }}
                                    </div>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import ratingService from "@components/services/ratings";

export default {
    name: "SkillDashboard",
    components: {
        Multiselect
    },
    mixins: [ratingChartsHelper],

    props: {
        skills: {
            type: Array,
            require: true
        },
        adminSkills: {
            type: Array,
            require: true
        },
        skill_occupationSkill: {
            type: Array,
            require: true
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true
        }
    },

    data() {
        return {
            errors: [],
            ratings: [],
            loading: false,
            selectedOccupation: null,
            disabledOccupationFilter: false,
            selectedJob: null,
            disabledJobFilter: false,
            selectedSkills: null,
            selectedOccupationSkill: null,
            selectedServices: null,
            selectedIndicator: {
                id: 0,
                title: "Taux d’acquisition par compétence",
                evalFn: level =>
                    this.noteById(level) > this.ratingSettings.NonAcquiredLevel,
                number: false
            },
            selectedEstablishments: null,
            filterByUserRelated: true,
            disabledFilterByUserRelated: false,
            employees: [],
            jobs: [],
            occupations: [],
            occupationskills: [],
            adminOccupationSkills: [],
            establishments: [],
            colors: [],
        };
    },

    async created() {
        await Promise.all([
            this.loadColors(),
            this.loadEmployees(),
            this.loadJobs(),
            this.loadOccupations(),
            this.loadOccupationskills(),
            this.loadAdminOccupationSkills(),
            this.loadEstablishment(),
        ])

        if (this.employeeIds?.length && !this.ratings.length) {
            this.$vs.loading();
            let tempRatings = await ratingService.getLastRatings({
                employee_ids: this.employeeIds.join("#"),
                count: 1
            });
            Object.values(tempRatings).forEach(employeeRatings => {
                employeeRatings.forEach(rating => {
                    this.reconstructRating(rating)
                })
            })
            this.ratings = tempRatings
            this.$vs.loading.close();
        }
    },

    computed: {
        employeeIds() {
            if (!this.employees?.length) return [];
            return this.employees.map(e => e.id);
        },

        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                return Object.keys(this.ratingSettings.params).map(id => {
                    let param = this.ratingSettings.params[id];
                    param.id = id;
                    return param;
                });
            }

            return [];
        },
        //Les indicateurs en dur peuvent reprendre d'autres indicateur qui sont générés. Faire le tri et supprimer les doublons
        indicators() {
            let indicators = [
                {
                    id: 0,
                    title: "Taux d’acquisition par compétence",
                    evalFn: level =>
                        this.noteById(level) >
                        this.ratingSettings.NonAcquiredLevel,
                    number: false
                },
                //Est répété deux fois
                {
                    id: 2,
                    title: "Taux de Non mesuré par compétence",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.NonMesuredLevel,
                    number: false
                },
                {
                    id: 1,
                    title: "Nb de personnes maitrisant la compétence",
                    evalFn: level =>
                        this.noteById(level) >
                        this.ratingSettings.NonAcquiredLevel,
                    number: true
                },
                {
                    id: 7,
                    title: "Nb de personnes Non mesuré sur la compétence",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.NonMesuredLevel,
                    number: true
                },
                {
                    id: 11,
                    title:
                        "Nb de personnes maitrisant la compétence en autonomie",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.AutonomyLevel.coef,
                    number: true
                },
                {
                    id: 10,
                    title:
                        "Taux de personnes maitrisant la compétence en autonomie",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.AutonomyLevel.coef,
                    number: false
                }
            ];
            this.ratingParams.forEach(param => {
                if (param.id == "1" && param.label.lower == "non mesuré") {
                    // continue
                } else {
                    indicators.push({
                        id: 3 + parseFloat(param.id) + 1,
                        title: `Taux de ${param.label} par compétence`,
                        evalFn: level => this.noteById(level) === param.note,
                        number: false
                    });
                    indicators.push({
                        id: 8 + parseFloat(param.id) + 1,
                        title: `Nb de ${param.label} par compétence`,
                        evalFn: level => this.noteById(level) === param.note,
                        number: true
                    });
                }
            });
            return indicators.sort(function(a, b) {
                return a.title.localeCompare(b.title, "fr", {
                    sensitivity: "base"
                });
            });
        },
        shownSkills() {
            // TODO - Revoir tout ce code. Tout cela est un peu fouilli. Mélange entre Skill et AdminSkill

            let skills = [];
            if (this.selectedOccupationSkill) {
                if (this.selectedOccupationSkill.adminskills) {
                    skills = this.selectedOccupationSkill.adminskills.slice();
                } else {
                    skills = this.occupationskillById(
                        this.selectedOccupationSkill.id
                    )["skills"].slice();
                }
            } else if (this.selectedJob) {
                skills = this.$_.unionBy(
                    skills,
                    ...this.selectedJob.occupation_skills.map(o =>
                        o.skills
                            ? o.skills
                            : this.occupationskillsByskills(o.id)
                    ),
                    "admin_skill_id"
                );
            } else if (this.selectedOccupation) {

                if (this.selectedOccupation.admin_id) {
                    skills = this.$_.unionBy(
                        skills,
                        ...this.selectedOccupation.admin_occupation_skills.map(
                            aos =>
                                aos.adminskills
                                    ? aos.adminskills.map(obj => ({
                                          ...obj,
                                          admin_skill_id: obj.id
                                      }))
                                    : []
                        ),
                        "admin_skill_id"
                    );
                } else {
                    skills = this.$_.unionBy(
                        skills,
                        ...this.selectedOccupation.occupation_skills.flatMap(o => o.skills),
                        "admin_skill_id"
                    );
                }
            } else {
                skills = this.skills.slice();
            }

            skills = skills.map(skill => {
                skill = window._.cloneDeep(skill);
                if (!skill.admin_skill) {
                    skill.admin_skill_id = skill.id;
                }

                // Correctif Sylvain du 8/11/2022 - Lorsque skill = admin_skill (a cause de this.selectedOccupation plus haut)
                if (skill.admin_skill) {
                    skill.category = skill.admin_skill.category;
                    skill.title = skill.admin_skill.title;
                    skill.title_falc = skill.admin_skill.title_falc;
                    skill.photo = skill.admin_skill.photo;
                    skill.categoryTitle = skill.category?.title || skill.admin_skill?.category;
                } else {
                    skill.category = skill.category;
                    skill.title = skill.title;
                    skill.title_falc = skill.title_falc;
                    skill.photo = skill.photo;
                    skill.categoryTitle = skill.category.title;
                }


                return skill;
            });

            skills.sort((a, b) => {
                return a.title.localeCompare(b.title, "fr", {
                    sensitivity: "base"
                });
            });

            return skills;
        },

        shownOccupationSkills() {
            let occupationskills = [];
            if (this.selectedJob) {
                //Only Client OccupationSkills
                let jobOccupationSkills = this.selectedJob.occupation_skills
                    ? this.selectedJob.occupation_skills
                    : this.joboccupationskills(this.selectedJob.id);
                occupationskills = jobOccupationSkills
                    .slice()
                    .sort((a, b) => +a.pivot.order - +b.pivot.order);
            } else if (this.selectedOccupation) {
                if (this.selectedOccupation.admin_id) {
                    occupationskills = this.selectedOccupation
                        .admin_occupation_skills
                        ? this.selectedOccupation.admin_occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                } else {
                    occupationskills = this.selectedOccupation.occupation_skills
                        ? this.selectedOccupation.occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                }
            } else {
                //Only Client OccupationSkills
                occupationskills = this.occupationskills
                    .slice()
                    .sort((a, b) => {
                        return a.title.localeCompare(b.title, "fr", {
                            sensitivity: "base"
                        });
                    });
            }

            if (this.filterByUserRelated) {
                occupationskills = occupationskills.filter(os => {
                    return (
                        !os.establishment_id ||
                        this.userEstablishmentsIds.includes(os.establishment_id)
                    );
                });
            }

            return occupationskills.map(os => ({
                ...os,
                titleEstablishment:
                    os.title +
                    (os.establishment ? " - " + os.establishment.name : "")
            }));
        },

        // A factoriser, problème de chargement des etablissements lorsque l'on met la fonction dans establishments.js
        shownEstablishments() {
            // Liste des établissements basée sur le périmètre de consultation des personnes
            if (this.establishments && this.establishments.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    return this.establishments
                        .slice()
                        .filter(e =>
                            userServices
                                .map(service => service.establishment_id)
                                .includes(e.id)
                        );
                }
            }
            return [];
        },
        employeesFilteredByServicesAndEstablishments() {
            let toShow = [];
            const establishments =
                this.selectedEstablishments &&
                this.selectedEstablishments.length > 0
                    ? this.selectedEstablishments
                    : this.shownEstablishments;
            establishments.forEach(e => {
                let employees = [];
                const establishmentEmployees = this.employees
                    ? this.employees.filter(
                          employee =>
                              employee.services.length &&
                              employee.services?.map(s => s.establishment_id).includes(e.id)
                      )
                    : [];
                if (this.selectedServices && this.selectedServices.length > 0) {
                    const services = this.selectedServices.map(s => s.id);
                    employees = establishmentEmployees.filter(employee =>
                        services.filter(s => employee.services.map(es => es.id).includes(s)).length
                    );
                } else {
                    employees = establishmentEmployees;
                }
                toShow.push(...employees);
            });
            return this.$_.uniqBy(toShow, "id");
        },
        tableGroupedServices() {
            let data = [];
            let services = [];
            const servicesByEstablishments =
                this.selectedServices && this.selectedServices.length > 0
                    ? this.groupedServicesSelectedByEstablishments
                    : this.groupedServicesByEstablishments;
            servicesByEstablishments.forEach(group => {
                services = group.services;
                data.push({
                    title: group.establishment,
                    services: services
                });
                services.forEach(service => {
                    data.push(service);
                });
            });
            return data;
        },
        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments =
                this.selectedEstablishments &&
                this.selectedEstablishments.length > 0
                    ? this.selectedEstablishments
                    : this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services
                              .filter(
                                  service =>
                                      service.establishment_id === e.id &&
                                      this.listOfServicesViewableFor(
                                          "employees"
                                      ).includes(service.id)
                              )
                              .sort(this.sortServices)
                        : []
                });
            });
            return toShow;
        },
        groupedServicesSelectedByEstablishments() {
            let toShow = [];
            let tabEstablishmentsServices = [];
            this.selectedServices.forEach(s => {
                if (tabEstablishmentsServices.length > 0) {
                    var found = 0;
                    tabEstablishmentsServices.forEach(e => {
                        if (e.establishment.id === s.establishment.id) {
                            found = 1;
                            e.services.push(s);
                        }
                    });
                    if (found === 0) {
                        tabEstablishmentsServices.push({
                            establishment: s.establishment,
                            services: [s]
                        });
                    }
                } else {
                    tabEstablishmentsServices.push({
                        establishment: s.establishment,
                        services: [s]
                    });
                }
            });
            tabEstablishmentsServices.forEach(group => {
                toShow.push({
                    establishment: group.establishment.name,
                    services: group.services
                        ? group.services.sort(this.sortServices)
                        : []
                });
            });
            return toShow;
        },

        groupedJobsByService() {
            let toShow = [];
            let services = this.services
                ? this.services.slice().sort((a, b) => {
                      return a.establishment.name.localeCompare(
                          b.establishment.name,
                          "fr",
                          { sensitivity: "base" }
                      );
                  })
                : [];

            services.forEach(service => {
                toShow.push({
                    service: service.establishment.name + " / " + service.title,
                    jobs: this.jobs
                        ? this.jobs.filter(
                              j =>
                                  j.service_id === service.id &&
                                  this.listOfServicesViewableFor(
                                      "jobs"
                                  ).includes(service.id)
                          )
                        : []
                });
            });

            return toShow;
        },

        shownOccupations() {
            return this.occupations ? this.occupations.slice() : [];
        },

        occupationsTypes() {
            let types = this.shownOccupations.map(t => t.type);
            return types
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b));
        },

        occupationsList() {
            let occupations = this.shownOccupations;
            let types = this.occupationsTypes.filter(id =>
                occupations.map(occ => occ.type).includes(id)
            );

            let occupationsList = [];
            types.forEach(type => {
                occupationsList.push({
                    type: type,
                    occupations: occupations
                        .filter(occ => occ.type == type)
                        .sort((a, b) => a.title.localeCompare(b.title))
                });
            });

            return occupationsList;
        },

        shownEmployees() {
            let employees = [];
            const service = this.serviceFilter;

            employees = this.employees;

            if (service && service.title) {
                employees = employees
                    .slice()
                    .filter(e => e.services.map(s => s.id).includes(service))
            }

            if (this.employeeFilter.length > 0) {
                employees = this.employeeFilter;
            }

            return employees;
        },

        userEstablishments() {
            if (this.user && this.user.establishments?.length) {
                return this.user.establishments;
            } else if (
                this.isASuperAdmin(this.user) ||
                (this.isAnAdmin(this.user) &&
                    this.user.establishments?.length == 0)
            ) {
                return this.establishments;
            }
            return [];
        },
        userEstablishmentsIds() {
            return this.userEstablishments.flatMap(e => e.id);
        }
    },
    watch: {
        async employeeIds() {
            if (this.employeeIds?.length) {
                this.loading = true;
                let tempRatings = await ratingService.getLastRatings({
                    employee_ids: this.employeeIds.join('#'),
                    count: 1
                });
                Object.values(tempRatings).forEach(employeeRatings => {
                    employeeRatings.forEach(rating => {
                        this.reconstructRating(rating)
                    })
                })
                this.ratings = tempRatings
                this.loading = false;
            }
        },
        selectedOccupation(newV) {
            this.selectedOccupationSkill = null;
            this.selectedSkills = null;
            if (newV) {
                this.selectedJob = null;
                this.selectedSkills = null;
                this.filterByUserRelated = false;
                this.disabledFilterByUserRelated = true;
            } else if (!this.selectedJob && !this.selectedOccupationSkill) {
                this.filterByUserRelated = true;
                this.disabledFilterByUserRelated = false;
            }
        },

        selectedJob(newV) {
            this.selectedOccupationSkill = null;
            this.selectedSkills = null;
            if (newV) {
                this.selectedOccupation = null;
                this.selectedSkills = null;
                this.selectedOccupationSkill = null;
                this.filterByUserRelated = false;
                this.disabledFilterByUserRelated = true;
            } else if (
                !this.selectedOccupation &&
                !this.selectedOccupationSkill
            ) {
                this.filterByUserRelated = true;
                this.disabledFilterByUserRelated = false;
            }
        },

        selectedServices(newV) {
            if (newV) {
                //this.selectedJob = null
            }
        },

        selectedEstablishments(newV) {
            if (newV) {
                this.selectedServices = null;
            }
        },
        loading(newValue) {
            if (newValue) {
                this.localLoading();
            } else {
                this.endLocalLoading();
            }
        }
    },

    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    this.colors = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, include: ['services.establishment'] }
                })
                .then(response => {
                    this.employees = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        employeesByService(id) {
            return this.employees.filter(e => e.services.map(s => s.id).includes(id))
        },

        employeesByServices(ids) {
            return this.employees.filter(e => !!ids.filter(s => e.services.map(es => es.id).includes(s)).length)
        },

        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: { perpage: 9999, include: ['occupation_skills'] }
                })
                .then(response => {
                    let jobs = response.data.data
                    jobs.forEach(j => {
                        j.occupation_skills.forEach(jos => {
                            this.reconstructSkillOccupationSkillRelation(jos, this.skill_occupationSkill)
                        })
                    })
                    this.jobs = jobs;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        joboccupationskills(id) {
            const job = this.jobs.slice().find(j => j.id === id)
            return job ? job.occupation_skills : []
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills",
                            "occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    occupations.forEach(o => {
                        o.occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.skill_occupationSkill, false)
                        })
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.adminSkill_adminOccupationSkill, true)
                        })
                    })
                    this.occupations = occupations;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        skillById(id) {
            return this.skills.find(s => s.id == id);
        },

        async loadOccupationskills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"],
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data
                    occupationskills.forEach(os => {
                        this.reconstructSkillOccupationSkillRelation(os, this.skill_occupationSkill, false)
                    })
                    this.occupationskills = occupationskills
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupationskills.find(os => os.id == id);
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: {'title': 'asc'} }
                })
                .then(response => {
                    let adminOccupationskills = response.data.data
                    adminOccupationskills.forEach(aos => {
                        this.reconstructSkillOccupationSkillRelation(aos, this.adminSkill_adminOccupationSkill, true)
                    })
                    this.adminOccupationSkills = adminOccupationskills
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        adminoccupationskillById(id) {
            return this.adminOccupationSkills.find(os => os.id == id);
        },

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        round(value, decimals) {
            return Number(value.toFixed(decimals));
        },

        requiredEntitySkillLevel(entity, skill, type = "occupation_skill") {
            let requiredLevel = null;
            switch (type) {
                case "occupation_skill": {
                    //entity = occupation_skill
                    const skillLevel = entity.adminskills
                        ? entity.adminskills.find(
                              s => s.id == skill.admin_skill_id
                          )
                        : entity.skills.find(
                              s => s.admin_skill_id == skill.admin_skill_id
                          );
                    if (skillLevel && skillLevel.pivot) {
                        if (
                            requiredLevel == null ||
                            (this.levelById(skillLevel.pivot.required_level)
                                ? this.levelById(
                                      skillLevel.pivot.required_level
                                  ).note
                                : this.NonMesuredLevel) >
                                (this.levelById(requiredLevel)
                                    ? this.levelById(requiredLevel).note
                                    : this.NonMesuredLevel)
                        ) {
                            requiredLevel = skillLevel.pivot.required_level;
                        }
                    }
                    break;
                }
                case "job": {
                    //entity = job
                    entity.occupation_skills.forEach(occupation_skill => {
                        const skillLevel = occupation_skill.skills.find(
                            s => s.admin_skill_id == skill.admin_skill_id
                        );
                        if (skillLevel && skillLevel.pivot) {
                            if (
                                requiredLevel == null ||
                                (this.levelById(skillLevel.pivot.required_level)
                                    ? this.levelById(
                                          skillLevel.pivot.required_level
                                      ).note
                                    : this.NonMesuredLevel) >
                                    (this.levelById(requiredLevel)
                                        ? this.levelById(requiredLevel).note
                                        : this.NonMesuredLevel)
                            ) {
                                requiredLevel = skillLevel.pivot.required_level;
                            }
                        }
                    });
                    break;
                }
                case "occupation": {
                    //entity = occupation
                    entity.admin_occupation_skills.forEach(occupation_skill => {
                        const skillLevel = occupation_skill.adminskills.find(
                            s => s.id == skill.admin_skill_id
                        );
                        if (skillLevel && skillLevel.pivot) {
                            if (
                                requiredLevel == null ||
                                (this.levelById(skillLevel.pivot.required_level)
                                    ? this.levelById(
                                          skillLevel.pivot.required_level
                                      ).note
                                    : this.NonMesuredLevel) >
                                    (this.levelById(requiredLevel)
                                        ? this.levelById(requiredLevel).note
                                        : this.NonMesuredLevel)
                            ) {
                                requiredLevel = skillLevel.pivot.required_level;
                            }
                        }
                    });
                    break;
                }
            }
            return this.levelById(requiredLevel)
                ? this.levelById(requiredLevel).label
                : "";
        },

        acquisitionPerSkillPerEstablishment(services, skill) {
            const evalFn = this.selectedIndicator
                ? this.selectedIndicator.evalFn
                : this.indicators.find(i => i.id === 0).evalFn;
            const servicesEmployees = this.employeesByServices(
                services.map(s => s.id)
            );
            if (servicesEmployees.length == 0) {
                return this.selectedIndicator && this.selectedIndicator.number
                    ? 0
                    : `0%`;
            }
            let counter = 0;
            servicesEmployees.forEach(e => {
                const level = parseFloat(this.employeeGradePerSkill(e, skill));
                counter += evalFn(level) ? 1 : 0;
            });
            return this.selectedIndicator && this.selectedIndicator.number
                ? counter
                : `${this.round(
                      (counter / servicesEmployees.length) * 100,
                      0
                  )}%`;
        },

        acquisitionPerSkillPerService(service, skill) {
            const evalFn = this.selectedIndicator
                ? this.selectedIndicator.evalFn
                : this.indicators.find(i => i.id === 0).evalFn;
            const serviceEmployees = this.employeesByService(service.id);
            if (serviceEmployees.length === 0) {
                return this.selectedIndicator && this.selectedIndicator.number
                    ? 0
                    : `0%`;
            }
            let counter = 0;
            serviceEmployees.forEach(e => {
                const level = parseFloat(this.employeeGradePerSkill(e, skill));
                counter += evalFn(level) ? 1 : 0;
            });

            return this.selectedIndicator && this.selectedIndicator.number
                ? counter
                : `${this.round(
                      (counter / serviceEmployees.length) * 100,
                      0
                  )}%`;
        },

        employeeGradePerSkill(employee, skill) {
            const rating = this.employeeRating(employee); // TODO:
            let level = this.findParameter(this.ratingSettings.NonMesuredLevel);

            if (!Object.values(rating).length) return level;

            const skillLevel = rating.skills.find(
                s => s.admin_skill_id == skill.admin_skill_id
            );
            if (skillLevel) {
                level = skillLevel.pivot.level;
            }
            return level;
        },

        findParameter(NonMesuredLevel) {
            if (this.ratingSettings.params) {
                switch (NonMesuredLevel) {
                    case this.ratingSettings.params[1].note:
                        return 1;
                    case this.ratingSettings.params[2].note:
                        return 2;
                    case this.ratingSettings.params[3].note:
                        return 3;
                    case this.ratingSettings.params[4].note:
                        return 4;
                    default:
                        return null;
                }
            }

            return null;
        },

        sortJobs(a, b) {
            if (a.service.title > b.service.title) return 1;
            if (a.service.title < b.service.title) return -1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
        },
        sortServices(a, b) {
            // if (a.establishment.name > b.establishment.name) return 1
            // if (a.establishment.name < b.establishment.name) return -1
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        },

        /*occupationServices(occupation) {
        let services = occupation.jobs.slice().map(j => j.service)
        return this.$_.uniqBy(services, 'id')
      },*/

        scrollTo(id) {
            return {
                el: `#chart-${id}`,
                offset: -200
            };
        },
        chartOptionsWith(employee, score, rating, job) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            const hasThisJob = employee.jobs
                ? employee.jobs.filter(j => j.id === job.id).length > 0
                : false;
            const wantsThisJob = rating.wishlist_jobs
                ? rating.wishlist_jobs.filter(j => j.id === job.id).length > 0
                : false;
            options.title.text = `${employee.last_name.toUpperCase()} ${
                employee.first_name
            } ${hasThisJob ? "(actuel)" : ""} ${
                wantsThisJob ? "(souhaité)" : ""
            }: ${score}%`;
            options.tooltips = {
                enabled: true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value = Math.floor(tooltipItem.yLabel);
                        return ` ${label}: ${value}`;
                    }
                }
            };
            return options;
        },
        occupationChartOptionsWith(employee, score, rating, occupation) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            const wantsThisOccupation = rating.wishlist_occupations
                ? rating.wishlist_occupations.filter(
                      o => o.id === occupation.id
                  ).length > 0
                : false;
            options.title.text = `${employee.last_name.toUpperCase()} ${
                employee.first_name
            } ${wantsThisOccupation ? "(souhaité)" : ""}: ${score}%`;
            options.scale.ticks.min = this.ratingSettings
                ? this.ratingSettings.RadarCenter
                : -1;
            options.tooltips = {
                enabled: true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value = Math.floor(tooltipItem.yLabel);
                        return ` ${label}: ${value}`;
                    }
                }
            };
            return options;
        },

        employeeRating(employee) {
            if (!Object.values(this.ratings).length) return [];
            if (!this.ratings[employee.id]) return [];
            return this.ratings[employee.id][0];
        },

        categoryTitle(skill) {
            let categoryTitle = "_";

            /*if (!skill.admin_skill)
          skill.admin_skill = this.adminSkillById(skill.admin_skill_id)

        if (skill.admin_skill.category) {
          categoryTitle = skill.admin_skill.category.title
        } else {
          const category = this.categoryById(skill.admin_skill.category_id)
          if (category && category.title) {
            categoryTitle = category.title
          }
        }*/
            if (skill.categoryTitle) {
                categoryTitle = skill.categoryTitle;
            }

            return categoryTitle;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.select::v-deep .multiselect {
    font-size: 14px;
    color: grey;
    .multiselect__single {
        font-size: 14px;
        color: grey;
    }
    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;
        &-icon:hover {
            background: rgb(151, 47, 95);
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }
    .multiselect__select:before {
        top: 45%;
    }
    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }
    .multiselect__tag-icon::after {
        background: rgb(151, 47, 95);
    }
    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: rgb(151, 47, 95);
            }
        }
    }
}
.checkboxes-container {
    padding: 10px 1em 0;
    border-top: 1px solid $grid-gray;
    z-index: 0;
}
</style>
