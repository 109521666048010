import './mixins/permissionsMixin'
import './mixins/generalMixin'
import './mixins/dateMixin'
//import './mixins/broadcastMixin'
import './mixins/errorAndSuccessMixin'
import './mixins/stringMixin'
import './mixins/imageMixin'
import './mixins/colors'
import './mixins/getters'
import './mixins/groupByMixin'
import './mixins/listMixin'
import './mixins/apiMixin'
import './mixins/arrayMixin'
import './mixins/fileMixin'
