var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { staticClass: "mb-5" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeInfos", {
                attrs: { employee: _vm.resource, endReasons: _vm.endReasons }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-10" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [_c("EmployeeBadges", { attrs: { employee: _vm.resource } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeJobsStages", {
                attrs: {
                  api: "/api/gestion/employees/" + _vm.resource.id + "/jobs",
                  employee: _vm.resource,
                  documentLicence: _vm.documentLicence,
                  receptionTypes: _vm.receptionTypes
                },
                on: {
                  change: _vm.updateEmployee,
                  "invite-tiers": function($event) {
                    return _vm.createEvaluatorResource(
                      _vm.userType,
                      "experience"
                    )
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeOccupations", {
                attrs: {
                  api:
                    "/api/gestion/employees/" +
                    _vm.resource.id +
                    "/occupations",
                  employee: _vm.resource,
                  documentLicence: _vm.documentLicence
                },
                on: {
                  change: _vm.updateEmployee,
                  "invite-tiers": function($event) {
                    return _vm.createEvaluatorResource(
                      _vm.userType,
                      "occupation"
                    )
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeRAE", {
                attrs: {
                  api:
                    "/api/gestion/employees/" +
                    _vm.resource.id +
                    "/experiences/rae",
                  employee: _vm.resource,
                  documentLicence: _vm.documentLicence
                },
                on: { change: _vm.updateEmployee }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeTrainings", {
                attrs: {
                  api:
                    "/api/gestion/employees/" +
                    _vm.resource.id +
                    "/experiences/trainings",
                  employee: _vm.resource,
                  financingTypes: _vm.financingTypes,
                  documentLicence: _vm.documentLicence
                },
                on: { change: _vm.updateEmployee }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeHabilitation", {
                attrs: {
                  api:
                    "/api/gestion/employees/" +
                    _vm.resource.id +
                    "/experiences/certificates",
                  employee: _vm.resource,
                  documentLicence: _vm.documentLicence
                },
                on: { change: _vm.updateEmployee }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeSuivi", {
                attrs: {
                  api:
                    "/api/gestion/employees/" +
                    _vm.resource.id +
                    "/experiences/follows",
                  employee: _vm.resource,
                  documentLicence: _vm.documentLicence
                },
                on: { change: _vm.updateEmployee }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mb-20" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("EmployeeEvaluators", {
                attrs: { employee: _vm.resource },
                on: {
                  change: _vm.updateEmployee,
                  "update-tiers": function($event) {
                    return _vm.updateEvaluatorResource($event)
                  },
                  "delete-tiers": function($event) {
                    return _vm.deleteEvaluatorResource($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-prompt",
        {
          attrs: {
            active: _vm.showPopup,
            title: _vm.getDataPrompt.title,
            "cancel-text": "Fermer",
            "accept-text": "Enregistrer",
            "button-accept": _vm.getDataPrompt.btnAccept,
            "is-valid": !_vm.formError
          },
          on: {
            "update:active": function($event) {
              _vm.showPopup = $event
            },
            cancel: _vm.resetEvaluatorResource,
            accept: _vm.storeEvaluatorResource,
            close: _vm.resetEvaluatorResource
          }
        },
        [
          _c("UserForm", {
            attrs: {
              type: _vm.formType,
              "user-type": _vm.userType,
              "users-list": true,
              reduced: _vm.reduced,
              "reduced-for": _vm.resource.id,
              "reduced-type": _vm.reducedType,
              "form-validation-errors": _vm.formValidationErrors
            },
            on: { error: _vm.setEvaluatorFormError },
            model: {
              value: _vm.evaluatorResource,
              callback: function($$v) {
                _vm.evaluatorResource = $$v
              },
              expression: "evaluatorResource"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }