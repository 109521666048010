<template>
    <div>
        <h3>Badges disponibles</h3>
        <vs-table
            :sst="true"
            :data="items.data"
            no-data-text="Aucune donnée à afficher"
            @sort="eventSortByCol"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol" :value="apiParams.sortBy['title']">Libellé</Sort>
                </vs-th>
                <vs-th class="text-center">
                    Évaluation
                </vs-th>
                <vs-th class="text-center">
                    Auto-évaluation
                </vs-th>
                <vs-th class="text-center">
                    Évaluation tiers
                </vs-th>
                <vs-th>

                </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td>
                        <feather-icon icon="AwardIcon" title="Compétence"></feather-icon>
                    </vs-td>
                    <vs-td :data="item.title">
                        <span class="badge-title">{{ item.title }}</span>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>
                    <vs-td class="text-center" :title="`Évaluation du ${formatDate(item.external_rating.rating_date)}`">
                        <CheckGreenIcon style="width: 20px"/>
                    </vs-td>

                    <vs-td v-if="can('read', 'employees') && can('create', 'badges')" style="width: 180px">
                        <vs-button
                            v-tooltip="'Demander'"
                            color="primary"
                            type="filled"
                            icon-pack="feather"
                            @click="createResource(item)"
                        >
                            Demander le badge
                        </vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
import CheckGreenIcon from '@components/svg/CheckGreenIcon';
import { formatDate } from "@assets/utils/helpers/helpers.js";


export default {
    components: {
        CheckGreenIcon
    },
    props: {
        api: {
            type: String
        },
        employee: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            log: console.log,
            items: {
                data: [],
                loading: false
            },
        }
    },

    methods: {
        createResource(item) {
            this.$swal({
                title: `Confirmation`,
                html: `<img src="data:image/png;base64,${item.image_base64}" alt="badge" style="width: 350px" /> <br> Merci de confirmer la demande`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Je confirme',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: "post",
                        url: `/api/gestion/employees/${this.employee.id}/badges`,
                        data: {
                            title: item.title,
                            skill_id: item.badgeable_id,
                            rating_id: item.rating_id,
                            external_rating_id: item.external_rating_id
                        }
                    })
                    .then(response => {
                        this.loadItems();
                        this.$emit('asked');
                    })
                    .catch(err => {
                        console.log(err);
                        this.notifyError(err, `Une erreur est survenue`);
                    });

                }
            })
        }
    },
    created() {
        this.apiParams = {
            sortBy: {"title": "asc"},
            perpage: 10,
            include: ["badgeable.admin_skill", "rating", "external_rating"]
        };
        this.loadItems();
    },
}
</script>

<style scoped>
.badge-title {
    font-size: 1.4em;
    font-weight: 600;
    color: #7f7f7f;
}
</style>
