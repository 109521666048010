<template>
    <vs-row id="employeeForm" class="con-exemple-prompt">
        <vs-col class="sm:w-3/5 w-full">
            <vs-row>
                <vs-col>
                    <label>Établissement*</label>
                    <Multiselect
                        v-model="form.establishment"
                        style="height: 10px;"
                        :options="currentEstablishments"
                        :group-select="false"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
            </vs-row>

            <vs-row class="mt-3">
                <vs-col>
                    <vs-input
                        v-model="form.end_date"
                        type="date"
                        name="employeeEstablishmentEnd"
                        label="Date de sortie*"
                        class="w-full"
                    />
                </vs-col>
            </vs-row>

            <vs-row class="mt-3">
                <vs-col>
                    <label>Motif de sortie*</label>
                    <Multiselect
                        v-model="form.end_reason"
                        style="height: 10px;"
                        :options="endReasons"
                        :group-select="false"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
            </vs-row>

            <vs-row v-if="form.end_reason && form.end_reason.id == 99" class="mt-3">
                <vs-col>
                    <vs-input v-model="form.comment" name="comment" label="Commentaire" class="w-full" />
                </vs-col>
            </vs-row>
        </vs-col>
        <vs-col class="sm:w-2/5 w-full">
            <vs-row>
                <vs-col>
                    <label>Date d'entrée</label>
                    <div>
                        {{ getStartDate }}
                    </div>
                </vs-col>
            </vs-row>
        </vs-col>
    </vs-row>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { formatDate } from "@assets/utils/helpers/helpers.js";
export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        employee: {
            required: true,
            type: Object
        },
        endReasons: {
            required: true,
            type: Array
        },
    },
    data () {
        return {
            log: console.log,
            form: {
                establishment: null,
                end_date: null,
                end_reason: null,
                comment: null
            }
        }
    },
    watch: {
        form: {
            handler(newValue, oldValue) {
                this.$emit('input', this.form);
            }, deep: true
        },
        hasError (value) {
            this.$emit('error', value)
        }
    },
    computed: {
        hasError() {
            let error = !this.form.establishment || !this.form.end_date || !this.form.end_reason
            return !!error;
        },
        currentEstablishments() {
            return this.employee.establishments;
        },
        getStartDate() {
            if (this.form.establishment) {
                return formatDate(this.form.establishment.pivot.start_date, '/')
            } else {
                return `Veuillez choisir un établissement`
            }
        }
    },
    created() {
        this.form.establishment = this.value.establishment;
        this.form.end_date = this.value.end_date;
        this.form.end_reason = this.value.end_reason;
    }
}
</script>
