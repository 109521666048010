<template>
  <div  :style="getStyle" class="bg-white" id="ratingsToValidateCard">
    <vx-card>
    <h2 class="mb-2">
        {{ projectNames.plural }} à valider
      </h2>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-table
          no-data-text="Aucune donnée à afficher"
          style="width:100%"
          :data="ratings"
        >
          <template slot="thead">
            <vs-th>Nom</vs-th>
            <vs-th>Prénom</vs-th>
            <vs-th>Date création</vs-th>
            <vs-th>Service</vs-th>
            <vs-th>Etablissement</vs-th>

            <vs-th>Valider</vs-th>
          </template>

          <template slot-scope="{ data }">
              <vs-tr v-for="(item, i) in ratings" :key="i" :data="item">
                  <vs-td>
                      <p>{{ get(item, ":employee.last_name") }}</p>
                  </vs-td>
                  <vs-td>
                      <p>{{ get(item, ":employee.first_name") }}</p>
                  </vs-td>
                  <vs-td>
                      <p>{{ get(item, ":created_at") }}</p>
                  </vs-td>
                  <vs-td>
                      {{ get(item, ":employee.services").map(s => s.title).join(", ") }}
                  </vs-td>
                  <vs-td>
                      <p>{{ get(item, ":employee.services").map(s => s.establishment.name).join(", ") }}</p>
                  </vs-td>
                  <vs-td class="action">
                      <vs-button
                          v-if="canMulti('update', 'rating_validations', item.employee.services.length > 0 ? item.employee.services.map(s => s.id) : null)"
                          :id="'createRatingEmployee_' + i"
                          :href="`/rh/gestion/personnes/${item.employee.id}/bilans/valider/${item.id}`"
                          :v-tooltip="'Valider le '+projectNames.singular"
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-check-square"
                      />
                      <vs-button
                          v-else
                          :id="'readRatingEmployee_' + i"
                          :href="`/rh/gestion/personnes/${item.employee.id}/bilans/consulter/${item.id}`"
                          v-tooltip="'Consulter'"
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-eye"
                      />
                  </vs-td>
              </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
    </vx-card>
  </div>
</template>
<script>
import EmployeeRatingForm from '@components/views/rh/gestion/employee/rating/RatingForm'
export default {
  components: {
    EmployeeRatingForm
  },
  data () {
    return {
      ratings:'',
    }
  },
  computed: {
    getStyle () {
      return 'height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;'
    }
  },
  created() {
    this.loadRatings();
  },
  methods: {
    showResource(resource) {
      this.resetResource();
      this.setResource(resource);
      this.showPopup = true;
    },
    setResource(data) {
      this.resource = JSON.parse(JSON.stringify(data));
    },
    resetResource() {
      this.resource = {
          title: null,
          description: null
      };
    },
    loadRatings() {
        window.axios({
            method: 'get',
            url: '/api/gestion/employees/-1/ratings/statuses',
            params: {statuses:[0], perpage: 4, sortBy: {'created_at': 'asc'}, include: ['employee.services.establishment'], filter: 'skill_review|=|0'},
        })
        .then(response => {
          response.data.data.forEach(element =>
            {
              element['created_at'] = new Date(element['created_at']).toLocaleString('fr-FR');
            });
            this.ratings = response.data.data;
        })
        .catch((err) => {
            console.log(err)
            this.notifyError(err, `Une erreur est survenue`)
        })
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
    .vx-card {
        height: 100%;
    }
}
</style>
