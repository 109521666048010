var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selector-container" }, [
    _c(
      "div",
      [
        _vm.hasSectors
          ? _c(
              "vs-row",
              { staticClass: "mb-6" },
              [
                _c(
                  "vs-col",
                  { attrs: { "vs-w": "12" } },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        name: "search",
                        placeholder: "Filtrer les filières affichées..."
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("vs-row", [
          _c("span", { staticClass: "vs-col" }, [
            _vm._v(
              "\n                Glissez-déposez les filières que vous souhaitez ajouter :\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _vm.hasSectors
          ? _c(
              "vs-row",
              { staticClass: "mt-5" },
              [
                _c(
                  "vs-col",
                  { attrs: { "vs-w": "5.5" } },
                  [
                    _c(
                      "vs-list",
                      [
                        _c("vs-list-header", {
                          attrs: {
                            title: "Filières disponibles",
                            color: "primary"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "vs-list",
                          { staticClass: "scrollable_list" },
                          [
                            _c(
                              "draggable",
                              {
                                staticClass: "p-2 cursor-move",
                                attrs: {
                                  value: _vm.availableSectors,
                                  group: "occupation-skills"
                                }
                              },
                              _vm._l(_vm.availableSectors, function(item, i) {
                                return _c("vs-list-item", {
                                  key: item.id,
                                  attrs: {
                                    id: "occupationSkill_" + item.id,
                                    title: item.title
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "1"
                      }
                    },
                    [
                      _c("chevrons-right-icon", {
                        staticClass: "custom-class",
                        attrs: { size: "3x", stroke: "rgb(151, 47, 95)" }
                      })
                    ],
                    1
                  )
                ],
                _vm._v(" "),
                _c(
                  "vs-col",
                  { attrs: { "vs-w": "5.5" } },
                  [
                    _c(
                      "vs-list",
                      [
                        _c("vs-list-header", {
                          attrs: { title: "Filières choisis", color: "primary" }
                        }),
                        _vm._v(" "),
                        _c(
                          "draggable",
                          {
                            staticClass: "p-2 cursor-move scrollable_list",
                            attrs: {
                              group: "occupation-skills",
                              emptyInsertThreshold: 250,
                              direction: "horizontal"
                            },
                            on: { change: _vm.selectedHandle },
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          },
                          _vm._l(_vm.selected, function(item, i) {
                            return _c("vs-list-item", {
                              key: item.i,
                              attrs: {
                                id: "sectors_" + item.id,
                                title: item.title
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          : _vm.sectors.loading
          ? _c("vs-row", { staticClass: "selector-container empty" }, [
              _c("p", [_vm._v("Chargement en cours...")])
            ])
          : _c("vs-row", { staticClass: "selector-container empty" }, [
              _c("p", [_vm._v("Aucune filière n'a encore été créée")])
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }