<template>
    <span class="sort-list" @click="handleClick">
        <i v-if="withoutDirection" class="fa fa-sort"></i>
        <i v-if="ascDirection" class="fa fa-sort-amount-desc"></i>
        <i v-if="descDirection" class="fa fa-sort-amount-asc"></i>
        <span class="ml-2">
            <slot />
        </span>
    </span>
</template>

<script>
export default {
    name: 'Sort',
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
        }
    },
    data() {
        return {
            direction: null
        }
    },
    computed: {
        withoutDirection() {
            return !this.direction;
        },
        ascDirection() {
            return this.direction == 'asc';
        },
        descDirection() {
            return this.direction == 'desc';
        }
    },
    methods: {
        handleClick() {

            if (this.direction == 'asc') {
                this.direction = 'desc';
            } else if (this.direction == 'desc') {
                this.direction = null;
            } else {
                this.direction = 'asc';
            }

            this.$emit('sort', this.name, this.direction);
        }
    },
    created() {
        if (this.value) {
            this.direction = this.value;
        }
    }
}
</script>

<style>
    .sort-list {
        white-space: nowrap;
    }
</style>
