<template>
    <div class="con-exemple-prompt mb-6 scrollBug">
        <!-- Filière -->
        <SectorsSelector
            v-model="resource.sectors"
        />
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SectorsSelector from "@components/form/SectorsSelector";
export default {
    name: "SectorForm",
    components: {
        Multiselect,
        SectorsSelector
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            resource : {
                sectors: []
            }
        };
    },

    watch: {
        resource: {
            handler() {
                this.$emit("input", this.resource);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        },
    },
    computed: {
        hasError() {
            let error = !this.resource.sectors || this.resource.sectors.length == 0
            return error;
        }
    },
    methods: {
        initResource() {
            this.resource = {
                sectors: this.value.sectors
            }
        },

        async loadSectorsUsed() {
            return window.axios({
                method: 'get',
                url: '/api/referentiel/occupation-skills/referential/sectors-used',
                params: {},
            })
            .then(response => {
                this.resource.sectors = response.data;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },

        async loadSectorAvailables() {
            return window.axios({
                method: 'get',
                url: '/api/referentiel/sector-availables',
                params: {include: ['sector']},
            })
            .then(response => {
                if (response.data.length > 0) {
                    this.resource.sectors = response.data.map(s => s.sector);
                } else {
                    this.loadSectorsUsed();
                }

            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        }

    },
    created() {
        this.initResource();
        this.loadSectorAvailables();
    }
}
</script>
