<template>
  <div  :style="getStyle" id="skillsCard">
    <div class="overlayed" :style="images[0][2].exists ? images[0][2].custom : images[0][2].default"></div>
    <vx-card>
        <h2 class="mb-2">
        Compétences
        </h2>
        <ul style="min-height: 50px;">
        <li>
            <a
            name="cardCompetence"
            class="link"
            href="/rh/referentiel/competences"
            >Consulter une compétence</a>
        </li>
        <li>
            <a
            name="cardEntreeCompetence"
            class="link"
            href="/rh/gestion/tableaux/competences"
            >Rechercher une compétence</a>
        </li>
        </ul>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        api: {
            type: String
        }
    },
    data() {
        return {
            images: [],
            loading: true,
        };
    },
    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
        items(value) {
            this.images = this.getServicesImage(value);
        }
    },
    computed: {
        getStyle () {
            return 'height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;'
        }
    },
    methods: {
    },
    created() {
        this.images = this.servicesImage;
        this.loadItems();
    }
}
</script>

<style lang="scss" scoped>
.overlayed {
    min-height: 200px;
    background-size: cover;
}
</style>
