var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-card__title mb-8" }, [
      !_vm.recover
        ? _c("h4", { staticClass: "mb-4" }, [_vm._v("Connexion à MySkilliz")])
        : _c("h4", { staticClass: "mb-4" }, [
            _vm._v("Récupérer le mot de passe")
          ]),
      _vm._v(" "),
      !_vm.recover
        ? _c("p", [
            _vm._v(
              "\n            Bienvenue, veuillez vous connecter à votre compte.\n        "
            )
          ])
        : _c("p", [
            _vm._v(
              "\n            Entrez votre email et les instructions de réinitialisation\n            vous seront envoyées par email !\n        "
            )
          ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.errors.email
          ? _c(
              "vs-alert",
              { attrs: { color: "danger", icon: "new_releases" } },
              [_c("span", [_vm._v(_vm._s(_vm.errors.email[0]))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.resetSuccess
          ? _c(
              "vs-alert",
              { attrs: { color: "success", icon: "new_releases" } },
              [
                _c("span", [
                  _vm._v(
                    "Votre demande de réinitialisation a bien été prise en compte"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "vx-row_ mb-4" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("vs-input", {
                staticClass: "no-icon-border",
                attrs: {
                  id: "email",
                  type: "email",
                  icon: "icon icon-user",
                  "icon-pack": "feather",
                  "label-placeholder": "Email",
                  name: "email"
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        !_vm.recover
          ? _c("div", { staticClass: "vx-row_ mb-4" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("input-password", {
                    staticClass: "mt-6 no-icon-border",
                    attrs: {
                      id: "password",
                      placeholder: "Mot de passe",
                      name: "password"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.recover
          ? _c(
              "vs-row",
              {
                staticClass: "mt-8",
                attrs: { "vs-type": "flex", "vs-justify": "space-between" }
              },
              [
                _c(
                  "vs-col",
                  {
                    staticStyle: { "padding-left": "0" },
                    attrs: { "vs-w": "6", "vs-type": "flex" }
                  },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        attrs: { id: "remember", name: "remember" },
                        model: {
                          value: _vm.remember,
                          callback: function($$v) {
                            _vm.remember = $$v
                          },
                          expression: "remember"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Se souvenir de moi\n                "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "vs-col",
                  {
                    staticStyle: { "padding-right": "0" },
                    attrs: {
                      "vs-w": "6",
                      "vs-type": "flex",
                      "vs-justify": "right",
                      "vs-align": "center"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showRecover.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Mot de passe oublié ?")]
                    )
                  ]
                )
              ],
              1
            )
          : _c("div", { staticClass: "flex flex-wrap justify-between my-5" }),
        _vm._v(" "),
        _c(
          "vs-row",
          { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
          [
            !_vm.recover
              ? _c(
                  "vs-col",
                  {
                    staticClass: "my-5 placeholder",
                    staticStyle: { "padding-right": "0" },
                    attrs: { "vs-type": "flex", "vs-justify": "right" }
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: {
                          id: "connexion",
                          button: "submit",
                          disabled: !_vm.isCompletedLogin
                        }
                      },
                      [_vm._v("Connexion")]
                    )
                  ],
                  1
                )
              : _c(
                  "vs-col",
                  {
                    staticClass: "my-5 placeholder",
                    staticStyle: { "padding-right": "0" },
                    attrs: { "vs-type": "flex", "vs-justify": "right" }
                  },
                  [
                    _vm.recover
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "mr-2",
                            attrs: { id: "recover", type: "border" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.hideRecover()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Annuler\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "vs-button",
                      {
                        staticClass: "float-right",
                        attrs: { id: "reset", disabled: !_vm.isCompletedReset },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resetPassword()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Réinitialiser\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }