<template >
    <div>
        <div class="vx-card__title mb-8">
            <h4 v-if="!recover" class="mb-4">Connexion à MySkilliz</h4>
            <h4 v-else class="mb-4">Récupérer le mot de passe</h4>
            <p v-if="!recover">
                Bienvenue, veuillez vous connecter à votre compte.
            </p>
            <p v-else>
                Entrez votre email et les instructions de réinitialisation
                vous seront envoyées par email !
            </p>
        </div>
        <div>
            <vs-alert v-if="errors.email" color="danger" icon="new_releases">
                <span>{{ errors.email[0] }}</span>
            </vs-alert>

            <vs-alert v-if="resetSuccess" color="success" icon="new_releases">
                <span>Votre demande de réinitialisation a bien été prise en compte</span>
            </vs-alert>

            <div class="vx-row_ mb-4">
                <div class="vx-col w-full">
                    <vs-input id="email" type="email" icon="icon icon-user" icon-pack="feather" label-placeholder="Email" class="no-icon-border" name="email" v-model="email"/>
                </div>
            </div>

            <div v-if="!recover" class="vx-row_ mb-4">
                <div class="vx-col w-full">
                    <input-password id="password" class="mt-6 no-icon-border" placeholder="Mot de passe" name="password" v-model="password" />
                </div>
            </div>

            <vs-row v-if="!recover" class="mt-8" vs-type="flex" vs-justify="space-between">
                <vs-col vs-w="6" vs-type="flex" style="padding-left: 0">
                    <vs-checkbox id="remember" name="remember" v-model="remember">
                        Se souvenir de moi
                    </vs-checkbox>
                </vs-col>
                <vs-col vs-w="6"  vs-type="flex" vs-justify="right" vs-align="center" style="padding-right: 0">
                    <a href="javascript:void(0)" @click.prevent="showRecover">Mot de passe oublié ?</a>
                </vs-col>
            </vs-row>
            <div v-else class="flex flex-wrap justify-between my-5" />

            <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-col v-if="!recover" class="my-5 placeholder" vs-type="flex" vs-justify="right" style="padding-right: 0">
                    <vs-button id="connexion" button="submit" :disabled="!isCompletedLogin">Connexion</vs-button>
                </vs-col>
                <vs-col v-else class="my-5 placeholder" vs-type="flex" vs-justify="right" style="padding-right: 0">
                    <vs-button
                        v-if="recover"
                        id="recover"
                        type="border"
                        class="mr-2"
                        @click.prevent="hideRecover()"
                    >
                        Annuler
                    </vs-button>
                    <vs-button
                        id="reset"
                        class="float-right"
                        :disabled="!isCompletedReset"
                        @click.prevent="resetPassword()"
                    >
                        Réinitialiser
                    </vs-button>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>

<script>
import InputPassword from '../theme/InputPassword';

export default {
    name: "Login",
    components: {
        'input-password': InputPassword,
    },
    props: {
        errors: {
            type: Array|Object,
            required: true,
        }
    },
    data() {
        return {
            email: '',
            password: '',
            remember: true,
            recover: false,
            loading: false,
            resetSuccess: false
        }
    },
    methods: {
        validateEmail() {
            // eslint-disable-next-line no-useless-escape
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(this.email).toLowerCase())
        },
        showRecover() {
            this.resetSuccess = false;
            this.recover = true
        },
        hideRecover() {
            this.recover = false
        },
        resetPassword() {
            this.resetSuccess = false
            this.showLoading()
            axios.post('/forgot-password', {
                email: this.email
            }).then(() => {
                this.hideLoading()
                this.resetSuccess = true
                this.recover = false
            }).catch(error => {
                this.hideLoading()
            })
        },
        showLoading() {
            this.loading = true
        },
        hideLoading() {
            this.loading = false
        }
    },
    computed: {
        isCompletedLogin: function() {
            return this.validateEmail() && this.password
        },
        isCompletedReset: function() {
            return this.validateEmail()
        }
    },
    created() {
        console.log(this.errors);
    }
}
</script>

<style lang="scss">

</style>
