var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          displayWishes: false
        }
      }),
      _vm._v(" "),
      _vm.resource.skills.length
        ? _c(
            "vs-row",
            { staticClass: "checkboxes-container mt-10" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "flex-start",
                    "vs-w": "12"
                  }
                },
                [
                  _c("label", [_vm._v("Toutes les compétences")]),
                  _vm._v(" "),
                  _c("vs-switch", {
                    staticClass: "ml-2",
                    attrs: { name: "showAllSkills" },
                    model: {
                      value: _vm.resource.allSkills,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "allSkills", $$v)
                      },
                      expression: "resource.allSkills"
                    }
                  }),
                  _vm._v(" "),
                  _vm.typeForm !== "creation" && _vm.typeForm !== "poursuite"
                    ? [
                        _c("label", { staticClass: "ml-20" }, [
                          _vm._v("Affichage :")
                        ]),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios-occSkill-display",
                              id: "radio-occSkill-display-table",
                              "vs-value": "table"
                            },
                            model: {
                              value: _vm.displayMode,
                              callback: function($$v) {
                                _vm.displayMode = $$v
                              },
                              expression: "displayMode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Tableau\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios-occSkill-display",
                              id: "radio-occSkill-display-graphic",
                              "vs-value": "graphic"
                            },
                            model: {
                              value: _vm.displayMode,
                              callback: function($$v) {
                                _vm.displayMode = $$v
                              },
                              expression: "displayMode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Graphique\n                "
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : !_vm.loading
        ? _c("div", { staticClass: "not-data-table vs-table--not-data" }, [
            _vm._v("Il n'y a aucune compétence à évaluer")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.displayMode === "table" && (_vm.resource.skills.length || _vm.loading)
        ? _c("Rating-Manager", {
            attrs: {
              name: "skills",
              "type-form": _vm.typeForm,
              "show-hidden": _vm.resource.allSkills,
              col1: {
                label: "Compétence",
                value: "title",
                title: "description"
              },
              col2: { label: "Catégorie", value: "category_name" },
              loading: _vm.loading,
              "skill-review": _vm.skillReview
            },
            model: {
              value: _vm.resource.skills,
              callback: function($$v) {
                _vm.$set(_vm.resource, "skills", $$v)
              },
              expression: "resource.skills"
            }
          })
        : _vm.displayMode === "graphic"
        ? _c(
            "div",
            { staticClass: "chart main" },
            [
              _c("Rating-Chart", {
                staticClass: "main-chart",
                attrs: {
                  employee: _vm.value.employee,
                  options: _vm.chart.options,
                  "chart-data": _vm.chart.data
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }