 
// import { loadUsers as loadUsersService } from "@components/services/resources.js";
export const loadUsers = async ()  => {
    return window
        .axios({
            method: "get",
            url: "/api/referentiel/users",
            params: {
                perpage: 9999,
                include: []
            }
        })
        .then(response => {
            let users = response.data.data;

            if (users.length == 0) return;

            users.slice().map(u => {
                u.label = `${u.name} (${u.email})`;
                return u;
            });

            return users;
        })
        .catch(err => {
            console.log(err);
            this.notifyError(err, `Une erreur est survenue`);
        });
}
