<template>
    <div class="selector-container">
        <div>
            <!-- Search bar -->
            <vs-row v-if="hasSectors" class="mb-6">
                <vs-col vs-w="12">
                    <vs-input
                        v-model="search"
                        name="search"
                        class="w-full"
                        placeholder="Filtrer les filières affichées..."
                    />
                </vs-col>
            </vs-row>

            <vs-row>
                <span class="vs-col">
                    Glissez-déposez les filières que vous souhaitez ajouter :
                </span>
            </vs-row>

            <vs-row class="mt-5" v-if="hasSectors">
                <!-- Skills left (not selected) -->
                <vs-col vs-w="5.5">
                    <vs-list>
                        <vs-list-header
                            title="Filières disponibles"
                            color="primary"
                        />
                        <vs-list class="scrollable_list">

                                <draggable
                                    :value="availableSectors"
                                    group="occupation-skills"
                                    class="p-2 cursor-move"
                                >
                                    <vs-list-item
                                        v-for="(item, i) in availableSectors"
                                        :id="'occupationSkill_' + item.id"
                                        :key="item.id"
                                        :title="item.title"
                                    />
                                </draggable>

                        </vs-list>
                    </vs-list>
                </vs-col>

                <!-- Icons >> -->
                <template>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                        <chevrons-right-icon
                            size="3x"
                            stroke="rgb(151, 47, 95)"
                            class="custom-class"
                        />
                    </vs-col>
                </template>

                <!-- Skills right (selected) -->
                <vs-col vs-w="5.5">
                    <vs-list>
                        <vs-list-header
                            :title="`Filières choisis`"
                            color="primary"
                        />

                        <draggable
                            v-model="selected"
                            group="occupation-skills"
                            class="p-2 cursor-move scrollable_list"
                            :emptyInsertThreshold="250"
                            direction="horizontal"
                            @change="selectedHandle"
                        >
                            <vs-list-item
                                v-for="(item, i) in selected"
                                :id="'sectors_' + item.id"
                                :key="item.i"
                                :title="item.title"
                            />
                        </draggable>
                    </vs-list>
                </vs-col>
                <!-- End Skills right (selected) -->
            </vs-row>
            <vs-row v-else-if="sectors.loading" class="selector-container empty">
                <p>Chargement en cours...</p>
            </vs-row>
            <vs-row v-else class="selector-container empty">
                <p>Aucune filière n'a encore été créée</p>
            </vs-row>
        </div>

    </div>
</template>

<script>
import draggable from "vuedraggable";
import { ChevronsRightIcon } from "vue-feather-icons";

import Fuse from "fuse.js";
const fuseOptions = {
    keys: ["title", "description"],
    isCaseSensitive: false,
    threshold: 0.4,
    location: 0,
    distance: 100
};
const fuse = new Fuse([], fuseOptions);

export default {
    name: "sectorSelector",
    components: {
        draggable,
        ChevronsRightIcon
    },
    props: {
        value: {
            type: Array,
            required: false
        }
    },
    data() {

        return {
            sectors: {
                data: [],
                loading: true
            },
            selected: [],
            search: null
        };
    },
    async created() {

        this.$vs.loading();
        this.loadSectors().then(() => {
            this.$vs.loading.close();
            this.initResource();
        });
    },
    watch: {
        // selected(value) {
        //     this.$emit("input", value);
        // },

        value(value) {
            this.initResource();
        }
    },
    computed: {
        hasSectors() {
            return this.sectors.data.length > 0;
        },

        sectorsList() {
            let sectors = this.sectors.data;

            // Filtre sur la recherche
            if (this.search) {
                fuse.setCollection(sectors);
                sectors = fuse.search(this.search);
            }

            return sectors;
        },

        availableSectors() {
            const selectedIds = this.selected.map(s => s.id);

            return this.sectorsList.filter(aos => {
                return !selectedIds.includes(aos.id);
            })
        }
    },
    methods: {
        async loadSectors() {
            return window.axios({
                method: 'get',
                url: '/api/admin/sectors',
                params: {perpage: 9999},
            })
            .then(response => {
                this.sectors.data = response.data.data;
                this.sectors.loading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        initResource() {
            this.selected = window._.cloneDeep(this.value);
        },
        selectedHandle(event) {
            this.$emit("input", this.selected);
        }
    }
};
</script>

<style lang="scss" scoped>
.selector-container {
    min-height: 200px;
    &.empty {
        background-color: #f8f8f8;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        a {
            cursor: pointer;
        }
        > * {
            font-size: 1.1em;
        }
    }
    p.title {
        font-size: 1.05em;
    }
    .vs-col {
        padding: 0;
    }
    .scrollable_list {
        height: 250px;
        min-height: 250px;
        overflow-y: auto;
    }
}
</style>
