<template>
    <div class="form-thematic-container">
        <template v-if="!showForm">
            <multiselect
                v-model="thematic"
                :options="thematics"
                :multiple="false"
                placeholder="Recherche ..."
                select-label
                selected-label
                deselect-label
                :loading="loading"
            >
                <span slot="noResult">Aucun résultat.</span>
            </multiselect>
            <p class="newThematic-link" @click="showForm = true" style="cus">
                + Ajouter une thematique
            </p>
        </template>

        <template v-else>
            <vs-input autofocus v-model="newThematic" name="thematic" class="w-full" />
            <vs-button
                color="primary"
                thematic="border"
                icon="done"
                :disabled="!newThematic"
                @click="add"
            ></vs-button>
            <vs-button
                color="primary"
                thematic="border"
                icon="clear"
                @click="cancel"
            ></vs-button>
        </template>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "ThematicField",
    components: {
        Multiselect
    },
    props: {
        value: {
            required: false,
            thematic: String
        },
        availableThematics: {
            required: true,
            thematic: Array
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            log: console.log,
            showForm: false,
            thematic: null,
            newThematic: null,
            thematics: []
        };
    },
    created() {
        // Unique thematics
        this.thematics = this.getAvailableTypes();
        this.thematic = this.value;
    },
    watch: {
        value(value) {
            this.thematic = value;
        },
        newThematic(value) {
            this.$emit("input", value);
        },
        thematic(value) {
            this.$emit("input", value);
        },
        availableThematics: {
            handler() {
                this.thematics = this.availableThematics;
            },
            deep: true
        },
    },
    methods: {
        getAvailableTypes() {
            return this.availableThematics.filter((v, i, a) => a.indexOf(v) === i);
        },
        add() {
            if (!this.newThematic) {
                return;
            }

            this.thematics = this.getAvailableTypes().slice();
            let exists = this.thematics.find(t => t == this.newThematic);
            if (!exists) {
                this.thematics.push(this.newThematic);
                this.thematics.sort((a, b) => a.localeCompare(b));
            }
            this.showForm = false;
        },
        cancel() {
            this.newThematic = null;
            this.showForm = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.form-thematic-container {
    width: 100%;
    display: flex;
    align-items: center;
}
.newThematic-link {
    color: rgb(151, 47, 95);
    font-weight: 500;
    text-align: right;
    margin-right: 10px;
    white-space: nowrap;
    margin-left: 10px;
    cursor: pointer;
}
.vs-component.vs-input {
    display: flex;
    flex: 1;
    min-height: 43px;
    ::v-deep .vs-con-input {
        width: 100%;
        align-items: initial;
        flex-direction: row;
        input {
            font-size: 14px;
            margin-right: 10px;
        }
    }
}
button:not(:last-child) {
    margin-right: 10px;
}
</style>
