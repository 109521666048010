var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt", attrs: { id: "resourceForm" } },
    [
      _c(
        "vs-row",
        [
          _c("vs-col", { attrs: { "vs-w": "12" } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Libellé* :")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                _c("div", [_c("b", [_vm._v(_vm._s(_vm.resource.title))])])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Libellé FALC* :")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                _c("div", [_c("b", [_vm._v(_vm._s(_vm.resource.title_falc))])])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Catégorie* :")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                _c("div", [
                  _c("b", [
                    _vm._v(_vm._s(_vm.resource.admin_skill.category.title))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.resource.admin_skill && !_vm.readonly
              ? _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("span", [_vm._v("Description du référentiel : ")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-4/5 w-full" },
                    [
                      _c("vs-textarea", {
                        staticClass: "disabledColor",
                        attrs: { name: "skill_ref_description", disabled: "" },
                        model: {
                          value: _vm.resource.admin_skill.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.resource.admin_skill,
                              "description",
                              $$v
                            )
                          },
                          expression: "resource.admin_skill.description"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Description : ")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _vm.readonly
                    ? _c("span", [_vm._v(_vm._s(_vm.resource.description))])
                    : _c("vs-textarea", {
                        attrs: { name: "skill_description" },
                        model: {
                          value: _vm.resource.description,
                          callback: function($$v) {
                            _vm.$set(_vm.resource, "description", $$v)
                          },
                          expression: "resource.description"
                        }
                      })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Description FALC : ")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _vm.readonly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.resource.description_falc))
                      ])
                    : _c("vs-textarea", {
                        attrs: { name: "skill_description_falc" },
                        model: {
                          value: _vm.resource.description_falc,
                          callback: function($$v) {
                            _vm.$set(_vm.resource, "description_falc", $$v)
                          },
                          expression: "resource.description_falc"
                        }
                      })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "vx-row tenancy" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("La signification des notes : ")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.noteById(1)) +
                        " - " +
                        _vm._s(_vm.levelLabelById(1)) +
                        " : "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-level vx-col sm:w-3/5 w-full" },
                  [
                    _vm.readonly
                      ? _c("span", [_vm._v(_vm._s(_vm.resource.level.level_0))])
                      : _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "modifySkillLevel_O",
                            placeholder: "0 : ",
                            type: "text"
                          },
                          model: {
                            value: _vm.resource.level.level_0,
                            callback: function($$v) {
                              _vm.$set(_vm.resource.level, "level_0", $$v)
                            },
                            expression: "resource.level.level_0"
                          }
                        })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }),
                _vm._v(" "),
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.noteById(2)) +
                        " - " +
                        _vm._s(_vm.levelLabelById(2)) +
                        " : "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-level vx-col sm:w-3/5 w-full" },
                  [
                    _vm.readonly
                      ? _c("span", [_vm._v(_vm._s(_vm.resource.level.level_1))])
                      : _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "modifySkillLevel_1",
                            placeholder: "1 : ",
                            type: "text"
                          },
                          model: {
                            value: _vm.resource.level.level_1,
                            callback: function($$v) {
                              _vm.$set(_vm.resource.level, "level_1", $$v)
                            },
                            expression: "resource.level.level_1"
                          }
                        })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }),
                _vm._v(" "),
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.noteById(3)) +
                        " - " +
                        _vm._s(_vm.levelLabelById(3)) +
                        " : "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-level vx-col sm:w-3/5 w-full" },
                  [
                    _vm.readonly
                      ? _c("span", [_vm._v(_vm._s(_vm.resource.level.level_2))])
                      : _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "modifySkillLevel_2",
                            placeholder: "2 : ",
                            type: "text"
                          },
                          model: {
                            value: _vm.resource.level.level_2,
                            callback: function($$v) {
                              _vm.$set(_vm.resource.level, "level_2", $$v)
                            },
                            expression: "resource.level.level_2"
                          }
                        })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }),
                _vm._v(" "),
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.noteById(4)) +
                        " - " +
                        _vm._s(_vm.levelLabelById(4)) +
                        " : "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-level vx-col sm:w-3/5 w-full" },
                  [
                    _vm.readonly
                      ? _c("span", [_vm._v(_vm._s(_vm.resource.level.level_3))])
                      : _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            name: "modifySkillLevel_3",
                            placeholder: " 3 : ",
                            type: "text"
                          },
                          model: {
                            value: _vm.resource.level.level_3,
                            callback: function($$v) {
                              _vm.$set(_vm.resource.level, "level_3", $$v)
                            },
                            expression: "resource.level.level_3"
                          }
                        })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Image de la compétence : ")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("avatar-cropper", {
                    attrs: {
                      id: "cropper",
                      trigger: "#edited-pick-photo",
                      labels: { submit: "Soumettre", cancel: "Annuler" },
                      "upload-handler": _vm.submitPhoto,
                      "cropper-options": {
                        aspectRatio: "16:9",
                        autoCropArea: 1,
                        viewMode: 1,
                        movable: false,
                        zoomable: false
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.resource.photo
                    ? _c("img", {
                        attrs: {
                          id: "edited-pick-photo",
                          src: _vm.resource.photo,
                          width: "120px"
                        }
                      })
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "font-size": "20px",
                            "background-color": "grey",
                            color: "white",
                            width: "120px",
                            height: "120px"
                          },
                          attrs: { id: "edited-pick-photo" }
                        },
                        [
                          _vm._v("\n                        Pas de photo "),
                          _c("small", [_vm._v("Cliquez pour en rajouter une")])
                        ]
                      )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.readonly
        ? _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-type": "flex", "vs-w": "3" } },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { id: "modifySkillReset" },
                      on: {
                        click: function($event) {
                          return _vm.resetSkillToAdminValue()
                        }
                      }
                    },
                    [_vm._v("\n                Réinitialiser\n            ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }