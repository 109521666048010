var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { "text-align": "right" } }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-outline-primary vs-component vs-button vs-button-primary vs-button-filled",
          on: {
            click: function($event) {
              return _vm.displayManageWidget()
            }
          }
        },
        [_c("span", [_vm._v("Gérer le tableau de bord ")])]
      ),
      _vm._v(" "),
      _vm.manageWidgets
        ? _c("div", { staticClass: "cardTdbord" }, [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _c("b", [_vm._v("Afficher/cacher les cartes")]),
              _c("br"),
              _vm._v("\n                10 cartes maximum\n                "),
              _c("table", [
                _vm._m(0),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("ratings")
                              }
                            },
                            model: {
                              value: _vm.ratings,
                              callback: function($$v) {
                                _vm.ratings = $$v
                              },
                              expression: "ratings"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.projectNames.plural) +
                                " à réaliser\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("ratingsInProgress")
                              }
                            },
                            model: {
                              value: _vm.ratingsInProgress,
                              callback: function($$v) {
                                _vm.ratingsInProgress = $$v
                              },
                              expression: "ratingsInProgress"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.projectNames.plural) +
                                " en cours\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("ratingsToValidate")
                              }
                            },
                            model: {
                              value: _vm.ratingsToValidate,
                              callback: function($$v) {
                                _vm.ratingsToValidate = $$v
                              },
                              expression: "ratingsToValidate"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.projectNames.plural) +
                                " à valider\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "skillRatingsInProgress"
                                )
                              }
                            },
                            model: {
                              value: _vm.skillRatingsInProgress,
                              callback: function($$v) {
                                _vm.skillRatingsInProgress = $$v
                              },
                              expression: "skillRatingsInProgress"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.skillRatingNames.plural) +
                                " en cours\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "skillRatingsToValidate"
                                )
                              }
                            },
                            model: {
                              value: _vm.skillRatingsToValidate,
                              callback: function($$v) {
                                _vm.skillRatingsToValidate = $$v
                              },
                              expression: "skillRatingsToValidate"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.skillRatingNames.plural) +
                                " à valider\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "employeesCreatedByUser"
                                )
                              }
                            },
                            model: {
                              value: _vm.employeesCreatedByUser,
                              callback: function($$v) {
                                _vm.employeesCreatedByUser = $$v
                              },
                              expression: "employeesCreatedByUser"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Mes personnes créées\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("employeesCreated")
                              }
                            },
                            model: {
                              value: _vm.employeesCreated,
                              callback: function($$v) {
                                _vm.employeesCreated = $$v
                              },
                              expression: "employeesCreated"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Personnes créées\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("jobsCreatedByUser")
                              }
                            },
                            model: {
                              value: _vm.jobsCreatedByUser,
                              callback: function($$v) {
                                _vm.jobsCreatedByUser = $$v
                              },
                              expression: "jobsCreatedByUser"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Mes postes/stages créés\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("jobsCreated")
                              }
                            },
                            model: {
                              value: _vm.jobsCreated,
                              callback: function($$v) {
                                _vm.jobsCreated = $$v
                              },
                              expression: "jobsCreated"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Postes/stages créés\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "occupationskillsModifiedByUser"
                                )
                              }
                            },
                            model: {
                              value: _vm.occupationskillsModifiedByUser,
                              callback: function($$v) {
                                _vm.occupationskillsModifiedByUser = $$v
                              },
                              expression: "occupationskillsModifiedByUser"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Mes savoir-faire modifiés\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "occupationskillsModified"
                                )
                              }
                            },
                            model: {
                              value: _vm.occupationskillsModified,
                              callback: function($$v) {
                                _vm.occupationskillsModified = $$v
                              },
                              expression: "occupationskillsModified"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Savoir-faire modifiés\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox(
                                  "lastSelfRatingsExternal"
                                )
                              }
                            },
                            model: {
                              value: _vm.lastSelfRatingsExternal,
                              callback: function($$v) {
                                _vm.lastSelfRatingsExternal = $$v
                              },
                              expression: "lastSelfRatingsExternal"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Dernières auto-évaluations transmises\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("externalRatings")
                              }
                            },
                            model: {
                              value: _vm.externalRatings,
                              callback: function($$v) {
                                _vm.externalRatings = $$v
                              },
                              expression: "externalRatings"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Dernières évaluations transmises\n                                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("employees")
                              }
                            },
                            model: {
                              value: _vm.personnes,
                              callback: function($$v) {
                                _vm.personnes = $$v
                              },
                              expression: "personnes"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Personnes\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("jobs")
                              }
                            },
                            model: {
                              value: _vm.metiers,
                              callback: function($$v) {
                                _vm.metiers = $$v
                              },
                              expression: "metiers"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Métiers & postes\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-checkbox",
                          {
                            staticStyle: { flex: "auto" },
                            on: {
                              click: function($event) {
                                return _vm.clickCheckbox("skills")
                              }
                            },
                            model: {
                              value: _vm.competences,
                              callback: function($$v) {
                                _vm.competences = $$v
                              },
                              expression: "competences"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Compétences\n                                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "action mt-3" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary vs-component vs-button vs-button-primary vs-button-filled",
                  on: {
                    click: function($event) {
                      return _vm.saveManagedWidgets()
                    }
                  }
                },
                [_c("span", [_vm._v(" Enregistrer ")])]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { width: "100%", "margin-top": "10px", height: "100%" } },
      [
        _c(
          "grid-layout",
          {
            key: _vm.refresh,
            attrs: {
              layout: _vm.getResources,
              colWidth: 300,
              "col-num": 6,
              "row-height": 30,
              "is-draggable": _vm.isDraggable,
              "is-resizable": _vm.isResizable,
              responsive: _vm.isResponsive,
              "is-mirrored": false,
              "vertical-compact": true,
              "prevent-collision": true,
              margin: [5, 5],
              cols: { lg: 6, md: 6, sm: 6, xs: 4, xxs: 1 },
              "use-css-transforms": true
            },
            on: {
              "update:layout": function($event) {
                _vm.getResources = $event
              }
            }
          },
          _vm._l(_vm.getResources, function(item, index) {
            return _c(
              "grid-item",
              {
                key: index,
                class: { editMode: _vm.manageWidgets },
                attrs: {
                  autoSize: true,
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  i: item.i,
                  static: _vm.isStatic,
                  "drag-allow-from": ".item-drag"
                },
                on: { moved: _vm.movedEvent, resized: _vm.resizeEvent }
              },
              [
                _c("div", {
                  staticClass: "item-drag",
                  attrs: { title: "Cliquer pour déplacer la carte" }
                }),
                _vm._v(" "),
                item.type == "employeesWidget"
                  ? _c("EmployeesWidget", {
                      attrs: { api: "/api/referentiel/settings/images" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type == "jobsWidget"
                  ? _c("JobsWidget", {
                      attrs: { api: "/api/referentiel/settings/images" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type == "skillsWidget"
                  ? _c("SkillsWidget", {
                      attrs: { api: "/api/referentiel/settings/images" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type == "ratingsWidget" ? _c("RatingsWidget") : _vm._e(),
                _vm._v(" "),
                item.type == "lastSelfRatingsExternalWidget"
                  ? _c("LastSelfRatingsExternalWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "externalRatingsWidget"
                  ? _c("ExternalRatingsWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "employeesCreatedWidget"
                  ? _c("EmployeesCreatedWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "employeesCreatedByUserWidget"
                  ? _c("EmployeesCreatedByUserWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "jobsCreatedWidget"
                  ? _c("JobsCreatedWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "jobsCreatedByUserWidget"
                  ? _c("JobsCreatedByUserWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "occupationskillsModifiedWidget"
                  ? _c("OccupationskillsModifiedWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "occupationskillsModifiedByUserWidget"
                  ? _c("OccupationskillsModifiedByUserWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "ratingsInProgressWidget"
                  ? _c("RatingsInProgressWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "ratingsToValidateWidget"
                  ? _c("RatingsToValidateWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "skillRatingsInProgressWidget"
                  ? _c("SkillRatingsInProgressWidget")
                  : _vm._e(),
                _vm._v(" "),
                item.type == "skillRatingsToValidateWidget"
                  ? _c("SkillRatingsToValidateWidget")
                  : _vm._e(),
                _vm._v("\n" + _vm._s(_vm.log(item.type)) + "\n                ")
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Mes suivis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Mes liens")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "left" } }, [
      _c("small", [
        _c("i", { staticClass: "fa fa-arrows mr-2" }),
        _vm._v(
          "Utiliser le coin supérieur droit des cartes pour les déplacer sur le tableau "
        ),
        _c("br"),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-arrows-h mr-2" }),
        _vm._v(
          "Utiliser le coin inférieur droit des cartes pour les redimensionner sur le tableau\n                "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }