<template>
    <li :id="id" :class="stepClass">
        <div class="timeline">
            <div class="icon">
                <span :v-if="step.cumulatedPost">
                    {{ step.cumulatedPostes }}
                </span>
            </div>

            <img v-if="star" :src="star" alt="star" />

            <div class="line" />

            <div
                class="end"
            />
        </div>

        <div
            class="details"
        >
            <h4 v-if="step.end && step.date">
                {{ step.start }}
            </h4>
            <h4 v-if="step.end && !step.date && !step.isToday">
                Du {{ step.start }} au {{ step.end }}
            </h4>
            <h4 v-if="step.end && !step.date && step.isToday">
                Du {{ step.start }} à aujourd'hui
            </h4>
            <h4>
                {{ step.name }}
            </h4>
            <h4 v-if="step.category === 'employee_ratings'">
                {{ projectNames.singular }}
            </h4>
            <p>
                {{ step.description }}
            </p>
        </div>
    </li>
</template>

<script>
import starMetier from "@assets/images/icons/starMetiers.svg";
import starPostes from "@assets/images/icons/starPostes.svg";

export default {
    name: "ExportStep",

    props: {
        step: Object,
        id: String
    },

    data: function() {
        return {
            star: null,
            isToday: false,
            stepClass: this.step.category
        };
    },

    created: function() {
        this.setStepStyle();
    },

    methods: {
        setStepStyle: function() {
            if (this.step.category === "employee_ratings") {
                this.star = null;
            } else if (this.step.category === "employee_rating_job_wishlists") {
                this.star = starPostes;
            } else if (
                this.step.category === "employee_rating_occupation_wishlists"
            ) {
                this.star = starMetier;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

li {
    list-style: none;
    width: 100%;
    display: flex;
    .timeline {
        width: 20%;
        min-height: 82px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 4;
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .line {
            z-index: 3;
            width: 2px;
            height: 100%;
        }
        .end {
            z-index: 3;
            width: 20px;
            height: 3px;
        }
    }
    .details {
        width: 60%;
        text-align: left;
        margin-bottom: 20px;
        h4 {
            margin: 0px;
        }
        p {
            margin: 0px;
        }
    }
}

.pageBreak {
    height: 60px;
    opacity: 0;
}

.trainings {
    .icon {
        width: 20px;
        min-height: 20px;
        background-color: #273583;
    }
    .line {
        background-color: #273583;
    }
    .end {
        background-color: #273583;
    }
}

.Immersions {
    .icon {
        width: 18px;
        min-height: 18px;
        left: -7px;
        background-color: #8dc8ec;
        transform: rotate(45deg);
    }
    .line {
        background-color: #8dc8ec;
    }
    .end {
        background-color: #8dc8ec;
    }
}

.occupations {
    z-index: 6;
    .icon {
        width: 26px;
        min-height: 26px;
        z-index: 13;
        border-radius: 18px;
        background-color: #af9bcb;
    }
    .line {
        background-color: #1c8d88;
    }
}

.establishments {
    .icon {
        display: none;
    }
    .line {
        background-color: #1c8d88;
    }
    .end {
        display: none;
    }
}

.jobs {
    .icon {
        width: 26px;
        min-height: 26px;
        border-radius: 18px;
        border: 2px solid #1c8d88;
        color: #1c8d88;
        background-color: #ffffff;
    }
    .line {
        background-color: #1c8d88;
    }
}

.employee_ratings {
    z-index: 3;
    .icon {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 12.5px 0 12.5px;
        border-color: #f9b03d transparent transparent transparent;
        z-index: 14;
        margin-top: 2px;
        left: 0;
    }
    .line {
        background-color: #1c8d88;
    }
}

.employee_rating_occupation_wishlists {
    .line {
        background-color: #1c8d88;
    }
}

.employee_rating_job_wishlists {
    .line {
        background-color: #1c8d88;
    }
}
</style>
