var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.togglesList, function(ref) {
      var value = ref.value
      var name = ref.name
      var icon = ref.icon
      var isChecked = ref.isChecked
      return _c("li", { key: name }, [
        _c("label", { staticClass: "checkbox" }, [
          _c("input", {
            attrs: { id: value, type: "checkbox" },
            domProps: { checked: !!isChecked, value: value },
            on: {
              change: function(e) {
                return _vm.handleChangeToggle(e.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark", attrs: { for: value } })
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(name))]),
        _vm._v(" "),
        _c("img", { attrs: { src: icon, alt: name } })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }