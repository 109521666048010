var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      true
        ? _c(
            "vs-button",
            {
              class: [
                "ml-2",
                "vs-con-loading__container",
                { exportPDF: "vs-con-loading__container" }
              ],
              attrs: {
                id: "export-pdf-button",
                color: "primary",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-file-text"
              },
              on: { click: _vm.initExport }
            },
            [_vm._v("\n        Export CV\n    ")]
          )
        : _c(
            "button",
            {
              staticClass:
                "vs-component vs-button vs-button-primary vs-button-filled includeIcon ml-2 vs-con-loading__container",
              attrs: { type: "button", disabled: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "con-vs-loading vs-loading-background-primary vs-loading-color-#FFF"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vs-loading default",
                      staticStyle: { transform: "scale(0.45)" }
                    },
                    [
                      _c("div", {
                        staticClass: "effect-1 effects",
                        staticStyle: {
                          "border-left": "3px solid rgb(255, 255, 255)"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "effect-2 effects",
                        staticStyle: {
                          "border-left": "3px solid rgb(255, 255, 255)"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "effect-3 effects",
                        staticStyle: {
                          "border-left": "3px solid rgb(255, 255, 255)"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", { attrs: { src: "" } })
                    ]
                  )
                ]
              ),
              _vm._v("\n        Export CV\n    ")
            ]
          ),
      _vm._v(" "),
      _c("div", { staticClass: "hide-pdf", attrs: { id: "cv-container" } }, [
        _c(
          "div",
          { ref: "cv" },
          [
            _vm.employee
              ? _c("CV", {
                  key: 0,
                  attrs: { employee: _vm.employee },
                  on: {
                    loaded: function($event) {
                      return _vm.download()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }