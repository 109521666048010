<template>
    <div class="form-type-container">
        <template v-if="!showForm">
            <multiselect
                v-if="options.length"
                v-model="fieldValue"
                :class="classes"
                :options="options"
                :multiple="false"
                placeholder="Recherche ..."
                select-label
                selected-label
                deselect-label
                :loading="loading"
            >
                <span slot="noResult">Aucun résultat.</span>
            </multiselect>
            <p class="newOption-link" @click="showForm = true">
                + Ajouter un {{ fieldLabel }}
            </p>
        </template>

        <template v-else>
            <vs-input autofocus v-model="newOption" name="fieldValue" class="w-full" />
            <vs-button
                color="primary"
                type="border"
                icon="done"
                :disabled="!newOption"
                @click="add"
            ></vs-button>
            <vs-button
                color="primary"
                type="border"
                icon="clear"
                @click="cancel"
            ></vs-button>
        </template>
    </div>
</template>

<script>
// import SemiSelectForm from "@components/form/SemiSelectForm.vue";

import Multiselect from "vue-multiselect";

export default {
    name: "SemiSelectForm",
    components: {
        Multiselect
    },
    props: {
        value: {
            required: false,
            type: String
        },
        classes: {
            required: false,
            type: String
        },
        fieldLabel: {
            required: true,
            type: String
        },  
        availables: {
            required: true,
            type: Array
        },
        loading: {
            required: false,
            type: Boolean
        },
    },
    data() {
        return {
            log: console.log,
            showForm: false,
            fieldValue: null,
            newOption: null,
            options: []
        };
    },
    created() {
        // Unique options
        this.options = this.getAvailableTypes();
        this.fieldValue = this.value;
    },
    watch: {
        value(value) {
            this.fieldValue = value;
        },
        newOption(value) {
            this.$emit("input", value);
        },
        fieldValue(value) {
            this.$emit("input", value);
        },
        availables: {
            handler() {
                this.options = this.availables;
            },
            deep: true
        },
    },
    methods: {
        getAvailableTypes() {
            return this.availables.filter((v, i, a) => a.indexOf(v) === i);
        },
        add() {
            if (!this.newOption) {
                return;
            }

            this.options = this.getAvailableTypes().slice();
            let exists = this.options.find(t => t == this.newOption);
            if (!exists) {
                this.options.push(this.newOption);
                this.options.sort((a, b) => a.localeCompare(b));
            }
            this.showForm = false;
        },
        cancel() {
            this.newOption = null;
            this.showForm = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.form-type-container {
    width: 100%;
    display: flex;
    align-items: center;
}
.newOption-link {
    color: rgb(151, 47, 95);
    font-weight: 500;
    text-align: right;
    margin-right: 10px;
    white-space: nowrap;
    margin-left: 10px;
}
.vs-component.vs-input {
    display: flex;
    flex: 1;
    min-height: 43px;
    ::v-deep .vs-con-input {
        width: 100%;
        align-items: initial;
        flex-direction: row;
        input {
            font-size: 14px;
            margin-right: 10px;
        }
    }
}
button:not(:last-child) {
    margin-right: 10px;
}
</style>
