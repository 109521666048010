<template>
    <div>
        <vs-row>
            <vs-col>
                <p class="h4 mt-5">{{ title }}</p>
            </vs-col>
        </vs-row>
        <vs-row>
            <vs-col>
                <vs-table
                    :sst="true"
                    @search="eventSearchItems"
                    @sort="eventSortByCol"
                    search
                    :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
                    style="width:100%"
                    :data="itemsData"
                >
                    <template slot="thead">
                        <vs-th>
                            <Sort name="employee,last_name" @sort="eventSortByCol">Nom</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="employee,first_name" @sort="eventSortByCol">Prénom</Sort>
                        </vs-th>
                        <vs-th>
                            Établissements
                        </vs-th>
                        <vs-th>
                            Services
                        </vs-th>
                        <vs-th>
                            <Sort name="rating_date" @sort="eventSortByCol">Date du {{ typeName }}</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="created_at" @sort="eventSortByCol">Créé le</Sort>
                        </vs-th>
                        <vs-th>
                            <Sort name="created_by" @sort="eventSortByCol">Créé par</Sort>
                        </vs-th>
                        <vs-th></vs-th>
                    </template>

                    <template>
                        <vs-tr v-for="(item, i) in itemsData" :key="i">
                            <vs-td>
                                {{ get(item, ":employee.last_name") }}
                            </vs-td>
                            <vs-td>
                                {{ get(item, ":employee.first_name") }}
                            </vs-td>
                            <vs-td>
                                {{ arrayUnique(get(item, ":employee.services").map(s => s.establishment.name)).join(', ') }}
                            </vs-td>
                            <vs-td>
                                {{ arrayUnique(get(item, ":employee.services").map(s => s.title)).join(', ') }}
                            </vs-td>
                            <vs-td>
                                {{ formatDateFr(get(item, ":rating_date")) }}
                            </vs-td>
                            <vs-td>
                                {{ formatDateFr(get(item, ":created_at")) }}
                            </vs-td>
                            <vs-td>
                                {{ get(item, ":creator.name") }}
                            </vs-td>
                            <vs-td class="action">
                                <div class="action-wrapper">
                                    <vs-button
                                        v-if="action[0] && dynamik(item, action[0].permission)"
                                        :href="dynamik(item, action[0].href)"
                                        v-tooltip="action[0].tooltip"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        :icon="action[0].icon"
                                    />
                                    <vs-button
                                        v-else-if="action[1] && dynamik(item, action[1].permission)"
                                        :href="dynamik(item, action[1].href)"
                                        v-tooltip="action[1].tooltip"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        :icon="action[1].icon"
                                    />
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-row>
                    <vs-col class="mt-4" v-if="items.total > 0">
                        <vs-pagination
                            :total="Math.ceil(items.total / apiParams.perpage)"
                            v-model="currentPage"
                        ></vs-pagination>
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import {formatDate} from '@assets/utils/helpers/helpers.js'

export default {
    name: "RatingsInProgress",
    props: {
        api: {
            type: String,
            required: true
        },
        typeName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        filter: {
            type: String,
            required: true
        },
        action: {
            type: Array,
        }
    },
    data() {
        return {
            formatDateFr: formatDate,
            currentPage: 1,
            loading: false,
        }
    },
    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },
    methods: {
        dynamik(item, callback) {
            return callback(item)
        }
    },
    created() {
        this.apiParams.include = [
            'employee.services.establishment',
            'creator'
        ];
        this.apiParams.perpage = 5;
        this.apiParams.filter = this.filter
        this.loadItems();
    }
};
</script>
