/* global window, exports, define */

!function() {
    'use strict'


    class Validator {


        constructor() {
            this.message = [];
            this.is_valid = true;
        }

        required(value) {
            var re    = new RegExp('.+');
            var is_valid = re.test(value);

            if (!is_valid) {
                this.message.push('Le champs est obligatoire')
            }

            return is_valid;
        }

        min(value, number) {
            var is_valid = true;

            if (typeof value == 'string') {

                is_valid = value.length >= number;

                if (!is_valid) {
                    this.message.push('La valeur saisie doit contenir minimum : ' + number + ' cars.');
                }
            } else {
                is_valid = value >= number;

                if (!is_valid) {
                    this.message.push('Le nombre saisi doit être supérieur à : ' + number);
                }
            }

            return is_valid;
        }

        number(value) {
            var is_valid = typeof value == 'number';

            if (!is_valid) {
                this.message.push('La valeur saisie doit être un nombre')
            }

            return is_valid;
        }

        string(value) {
            var is_valid = typeof value == 'string';

            if (!is_valid) {
                this.message.push('La valeur saisie doit être une chaine de caractères')
            }

            return is_valid;
        }

        max(value, number) {
            var is_valid = true;

            if (typeof value == 'string') {

                is_valid = value.length <= number;

                if (!is_valid) {
                    this.message.push('La valeur saisie doit contenir maximum : ' + number + ' cars.');
                }
            } else {
                is_valid = value <= number;

                if (!is_valid) {
                    this.message.push('Le nombre saisi doit être inférieur à : ' + number);
                }
            }

            return is_valid;
        }

        regex(value, regex) {
            regex     = trim(regex, '/#');
            var re    = new RegExp(regex);
            var is_valid = re.test(value);

            if (!is_valid) {
                this.message.push('La valeur saisie n\'est pas valide')
            }

            return is_valid;
        }

        check(rule, value) {
            this.is_valid = true;

            var rules = rule.split('|');

            // Parcours des règles de validation
            for (var key in rules) {
                let rule = rules[key];

                // Récupération de la méthode de test et de ses paramèters
                let matches = rule.match(/^([^:]*):?(.*)?$/);
                let method  = matches[1];
                let arg     = matches[2];

                // Vérification que la règle peut être traitée
                if (typeof this[method] == 'function') {

                    // Vérification que le contenu passe la règle
                    if (!this[method](value, arg)) {
                        this.is_valid = false;
                    }

                } else {
                    throw 'Unknow rule type for validation : ' + method;
                }
            }

            return this.is_valid;
        }

        isValid() {
            return this.is_valid;
        }

        hasErrors() {
            return this.is_valid != true;
        }

        getMessages() {
            return this.message;
        }

        getFirstMessage() {
            return this.message[0];
        }

    }

    /* eslint-disable quote-props */
    if (typeof exports !== 'undefined') {
        exports['Validator'] = Validator
    }
    if (typeof window !== 'undefined') {
        window['Validator'] = Validator

        if (typeof define === 'function' && define['amd']) {
            define(function() {
                return {
                    'Validator': Validator
                }
            })
        }
    }
    /* eslint-enable quote-props */
}(); // eslint-disable-line
