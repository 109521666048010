var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parentx-static" },
    [
      _c(
        "vs-sidebar",
        {
          ref: "mainSidebar",
          staticClass: "sidebarx main-menu-sidebar items-no-padding",
          attrs: {
            color: "primary",
            parent: ".layout--main",
            "hidden-background": true,
            reduce: false,
            "default-index": _vm.currentIndex,
            "click-not-close": false,
            "reduce-not-rebound": false,
            "reduce-not-hover-expand": true
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "header-sidebar",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("div", { staticClass: "logo flex items-center" }, [
                _c("img", {
                  staticClass: "main-logo-image",
                  attrs: {
                    src: "/assets/images/logo-dark-icon.png",
                    height: "32px"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "main-logo-image truncate",
                  attrs: {
                    src: "/assets/images/logo-dark-text.png",
                    height: "32px"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "scroll-container" }, [
            _c("div", { staticClass: "shadow-bottom" }),
            _vm._v(" "),
            _c(
              "div",
              { ref: "sideBar", staticClass: "scroll-area--main-sidebar" },
              [
                _c(
                  "vs-sidebar-item",
                  {
                    ref: "sidebarLink",
                    attrs: { href: "/rh", index: "/rh", title: "Accueil" }
                  },
                  [
                    _c("feather-icon", { attrs: { icon: "HomeIcon" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "truncate" }, [_vm._v("Accueil")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "navigation-header truncate" }, [
                  _vm._v("Espace Utilisateur")
                ]),
                _vm._v(" "),
                _vm.can("read", "employees")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/personnes",
                          index: "/rh/gestion/personnes",
                          title: "Personnes"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Personnes")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "employees")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/parcours",
                          index: "/rh/gestion/parcours",
                          title: "Parcours"
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "AlignRightIcon" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Parcours")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "employee_ratings")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/bilans",
                          index: "/rh/gestion/bilans",
                          title: _vm.projectNames.plural
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CheckSquareIcon" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Evaluations")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() ||
                _vm.isAnAdmin() ||
                _vm.isADrh() ||
                _vm.isAMonitor()
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "PenToolIcon",
                          name: "Suivis",
                          "base-index": "/rh/gestion/suivis*",
                          title: "Suivi"
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/suivis/objectifs",
                            icon: "TrendingUpIcon",
                            label: "Objectifs",
                            title: "Suivi des objectifs",
                            "required-plan": "suivi-objectifs"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() ||
                _vm.isAnAdmin() ||
                _vm.isADrh() ||
                _vm.isAMonitor()
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "PieChartIcon",
                          name: "Tableau de bord",
                          "base-index": "/rh/gestion/tableaux*",
                          title: "Tableau de bord"
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/personnes",
                            icon: "UserIcon",
                            label: "Personnes",
                            title: "Tableau de bord - Personnes",
                            "required-plan": "dashbords"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/metiers-postes",
                            icon: "BookmarkIcon",
                            label: "Métiers / postes",
                            title: "Tableau de bord - Métiers / poste",
                            "required-plan": "dashbords"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/competences",
                            icon: "BoxIcon",
                            label: "Compétences",
                            title: "Tableau de bord - Compétences",
                            "required-plan": "dashbords"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/savoir-faire",
                            icon: "ApertureIcon",
                            label: "Savoir-faire",
                            title: "Tableau de bord - Savoir-faire",
                            "required-plan": "dashbords"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "navigation-header truncate" }, [
                  _vm._v("Référentiel")
                ]),
                _vm._v(" "),
                _vm.isAbleTo("read-services", undefined, _vm.user)
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/services",
                          index: "/rh/referentiel/services",
                          title: "Services"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "LayersIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Services")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "skills")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/competences",
                          index: "/rh/referentiel/competences",
                          title: "Compétences"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "AwardIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Compétences")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "occupation_skills")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/savoir-faire",
                          index: "/rh/referentiel/savoir-faire",
                          title: "Savoir-faire"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "ApertureIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Savoir-faire")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "jobs")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/postes",
                          index: "/rh/referentiel/postes",
                          title: "Postes"
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ClipboardIcon" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Postes/stages")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "occupations")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/metiers",
                          index: "/rh/referentiel/metiers",
                          title: "Métiers"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "TargetIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Métiers")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/laratrust",
                          index: "/rh/referentiel/laratrust",
                          title: "Rôles & Permissions"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UnlockIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Rôles & Permissions")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "establishments")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/etablissements",
                          index: "/rh/referentiel/etablissements",
                          title: "Établissements"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "GridIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Établissements")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "users")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/utilisateurs",
                          index: "/rh/referentiel/utilisateurs",
                          title: "Utilisateurs"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Utilisateurs")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "vs-sidebar-item",
                  {
                    ref: "sidebarLink",
                    attrs: {
                      href: "/rh/referentiel/contacts",
                      index: "/rh/referentiel/contacts",
                      title: "Contacts"
                    }
                  },
                  [
                    _c("feather-icon", { attrs: { icon: "MailIcon" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "truncate" }, [
                      _vm._v("Contacts")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isASuperAdmin()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        staticStyle: { opacity: "0.5" },
                        attrs: {
                          href: "#",
                          "data-href": "/rh/referentiel/alertes",
                          index: "/rh/referentiel/alertes",
                          title: "Message d'informations"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "InfoIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Message d'informations")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "vs-sidebar-item",
                  {
                    ref: "sidebarLink",
                    attrs: {
                      href: "/rh/referentiel/statistiques",
                      index: "/rh/referentiel/statistiques",
                      title: "Statistiques"
                    }
                  },
                  [
                    _c("feather-icon", { attrs: { icon: "TrelloIcon" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "truncate" }, [
                      _vm._v("Statistiques")
                    ]),
                    _vm._v(" "),
                    !_vm.planAllow("indicateurs-activite")
                      ? _c(
                          "vs-icon",
                          {
                            staticClass: "menu-bullet",
                            attrs: { bg: "#FF9B3A", round: "" }
                          },
                          [
                            _c("CrownIcon", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                padding: "3px"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isASuperAdmin() || _vm.isAnAdmin()
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "SettingsIcon",
                          name: "Paramètres",
                          "base-index": "/rh/referentiel/parametres*",
                          title: "Paramètres"
                        }
                      },
                      [
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/parcours",
                                icon: "AlignRightIcon",
                                label: "Parcours",
                                title: "Paramètres - Parcours"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/notation",
                                icon: "TagIcon",
                                label: "Notation",
                                title: "Paramètres - Notation"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/bilans",
                                icon: "CheckSquareIcon",
                                label: "Evaluations",
                                title: "Paramètres - Evaluations"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/images",
                                icon: "ImageIcon",
                                label: "Images",
                                title: "Paramètres - Images"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/graphisme",
                                icon: "ApertureIcon",
                                label: "Graphisme",
                                title: "Paramètres - Graphisme"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/imports",
                                icon: "ApertureIcon",
                                label: "Imports",
                                title: "Paramètres - Imports"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/exports",
                                icon: "DownloadIcon",
                                label: "Exports",
                                title: "Paramètres - Exports"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.can("read", "trainings")
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/trainings",
                                icon: "FileIcon",
                                label: "Ressources",
                                title: "Paramètres - Ressources",
                                "required-plan": "publication-contenu-peda"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() || _vm.isAnAdmin() || _vm.isADrh()
                  ? _c("span", { staticClass: "navigation-header truncate" }, [
                      _vm._v("Archives")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() || _vm.isAnAdmin() || _vm.isADrh()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/personnes/archives",
                          index: "/rh/gestion/personnes/archives",
                          title: "Liste des archivés"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UserXIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Archivés")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }