import axios from 'axios'

export class Dynamik {

    constructor(source) {
        this.source = source;
    }

    getValue(key) {
        var value = null;

        if (this.isJson(key)) {
            var values = [];

            // Parcours des arguments
            for (var i in key) {
                var data_key = key[i];

                switch (i) {
                    case 'condition':
                        values.push(this.getConditionValue(data_key));
                        break;
                    case 'template':
                        values.push(this.getTemplatevalue(data_key));
                        break;
                    case 'callback':
                        values.push(this.getCallbackValue(data_key));
                        break;
                    case 'patch':
                        values.push(this.getPatchValue(data_key));
                        break;
                    default:
                        values.push(this.getValue(data_key));
                }
            }

            // Si une seule valeur alors on supprime le tableau
            if (values.length == 1) {
                value = values[0];
            } else {
                value = values;
            }
        } else {

            // Valeur dynamique
            if (/^:.*$/.test(key)) {
                key = trim(key, ':');
                value = data_get(this.source, key);
            } else {
                value = key;
            }
        }

        return value;
    }

    /**
     * Traitement de la valeur suivant une condition
     * @param {
     *  'value'      : ':clef.de.recherche',
     *  'operator'   : '==',
     *  'comparator' : 'valeur de comparaison',
     *  'true'       : 'valeur si vrai',
     *  'false'      : 'valeur si faux'
     * } data
     */
    getConditionValue(data) {
        var value      = null;
        var val        = this.getValue(data.value);
        var operator   = data_get(data, 'operator', '==');
        var comparator = data_get(data, 'comparator', null);
        var val_true   = data_get(data, 'true');
        var val_false  = data_get(data, 'false');

        switch (operator) {
            case '==' :
                value = val == comparator ? val_true : val_false;
                break;
            case '===' :
                value = val === comparator ? val_true : val_false;
                break;
            case '>' :
                value = val > comparator ? val_true : val_false;
                break;
            case '<' :
                value = val < comparator ? val_true : val_false;
                break;
            case '!=' :
                value = val != comparator ? val_true : val_false;
                break;
            case '!==' :
                value = val !== comparator ? val_true : val_false;
                break;
            case '<=' :
                value = val <= comparator ? val_true : val_false;
                break;
            case '>=' :
                value = val >= comparator ? val_true : val_false;
                break;
            default :
                value = val_false;
        }

        return this.getValue(value);
    }

    /**
     * Traitement de la valeur suivant un template de type « vsprintf »
     * @param {
     *  'format' => '%s %s',
     *  'args'   => [':clef.de.recherche', 'Valeur fixe'],
     * } data
     */
    getTemplatevalue(data) {
        var value = null;
        var format = data.format;
        var args = data.args;


        // Traitement des % seuls pour éviter les conflits
        format = format.replace(/(%)[^0-9sd.\']/, '%%');

        // Préparation des arguments pour le template
        var _args = this.getValue(args);

        if (!Array.isArray(_args)) {
            _args = [_args];
        }

        value = vsprintf(format, _args);

        return value;
    }

    /**
     * Traitement de la valeur suivant une fonction
     * Ex : Math.pow(:value)
     * @param {
     *  'function' => 'function_callback',
     *  'args'     => [':clef.de.recherche', 'Valeur fixe'],
     * } data
     */
    getCallbackValue(data) {
        var value = null;
        var func  = data.function;
        var args  = data.args;

        // Traitement sur le nom de la fonction (suppression des parenthèses)
        func = func.replace(/(\(.*)/, '');

        // Préparation des arguments pour la fonction
        args = this.getValue(args);

        // Remise des arguments sous forme de tableau
        if (typeof args != 'object') {
            args = [args];
        }

        // Décomposition des objets
        const func_split = func.split('.');

        var object = window;

        for (var i in func_split) {
            object = object[func_split[i]];
        }

        // Appel de la fonction dynamiquement
        value = object.call(null, ...args);

        return value;
    }

    /**
     * Patch de la valeur avec une fonction
     * Ex : :value.toUpperCase(:args)
     * @param {
     *  'value'    => :clef.de.recherche
     *  'function' => 'function_callback',
     *  'args'     => [':clef.de.recherche', 'Valeur fixe'],
     * } data
     */
    getPatchValue(data) {
        var value = data.value;
        var func  = data.function;
        var args  = data.args;

        // Traitement sur le nom de la fonction (suppression des parenthèses)
        func = func.replace(/(\(.*)/, '');

        // Préparation des éléments dynamique
        value = this.getValue(value);
        args  = this.getValue(args);

        // Remise des arguments sous forme de tableau
        if (typeof args != 'object') {
            args = [args];
        }

        // Appel de la fonction dynamiquement
        value = value[func](...args);

        return value;
    }

    /**
     *
     * @param {
     *      url: 'https://url-de-la-source',
     *      method: 'GET',
     *      target: chemin.vers.la.collection
     * } source
     */
    static getDataFromUrl(source) {

        return axios({
            method: source.method,
            url: source.url,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + window.Laravel.api_token,
                Accept: 'application/json'
            }
        })
        .then(function (response) {
            if (source.target) {
                return data_get(response.data, source.target);
            }
            return response.data;
        });
    }

    isJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }

    /**
     *
     * @param {object} datas
     * Utilisation :
     *      var dynamik = new Dynamik(item);
     *      dynamik.dynamikize(datas);
     */
    dynamikize(datas) {
        var value       = null;
        var dynamik     = null;
        var dynamikized =  {};

        if (datas != null && typeof datas == 'object') {

            for (const [key, data] of Object.entries(datas)) {

                // Test de la valeur pour récupération dynamique
                if (this.isDynamik(data)) {
                    value = this.getValue(data);
                } else {
                    value = data;
                }

                // Test si une récursivité est nécessaire
                if (typeof value == 'object') {
                    dynamik = this.dynamikize(value)
                } else {
                    dynamik = value;
                }

                dynamikized[key] = dynamik;
            }
        } else {
            dynamikized = datas;
        }

        return dynamikized;

    }

    /**
     * Test si un objet est de type Dynamik
     * @param {object} data
     */
    isDynamik(data) {
        var keys_dynamik = ['condition', 'template', 'callback'];

        if (data != null && typeof data == 'object') {

            for (const [key, value] of Object.entries(data)) {

                if (keys_dynamik.indexOf(key) != -1) {
                    return true;
                }
            }
        } else if (/^:.*$/.test(data)) {
            return true;
        }

        return false;

    }

    render(value, type, source) {
        if (!render) {
            return value;
        }

        let rendered = value;

        // Gestion des multi-rendus
        render.split('|').forEach(_render => {

            let type;
            let args;
            let has_args = false;

            // Détection d'arguments
            if (has_args = _render.match(/^([^()]*)\((.*)\)$/)) {
                type = has_args[1];
                args = has_args[2].split(',').map(item => item.replace(' ', ''));
            } else {
                type = _render;
            }

            // Traitement des types de rendu
            switch(type) {
                case 'date_fr':

                    break;
                case 'date_time_fr':

                    break;
                case 'date_human_fr':

                    break;
                case 'published_html':

                    break;
                case 'boolean_html':

                    break;
                case 'position':

                    break;
                case 'limit':

                    break;
                case 'strip':

                    break;
                default:
                    //rendered = value;
            }

            return rendered;
        });
    }

    renderDateFr() {

    }

    renderDateTimeFr() {

    }

    renderDateHumanFr() {

    }

    renderPublishedHtml() {

    }

    renderBooleanHtml() {

    }

    renderPosition() {

    }

    renderLimit() {

    }

    renderStrip() {

    }


}


if (typeof exports !== 'undefined') {
    exports['Dynamik'] = Dynamik
}
if (typeof window !== 'undefined') {
    window['Dynamik'] = Dynamik

    if (typeof define === 'function' && define['amd']) {
    define(function() {
        return {
            'Dynamik': Dynamik
        }
    })
    }
}

