<template>
    <div class="vx-row">

        <div class="vx-col w-full" v-if="isInit">
            <vs-table
                max-height="340px"
                search
                :sst="true"
                @search="searchSkillHandle"
                :data="skillsList"
                class="skills-table"
            >
                <template slot="thead">
                    <vs-th class="skills-title">Compétence</vs-th>
                    <vs-th class="skills-title">Catégorie</vs-th>
                    <vs-th class="skills-levels">{{
                        ratingSettings.RequiredLevel
                            ? ratingSettings.RequiredLevel.label
                            : "Requis"
                    }}</vs-th>
                    <vs-th class="skills-levels">{{
                        ratingSettings.IndispensableLevel
                            ? ratingSettings.IndispensableLevel.label
                            : "Indispensable"
                    }}</vs-th>
                    <vs-th class="skills-levels">{{
                        ratingSettings.AutonomyLevel
                            ? ratingSettings.AutonomyLevel.label
                            : "Indispensable en autonomie"
                    }}</vs-th>
                    <vs-th v-if="type !== 'show'" class="center">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr v-for="(skill, i) in data" :key="'s-' + i">
                        <vs-td
                            v-tooltip="{
                                content: skill.description,
                                placement: 'top-start',
                                classes: ['occ-description']
                            }"
                        >
                            {{ skill.admin_skill.title }}
                        </vs-td>
                        <vs-td>{{ skill.admin_skill.category.title }}</vs-td>

                        <vs-td class="center">
                            <vs-radio
                                :key="skill.id"
                                v-model="selectedSkills[skill.id]"
                                v-tooltip="{
                                    content: getTooltipContent(ratingSettings.RequiredLevel, skill.admin_skill_id, value[skill.id], 'Requis'),
                                    classes: ['occ-level']
                                }"
                                :vs-name="'RequiredLevel_' + skill.id"
                                :vs-value="ratingSettings.RequiredLevel.id"
                                :color="needValidation(skill.id) ? 'warning' : 'primary'"
                                @input="onSelectedSkillsChange(skill.id)"
                                :disabled="type === 'show'"
                            />
                        </vs-td>
                        <vs-td class="center">
                            <vs-radio
                                :key="skill.id"
                                v-model="selectedSkills[skill.id]"
                                v-tooltip="{
                                    content: getTooltipContent(ratingSettings.IndispensableLevel, skill.admin_skill_id, value[skill.id], 'Indispensable'),
                                    classes: ['occ-level']
                                }"
                                :vs-name="'IndispensableLevel_' + skill.id"
                                :vs-value="ratingSettings.IndispensableLevel.id"
                                :color="needValidation(skill.id) ? 'warning' : 'primary'"
                                @input="onSelectedSkillsChange(skill.id)"
                                :disabled="type === 'show'"
                            />
                        </vs-td>
                        <vs-td class="center">
                            <vs-radio
                                :key="skill.id"
                                v-model="selectedSkills[skill.id]"
                                v-tooltip="{
                                    content: getTooltipContent(ratingSettings.AutonomyLevel, skill.admin_skill_id, value[skill.id], 'Indispensable en autonomie'),
                                    classes: ['occ-level']
                                }"
                                :vs-name="'AutonomyLevel_' + skill.id"
                                :vs-value="ratingSettings.AutonomyLevel.id"
                                :color="needValidation(skill.id) ? 'warning' : 'primary'"
                                @input="onSelectedSkillsChange(skill.id)"
                                :disabled="type === 'show'"
                            />
                        </vs-td>

                        <vs-td v-if="type !== 'show'" class="center">
                            <vs-button
                                color="primary"
                                type="line"
                                @click="clearSkill(skill.id)"
                            >
                                <vs-icon icon-pack="fa" icon="fa-eraser" />
                            </vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
    </div>
</template>

<script>
import { formatDate } from "@assets/utils/helpers/helpers.js";

export default {
    name: "transversal-skills-selector",
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: false,
            type: String
        },
        skills: {
            required: true,
            type: Array
        },
        adminOs: {
            type: Object
        }
    },
    data() {
        return {
            log: console.log,
            selectedSkills: {},
            needValidations: {},
            searchSkill: null,
        };
    },
    watch: {
        value(value) {
            this.selectedSkills = Object.assign({}, this.value);

            if (this.type == 'update-from-admin') {
                this.checkValidation();
            }
        },
        adminOs: {
            handler(value) {
                if (this.type == 'update-from-admin') {
                    this.updateSelectedSkillFromAdmin();
                }
            }, deep: true
        }

    },
    computed: {

        isInit() {
            return !!Object.keys(this.ratingSettings).length;
        },
        establishmentsServices() {
            if (this.user && this.user.establishments) {
                return this.user.establishments.flatMap(e => e.services);
            }
            return [];
        },
        establishmentsServicesIds() {
            return this.establishmentsServices.flatMap(s => s.id);
        },
        /**
         * List of transversal skills
         * Filtered by category title or title
         * @returns Array
         */
        skillsList() {
            let skills =  this.skills.slice().sort(function(a, b) {
                if (
                    a.admin_skill.category &&
                    a.admin_skill.category.title &&
                    a.admin_skill.category &&
                    a.admin_skill.category.title
                ) {
                    if (
                        a.admin_skill.category.title.toLowerCase() >
                        b.admin_skill.category.title.toLowerCase()
                    )
                        return 1;
                    if (
                        a.admin_skill.category.title.toLowerCase() <
                        b.admin_skill.category.title.toLowerCase()
                    )
                        return -1;
                    if (a.admin_skill.title.toLowerCase() > b.admin_skill.title.toLowerCase()) return 1;
                    if (a.admin_skill.title.toLowerCase() < b.admin_skill.title.toLowerCase())
                        return -1;
                }
                return 0;
            });

            // Filtre sur la recherche libellé, description et catégorie
            if (this.searchSkill) {
                skills = skills.filter(s => {
                    return s.description && s.description.toLowerCase().indexOf(this.searchSkill.toLowerCase()) != -1 ||
                        s.admin_skill.title.toLowerCase().indexOf(this.searchSkill.toLowerCase()) != -1 ||
                        s.admin_skill.category.title.toLowerCase().indexOf(this.searchSkill.toLowerCase()) != -1
                });
            }

            return skills;
        }
    },
    methods: {
        clearSkill(id) {
            this.needValidations[id] = false;
            this.selectedSkills[id] = 0;
            delete this.selectedSkills[id];
            this.$emit("input", this.selectedSkills);
        },
        onSelectedSkillsChange(skillId) {
            this.$emit("input", this.selectedSkills);
            this.needValidations[skillId] = false;
        },

        checkValidation() {
            const validation = Object.values(this.needValidations).includes(true);

            this.$emit('needvalidation', validation);

            return validation;
        },

        updateSelectedSkill(data) {
            this.value = JSON.parse(JSON.stringify(data));
        },
        getAOSAdminSkill(adminSkillId) {

            if (!this.adminOs.adminskills) {
                return;
            }
            return this.adminOs.adminskills.find(as => as.id === adminSkillId)
        },
        adminIsDifferent(adminSkillId, level) {

            const adminSkill = this.getAOSAdminSkill(adminSkillId);

            let asLevel = null;
            if (adminSkill) {
                asLevel = adminSkill.pivot.required_level
            } else {
                //console.log(adminSkillId, level)
            }

            const diff = level != asLevel;

            return diff;
        },

        getTooltipContent(settingLevel, adminSkillId, level = null, def = null) {
            let content = '';

            const currentLevel = settingLevel ? settingLevel.label : def;

            if (this.adminOs.adminskills && this.adminOs.adminskills.length > 0 && this.adminIsDifferent(adminSkillId, level)) {
                const adminSkill = this.getAOSAdminSkill(adminSkillId);
                let adminSkillRequiredLevel = null
                if (adminSkill) {
                    adminSkillRequiredLevel = this.levelById(adminSkill.pivot.required_level);
                }

                content = `Votre version : ${level ? this.levelById(level).label : 'Non appelée'}<br />`;
                content += `Version du référentiel du ${formatDate(this.adminOs.adminskills[0].updated_at)} : ${adminSkillRequiredLevel ? adminSkillRequiredLevel.label : 'Non appelée'}`
            } else {
                content = currentLevel;
            }

            return content;
        },

        needValidation(skillId) {
            return this.needValidations[skillId]
        },

        updateSelectedSkillFromAdmin() {
            if (this.adminOs.adminskills && this.adminOs.adminskills.length > 0) {

                this.skills.forEach((skill) => {

                    if (this.adminIsDifferent(skill.admin_skill_id, this.selectedSkills[skill.id])) {
                        const adminSkill = this.getAOSAdminSkill(skill.admin_skill_id);

                        if (adminSkill) {
                            this.selectedSkills[skill.id] = adminSkill.pivot.required_level;
                        }

                        if (this.type == 'update-from-admin') {
                            this.needValidations[skill.id] = true;
                            this.$emit('needvalidation', true);
                        }

                    }
                })
            }
        },
        searchSkillHandle(search) {
            this.searchSkill = search;
        }
    },
    created() {

        if (this.value && Object.keys(this.value).length) {
            this.selectedSkills = Object.assign({}, this.value);
            this.updateSelectedSkillFromAdmin();
        }
    }
};
</script>

<style lang="scss" scoped>
.skills-table {
    ::v-deep .vs-table--search {
        padding: 4px;
    }
    .skills-title {
        width: 24%;
    }
    ::v-deep .skills-levels {
        width: 15%;
        .vs-table-text {
            justify-content: center;
        }
    }
    th:not(:first-child):not(.skills-title):not(.skills-levels) {
        width: 7%;
        min-width: 55px;
    }
    ::v-deep td {
        padding: 5px;
    }
}
p.title {
    font-size: 1.05em;
}
::v-deep header {
    background-color: #f8f8f8;
}
::v-deep .center {
    .vs-table-text {
        justify-content: center;
    }
}
::v-deep .center.vs-table--td {
    text-align: center;
}

::v-deep th {
    width: 70px;
    text-align: center;
    line-height: 1;
}

</style>

<style>

.occ-description {
    z-index: 99999 !important;
}
</style>

<style>
.tooltip {
    z-index: 1000000
}
</style>
