<template>
    <!-- Export du CV d'une seule personne -->
    <span>
        <vs-button
            v-if="planAllow('edit-cv-pro')"
            id="export-pdf-button"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-file-text"
            :class="[
                'ml-2',
                'vs-con-loading__container',
                { exportPDF: 'vs-con-loading__container' }
            ]"
            @click="initExport"
        >
            Export CV
        </vs-button>

        <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
            <vs-button
                id="export-pdf-button"
                color="primary"
                type="filled"
                class="ml-2 includeIcon"
                icon-pack="feather"
                icon="icon-file-text"
                disabled="true"
            >
                Export CV
            </vs-button>
            <vs-icon class="button-bullet" bg="#FF9B3A" round >
                <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
            </vs-icon>
        </div>
        <!-- Bouton de chargement -->
        <!-- <button
            v-else
            class="vs-component vs-button vs-button-primary vs-button-filled includeIcon ml-2 vs-con-loading__container"
            type="button"
            disabled
        >
            <div
                class="con-vs-loading vs-loading-background-primary vs-loading-color-#FFF"
            >
                <div class="vs-loading default" style="transform: scale(0.45);">
                    <div
                        class="effect-1 effects"
                        style="border-left: 3px solid rgb(255, 255, 255);"
                    ></div>
                    <div
                        class="effect-2 effects"
                        style="border-left: 3px solid rgb(255, 255, 255);"
                    ></div>
                    <div
                        class="effect-3 effects"
                        style="border-left: 3px solid rgb(255, 255, 255);"
                    ></div>
                    <img src="" />
                </div>
            </div>
            Export CV
        </button> -->
        <div id="cv-container" class="hide-pdf">
            <div ref="cv">
                <CV v-if="employee" :employee="employee" @loaded="download()" :key="0"></CV>
            </div>
        </div>
    </span>
</template>

<script>
import CV from "@components/divers/CV"
import CrownIcon from '@components/svg/CrownIcon';
import { buildCVMixin } from "@components/divers/buildCV.js";

export default {
    name: "ExportCV",
    components: { CV, CrownIcon },
    mixins: [buildCVMixin],
    props: {
        employeeId: Number,
        start: Boolean
    },

    data() {
        return {
            log: console.log,
            employee: null,
            exportPDF: false,
            cvLoaded: false,
            key: 0
        };
    },

    created() {

    },

    computed: {},

    watch: {},

    methods: {

        async loadEmployee() {
            return window.axios({
                method: 'get',
                url: `/api/gestion/employees/${this.employeeId}`,
            })
            .then(response => {
                this.employee = response.data;
                this.key++;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },

        initExport() {
            this.exportPDF = true;
            this.$vs.loading({
                background: "primary",
                color: "#FFF",
                container: "#export-pdf-button",
                scale: 0.45
            });

            this.loadEmployee();
        },

        closeExport() {
            this.exportPDF = false;
            this.$vs.loading.close("#export-pdf-button > .con-vs-loading");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

$grey: #7a7a7a;

.hide-pdf {
    transform: translateX(-500vw);
    background-color: $white;
    z-index: -12;
}

#cv-container {
    background-color: white;
    position: absolute;
    top: 0;
    width: 800px;
    padding: 5px;
    z-index: -1;
    right: 0;

    p {
        font-size: 14px;
    }
}
</style>
