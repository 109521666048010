<template>
    <div class="dashboard">
        <h4>
            Identifier les personnes en capacité de répondre aux attendus d’un
            métier, d’un poste ou d’un savoir-faire
        </h4>
        <p>
            Pour chaque métier, poste ou savoir-faire sélectionné, ce tableau de
            bord permet d’identifier les personnes en capacité d’y accéder en
            sécurité.
        </p>
        <!-- Filtre de recherche -->
        <vx-card class="mb-4 mt-3">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row class="mb-6">
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un métier</label
                            >
                            <Multiselect
                                v-model="filters.occupation"
                                :options="occupationsList"
                                :multiple="false"
                                group-values="occupations"
                                group-label="type"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :loading="occupations.loading"
                                @input="filterOccupationChangeHandle"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                                <span slot="noOptions">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un poste</label
                            >
                            <Multiselect
                                v-model="filters.job"
                                :options="groupedJobsByService"
                                :multiple="false"
                                group-values="jobs"
                                group-label="service"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label="Etablissement/service"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :loading="jobs.loading"
                                @input="filterJobChangeHandle"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                                <span slot="noOptions">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un savoir-faire</label
                            >
                            <Multiselect
                                v-model="filters.occupationskill"
                                :options="shownOccupationSkills"
                                :multiple="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="titleEstablishment"
                                select-label
                                selected-label
                                deselect-label
                                deselect-group-label
                                :loading="occupation_skills.loading"
                                @input="filterOccupationskillChangeHandle"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                                <span slot="noOptions">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>
                    </vs-row>

                    <vs-row class="checkboxes-container">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox v-model="filters.wishlistOnly">
                                Souhaités uniquement
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox v-model="filters.securityOnly">
                                Accessibles en sécurité
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="3">
                            <vs-checkbox
                                v-model="filters.mineOnly"
                                :disabled="disabledMineOnly"
                            >
                                Mes savoir-faire uniquement
                            </vs-checkbox>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <vs-row v-if="can('read', 'skill_reviews') || can('read', 'ratings')" class="mt-5">
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col class="vx-row" vs-align="flex-start">
                            Résultats basés sur :
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-ratings"
                                class="mr-5 ml-2"
                                :vs-value="typeComputeValues.RATINGS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings'))"
                            >
                                {{ projectNames.plural }}
                            </vs-radio>
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-reviews"
                                class="mr-5"
                                :vs-value="typeComputeValues.REVIEWS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings'))"
                            >
                                {{ skillRatingNames.plural }}
                            </vs-radio>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Selection des personnes -->
        <vx-card class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-row>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner un service</label
                            >
                            <Multiselect
                                v-model="filters.services"
                                :options="groupedServicesByEstablishments"
                                :multiple="true"
                                group-values="services"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                select-group-label="L'établissement"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledServices"
                                :loading="groupedServicesByEstablishments.length == 0"
                                @input="filterServicesChangeHandle"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>
                        <vs-col vs-justify="center" vs-align="center" vs-w="3">
                            <label class="typo__label"
                                >Sélectionner une personne</label
                            >
                            <Multiselect
                                v-model="filters.employees"
                                :options="groupedEmployeesByEstablishmentsAndFilteredByServices"
                                :multiple="true"
                                group-values="employees"
                                group-label="establishment"
                                :group-select="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label=""
                                select-group-label="L'établissement"
                                selected-label=""
                                deselect-label=""
                                deselect-group-label=""
                                :disabled="disabledEmployees"
                                :loading="groupedEmployeesByEstablishmentsAndFilteredByServices.length == 0"
                                @input="filterEmployeesChangeHandle"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Lancer le recherche -->
        <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-end">
                <vs-button
                    id="search-button"
                    color="primary"
                    :disabled="!canSearch"
                    @click="searchClick"
                >
                    Lancer la recherche
                </vs-button>
            </vs-col>
        </vs-row>

        <!-- Résumé -->
        <vx-card class="mt-6">
            <div v-if="evaluationForChart.length > 0">
                <vs-row>
                    <ul id="employee-skills-show" style="columns:4;-webkit-columns:4;-moz-columns:4;">
                        <li v-for="(chart, i) in evaluationForChart" :key="`${i}-${chart.job.id}`" >
                            <a @click="scrollTo('chart-' + i + '-' + chart.job.id)">
                                <span v-tooltip="{ content: chart.job.description }">
                                    {{ chart.options.title.text.split(":")[0] }}
                                </span>
                                :
                                <b class="text-primary">
                                    {{ round(chart.scoreNumber * 100, 0) + "% " }}
                                </b>
                            </a>
                            <b v-if="chart.check" style="color: green;">✓</b>
                        </li>
                    </ul>
                </vs-row>
                <vs-row class="mt-10">
                    <vs-col vs-type="flex" vs-justify="center">
                        <vs-button
                            v-if="hasMorePage"
                            id="more-button"
                            color="primary"
                            type="border"
                            @click="loadEvaluationsPage"
                        >
                            Charger plus...
                        </vs-button>
                    </vs-col>
                </vs-row>
            </div>

            <div v-else-if="evaluation.loaded">
                Aucune donnée à afficher
            </div>
        </vx-card>
        <!-- Détail avec graphique -->
         <vx-card class="mt-6">
            <vs-row v-if="evaluationForChart.length > 0">


                <vs-col
                    v-for="(chart, i) in evaluationForChart"
                    :id="`chart-${i}-${chart.job.id}`"
                    :key="`chart-${i}-${chart.score}-${chart.job.id}`"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-6"
                    vs-lg="6"
                    vs-sm="12"
                    v-tooltip="{ content: chart.job.description }"
                >
                    <RatingChart
                        :employee="filters.employee"
                        class="rating"
                        :options="chart.options"
                        :chart-data="chart.data"
                    />
                </vs-col>
            </vs-row>
        </vx-card>
        <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="center">
                <vs-button
                    v-if="hasMorePage"
                    id="more-button"
                    color="primary"
                    type="border"
                    @click="loadEvaluationsPage"
                >
                    Charger plus...
                </vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import RatingChart from "@components/app/RatingChart";

export default {
    name: "DashboardOccupationJobs",
    components: {
        RatingChart,
        Multiselect
    },
    mixins: [ratingChartsHelper],

    props: {},
    data() {
        return {
            log: console.log,
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                    display: true,
                    text: `placeholder`,
                    fontSize: 16
                },
                scale: {
                    ticks: {
                        min: -0.5,
                        max: 3,
                        stepSize: 1
                    }
                }
            },
            employees: {
                data: [],
                loading: true
            },
            jobs: {
                data: [],
                loading: true
            },
            occupations: {
                data: [],
                loading: true
            },
            referentialOccupations: {
                data: [],
                loading: true
            },
            occupation_skills: {
                data: [],
                loading: true
            },
            adminOccupationSkills: {
                data: [],
                loading: true
            },
            establishments: {
                data: [],
                loading: true
            },
            filters: {
                occupation: null,
                job: null,
                occupationskill: null,
                wishlistOnly: false,
                securityOnly: false,
                mineOnly: true,
                services: [],
                employees: []
            },
            searchLoading: false,
            evaluation: {
                data: [],
                lastPage: 0,
                currentPage: 0,
                loaded: false
            },
            typeComputeValues: {
                RATINGS: 1,
                REVIEWS: 2,
            },
            typeCompute: 1,
        };
    },
    created() {
        let start = Date.now();

        if (this.can('read', 'skill_reviews') && !this.can('read', 'ratings')) {
            this.typeCompute = this.typeComputeValues.REVIEWS
        }

        this.localLoading();
        Promise.all([this.loadColors()]).then(() => {
            console.info(
                "=> Chargement des listes (avec reconstruction) : +" +
                    (Date.now() - start) +
                    "ms"
            );
            this.loadEmployees(),
                this.loadJobs(),
                this.loadOccupations(),
                this.loadOccupationskills(),
                this.loadAdminOccupationSkills(),
                this.loadReferentialOccupations(),
                this.loadEstablishment()
        });

        this.endLocalLoading();
    },
    computed: {
        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                const params = Object.keys(this.ratingSettings.params).map(
                    id => {
                        var param = this.ratingSettings.params[id];
                        param.id = id;
                        return param;
                    }
                );
                return params;
            }
            return [];
        },
        shownEstablishments() {
            // Liste des établissements basée sur le périmètre de consultation des personnes
            if (this.establishments.data && this.establishments.data.length > 0) {
                if (this.isASuperAdmin(this.user)) {
                    return this.establishments.data;
                } else {
                    const userServices = this.services.slice().filter(service =>
                        this.listOfServicesViewableFor("employees")
                            .map(id => id)
                            .includes(service.id)
                    );
                    const userEstablishments = this.establishments.data
                        .slice()
                        .filter(e =>
                            userServices
                                .map(service => service.establishment_id)
                                .includes(e.id)
                        );
                    return userEstablishments;
                }
            }
            return [];
        },
        shownOccupationSkills() {
            let occupationskills = [];
            if (this.filters.job) {
                //Only Client OccupationSkills
                let jobOccupationSkills = this.filters.job.occupation_skills
                    ? this.filters.job.occupation_skills
                    : this.joboccupationskills(this.filters.job.id);
                occupationskills = jobOccupationSkills
                    .slice()
                    .sort((a, b) => +a.pivot.order - +b.pivot.order);
            } else if (this.filters.occupation) {
                if (this.filters.occupation.admin_id) {
                    occupationskills = this.filters.occupation
                        .admin_occupation_skills
                        ? this.filters.occupation.admin_occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                } else {
                    occupationskills = this.filters.occupation.occupation_skills
                        ? this.filters.occupation.occupation_skills
                              .slice()
                              .sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                }
            } else {

                if (!this.occupation_skills.data) {
                    return [];
                }

                //Only Client OccupationSkills
                occupationskills = this.occupation_skills.data
                    .slice()
                    .sort((a, b) => {
                        return a.title.localeCompare(b.title, "fr", {
                            sensitivity: "base"
                        });
                    });
            }

            if (this.filters.mineOnly) {
                occupationskills = occupationskills.filter(os => {
                    return (!os.establishment_id || this.userEstablishmentsIds.includes(os.establishment_id));
                });
            }

            return occupationskills.map(os => ({
                ...os,
                titleEstablishment: os.title + (os.establishment ? " - " + os.establishment.name : "")
            }));
        },

        groupedEmployeesByEstablishmentsAndFilteredByServices() {
            let toShow = [];
            let employees = [];
            const establishments = this.shownEstablishments;

            establishments.forEach(e => {
                const establishmentEmployees = this.employees.data
                    ? this.employees.data.filter(employee => employee.services.length && employee.services?.map(s => s.establishment_id).includes(e.id))
                    : [];
                if (this.selectedServices && this.selectedServices.length > 0) {
                    const services = this.selectedServices.map(s => s.id);
                    employees = establishmentEmployees.filter(employee => services.filter(s => employee.services.map(es => es.id).includes(s)).length);
                } else {
                    employees = establishmentEmployees;
                }
                toShow.push({
                    establishment: e.name,
                    employees: employees.map(employee => {
                        return {
                            ...employee,
                            name: `${employee.last_name.toUpperCase()} ${
                                employee.first_name
                            }`
                        };
                    })
                });
            });

            return toShow;
        },

        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments = this.shownEstablishments;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services.filter(service => service.establishment_id === e.id &&
                          this.listOfServicesViewableFor("employees").includes(service.id))
                        : []
                });
            });
            return toShow;
        },

        groupedJobsByService() {
            let toShow = [];
            let services = !this.filters.services.length ? this.services : this.filters.services;
            services = services.slice().sort((a, b) => {
                return a.establishment.name.localeCompare(b.establishment.name, "fr", { sensitivity: "base" });
            });

            services.forEach(service => {
                let data = {
                    service: service.establishment.name + " / " + service.title,
                    jobs: this.jobs.data ? this.jobs.data.filter(j =>
                            j.service.id === service.id &&
                            this.listOfServicesViewableFor("jobs").includes(service.id)
                        )
                        : []
                };

                if (data.jobs.length > 0) {
                    toShow.push(data);
                }

            });

            return toShow;
        },

        shownOccupations() {
            return this.occupations.data  ? [...this.occupations.data, ...this.referentialOccupations.data] : [];
        },

        occupationsTypes() {
            let types = this.shownOccupations.map(t => t.type);
            return types
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b));
        },

        occupationsList() {
            let occupations = this.shownOccupations;
            let types = this.occupationsTypes.filter(id =>
                occupations.map(occ => occ.type).includes(id)
            );

            let occupationsList = [];
            types.forEach(type => {
                occupationsList.push({
                    type: type,
                    /* SGA 31/05/2024 Le style disabled est aussi appliqué sur le group et ne peut pas être différencier */
                    // occupations: occupations.map(o => {
                    //     if (this.occupationWithOnlyOccupationSkillsWithoutSkill(o)) {
                    //         o.$isLabel = true
                    //         o.class = 'item-disabled';
                    //     }
                    //     return o;
                    // })
                    occupations: occupations
                        .filter(occ => occ.type == type)
                        // SGA 31/05/2024 Suppression de la liste des métiers avec des savoir-faire sans compétence appelée
                        .filter(occ => !this.occupationWithOnlyOccupationSkillsWithoutSkill(occ))
                        .sort((a, b) => a.title.localeCompare(b.title))
                });
            });
            return occupationsList;
        },

        userEstablishments() {
            if (this.user && this.user.establishments?.length) {
                return this.user.establishments;
            } else if (
                this.isASuperAdmin(this.user) ||
                (this.isAnAdmin(this.user) &&
                    this.user.establishments?.length == 0)
            ) {
                return this.establishments.data;
            }
            return [];
        },

        userEstablishmentsIds() {
            return this.userEstablishments.flatMap(e => e.id);
        },

        canSearch() {
            return this.filters.job != null || this.filters.occupation != null || this.filters.occupationskill != null;
        },

        disabledServices() {
            return !this.filters.job && !this.filters.occupation && !this.filters.occupationskill;
        },

        disabledEmployees() {
            return !this.filters.job && !this.filters.occupation && !this.filters.occupationskill;
        },

        disabledMineOnly() {
            return this.filters.job != null || this.filters.occupation != null;
        },

        typeDisplay() {
            let type;

            if (this.filters.occupationskill != null) {
                type = 'occupation_skill'
            } else if (this.filters.job != null) {
                type = 'job';
            } else if (this.filters.occupation != null) {
                type = 'occupation';
            }

            return type;
        },

        evaluationForChart() {

            if (!this.evaluation.data) {
                return [];
            }

            let charts = [];


            this.evaluation.data.forEach(e => {

                if (e.data.length == 0) {
                    return;
                }

                const data = e.data[0];
                const score = `${Math.round(data.accessibility * 100)}% ${data.security ? '✓' : ''}`

                let job = null;

                switch (this.typeDisplay) {
                    case 'occupation_skill' :
                        job = data.occupation_skill ? data.occupation_skill : data.admin_occupation_skill;
                        break;
                    case 'job' :
                        job = data.job;
                        break;
                    case 'occupation' :
                        job = data.occupation;
                        break;
                }

                charts.push({
                    score: score,
                    scoreNumber: data.accessibility,
                    check: data.security,
                    wishlist: data.wishlist,
                    options: this.chartOptionsWith(score, this.typeDisplay, data),
                    data: this.employeeEvaluationChartData(e.rating, data),
                    job: job
                });
            });

            charts.sort((a, b) =>
                a.scoreNumber === b.scoreNumber
                    ? b.check ? 1 : -1
                    : b.scoreNumber - a.scoreNumber
            );

            if (this.filters.securityOnly) {
                charts = charts.filter(chart => chart.check == true);
            }

            if (this.filters.wishlistOnly) {
                charts = charts.filter(chart => chart.wishlist == true);
            }

            return charts;
        },

        hasMorePage() {
            return this.evaluation.currentPage < this.evaluation.lastPage
        }
    },
    watch: {
        searchLoading(value) {
            if (value) {
                this.$vs.loading({
                    background: this.backgroundLoading,
                    color: this.colorLoading,
                    container: document.getElementById('search-button'),
                    scale: 0.45
                })
            } else {
                setTimeout(() => {
                    this.$vs.loading.close(document.getElementById('search-button'))
                }, 100)
            }
        },
    },
    methods: {
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ["services.establishment"]
                    }
                })
                .then(response => {
                    this.employees.data = response.data.data;
                    this.employees.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: {
                        perpage: 9999,
                        include: ["service", "occupation_skills"]
                    }
                })
                .then(response => {
                    let jobs = response.data.data;
                    this.jobs.data = jobs;
                    this.jobs.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        // Ajout - à l'image du store
        jobsByEstablishment(id) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const services = this.services.filter(
                service => service.establishment.id == id
            );

            const serviceIds = services.slice().map(s => s.id);
            return this.jobs.filter(j => serviceIds.includes(j.service.id));
        },

        jobsByEstablishments(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const services = this.services.filter(service =>
                ids.includes(service.establishment.id)
            );
            const serviceIds = services.slice().map(s => s.id);
            return this.jobs.filter(j => serviceIds.includes(j.service.id));
        },

        jobsByServices(ids) {
            if (this.jobs.length == 0 || this.services.length == 0) {
                return [];
            }

            const serviceIds = this.services
                .filter(s => ids.includes(s.id))
                .map(s => s.id);
            return this.jobs.filter(j => serviceIds.includes(j.service.id));
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills.adminskills",
                            "occupation_skills.skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data;
                    this.occupations.data = occupations;
                    this.occupations.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadReferentialOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations/referential",
                    params: {
                        perpage: 9999,
                        include: ["sector", "admin_occupation_skills.adminskills"]
                    }
                })
                .then(response => {
                    let referentialOccupations = response.data.data;
                    this.referentialOccupations.data = referentialOccupations;
                    this.referentialOccupations.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadOccupationskills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"]
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data;
                    this.occupation_skills.data = occupationskills;
                    this.occupation_skills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupation_skills.data.find(os => os.id == id);
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: { title: "asc" } }
                })
                .then(response => {
                    let adminOccupationskills = response.data.data;
                    this.adminOccupationSkills.data = adminOccupationskills;
                    this.adminOccupationSkills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        adminoccupationskillById(id) {
            return this.adminOccupationSkills.data.find(os => os.id == id);
        },

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        filterJobChangeHandle() {
            this.filters.occupation = null;
            this.filters.occupationskill = null;
            this.resetEvaluationData();
        },

        filterOccupationChangeHandle() {
            this.filters.job = null;
            this.filters.occupationskill = null;
            this.resetEvaluationData();
        },

        filterOccupationskillChangeHandle() {
            this.filters.employees = [];
            this.resetEvaluationData();
        },

        filterServicesChangeHandle() {
            this.filters.employees = [];
            this.resetEvaluationData();
        },

        filterEmployeesChangeHandle() {
            this.resetEvaluationData();
        },

        searchClick() {
            this.evaluation.currentPage = 0;
            this.evaluation.data = [];

            this.loadEvaluationsPage();
        },

        loadEvaluationsPage() {
            this.evaluation.currentPage++;
            let start = Date.now();
            this.searchLoading = true;
            this.evaluation.loaded = false;

            let apiMethod = null;

            if (this.typeDisplay == 'job') {
                apiMethod = 'loadEvaluationJobs';
            } else if (this.typeDisplay == 'occupation') {
                apiMethod = 'loadEvaluationOccupations';
            } else if (this.typeDisplay == 'occupation_skill') {
                apiMethod = 'loadEvaluationOccupationSkills'
            }

            this[apiMethod](this.evaluation.currentPage).then(response => {

                this.evaluation.data = this.evaluation.data.concat(response.data.data);
                this.evaluation.lastPage = response.data.last_page;
                console.info('=> Chargement accessibilité : +' + (Date.now() - start) + 'ms');
                this.searchLoading = false;
                this.evaluation.loaded = true;
            })
        },

        async loadEvaluationOccupationSkills(page = 1) {

            const employeeIds = this.filters.employees ? this.filters.employees.map(e => e.id) : -1;
            const jobId = this.filters.job ? [this.filters.job.id] : [];
            const occupationId = this.filters.occupation ? [this.filters.occupation.id] : [];
            const occupationSkillId = this.filters.occupationskill ? [this.filters.occupationskill.id] : []

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/evaluation/occupation-skills`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        employee_ids: employeeIds,
                        job_ids: jobId,
                        occupation_ids: occupationId,
                        occupation_skill_ids: occupationSkillId,
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEvaluationJobs(page = 1) {

            const employeeIds = this.filters.employees ? this.filters.employees.map(e => e.id) : -1;
            const jobId = this.filters.job ? [this.filters.job.id] : [];

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/evaluation/jobs`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        employee_ids: employeeIds,
                        job_ids: jobId,
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEvaluationOccupations(page = 1) {

            const employeeIds = this.filters.employees ? this.filters.employees.map(e => e.id) : -1;
            const occupationId = this.filters.occupation ? [this.filters.occupation.id] : [];

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/evaluation/occupations`,
                    params: {
                        sort_by: 'accessibility',
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                        service_ids: this.filters.services.map(s => s.id),
                        occupation_ids: occupationId,
                        employee_ids: employeeIds,
                        perpage: 16,
                        page: page != 1 ? page : null
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        resetEvaluationData() {
            this.evaluation.lastPage = 0;
            this.evaluation.currentPage = 0;
            this.evaluation.data = [];
            this.evaluation.rating = {}
            this.evaluation.loaded = false;
        },

        chartOptionsWith(score, type, evaluation) {
            let options = JSON.parse(JSON.stringify(this.chartOptions));
            options.scale.ticks.min = this.ratingSettings ? this.ratingSettings.RadarCenter : -1;
            let title = '';
            const ratingValue = evaluation.rating_level;
            const ratingText = ratingValue > 1 ? `(${this.ratingParams.find(param => +param.id === ratingValue)?.label})` : '';
            const actual = evaluation.actual ? " (actuel)" : "";
            const wishlist = evaluation.wishlist ? " (souhaité)" : "";

            title = evaluation.employee;

            options.title.text = `${title}${actual}${wishlist}${ratingText} : ${score}`;

            options.tooltips = {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    },
                    label: function(tooltipItem, data) {
                        const label =
                            data.datasets[tooltipItem.datasetIndex].label;
                        const value =
                            data.datasets[tooltipItem.datasetIndex].levels[
                                tooltipItem.index
                            ];
                        return ` ${label}  :  ${value}`;
                    }
                }
            };
            return options;
        },

    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.dashboard::v-deep .multiselect {
    font-size: 14px;
    color: grey;

    .multiselect__single {
        font-size: 14px;
        color: grey;
    }

    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;

        &-icon:hover {
            background: rgb(151, 47, 95);
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }

    .multiselect__select:before {
        top: 45%;
    }

    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }

    .multiselect__tag-icon::after {
        background: rgb(151, 47, 95);
    }

    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;

        &::after {
            display: none;

            &:hover {
                background: rgb(151, 47, 95);
            }
        }
    }
}

.checkboxes-container {
    padding-top: 10px;
    border-top: 1px solid $grid-gray;
    z-index: 0;
}
</style>
