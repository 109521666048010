<template>
    <div class="con-exemple-prompt" v-if="resourceLoading">
        <!-- From existing User -->
        <div v-if="!isAdminInterface() && usersList" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Utilisateur déjà existant :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-model="resource.user"
                    :options="users"
                    name="user"
                    label="display_name"
                    placeholder="Recherche ..."
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :loading="usersLoading"
                />
            </div>
        </div>
        <!-- Nom -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Nom* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-model="resource.last_name"
                    name="userLastName"
                    class="w-full"
                />
            </div>
        </div>

        <!-- Prénom -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Prénom* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-model="resource.first_name"
                    name="userFirstName"
                    class="w-full"
                />
            </div>
        </div>

        <!-- Email -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Email* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-model="resource.email"
                    name="userEmail"
                    type="email"
                    class="w-full"
                    :description-text="
                        resource.sendEmail
                            ? 'Un email d\'invitation sera envoyé automatiquement à cette adresse.'
                            : ''
                    "
                    :danger="validationHasError(formValidationErrors, 'email')"
                    :danger-text="displayValidationError(formValidationErrors, 'email')"
                    @blur="checkExistingEmailInDeleted(resource.email)"
                />
            </div>
        </div>

        <!-- Envoi email -->
        <div v-if="isASuperAdmin(user) && !reduced" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Envoi de l'email d’initialisation ?</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-switch v-model="resource.sendEmail" name="sendEmail" />
                <small
                    ><strong>Oui</strong>: un email sera envoyé automtiquement
                    au nouvel utilisateur créé. <strong>Non</strong>: vous
                    devrez envoyer l'email manuellement ultérieurement à
                    l'utilisateur créé</small
                >
            </div>
        </div>

        <!-- Role -->
        <div
            v-if="
                (!isAdminInterface() &&
                    !isAMonitor(user) &&
                    can('update', 'users') &&
                    !isEvaluatorForm()) ||
                    debug
            "
            class="vx-row mb-6"
        >
            <div class="vx-col sm:w-1/3 w-full">
                <span>Role* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-model="resource.roles[0]"
                    :options="rolesList"
                    name="roles"
                    label="display_name"
                    placeholder="Recherche ..."
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :loading="rolesLoading"
                />
            </div>
        </div>

        <!-- Etablissement -->
        <div
            v-if="
                (!isAdminInterface() &&
                    !isAMonitor(user) &&
                    can('update', 'users') &&
                    !isEvaluatorForm()) ||
                    debug
            "
            class="vx-row mb-6"
        >
            <div class="vx-col sm:w-1/3 w-full">
                <span v-if="establishmentRequired">Établissement* :</span>
                <span v-else>Établissement(s) :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-model="resource.establishments"
                    name="establishments"
                    :options="establishments"
                    :multiple="true"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="name"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :loading="establishmentsLoading"
                    @input="eventEstablishmentHandle"
                />
            </div>
        </div>

        <!-- Service(s) -->
        <div
            v-if="
                (!isAdminInterface() &&
                    !isAMonitor(user) &&
                    can('update', 'users') &&
                    !isEvaluatorForm()) ||
                    debug
            "
            class="vx-row mb-6"
        >
            <div class="vx-col sm:w-1/3 w-full">
                <span>Service(s) :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-model="resource.services"
                    name="userServices"
                    :options="shownServices"
                    :multiple="true"
                    group-values="items"
                    group-label="group"
                    :group-select="false"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label
                    select-group-label
                    selected-label
                    deselect-label
                    deselect-group-label
                    @input="eventServicesHandle"
                />
            </div>
        </div>

        <!-- Utilisateur référent -->
        <div
            v-if="
                (!isAdminInterface() &&
                    !isAMonitor(user) &&
                    can('update', 'users') &&
                    !isEvaluatorForm()) ||
                    debug
            "
            class="vx-row mb-6"
        >
            <div class="vx-col sm:w-1/3 w-full">
                <span>Utilisateur référent ?</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-switch v-model="resource.reference" name="userIsRef" />
                <small
                    >Un utilisateur référent recevra les emails du formulaire de
                    contact.</small
                >
            </div>
        </div>

        <!--Habilitation avancées -->
        <UserAuthorizationsForm
            v-if="
                (!isAdminInterface() &&
                    !isAnEvaluator(resource) &&
                    !isAMonitor(user) &&
                    !isEvaluatorForm() &&
                    can('update', 'users') &&
                    hasRole)
            "
            v-model="authorizations"
            :serviceslist="services"
            :establishments="establishments"
        />

        <!-- Paramètre de l'évaluateur externe
        v-if="
                    (!isAdminInterface() &&
                        (isAnEvaluator(resource) || isAMonitor(resource)) &&
                        can('update', 'users'))
                "
        -->
        <UserEvaluatorForm
            v-if="isEvaluatorForm() || can('update', 'users')"
            v-model="evaluatorScope"
            :establishmentsList="
                resource.establishments.length
                    ? resource.establishments
                    : establishments
            "
            :servicesList="
                resource.services.length ? resource.services : services
            "
            :evaluator="isAnEvaluator(resource)"
            :reduced="reduced"
            :reduced-for="reducedFor"
            :reduced-type="reducedType"
        />
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import UserAuthorizationsForm from "./partials/UserAuthorizationsForm.vue";
import UserEvaluatorForm from "./partials/UserEvaluatorForm.vue";

export default {
    name: "user-form",
    components: {
        Multiselect,
        UserAuthorizationsForm,
        UserEvaluatorForm
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
        userType: {
            required: true,
            type: String
        },
        usersList: {
            required: false,
            type: Boolean,
            default: false,
        },
        reduced: {
            required: false,
            type: Boolean,
            default: false,
        },
        reducedFor: {
            required: false,
        },
        reducedType: {
            required: false,
            type: String,
        },
        formValidationErrors: {
            required: false,
            type: Object,
            default() {
                return {}
            },
        },
        deletedUsersEmails: {
            required: false,
            default() {
                return []
            },
        }
    },
    data() {
        return {
            log: console.log,
            debug: false,
            resource: {},
            authorizations: {},
            evaluatorScope: {},
            roles: [],
            rolesLoading: true,
            users: [],
            usersLoading: true,
            establishments: [],
            establishmentsLoading: true,
            employees: [],
            employeesLoading: true,
            initializedResource: true
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.initResource();
        },
        resource: {
            handler(value) {
                this.value.name = value.name;
                this.value.last_name = value.last_name;
                this.value.first_name = value.first_name;
                this.value.email = value.email;
                this.value.roles = value.roles;
                this.value.establishments = value.establishments;
                this.value.services = value.services;
                this.value.sendEmail = value.sendEmail;
                this.value.reference = value.reference;
                this.value.id = value.id

                if (!this.isAdminInterface()) {
                    this.value.evaluatorScope = this.evaluatorScope;

                    if (this.hasRole && !this.isAnEvaluator(this.value)) {
                        this.value.authorizations = this.authorizations;
                    }
                }

                this.$emit("input", this.value);
            },
            deep: true
        },

        authorizations: {
            handler(value) {
                this.value.authorizations = value;
                this.$emit("input", this.value);
            },
            deep: true
        },

        hasError(value) {
            this.$emit("error", value);
        },
        "resource.roles": {
            handler(value) {
                if (this.isAdminInterface() || this.value.id) {
                    return;
                }

                if (value === "" || !value) {
                    this.resetAuths(this.type);
                    return;
                }

                if (value.name === "evaluator") {
                    this.resource.establishments = this.user.establishments
                        .length
                        ? this.user.establishments
                        : this.establishments;
                }

                this.resource.permissions = [];
                this.setAllAuthorizations(this.type);
            }
        },

        "resource.user": {
            handler(value) {
                if (this.isAdminInterface()) {
                    return;
                }

                this.resource.id = value.id
                this.resource.name = value.name;
                this.resource.last_name = value.last_name;
                this.resource.first_name = value.first_name;
                this.resource.email = value.email;
                this.resource.establishments = value.establishments
                this.resource.services = value.services
                this.resource.permissions = value.permissions
                this.resource.roles = value.roles
                this.resource.sendEmail = value.sendEmail
                this.resource.reference = value.reference
                this.setAllAuthorizations(this.type)
            }
        }
    },
    computed: {
        hasError() {
            let evalTypeError = false
            this.evaluatorScope.employees?.forEach(e => {
                if (!e.eval_types || !e.eval_types.length) {
                    evalTypeError = true
                    return;
                }
            })

            let error = evalTypeError ||
                (
                    !this.reduced ?
                    (
                        !this.resource.last_name ||
                        !this.resource.first_name ||
                        !this.resource.email ||
                        (this.resource.roles.length === 0 &&
                            !this.isAdminInterface()) ||
                        (this.establishmentRequired
                            ? !this.resource.establishments[0]
                            : false)
                    )
                    :
                    (
                        !this.resource.last_name ||
                        !this.resource.first_name ||
                        !this.resource.email
                    )
                )

            return error;
        },

        hasRole() {
            return this.resource.roles[0] ? true : false;
        },

        resourceLoading() {
            return Object.keys(this.resource).length > 0;
        },

        establishmentRequired() {
            if (
                this.isEvaluatorForm() ||
                this.resource.roles[0]?.name == "superadministrator" ||
                this.resource.roles[0]?.name == "administrator" ||
                this.resource.roles[0]?.name == "drh"
            ) {
                return false;
            } else {
                return true;
            }
        },
        establishmentNotValid() {
            if (this.establishmentRequired) {
                if (!this.resource.establishments[0]) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        },

        shownServices() {
            let toShow = [];

            if (
                this.resource.establishments &&
                this.resource.establishments.length > 0
            ) {
                this.resource.establishments.forEach(e => {
                    toShow.push({
                        group: e.name,
                        items: e.services
                    });
                });
                return toShow.sort(function(a, b) {
                    if (a.establishment > b.establishment) return 1;
                    if (a.establishment < b.establishment) return -1;
                    return 0;
                });
            }
            return toShow;
        },
        servicesList() {
            return this.groupForMultiselect(this.services, [
                "establishment.name"
            ]);
        },

        rolesList() {
            if (this.isEvaluatorForm()) {
                return this.roles.filter(r => r.name == 'evaluator');
            } else {
                return this.roles.filter(r => r.name != 'evaluator');
            }
        }
    },
    methods: {
        isEvaluatorForm() {
            return this.userType === 'Evaluateur';
        },

        eventServicesHandle(value) {

            if (this.isAdminInterface()) {
                return;
            }
            this.setAuthorizationByServices(value, this.type);
        },

        eventEstablishmentHandle(value) {
            if (this.isAdminInterface()) {
                return;
            }
            this.setAuthorizationByEstablishments(value, this.type);
        },

        initResource() {
            this.initializedResource = false;

            this.resource = {
                id: this.value.id,
                first_name: this.value.first_name,
                last_name: this.value.last_name,
                name: this.value.name,
                email: this.value.email,
                sendEmail: this.value.sendEmail ? this.value.sendEmail : true,
                roles: this.value.roles.length > 0 ? this.value.roles : (this.isEvaluatorForm() ? [this.getRoleEvaluator()] : []),
                reference: this.isAdminInterface()
                    ? ""
                    : this.value.reference === 1
                    ? true
                    : false,
                establishments: this.value.establishments
                    ? this.value.establishments
                    : [],
                services: this.value.services
                    ? this.value.services
                    : [],
            };

            this.evaluatorScope = {
                employees: [],
                eval_source: this.value.eval_source
                    ? this.value.eval_source
                    : ""
            };

            // Authorization
            if (
                !this.isAdminInterface() &&
                !this.isAnEvaluator(this.resource)
            ) {
                if (this.value.id) {

                    this.authorizations = {
                        employees: {
                            read: this.listOfServicesViewableFor(
                                "employees",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            })),
                            update: this.listOfServicesUpdatableFor(
                                "employees",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        validation: {
                            read: this.isAbleTo(
                                "read-rating_validations",
                                undefined,
                                this.value
                            ),
                            update: this.listOfServicesUpdatableFor(
                                "rating_validations",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        skill_review: {
                            read: this.isAbleTo(
                                "read-skill_reviews",
                                undefined,
                                this.value
                            ),
                            update: this.listOfServicesUpdatableFor(
                                "skill_reviews",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        skill_review_validation: {
                            read: this.isAbleTo(
                                "read-skill_review_validations",
                                undefined,
                                this.value
                            ),
                            update: this.listOfServicesUpdatableFor(
                                "skill_review_validations",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        ratings: {
                            read: this.isAbleTo(
                                "read-ratings",
                                undefined,
                                this.value
                            ),
                            update: this.listOfServicesUpdatableFor(
                                "ratings",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        jobs: {
                            read: this.listOfServicesViewableFor(
                                "jobs",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            })),
                            update: this.listOfServicesUpdatableFor(
                                "jobs",
                                this.value
                            ).map(id => ({
                                ...this.serviceById(id),
                                label:
                                    this.establishmentById(
                                        this.serviceById(id)?.establishment_id
                                    )?.name +
                                    " / " +
                                    this.serviceById(id)?.title
                            }))
                        },
                        skills: {
                            read: this.isAbleTo(
                                "read-skills",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-skills",
                                undefined,
                                this.value
                            )
                        },
                        occupation_skills: {
                            read: this.isAbleTo(
                                "read-occupation_skills",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-occupation_skills",
                                undefined,
                                this.value
                            )
                        },
                        establishments: {
                            read: this.isAbleTo(
                                "read-establishments",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-establishments",
                                undefined,
                                this.value
                            )
                        },
                        services: {
                            read: this.isAbleTo(
                                "read-services",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-services",
                                undefined,
                                this.value
                            )
                        },
                        users: {
                            read: this.isAbleTo(
                                "read-users",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-users",
                                undefined,
                                this.value
                            )
                        },
                        trainings:  {
                            read: this.isAbleTo(
                                "read-trainings",
                                undefined,
                                this.value
                            ),
                            update: this.isAbleTo(
                                "update-trainings",
                                undefined,
                                this.value
                            )
                        },
                        contact_form: {
                            read: null //TODO ??
                        }
                    };

                } else {
                    this.authorizations = {
                        employees: { read: [], update: [] },
                        validation: { read: [], update: [] },
                        skill_review: { read: [], update: [] },
                        skill_review_validation:  { read: [], update: [] },
                        ratings: { read: [], update: [] },
                        jobs: { read: [], update: [] },
                        skills: { read: [], update: [] },
                        occupation_skills: { read: [], update: [] },
                        establishments: { read: [], update: [] },
                        services: { read: [], update: [] },
                        users: { read: [], update: [] },
                        trainings: { read: [], update: [] },
                        contact_form: { read: [] }
                    };
                }
            }

            // Evaluateur
            if (this.value.updatable_employees) {
                this.evaluatorScope = {
                    employees: this.value.updatable_employees.map(employee => {
                        let userEmployee = window._.cloneDeep(employee);
                        let jobs = userEmployee?.pivot?.jobs
                            ? JSON.parse(userEmployee?.pivot?.jobs)
                            : [];
                        let occupations = userEmployee?.pivot?.occupations
                            ? JSON.parse(userEmployee?.pivot?.occupations)
                            : [];
                        let eval_types = userEmployee?.pivot?.eval_types
                            ? JSON.parse(userEmployee?.pivot?.eval_types)
                            : [];
                        employee = this.employees.find(
                            e => e.id === employee.id
                        );

                        return {
                            id: employee?.id,
                            email: employee?.email,
                            mobility: employee?.mobility,
                            mobility_id: employee?.mobility_id,
                            first_name: employee?.first_name,
                            last_name: employee?.last_name,
                            jobs: employee?.jobs,
                            occupations: employee?.occupations,
                            name: `${employee?.last_name?.toUpperCase()} ${
                                employee?.first_name
                            }`,
                            jobsToEvaluate:
                                jobs?.map(j => {
                                    return { id: j.id, title: j.title };
                                }) || [],
                            occupationsToEvaluate:
                                occupations?.map(occ => {
                                    return { id: occ.id, admin_id: (employee?.occupations?.find(o => o.id === occ.id)?.admin_id || null), title: occ.title };
                                }) || [],
                            eval_types: eval_types,
                        };
                    })
                };
            }

            this.initializedResource = true;
        },

        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ["occupations"],
                        updatable: 'true', //only ones auth user has permissions to update
                    }
                })
                .then(response => {
                    this.employees = response.data.data;
                    this.employeesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadRoles() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/roles",
                    params: {
                        perpage: 9999,
                        include: ["permissions"]
                    }
                })
                .then(response => {
                    this.roles = response.data.data;
                    this.rolesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadUsers(needed) {
            if (!needed)
                return

            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/users",
                    params: { perpage: 999, sort: "name", include: [
                            'roles.permissions',
                            'permissions',
                            'establishments.services',
                            'services',
                            'viewableEmployees',
                            'updatableEmployees',
                        ]
                    }
                })
                .then(response => {
                    let users = response.data.data
                    users.forEach(u => {
                        u.display_name = (u.roles[0].name === 'evaluator' ? 'Evaluateur':'Utilisateur')+' - '+u.first_name+' '+u.last_name+' - '+u.email
                    })
                    this.users = users
                    this.usersLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getRoleEvaluator() {
            let evaluator = this.roles.find(r => r.name == 'evaluator');

            if (!evaluator) {
                alert("Erreur - Le role `evaluator` est introuvable !! \nVeuillez contacter l'administrateur.");
                throw new Error('Le role `evaluator` est introuvable !!!');
            }

            return evaluator;
        },

        async loadEstablishments() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                    this.establishmentsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        establishmentById(id) {
            return this.establishments.find(el => el.id === id);
        },

        setAllAuthorizations(type) {
            let entity = this.resource;

            if (!this.hasRole) {
                return;
            }

            // TODO - A voir à quoi sert cette condition
            // if (!entity.email || entity.email === "") {
            //     return;
            // }

            let auths = {
                employees: {
                    read: this.listOfServicesViewableFor(
                        "employees",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    })),
                    update: this.listOfServicesUpdatableFor(
                        "employees",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    }))
                },
                validation: {
                    read: this.isAbleTo(
                        "read-rating_validations",
                        undefined,
                        entity
                    ),
                    update: this.listOfServicesUpdatableFor(
                        "rating_validations",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    }))
                },
                skill_review: {
                    read: this.isAbleTo(
                        "read-skill_reviews",
                        undefined,
                        entity
                    ),
                    update: this.listOfServicesUpdatableFor(
                        "skill_reviews",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    }))
                },
                skill_review_validation: {
                    read: this.isAbleTo(
                        "read-skill_review_validations",
                        undefined,
                        entity
                    ),
                    update: this.listOfServicesUpdatableFor(
                        "skill_review_validations",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    }))
                },
                ratings: {
                    read: this.isAbleTo(
                        "read-ratings",
                        undefined,
                        entity
                    ),
                    update: this.listOfServicesUpdatableFor(
                        "ratings",
                        entity
                    ).map(id => ({
                        ...this.serviceById(id),
                        label:
                            this.establishmentById(
                                this.serviceById(id)?.establishment_id
                            )?.name +
                            " / " +
                            this.serviceById(id)?.title
                    }))
                },
                jobs: {
                    read: this.listOfServicesViewableFor("jobs", entity).map(
                        id => ({
                            ...this.serviceById(id),
                            label:
                                this.establishmentById(
                                    this.serviceById(id)?.establishment_id
                                )?.name +
                                " / " +
                                this.serviceById(id)?.title
                        })
                    ),
                    update: this.listOfServicesUpdatableFor("jobs", entity).map(
                        id => ({
                            ...this.serviceById(id),
                            label:
                                this.establishmentById(
                                    this.serviceById(id)?.establishment_id
                                )?.name +
                                " / " +
                                this.serviceById(id)?.title
                        })
                    )
                },
                skills: {
                    read: this.isAbleTo("read-skills", undefined, entity),
                    update: this.isAbleTo("update-skills", undefined, entity)
                },
                occupation_skills: {
                    read: this.isAbleTo(
                        "read-occupation_skills",
                        undefined,
                        entity
                    ),
                    update: this.isAbleTo(
                        "update-occupation_skills",
                        undefined,
                        entity
                    )
                },
                establishments: {
                    read: this.isAbleTo(
                        "read-establishments",
                        undefined,
                        entity
                    ),
                    update: this.isAbleTo(
                        "update-establishments",
                        undefined,
                        entity
                    )
                },
                services: {
                    read: this.isAbleTo("read-services", undefined, entity),
                    update: this.isAbleTo("update-services", undefined, entity)
                },
                users: {
                    read: this.isAbleTo("read-users", undefined, entity),
                    update: this.isAbleTo("update-users", undefined, entity)
                },
                trainings: {
                    read: this.isAbleTo("read-trainings", undefined, entity),
                    update: this.isAbleTo("update-trainings", undefined, entity)
                },
                contact_form: {
                    read: null //TODO ??
                }
            };

            this.authorizations = auths;
        },

        setAuthorizationByEstablishments(establishments, type) {
            if (!establishments.length) {
                this.resource.services = [];
                return;
            }

            // Cas pour évaluateur externe (pas d'autorisation)
            if (Object.keys(this.authorizations).length === 0) {
                return;
            }

            let services = [];
            establishments.forEach(e => {
                services = services.concat(e.services);
            });

            if (services.length) {
                this.setAuthorizationByServices(services, type);
            }

        },

        setAuthorizationByServices(services, type) {
            // Cas pour évaluateur externe (pas d'autorisation)
            if (Object.keys(this.authorizations).length === 0) {
                return;
            }

            let entity = this.resource;

            // Aucun service de selectionné
            if (!services.length) {
                // Au moins un etablissement est selectionné
                if (entity.establishments.length > 0) {
                    this.setAuthorizationByEstablishments(
                        entity.establishments,
                        type
                    );
                } else {
                    this.setAllAuthorizations(type);
                }
                return;
            }

            if (!entity.roles.length) {
                return;
            }

            // TODO - A voir à quoi sert cette condition
            // if (!entity.email || entity.email === "") {
            //     return;
            // }

            let employeesRead = this.listOfServicesViewableFor(
                "employees",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.employees.read = employeesRead;

            let jobsRead = this.listOfServicesViewableFor("jobs", entity)
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.jobs.read = jobsRead;

            let employeesUpdate = this.listOfServicesUpdatableFor(
                "employees",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.employees.update = employeesUpdate;

            let jobsUpdate = this.listOfServicesUpdatableFor("jobs", entity)
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.jobs.update = jobsUpdate;

            let validationUpdate = this.listOfServicesUpdatableFor(
                "rating_validations",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.validation.update = validationUpdate;

            let skillReviewUpdate = this.listOfServicesUpdatableFor(
                "skill_reviews",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.skill_review.update = skillReviewUpdate;

            let skillReviewValidationUpdate = this.listOfServicesUpdatableFor(
                "skill_review_validations",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.skill_review_validation.update = skillReviewValidationUpdate;

            let ratingsUpdate = this.listOfServicesUpdatableFor(
                "ratings",
                entity
            )
                .filter(id => services.map(s => s.id).includes(id))
                .map(id => ({
                    ...this.serviceById(id),
                    label:
                        this.establishmentById(
                            this.serviceById(id)?.establishment_id
                        )?.name +
                        " / " +
                        this.serviceById(id)?.title
                }));

            this.authorizations.ratings.update = ratingsUpdate;
        },

        checkExistingEmailInDeleted(email) {
            if (this.deletedUsersEmails.includes(email)) {
                this.$emit("email-in-deleted", email);
            }
        }
    },
    async created() {

        this.$vs.loading();
        await Promise.all([
            this.loadUsers(this.usersList),
            this.loadRoles(),
            this.loadEstablishments(),
            this.loadEmployees()
        ]);

        this.initResource();
        this.$vs.loading.close();
    }
};
</script>

<style lang="scss" scoped>
.avatar-cropper
    .avatar-cropper-container
    .avatar-cropper-footer
    .avatar-cropper-btn:hover {
    background-color: #8d1f54;
}

.cropper-point {
    background-color: #8d1f54;
}

.cropper-line {
    background-color: #8d1f54;
}

.cropper-view-box {
    outline: 1px solid #8d1f54;
}
</style>
