var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "step",
      class: _vm.stepClass,
      style: {
        width: _vm.stepWidth + "%",
        opacity: _vm.stepOpacity,
        display: _vm.stepDisplay,
        marginLeft: _vm.stepMarginLeft
      },
      attrs: { id: "step" + _vm.id + _vm.index }
    },
    [
      _c(
        "div",
        {
          staticClass: "icon",
          style: _vm.isEndCard ? "opacity: 0.3" : "",
          on: {
            mouseover: function(e) {
              return _vm.setCardIsActive(e, true, false)
            },
            mouseleave: function(e) {
              return _vm.setCardIsActive(e, false, false)
            }
          }
        },
        [
          _c("span", { attrs: { "v-if": _vm.stepProp.cumulatedPost } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.stepProp.cumulatedPostes) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("span", { attrs: { "v-if": _vm.stepProp.cumulatedOccupation } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.stepProp.cumulatedOccupation) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.step.category !== "employee_ratings"
        ? _c(
            "div",
            {
              staticClass: "line-hover-area",
              style: {
                transform: _vm.lineTransform
              },
              on: {
                mouseover: function(e) {
                  return _vm.setCardIsActive(e, true, false)
                },
                mouseleave: function(e) {
                  return _vm.setCardIsActive(e, false, false)
                }
              }
            },
            [
              _c("div", {
                staticClass: "line",
                style: {
                  opacity: _vm.lineOpacity
                }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentJob
        ? _c(
            "div",
            {
              staticClass: "end",
              style: _vm.tooltipIsActive
                ? _vm.isEndCard
                  ? ""
                  : "opacity: 0.3"
                : "",
              on: {
                mouseover: function(e) {
                  return _vm.setCardIsActive(e, true, true)
                },
                mouseleave: function(e) {
                  return _vm.setCardIsActive(e, false, false)
                }
              }
            },
            [
              _c("span", { attrs: { "v-if": _vm.stepProp.endCumulatedPost } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.stepProp.endCumulatedPostes) +
                    "\n        "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.star
        ? _c("img", {
            attrs: { src: _vm.star, alt: "star" },
            on: {
              mouseover: function(e) {
                return _vm.setCardIsActive(e, true, false)
              },
              mouseleave: function(e) {
                return _vm.setCardIsActive(e, false, false)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-container",
          style: { top: _vm.cardTop, left: _vm.cardLeft }
        },
        [
          !_vm.cardsTimeLineIsActive && _vm.tooltipCards.length
            ? _c(
                "div",
                {
                  class: {
                    "slideInCard cards": _vm.tooltipIsActive,
                    card: !_vm.tooltipIsActive
                  },
                  attrs: { id: "card" + _vm.id + _vm.index }
                },
                _vm._l(_vm.tooltipCards, function(card, i) {
                  return _c("CareerCard", {
                    key: i,
                    attrs: {
                      "is-tooltip": true,
                      card: card,
                      "is-end-card": _vm.isEndCard
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }